/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import moment from "moment";

class PatientDetail extends Component {
    state = {
        patientForm: null,
        primaries: null,
        medRecNbr: null,
        pathText: null,
    }
    setFormData = () => {
        let dataForm = this.props.selectedPatient;

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];
            if (t === "socSecNbr" && dataVal !== null && dataVal !== "") {
                dataVal = dataVal.replace(/-/g, '');
                if (dataVal.length >= 4 && dataVal.length <= 5) {
                    dataVal = dataVal.substr(0, 3) + "-" + dataVal.substr(3, 2);
                } else {
                    dataVal = dataVal.substr(0, 3) + "-" + dataVal.substr(3, 2) + "-" + dataVal.substr(5);
                }
            } else if (t === "medicareBeneficiaryId" && dataVal !== null && dataVal !== "") {
                var formattedMBI = dataVal.replace(/[^0-9A-Za-z]/gi, '');
                formattedMBI = formattedMBI.substring(0, 11);
                if (formattedMBI.length >= 5 && formattedMBI.length <= 7) {
                    formattedMBI = formattedMBI.substring(0, 4) + "-" + formattedMBI.substring(4, 7);
                } else if (formattedMBI.length >= 8) {
                    formattedMBI = formattedMBI.substring(0, 4) + "-" + formattedMBI.substring(4, 7) + "-" + formattedMBI.substring(7);
                }
                dataVal = formattedMBI;
            }

            dataForm[t] = dataVal;
        });

        this.setState({
            patientForm: { ...dataForm },
        });

        return false;
    }

    componentDidMount() {
        this.setState({ patientForm: null, primaries: null, medRecNbr: null, pathText: null });
        /*
        if (this.props.patientId !== 0)
            this.props.selectPatientLookupView(this.props.patientId);
        */
        if (!this.state.patientForm && this.props.selectedPatient) {
            this.setFormData();
        }

        this.props.showPageLoading(true);
    }

    componentDidUpdate(prevProps) {
        //
        if ((!this.state.patientForm && this.props.selectedPatient)
            || (prevProps.selectedPatient && prevProps.selectedPatient.pid !== this.props.selectedPatient.pid)
            || (prevProps.selectedPatient && prevProps.selectedPatient !== this.props.selectedPatient)) {
            this.props.showPageLoading(true);
            this.setFormData();
            this.setState({ loaded: true });
        }

        if ((this.props.savedPatientMaitenance && !this.state.reloadPrimaryData) || this.props.selectedPatient && !this.state.primaries && !prevProps.primaries && !this.state.isLoadingPrimaries) {
            this.setState({ isLoadingPrimaries: true, reloadPrimaryData: true });
            var pm = this;
            var _params = { patientId: this.props.patientId, exactly: 1, nlp: 1, accessToken: localStorage.getItem('token') };
            axios.post(this.props.clientgroup + "/GetPrimarySummaryList", _params)
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        pm.setState({
                            primaries: res.data.primarySummaryList,
                        });
                        setTimeout(function () {
                            pm.props.showPageLoading(false);
                        }, 2000);
                    } else {
                        setTimeout(function () {
                            pm.props.showPageLoading(false);
                        }, 2000);
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }

        if (this.props.cid && this.state.patientForm && !this.state.patientForm.pathText && !this.state.getPathText) {
            this.setState({ getPathText: true });
            var pm = this;
            axios.post(this.props.clientgroup + "/GetMrnAndPathText",
                {
                    pid: this.props.pid,
                    cid: this.props.cid,
                    accessToken: localStorage.getItem('token')
                })
                .then(res => {
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error && res.data.patientDetail) {
                        var pform = pm.state.patientForm ? pm.state.patientForm : {};
                        pform["medRecNbr"] = res.data.patientDetail.medRecNbr;
                        pform["pathText"] = res.data.patientDetail.pathText;
                        pm.setState({
                            patientForm: pform,
                            medRecNbr: res.data.patientDetail.medRecNbr,
                            pathText: res.data.patientDetail.pathText
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }
    }

    render() {
        const patientDtl = this.state.patientForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid" id="patientDetailsModal">
                    <div className="row">
                        <div className="col-md-12">
                            <fieldset>
                                <legend>Patient Information</legend>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Input type="label" lineStyle="oneLine"
                                            labelWidth="95px" width="auto"
                                            labelText="Name" field="firstName"
                                            value={(this.state.patientForm.firstName ? this.state.patientForm.firstName : "") + " " + (this.state.patientForm.lastName ? this.state.patientForm.lastName : "")}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input type="label" lineStyle="oneLine"
                                            labelWidth="120px" width="auto"
                                            labelText="SSN" field="socSecNbr"
                                            value={this.state.patientForm.socSecNbr ? this.state.patientForm.socSecNbr : ""}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <Input type="label" lineStyle="oneLine"
                                            labelWidth="95px" width="auto"
                                            labelText="DOB" field="dob"
                                            value={this.state.patientForm.dob ? moment(new Date(this.state.patientForm.dob)).format("MM/DD/YYYY") : ""}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Input type="label" lineStyle="oneLine"
                                            labelWidth="45px" width="auto"
                                            labelText="Sex" field="sex"
                                            value={this.state.patientForm.sex ? this.state.patientForm.sex : ""}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input type="label" lineStyle="oneLine"
                                            labelWidth="120px" width="auto"
                                            labelText="Current Address" field="currAdd"
                                            value={this.state.patientForm.currAdd ? this.state.patientForm.currAdd : ""}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <Input type="label" lineStyle="oneLine"
                                            labelWidth="95px" width="auto"
                                            labelText="MBI" field="medicareBeneficiaryId"
                                            value={this.state.patientForm.medicareBeneficiaryId}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                    </div>
                                    <div className="col-md-6">
                                        <Input type="label" lineStyle="oneLine"
                                            labelWidth="120px" width="auto"
                                            labelText="Email" field="emailAddress"
                                            value={this.state.patientForm.emailAddress ? this.state.patientForm.emailAddress : ""}
                                        />
                                    </div>
                                </div>

                                <div className="emptyLine"></div>

                                <div className="primaryListArea">
                                    <div className="innerHeader" style={{ width: "680px" }}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="S/A" field="abstSunaFlag" /></th>
                                                    <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="Flag" field="stateRpt" /></th>
                                                    <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Site" field="siteCode" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Seq" field="seqPrim" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Hosp" field="hosp" /></th>
                                                    <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Class" field="class" /></th>
                                                    <th style={{ width: "112px" }}>
                                                        <Input type="gridHeader" labelWidth="auto" labelText="Diagnosis Date" field="diagnosisDate" />
                                                    </th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Topo" field="topoCode" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Lat" field="latCode" /></th>
                                                    <th style={{ width: "52px" }}><Input type="gridHeader" labelWidth="auto" labelText="Hist" field="histCode" /></th>
                                                    <th style={{ width: "82px" }}><Input type="gridHeader" labelWidth="auto" labelText="MRN" field="primSurgeon" /></th>
                                                    <th style={{ width: "112px" }}><Input type="gridHeader" labelWidth="auto" labelText="Accession Number" field="accNbr" /></th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                <tr>
                                                    <td colSpan="12">&nbsp;</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="innerBody" style={{ height: "85px", overflow: "auto" }}>
                                        <table id="patientDetails_primaries_tbl">
                                            <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                <tr>
                                                    <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="S/A" field="abstSunaFlag" /></th>
                                                    <th style={{ width: "43px" }}><Input type="gridHeader" labelWidth="auto" labelText="Flag" field="stateRpt" /></th>
                                                    <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Site" field="siteCode" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Seq" field="seqPrim" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Hosp" field="hosp" /></th>
                                                    <th style={{ width: "47px" }}><Input type="gridHeader" labelWidth="auto" labelText="Class" field="class" /></th>
                                                    <th style={{ width: "112px" }}>
                                                        <Input type="gridHeader" labelWidth="auto" labelText="Diagnosis Date" field="diagnosisDate" />
                                                    </th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Topo" field="topoCode" /></th>
                                                    <th style={{ width: "32px" }}><Input type="gridHeader" labelWidth="auto" labelText="Lat" field="latCode" /></th>
                                                    <th style={{ width: "52px" }}><Input type="gridHeader" labelWidth="auto" labelText="Hist" field="histCode" /></th>
                                                    <th style={{ width: "82px" }}><Input type="gridHeader" labelWidth="auto" labelText="MRN" field="primSurgeon" /></th>
                                                    <th style={{ width: "112px" }}><Input type="gridHeader" labelWidth="auto" labelText="Accession Number" field="accNbr" /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.primaries !== null && this.state.primaries.length > 0
                                                    ? this.state.primaries.map((prm, index) => (
                                                        <tr key={"data-key-" + index}>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"abstSunaFlag" + index} rowIndex={index} labelMainStyle="tblCell"
                                                                    labelText="" field="abstSunaFlag" value={prm.abstSunaFlag ? prm.abstSunaFlag : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"stateRpt" + index} rowIndex={index}
                                                                    labelText="" field="stateRpt" value={prm.stateRpt ? prm.stateRpt : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"siteCode" + index} rowIndex={index}
                                                                    labelText="" field="siteCode" value={prm.siteCode ? prm.siteCode : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"seqPrim" + index} rowIndex={index}
                                                                    labelText="" field="seqPrim" value={prm.seqPrim ? prm.seqPrim : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"hosp" + index} rowIndex={index}
                                                                    labelText="" field="hosp" value={prm.hosp ? prm.hosp : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"class" + index} rowIndex={index}
                                                                    labelText="" field="class" value={prm.class ? prm.class : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"diagnosisDate" + index} rowIndex={index}
                                                                    labelText="" field="diagnosisDate"
                                                                    value={prm.diagnosisDate != null && prm.diagnosisDate !== "" ? moment(new Date(prm.diagnosisDate)).format("MM/DD/YYYY") : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"topoCode" + index} rowIndex={index}
                                                                    labelText="" field="topoCode" value={prm.topoCode ? prm.topoCode : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"latCode" + index} rowIndex={index}
                                                                    labelText="" field="latCode" value={prm.latCode ? prm.latCode : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"histCode" + index} rowIndex={index}
                                                                    labelText="" field="histCode" value={prm.histCode ? prm.histCode : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"medRecNbr" + index} rowIndex={index}
                                                                    labelText="" field="medRecNbr" value={prm.medRecNbr ? prm.medRecNbr : ""}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input type="label" labelWidth="0px" width="90px" lineStyle="oneLine"
                                                                    keyId={"accNbr" + index} rowIndex={index}
                                                                    labelText="" field="accNbr" value={prm.accNbr ? prm.accNbr : ""}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )) : <tr><td colSpan="12">No records available</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="emptyLine"></div>
                            </fieldset>
                        </div>
                    </div>

                    <div className="emptyLine"></div>

                    <div className="row hidden">
                        <div className="col">
                            <Input type="textarea" readOnly={true} tabIndex="-1"
                                labelText="Suspense Text" labelWidth="auto" field="suspenseRemarks"
                                value={this.state.patientForm.suspenseRemarks}
                                rows="3" normalTaField={true}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <Input type="textarea" readOnly={true} tabIndex="-1"
                                labelText="Pathology Text" labelWidth="auto" field="pathText"
                                value={this.state.pathText ? this.state.pathText : this.state.patientForm.pathText}
                                rows="9" normalTaField={true}
                            />
                        </div>
                    </div>

                    <div className="emptyLine"></div>
                </div>
            )
            : <p className="data_loading_page">Patient Detail data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {patientDtl}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PatientDetail.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedPatientOnGrid: state.patientAbstract.selectedPatientOnGrid,
        selectedPatient: state.patientAbstract.selectedPatient,
        isPatientLoading: state.patientAbstract.isPatientLoading,
        patientId: state.patientAbstract.patientId,
        isFormDirty: state.patientAbstract.isFormDirty,
        //
        clientgroup: state.auth.clientgroup,
        userId: state.auth.userId,
        securityGroup: state.auth.securityGroup,
        savedPatientMaitenance: state.patientAbstract.savedPatientMaitenance,
        //
        isInquiringMode: state.auth.inquiringMode,//FN_InquiryMode
        inquiringMode: state.auth.inquiringMode,
        alphaUrl: window.location ? window.location.pathname : "",
        availableHospsData: state.auth.availableHospsData,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        savePatient: (patientData, isAddNew, patientId, moveToNextPage) => dispatch(actionCreators.savePatient(patientData, isAddNew, patientId, moveToNextPage)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        //selectPatientLookupView: (patientId) => dispatch(actionCreators.selectPatientLookupView(patientId)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);