/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Logo from '../../components/UI/Logo/Logo';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import classes from './Auth.css';
import * as actions from '../../store/actions/index';
import * as dialogTypes from "../../components/AbstractManagement/maintDialogs";
import { actionCreators } from "../../store/PatientAbstract";
import axios from "../../axios-instance";
import ConfigData from '../../store/config.json';
import OktaAuthHome from './OktaAuthHome';
import { withOktaAuth } from '@okta/okta-react';
class Auth extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        controls: {
            email: {
                elementType: 'input',
                label: 'User ID',
                elementConfig: {
                    type: 'email',
                    placeholder: 'User ID'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false,
                autoFocus: true
            },
            password: {
                elementType: 'input',
                label: 'Password',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        },
        isSignup: true,
        noticeMsg: null,
        checkExistedMessageInHtml: false,
        existedHtmlFile: false,
        noticeMsgHtmlFile: null,
        clearCommonFlg: false,
        limitedNo: 0,
    }

    // Function to clear complete cache data
    clearCacheData = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('userId');
        localStorage.removeItem('crstar_user');
        localStorage.removeItem('user_hasChangePass');
        localStorage.removeItem('identificationSystemAlive');
    };

    checkExistedMessageInHtmlFnc(flag) {
        this.setState({ checkExistedMessageInHtml: true });

        if (flag) {
            var iframeParent = document.querySelector('#iFrmLoginTopPageMessage');
            const iframe = document.createElement("iframe");
            iframe.src = '/LoginTopPageMessage.html';
            iframeParent.appendChild(iframe);
        }
        return false;
    }

    /**/
    componentDidMount() {
        if (!this.state.noticeMsg) {
            let link = document.baseURI + 'LoginTopPageMessage.html';
            let url = "ers/GetNoticeLoginMessage?url=" + link;
            var _this = this;
            axios.post(url, { url: link, accessToken: localStorage.getItem('token') })
                .then(function (response) {
                    var noticeMsg = response && response.data ? response.data.noticeMsg : "";
                    var existedHtmlFile = response && response.data ? response.data.existedHtmlFile : false;
                    var noticeMsgHtmlFile = response && response.data ? response.data.noticeMsgHtmlFile : null;
                    _this.setState({ noticeMsg: noticeMsg, existedHtmlFile: existedHtmlFile, noticeMsgHtmlFile: noticeMsgHtmlFile });
                    return;
                })
                .catch(function (error) {
                    console.log(error);
                    _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                });
        }

        this.clearCacheData();

        if (!this.props.isAuthenticated && this.props.authRedirectPath !== '/login') {
            this.props.onSetAuthRedirectPath('/login');
        }
        else this.props.onSetAuthRedirectPath(this.props.location.pathname);

        //---
        this.props.setTab(dialogTypes.LOGIN);
        this.props.setTwoFactorAuthDialogVisible(true);
        // clear all previous session
        this.props.resetAllStoringOfUser();
        //---

        //make sure it's shown change password form if passed
        this.props.setChangePwdDialogVisible(false);
    }

    componentDidUpdate() {
        if (!this.state.noticeMsg && this.state.limitedNo < 10) {
            let link = document.baseURI + 'LoginTopPageMessage.html';
            let url = "ers/GetNoticeLoginMessage?url=" + link;
            var _this = this;
            axios.post(url, { url: link, accessToken: localStorage.getItem('token') })
                .then(function (response) {
                    var noticeMsg = response && response.data ? response.data.noticeMsg : "";
                    var existedHtmlFile = response && response.data ? response.data.existedHtmlFile : false;
                    var noticeMsgHtmlFile = response && response.data ? response.data.noticeMsgHtmlFile : null;
                    _this.setState({
                        noticeMsg: noticeMsg,
                        existedHtmlFile: existedHtmlFile,
                        noticeMsgHtmlFile: noticeMsgHtmlFile,
                        limitedNo: _this.state.limitedNo ? _this.state.limitedNo + 1 : 1
                    });
                    return;
                })
                .catch(function (error) {
                    console.log(error);
                    _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                });
        }

        if (!this.state.iFrmLoginTopPageMessage && this.state.noticeMsgHtmlFile) {
            var noticeMsgHtmlFile = this.state.noticeMsgHtmlFile;
            var iframeParent = document.getElementById('iFrmLoginTopPageMessage');
            iframeParent.innerHTML = noticeMsgHtmlFile;

            this.setState({ iFrmLoginTopPageMessage: true, noticeMsgHtmlFile: null });
        }

        if (!this.state.clearCommonFlg) {
            //make sure it's shown change password form if passed
            this.props.setChangePwdDialogVisible(false);
            this.setState({ clearCommonFlg: true });
        }
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid;
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid;
        }

        return isValid;
    }

    inputChangedHandler = (event, controlName) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity(event.target.value, this.state.controls[controlName].validation),
                touched: true
            }
        };
        this.setState({ controls: updatedControls });
    }

    clearInputForm = (event) => {
        event.preventDefault();
        const updatedControls = {
            ...this.state.controls
        };
        updatedControls.email.value = '';
        updatedControls.password.value = '';
        this.setState({ controls: updatedControls });
    }

    submitHandler = (event) => {
        event.preventDefault();
        try {
            this.props.onAuth(this.state.controls.email.value, this.state.controls.password.value, this.state.isSignup);
            this.props.setCurrentDialog(dialogTypes.LOOKUP);
            //make sure it's shown change password form if passed
            this.props.setChangePwdDialogVisible(false);
        } catch (ex) {}
        return false;
    }

    _getCookie(cookiename) {
        var cookiestring = document.cookie;
        var cookiearray = cookiestring.split(';');
        for (var i = 0; i < cookiearray.length; ++i) {
            if (cookiearray[i].trim().match('^' + cookiename + '=')) {
                return cookiearray[i].replace(`${cookiename}=`, '').trim();
            }
        }
        return null;
    }

    _reloadPage = (event) => {
        event.preventDefault();

        const oktaAuthFailed = sessionStorage.getItem('oktaAuthFailed');
        if (oktaAuthFailed) {
            const oktaUser = sessionStorage.getItem('oktaLoggedOnUser');
            if (oktaUser) {
                let url = "/ers/OktaLoginCallback";
                let params = { email: oktaUser };

                axios.post(url, params)
                    .then(response => {
                        if (!response.data.error) {
                            sessionStorage.removeItem('CrstarLogout');
                            sessionStorage.removeItem('oktaAuthFailed');
                            window.location.href = window.location.origin;
                            return true;
                        } else {
                            alert(response.data.message);
                            return false;
                        }
                    });
            }
            return false;
        }

        sessionStorage.removeItem('CrstarLogout');
        window.location.href = window.location.origin;
        return true;
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.controls) {
            formElementsArray.push({
                id: key,
                config: this.state.controls[key]
            });
        }

        let form = formElementsArray.map(formElement => (
            <div key={"loginForm_" + formElement.id}>
                <Input key={formElement.id}
                    label={formElement.config.label}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    invalid={!formElement.config.valid}
                    shouldValidate={formElement.config.validation}
                    touched={formElement.config.touched}
                    autoFocus={formElement.config.autoFocus}
                    changed={(event) => this.inputChangedHandler(event, formElement.id)} />
            </div>
        ));

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p className="AuthInvalidData">{this.props.error}</p>
            );
        }

        let authRedirect = null;
        if (this.props.isAuthenticated && this.props.authRedirectPath !== '/') {
            console.log('[Auth:]', 'path=' + this.props.authRedirectPath)
            authRedirect = <Redirect to={this.props.authRedirectPath} />;
        }

        const crstarLoggedOut = sessionStorage.getItem('CrstarLogout');
        const oktaAuthFailed = sessionStorage.getItem('oktaAuthFailed');
        if (this.props.authState && this.props.authState.isAuthenticated && !crstarLoggedOut && !oktaAuthFailed) {
            window.location.href = window.location.origin + "/ok2callback";
            return true;
        }

        return (
            <div className="LoginLayout">

                <Logo />

                <fieldset className="fieldset">
                    <div className={classes.Auth}>
                        {authRedirect}
                        {errorMessage}
                        <form onSubmit={this.submitHandler}>
                            {form}
                            <div className="btnArea">
                                <Button btnType="Success">Login</Button>
                                <Button btnType="Success" clicked={this.clearInputForm}>Clear</Button>
                                <OktaAuthHome />
                            </div>
                        </form>
                    </div>

                    <div style={{ textAlign: "center" }}>
                        <Link className="text-dark forgot_password" to="/change-password">Change Your Password</Link>
                    </div>

                </fieldset>

                <div className="emptyLine"></div>
                <div className="container" id="VersionMsg">
                    {/* {this.state.noticeMsg ? this.state.noticeMsg : ""} */}
                    CRStar Version {ConfigData.CRStarVersion}  build {ConfigData.CRStarBuild}
                </div>

                <div className="emptyLine"></div>
                <div className="container" id="_iFrmLoginTopPageMessage_">
                    <p align="center" id="iFrmLoginTopPageMessage"></p>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password, isSignup) => dispatch(actions.auth(email, password, isSignup)),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
        setCurrentDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChangePwdDialogVisible: (flag) => dispatch(actionCreators.setChangePwdDialogVisible(flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setTwoFactorAuthDialogVisible: (flg) => dispatch(actionCreators.setTwoFactorAuthDialogVisible(flg)),
        resetAllStoringOfUser: () => dispatch(actionCreators.resetAllStoringOfUser()),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        oktaLoginSuccess: (userInfo) => dispatch(actions.oktaLoginSuccess(userInfo)),
    };
};

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(Auth));
