/* eslint-disable */
import React, { Component } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import ConfigData from '../../store/config.json';
import { connect } from "react-redux";
import { formatDate } from '@telerik/kendo-intl';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import axios from "axios";
import { actionCreators } from "../../store/PatientAbstract";
import Draggable from 'react-draggable';

class KendoGridDateCell extends React.Component {
    render() {
        const value = this.props.dataItem[this.props.field];
        return (
            <td>
                {value ? formatDate(new Date(value), "MM/dd/yyyy") : ""}
            </td>
        );
    }
}

class CommonLookup extends Component {

    state = {
        codes: [],
        isLoading: false,
        filter: {
            logic: "",
            filters: []
        },
        filterOperators: {
            'text': [
                { text: 'grid.filterContainsOperator', operator: 'contains' }
            ],
        },
        sort: [],
        total: 0,
        skip: 0,
        take: 50,

        enterKeyPressed: false,
        shiftKeyPressed: false,
        tabKeyPressed: false,
        focusedItem: null,
        filterIndexNo: 0,

        titleDialog: null,
        focusedItemValue: null,
    }
    resetPageNumber = false;

    addEventListener = () => {
        var cl = this;
        var currentLookupModal = document.querySelector('#commonLookup_' + cl.props.field);
        currentLookupModal.addEventListener('keydown', function (e) {
            if (e.keyCode == 40) {// down arrow /TAB
                var filterInputFocusing = e.target.getAttribute("class") === "k-input-inner" || e.target.getAttribute("class") === "k-table-th" ? true : false;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (filterInputFocusing && trs && trs[0] && trs[0].firstChild) {
                    trs[0].firstChild.setAttribute('tabindex', '0');
                    trs[0].firstChild.focus();

                    setTimeout(function () {
                        trs[0].scrollIntoView();
                        trs[0].scrollTop = 0;
                    }, 0);

                    let currentClasses = trs[0].getAttribute("class");
                    if (currentClasses && currentClasses.indexOf('k-selected') == -1)
                        trs[0].setAttribute("class", currentClasses + " k-selected");
                }

                var itemInRowFocusing = e.target.getAttribute("class") === "k-table-td" ? true : false;
                if (itemInRowFocusing && e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    let currentClasses = e.target.parentNode.getAttribute("class");
                    if (currentClasses && currentClasses.indexOf('k-selected') != -1)
                        e.target.parentNode.setAttribute("class", currentClasses.replace("k-selected", ""));
                }

            } else if (e.key === "Tab" || (e.key === "Shift" && e.keyCode == 9) || (e.key === "Tab" && e.shiftKey)) {// shift + tab
                if (e.key === "Tab" && e.shiftKey) {
                    if (e.target.nodeName === "BUTTON") {
                        var inputFitler = currentLookupModal.querySelectorAll('input') && currentLookupModal.querySelectorAll('input').length > 0 ? currentLookupModal.querySelectorAll('input') : null;
                        if (inputFitler) {
                            setTimeout(function () {
                                inputFitler[inputFitler.length - 1].focus();
                            }, 0);
                        } else {
                            setTimeout(function () {
                                currentLookupModal.querySelectorAll('input') && currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";
                            }, 0);
                        }
                        return false;
                    } else if (e.target.nodeName === "TH" || (e.target.nodeName === "INPUT" && e.target.getAttribute('class') === "k-input-inner")) {//Don't allow press shift+tab on these filter input fields
                        //
                        if (e.target.nodeName === "TH") {
                            // should set focus into button
                            e.preventDefault();
                            return false;
                        }
                        // @TODO - ???
                        else if (!e.target.parentNode.parentNode.parentNode.parentNode.previousSibling || e.target.parentNode.parentNode.parentNode.parentNode.previousSibling === null) {
                            var btnCancel = currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button').length > 0 ? currentLookupModal.querySelectorAll('button') : null;
                            if (btnCancel) {
                                setTimeout(function () {
                                    btnCancel[btnCancel.length - 1] ? btnCancel[btnCancel.length - 1].focus() : "";
                                }, 0);
                            } else {
                                setTimeout(function () {
                                    currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button')[0] ? currentLookupModal.querySelectorAll('button')[0].focus() : "";
                                }, 0);
                            }
                        } else {
                            setTimeout(function () {
                                e.target.parentNode.parentNode.parentNode.parentNode.previousSibling.querySelector('input') ? e.target.parentNode.parentNode.parentNode.parentNode.previousSibling.querySelector('input').focus() : "";
                            }, 0);
                        }
                    }
                } else {
                    if (e.target.nodeName === "BUTTON") {
                        setTimeout(function () {
                            currentLookupModal.querySelectorAll('input')[0] ? currentLookupModal.querySelectorAll('input')[0].focus() : "";
                        }, 0);
                    } else if (e.target.nodeName === "INPUT") {
                        if (!e.target.parentNode.parentNode.parentNode.parentNode.nextSibling || e.target.parentNode.parentNode.parentNode.parentNode.nextSibling === null) {
                            var btnCancel = currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button').length > 0 ? currentLookupModal.querySelectorAll('button') : null;
                            if (btnCancel) {
                                setTimeout(function () {
                                    btnCancel[btnCancel.length - 1] ? btnCancel[btnCancel.length - 1].focus() : "";
                                }, 0);
                            } else {
                                setTimeout(function () {
                                    currentLookupModal.querySelectorAll('button') && currentLookupModal.querySelectorAll('button')[0] ? currentLookupModal.querySelectorAll('button')[0].focus() : "";
                                }, 0);
                            }
                        } else {
                            //
                            setTimeout(function () {
                                e.target.parentNode.parentNode.parentNode.parentNode.nextSibling.querySelector('input') ? e.target.parentNode.parentNode.parentNode.parentNode.nextSibling.querySelector('input').focus() : "";
                            }, 0);
                        }
                    }
                }
            } else if (e.keyCode == 38) {// up arrow
                var pagingLinkFocusing = e.target.getAttribute("class") ? (e.target.getAttribute("class").indexOf("k-link") !== -1 || e.target.getAttribute("class").indexOf("k-button") !== -1 ? true : false) : false;
                let lastItemOnGrid = currentLookupModal.querySelector(".k-grid-table") &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild &&
                    currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount ? currentLookupModal.querySelector(".k-grid-table").lastChild.childElementCount : 10;
                lastItemOnGrid = lastItemOnGrid - 1;

                var trs = currentLookupModal.getElementsByClassName("k-master-row");
                if (pagingLinkFocusing) {
                    for (var len = 0; len < trs.length; len++) {
                        trs[len] ? trs[len].setAttribute("class", len % 2 == 0 ? "k-master-row" : "k-master-row k-alt") : "";
                    }

                    if (trs && trs[lastItemOnGrid] && trs[lastItemOnGrid].firstChild) {
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.setAttribute('tabindex', '0') : "";
                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].firstChild.focus() : "";

                        trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt k-selected") : "";

                        setTimeout(function () {
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollIntoView() : "";
                            trs[lastItemOnGrid] ? trs[lastItemOnGrid].scrollTop = 10000 : "";//scroll to bottom of grid
                        }, 0);
                    }
                } else if (e.target.parentNode.getAttribute("data-grid-row-index") === "0") {
                    trs[0] ? trs[0].setAttribute("class", "k-master-row") : "";
                } else {
                    trs[lastItemOnGrid] ? trs[lastItemOnGrid].setAttribute("class", "k-master-row k-alt") : "";
                }

            } else if (e.keyCode == 13 && e.target.getAttribute("class") === "k-input-inner") {//enter
                !cl.enterKeyPressed ? cl.setState({
                    enterKeyPressed: true,
                    focusedItem: e.target,
                    filterIndexNo: e.target.getAttribute("data-index")
                }) : "";
                cl.props.setPageLoaded(false);
                e.preventDefault();
                return false;
            } else {
                cl.setState({ enterKeyPressed: false, focusedItem: null });
            }
        });
    }

    init = () => {
        var currentLookupModal = document.getElementById("commonLookup_" + this.props.field);
        var cl = this;

        let table = cl.props.dataSource;
        if (table === "CstageLookupView") {
            var sInVal = setInterval(function () {
                let titleDialog = cl.state.titleDialog;
                let pTitleDialog = cl.props.titleDialog;
                if ((pTitleDialog !== undefined && pTitleDialog !== "") ||
                    (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\"))) {
                    clearInterval(sInVal);
                } else {
                    // show loading... |/--\| with disabled items
                    switch (titleDialog) {
                        case "|":
                            titleDialog = "/";
                            break;
                        case "/":
                            titleDialog = "--";
                            break;
                        case "--":
                            titleDialog = "\\";
                            break;
                        case "\\":
                            titleDialog = "|";
                            break;
                        default:
                            titleDialog = "|";
                            break;
                    }
                    cl.setState({ titleDialog: titleDialog });
                }
            }, 250);
        }

        var filterIndexNo = cl.state.filterIndexNo;
        if (currentLookupModal && currentLookupModal.querySelectorAll(".k-input-inner")) {
            var inputs = currentLookupModal.querySelectorAll(".k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                input.setAttribute("id", "hosp-lookup_filter-k-textbox-" + idx);
                input.setAttribute("data-index", idx);
                input.setAttribute("disabled", true);

                input.addEventListener('focus', function (e) {
                    cl.setState({ focusedItem: input });
                });
            }
        }

        // Don't set focus into first item if it's shown as dropdown
        if (cl.props.shownType == "dropdown") {
            return false;
        }

        setTimeout(function () {
            if (cl.state.focusedItem && document.getElementById(cl.state.focusedItem)) {
                document.getElementById("hosp-lookup_filter-k-textbox-" + filterIndexNo) ? document.getElementById("hosp-lookup_filter-k-textbox-" + filterIndexNo).focus() : "";
            } else if (filterIndexNo > 0) {
                document.getElementById("hosp-lookup_filter-k-textbox-" + filterIndexNo) ? document.getElementById("hosp-lookup_filter-k-textbox-" + filterIndexNo).focus() : "";
            } else {
                currentLookupModal && currentLookupModal.querySelector(".k-input-inner") ? currentLookupModal.querySelector(".k-input-inner").focus() : "";
            }
            return false;
        }, 0);

        if (filterIndexNo > 0) {
            document.getElementById("phys-lookup_filter-k-textbox-" + filterIndexNo) ? document.getElementById("phys-lookup_filter-k-textbox-" + filterIndexNo).focus() : "";
            setTimeout(function () {
                document.getElementById("phys-lookup_filter-k-textbox-" + filterIndexNo) ? document.getElementById("phys-lookup_filter-k-textbox-" + filterIndexNo).focus() : "";
            }, 0);
        }        
    }

    enabledAllFilter() {
        var currentLookupModal = document.getElementById("commonLookup_" + this.props.field);
        if (currentLookupModal && currentLookupModal.querySelectorAll(".k-input-inner")) {
            var inputs = currentLookupModal.querySelectorAll(".k-input-inner");
            for (var idx = 0; idx < inputs.length; idx++) {
                let input = inputs[idx];
                input.removeAttribute("disabled");
                idx == 0 ? input.focus() : null;
            }
        }
    }

    componentDidMount() {
        // Fixing item #PR00111
        this.addEventListener();

        this.props.setPageLoaded(false);
        this.props.setChildrenOpening(true);
        this.props.clearErrorMessage();

        this.init();

        var _val = this.props.values;
        this.setState({ focusedItemValue: _val });

        let table = this.props.dataSource;
        if (table === undefined || table === "") {
            return false;
        }

        if (table === "Icd9_Codes" || table === "Icd10_Codes") {
            this.getCommonLookupList();
            return false;

        } else if (table === "CstageLookupView" /*&& this.props.stagingPre2018*/) {//StagingPre2018 screen
            var code = this.props.aliasNo !== undefined ? this.props.aliasNo : "0";
            //let flag = this.props.flag !== undefined ? this.props.flag : "";

            //var cid = this.props.primKey !== undefined ? this.props.primKey : "0";
            var cid = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : "0";
            if (cid === null || cid === undefined || cid === "") cid = "0";

            // get and set title dialog
            var url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetCstageLookupView";

            var _params = { code: code, primarySummaryId: cid, accessToken: localStorage.getItem('token') };

            this.setTitleDialog(url, _params);
            var _this = this;
            axios.post(url, { code: code, primarySummaryId: cid, accessToken: localStorage.getItem('token') })
                //.then(res => res.json())
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    _this.enabledAllFilter();
                    return res.data && res.data.commonLookupList ? this.setState({ codes: res.data.commonLookupList }) : "";
                });

        } else if (table === "SiteApprCodesPopup") {//Retired Maintenance screen
            var code = this.props.aliasNo !== undefined ? this.props.aliasNo : "0";

            var cid = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : "0";
            if (cid === null || cid === undefined || cid === "") cid = "0";

            // get and set title dialog
            var url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetSiteApprCodesView";
            var _params = { code: code, primarySummaryId: cid, accessToken: localStorage.getItem('token') };
            var _this = this;
            axios.post(url, _params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    _this.enabledAllFilter();
                    return res.data && res.data.commonLookupList ? this.setState({ codes: res.data.commonLookupList }) : "";
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        } else if (table === "Morpho_Codes") {
            this.getCommonLookupListWithPaging(table, true);
            return false;
        } else {
            let code = this.props.aliasNo !== undefined ? this.props.aliasNo : "0";
            let flag = this.props.flag !== undefined ? this.props.flag : "";
            let codeLen = this.props.codeLength ? this.props.codeLength : "";
            let _filterBy = this.props.filterBy ? this.props.filterBy : "";

            //Fixing #PR00365
            if (table == "County_Codes") {
                if (document.getElementById("ReferStateOfSelectedHospital")) {
                    flag = document.getElementById("ReferStateOfSelectedHospital").value;
                }

                //ZipCodes screen
                if (document.getElementById("Tabid_Zip_Codes") && document.getElementById("Tabid_Zip_Codes").getAttribute("class").indexOf("active") != -1) {
                    flag = this.props.flag !== undefined ? this.props.flag : "";
                }
            }

            // Only used for Staging page
            var _this = this;
            var _filterAutoByGroupTNM = null;
            var url = ConfigData.CRStarAPI_URL + (table === "Client_Group" ? "ers" : this.props.clientgroup) + "/GetCommonLookupView";

            var _params = { table: table, code: code, flag: flag, len: codeLen, filterBy: _filterBy, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup };

            if (table === "Tnm_Lookup8th_Mv" || table === "Tnm_Html") {
                let edition = document.getElementById("tnmEdNbr") && document.getElementById("tnmEdNbr").value != "" ? document.getElementById("tnmEdNbr").value : "08";
                url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetCommonLookupViewByTnm9Dll";
                _params = { code: code, flag: flag, edition: edition, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup };

                //#PR00620: 3.For Breast and Prostate, filter grp popup by entered t,n,m and grade values. The filtered values should be visible in the search fields at top of columns.
                if (_this.props.schemaId == "Breast" || _this.props.schemaId == "Prostate") {
                    var filterT = _this.props.t ? _this.props.t : "";
                    var filterN = _this.props.n ? _this.props.n : "";
                    var filterM = _this.props.m ? _this.props.m : "";

                    filterT = filterT.replace("p", "").replace("c", "").replace("y", "").replace("T","");
                    filterN = filterN.replace("p", "").replace("c", "").replace("y", "").replace("N","");
                    filterM = filterM.replace("p", "").replace("c", "").replace("y", "").replace("M","");

                    const t = filterT;
                    const n = filterN;
                    const m = filterM;
                    
                    var _filters = [];
                    var iNo = 0;
                    if (t) {
                        //url += "&fieldExt2=" + encodeURIComponent("%" + t + "%");
                        _params["fieldExt2"] = "%" + t + "%";

                        _filters[iNo] = { field: "fieldExt2", operator: "contains", value: t };
                        iNo++;
                    }
                    if (n) {
                        //url += "&fieldExt3=" + encodeURIComponent("%" + n + "%");
                        _params["fieldExt3"] = "%" + n + "%";

                        _filters[iNo] = { field: "fieldExt3", operator: "contains", value: n };
                        iNo++;
                    }
                    if (m) {
                        //url += "&fieldExt4=" + encodeURIComponent("%" + m + "%");
                        _params["fieldExt4"] = "%" + m + "%";

                        _filters[iNo] = { field: "fieldExt4", operator: "contains", value: m };
                        iNo++;
                    }
                    _filterAutoByGroupTNM = { logic: "and", filters: _filters };
                }
            }

            //
            if (this.props.shownType == "dropdown") {
                return false;
                /*
                const idValue = this.props.values ? this.props.values : "";
                //url += "&idValue=" + idValue;
                _params["idValue"] = idValue;

                url = url.replace("GetCommonLookupView", "GetCommonDropdownView");

                console.log("[1]::GetCommonDropdownView::", url);
                */
            }

            axios.post(url, _params)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    _this.enabledAllFilter();
                    if (res && res.data && res.data.StatusCode == "408") {
                        console.log(res.data.ErrorMessage);
                        _this.props.showSessionTimeoutMsg(true);
                    }
                    if (table === "Tnm_Lookup8th_Mv" && res.data.commonLookupList && res.data.commonLookupList.length > 0) {
                        var commonLookupList = res.data.commonLookupList;
                        var header = res.data.headerGrid;
                        var codesList = commonLookupList;

                        /* Set up tooltip for header's column */
                        setTimeout(function () {
                            _this.setTitleForHeader();
                        }, 1000);

                        if (_filterAutoByGroupTNM) {
                            return _this.setState({ filter: _filterAutoByGroupTNM, header: header, codes: codesList });
                        } else {
                            return _this.setState({ header: header, codes: codesList });
                        }
                    } else {
                        return _this.setState({ codes: res.data.commonLookupList });
                    }
                })
                .catch(function (error) {
                    console.log(error);

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }
    }

    setTitleForHeader() {
        var headerOnGrid = document.querySelectorAll(".k-header");
        if (headerOnGrid) {
            for (var idx = 0; idx < headerOnGrid.length; idx++) {
                let item = headerOnGrid[idx];
                item.setAttribute("title", item.getAttribute("data-keyboardnavid"));
            }
        }
    }

    // show popup with paging
    async getCommonLookupListWithPaging(table, focus) {
        this.props.setPageLoaded(true);

        const enterKeyPressed = this.resetPageNumber;
        let skipNo = enterKeyPressed ? 0 : this.state.skip;
        let takeNo = enterKeyPressed ? 50 : this.state.take;
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/GetCommonLookupViewWithPaging";

        var _params = { table: table, skip: skipNo, take: takeNo, accessToken: localStorage.getItem('token'), clientAlias: this.props.clientgroup };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                //url += "&" + f["field"] + "=" + encodeURIComponent("%" + f["value"] + "%");
                _params[f["field"]] = "%" + f["value"] + "%";
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                //url += "&sortby=" + s["field"] + "&orderby=" + s["dir"];
                _params["sortby"] = s["field"];
                _params["orderby"] = s["dir"];
            });
        }

        var _this = this;
        let data = await axios
            .post(url, _params)
            .then(function (response) {
                var currentLookupModal = document.getElementById("commonLookup_" + _this.props.field);
                if (currentLookupModal && currentLookupModal.querySelectorAll(".k-input-inner")) {
                    var inputs = currentLookupModal.querySelectorAll(".k-input-inner");
                    for (var idx = 0; idx < inputs.length; idx++) {
                        let input = inputs[idx];
                        input.removeAttribute("disabled");

                        idx == 0 && focus ? input.focus() : null;
                    }
                }

                if (response && response.data && response.data.StatusCode == "408") {
                    console.log(response.data.ErrorMessage);
                    _this.props.showSessionTimeoutMsg(true);
                }

                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var iList = data && data.data ? data.data.commonLookupList : null;
        this.setState({
            codes: iList,
            total: data && data.data ? data.data.total : 0,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
            skip: skipNo,
            take: takeNo
        });

        this.props.setPageLoaded(true);

        //this.init();//#ADD 2021/10/18

        return false;
    }

    //
    async getCommonLookupList() {
        this.props.setPageLoaded(true);

        let icdCode = (this.props.dataSource === "Icd9_Codes" ? "/GetIcd9CodesList" : "/GetIcd10CodesList");
        let url = ConfigData.CRStarAPI_URL + this.props.clientgroup + icdCode;

        var _params = { skip: this.state.skip, take: this.state.take, accessToken: localStorage.getItem('token') };

        var _filter = {};
        if (this.state.filter !== null) {
            _filter = this.state.filter;
            this.state.filter.filters.map((f, i) => {
                _params[f["field"]] = "%" + f["value"] + "%";
            });
        }

        // adding sort options
        var _sorter = [];
        if (this.state.sort !== null && this.state.sort.length > 0) {
            _sorter = this.state.sort;
            this.state.sort.map((s, i) => {
                _params["sortby"] = s["field"];
                _params["orderby"] = s["dir"];
            });
        }

        var _this = this;
        let data = await axios
            .post(url, _params)
            .then(function (response) {
                _this.enabledAllFilter();

                if (response && response.data && response.data.StatusCode == "408") {
                    console.log(response.data.ErrorMessage);
                    _this.props.showSessionTimeoutMsg(true);
                }
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var iList = data && data.data ? data.data.commonLookupList : null;
        this.setState({
            codes: iList,
            total: data && data.data ? data.data.total : 0,
            filter: _filter,
            sort: _sorter,
            enterKeyPressed: false,
        });

        this.props.setPageLoaded(true);

        //this.init();//#ADD 2021/10/18

        return false;
    }

    //
    async getCommonLookupListForDropdown() {
        this.props.setPageLoaded(false);

        let table = this.props.dataSource;
        let code = this.props.aliasNo !== undefined ? this.props.aliasNo : "0";
        let flag = this.props.flag !== undefined ? this.props.flag : "";
        var _val = this.props.values ? this.props.values : "";

        var searchByKey = this.props.searchByKey ? this.props.searchByKey : "";
        if (_val.lastIndexOf(",") == -1) {
            _val = _val.split(",")[_val.split(",").length - 1];
        }

        let url = ConfigData.CRStarAPI_URL + (table === "Client_Group" ? "ers" : this.props.clientgroup) + "/GetCommonDropdownView";

        if (document.getElementById("crstarImportForm")) {
            _val = "";
        }

        var _params = {
            table: table,
            code: code,
            flag: flag,
            idValue: _val,
            searchByKey: searchByKey,
            accessToken: localStorage.getItem('token'),
            clientAlias: this.props.clientgroup
        };

        if (this.props.setNameAlias && this.props.setNameAlias == "name+code") {
            _params["filterIn"] = "name";
        }
        console.log("[2]::GetCommonDropdownView::", url);

        var _this = this;
        let data = await axios
            .post(url, _params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
		
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        var newAccessToken = data && data.data ? data.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        var iList = data && data.data && data.data.commonLookupList ? data.data.commonLookupList : null;
        //if (!iList || iList.length == 0) {
            //this.props.closeCode();
        //} else {
            this.setState({
                codes: iList,
                total: data && data.data && data.data.total ? data.data.total : 0,
                enterKeyPressed: false,
            });

        //this.selectGridRow(_val, iList, "id");

        //
        setTimeout(function () {
            _this.autoFocusSelectionItem(_val);
        }, 500);
        //}

        this.props.setPageLoaded(true);

        //this.init();//#ADD 2021/10/18

        return false;
    }

    autoFocusSelectionItem = (_val) => {
        const alias = this.props.setNameAlias ? this.props.setNameAlias : "code";
        if (alias === "name+code" || alias === "name") {
            //this.state.codes.forEach(item => item.selected = _val == item.name);
            var firstItemFound = false;
            this.state.codes.forEach((item) => {
                const selected = (_val == item.name);
                if (selected && !firstItemFound) {
                    item.selected = selected;
                    firstItemFound = selected;
                    return false;
                }
            });
        } else {
            this.state.codes ? this.state.codes.forEach(item => item.selected = _val == item.code) : null;
        }
        this.forceUpdate();
    }

    componentDidUpdate(prevProps) {
        let table = this.props.dataSource;
        if ((table === undefined || table === "") && !this.props.options) {
            return false;
        }

        if (this.props.values && this.props.values != "" && !this.state.focusedItemValue && this.props.research) {
            const _val = this.props.values;
            this.setState({ focusedItemValue: _val });//
            return false;
        }

        if (table === "Icd9_Codes" || table === "Icd10_Codes") {
            if (!this.props.pageLoaded || this.state.codes == null || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)) {
                this.getCommonLookupList();
            }
        } else if (table == "Morpho_Codes") {
            if (!this.props.pageLoaded || this.state.codes == null || (!this.props.pageLoaded && this.state.sort !== null && this.state.sort.length > 0)) {
                this.getCommonLookupListWithPaging(table, false);
            }
        }

        // clear |/--\| with disabled items
        let titleDialog = this.state.titleDialog;
        let pTitleDialog = this.props.titleDialog;
        if (table === "CstageLookupView" && this.state.codes.length > 0 && pTitleDialog === undefined
            && titleDialog && (titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
            this.setState({ titleDialog: " "});
        }

        // Must research with filter every times the field's value had changed
        if (this.props.shownType == "dropdown"
            //&& this.state.focusedItemValue && this.state.focusedItemValue != this.props.values
            //&& this.state.focusedItemValue && this.state.focusedItemValue != ""
            && this.props.research
        ) {
            if (this.props.options) {
                var iList = [];
                var _val = this.props.values;
                var thjs = this;
                this.props.options.map((opt, _idx) => {
                    /*if (_val) {
                        if (opt && opt != "" && opt.length >= _val.length && opt.substring(0, _val.length).toLowerCase() == _val.toLowerCase()) {
                            let fields = { code: opt, name: opt };
                            iList.push(fields);
                        }
                    } else {*/
                        if (opt && opt != "" && !thjs.props.autocompleted) {
                            let fields = { code: opt, name: opt };
                            iList.push(fields);
                        } else if (thjs.props.autocompleted) {
                            thjs.props.storeToGlobal(null);
                        }
                    //}                    
                })
                if (iList.length > 0) {
                    this.setState({
                        codes: iList,
                        enterKeyPressed: false
                    });
                } else {
                    this.props.storeToGlobal(null);
                }                
            } else  if (table === "Global_Dir" || table === "Lookup_Mv" || table === "Global_Dir_Mv" || table === "Site_Codes"
                || table === "Acc_Nbrs_Code" || table === "Acc_Nbrs_Year"
                || table === "SortOrder_StaticOptions" || table === "Letters" || table === "Letters_2" || table === "Letters_Form_Type"//Print_Tx_Summary screen
                || table === "ReportFile_TableName" || table === "Table_Name" || table === "RF_Labels" || table === "View_Columns" || table === "Xtab_Labels"
                || table === "user_settings_state_metafiles_Mv"
                || table === "x_Merge_Options" || table === "Import_Column_Option" || table === "Hospital" || table === "Global_Dir_Mv"
                || table === "x_Imported_Records" || table === "Labels" || table === "Letters_Fu" || table === "County_Codes"
            ) {
                this.getCommonLookupListForDropdown();
            }

            this.props.stopResearch();

            //var _val = this.props.values;
            this.setState({ focusedItemValue: null, research: false });//_val
        }

        return false;
    }

    pageChange = (event, isFilter) => {
        //
        if (isFilter === "sorting") {
            let _sorter = event.sort;

            this.setState({
                sort: _sorter,
                enterKeyPressed: false
            });

            this.props.setPageLoaded(false);
            this.resetPageNumber = false;
            return false;
        }
        //

        let _filter = {
            logic: "",//and
            filters: []
        };
        let enterKeyPressed = this.state.enterKeyPressed;
        if (event && event.filter) {
            _filter = event.filter;

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                filter: _filter,
                enterKeyPressed: allowSearch, //true
            });

            this.resetPageNumber = true;
        } else {
            // click a page number on paging area
            _filter = event.target.props.filter && !isFilter ? event.target.props.filter : {
                logic: "",//and
                filters: []
            };

            let allowSearch = event.nativeEvent.target.value != "";

            this.setState({
                skip: event.page ? event.page.skip : this.state.skip,
                take: event.page ? event.page.take : this.state.take,
                filter: _filter,
                enterKeyPressed: allowSearch, //true
            });

            enterKeyPressed = allowSearch; //true

            if (event.page) {
                this.resetPageNumber = false;
            } else {
                this.resetPageNumber = true;
            }
        }
        this.props.setPageLoaded(!enterKeyPressed);
        return false;
    }

    filterChange = (event) => {
        let _filter = {
            logic: "",
            filters: []
        };
        if (event && event.filter) {
            _filter = event.filter;

            this.setState({
                filter: _filter
            });
        }

        // 
        var currentLookupModal = document.getElementById("commonLookup_" + this.props.field);
        var curCls = currentLookupModal.getAttribute("class");
        if (curCls.indexOf("filterCls") == -1 && _filter.filters.length > 0) {
            curCls = curCls.trim() + " filterCls";
            currentLookupModal.setAttribute("class", curCls);
        } else {
            curCls = curCls.replaceAll("filterCls", "");
            currentLookupModal.setAttribute("class", curCls.trim());
        }

        return false;
    }

    //
    async setTitleDialog(url, _params) {
        var _this = this;
        _params["isTitle"] = "1";
        var result = await axios.post(url, _params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = result && result.data ? result.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({ titleDialog: result && result.data ? result.data.result : "" });
    }

    selectCode = (event, alias) => {
        var _code = event.dataItem.code;
        var _name = event.dataItem.name;

        if (alias === "code+name") {
            this.props.setCode(_code, _name);
        } else if (alias === "name+code") {
            if (this.props.dataSource === "x_Imported_Records")
                this.props.setAllRecord(event.dataItem);
            else
                this.props.setCode(_name, _code, this.props.dataSource === "Letters_2" || this.props.dataSource === "Letters" ? event.dataItem.id : null);
        } else if (alias === "name") {
            this.props.setCode(_name, event.dataItem.name, this.props.dataSource === "Templates_FollowUp_Order" ? event.dataItem.id : null);
        } else if (alias == "codeOnly") {
            this.props.setCode(_code, alias);
        } else {
            this.props.setCode(_code, event.dataItem.name);
        }
        this.setState({ isDialogVisible: true });

        this.props.setChildrenOpening(false);
    }

    handleRowClick = (props) => {
        const data = this.state.codes;
        const newData = data.map((item) => ({
            ...item,
            selected: item.code === props.dataItem.code,
        }));
        this.setState({ codes: newData });
    };

    // To get more reference at https://www.telerik.com/kendo-react-ui/components/grid/accessibility/
    // and https://www.telerik.com/kendo-react-ui/getting-started/
    handleNavigationAction = (event) => {
        const { focusElement } = event;
        const data = this.state.codes;

        const rowId = focusElement.parentElement.getAttribute("data-id");

        if (rowId) {
            const newData = data.map((item) => ({
                ...item,
                selected: String(item.code) === rowId,
            }));
            this.setState({ codes: newData });
        }
    };

    rowRender = (trElement, rowProps) => {
        const trProps = {
            ...trElement.props,
            ["data-id"]: rowProps.dataItem.code,
        };
        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    handleKeyDownAction = (props) => {
        var event = props.nativeEvent;
        if (event.key == "Enter") {
            event.target.click();
        }
    }

    closeCode = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }

        this.props.closeCode();
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            //
        }, 1000);

        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        return false;
    }

    render() {
        let _styleAttr = {};
        if (this.props.shownType == "dropdown") {
            _styleAttr = {
                left: this.props.iLeftPos ? this.props.iLeftPos : "auto",
                top: this.props.iTopPos ? this.props.iTopPos : "auto",
                maxWidth: this.props.iWidthLen && this.props.limitByWidth ? this.props.iWidthLen : "auto",
                minWidth: this.props.iWidthLen ? this.props.iWidthLen : "auto",
            };
        }

        let commonLookup = null;
        {
            if (this.props.dataSource === "Morpho_Codes") {
                commonLookup = (
                    <Draggable handle={this.props.shownType == "dropdown" ? ".k-grid-header_freeze" : ".k-window-titlebar"}>
                        <div className={this.props.shownType == "dropdown" ? "dragableDialogCls dropdownType" + (this.props.displayingCodeOnly ? "Standard" : "") : "dragableDialogCls"} id={"commonLookup_" + this.props.field}
                            style={_styleAttr}
                        >
                            <div className={this.props.shownType == "dropdown" ? "hidden" : "k-window-titlebar k-dialog-titlebar k-header"}>
                                <div className="k-window-title k-dialog-title">
                                    {this.state.titleDialog && this.state.titleDialog !== " " && this.state.titleDialog !== undefined ? this.state.titleDialog : (this.props.titleDialog ? this.props.titleDialog : " ")}
                                </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            {
                                <Grid
                                    style={{ height: '500px' }}
                                    data={this.state.codes != undefined && this.state.codes.length !== 0 ? this.state.codes : null}
                                    filter={this.state.filter}
                                    filterable
                                    filterOperators={this.state.filterOperators}
                                    onFilterChange={e => { this.pageChange(e, true) }}
                                    onRowClick={this.selectCode}
                                    pageable
                                    onPageChange={e => { this.pageChange(e, false) }}
                                    scrollable="scrollable"
                                    sortable
                                    sort={this.state.sort}
                                    onSortChange={e => { this.pageChange(e, "sorting") }}
                                    skip={this.state.skip}
                                    take={this.state.take}
                                    total={this.state.total}
                                    selectedField="selected"
                                    rowRender={this.rowRender}
                                    navigatable={true}
                                    onNavigationAction={this.handleNavigationAction}
                                    onKeyDown={this.handleKeyDownAction}
                                >
                                    <GridColumn field="code" filter="text" title="Code" />
                                    <GridColumn field="name" filter="text" title="Name" />
                                </Grid>
                            }
                            <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#c5ddfe" }}>
                                <button className="ErsGrBlueButton" onClick={this.props.closeCode}>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                );
            }
            else if (this.props.dataSource === "x_Imported_Records")
            {
                commonLookup = (
                    <Draggable handle={this.props.shownType == "dropdown" ? ".k-grid-header_freeze" : ".k-window-titlebar"}>
                        <div className={this.props.shownType == "dropdown" ? "dragableDialogCls dropdownType" + (this.props.displayingCodeOnly ? "Standard" : "") : "dragableDialogCls"} id={"commonLookup_" + this.props.field}
                            style={_styleAttr}
                        >
                            <div className={this.props.shownType == "dropdown" ? "hidden" : "k-window-titlebar k-dialog-titlebar k-header"}>
                                <div className="k-window-title k-dialog-title">
                                    {this.state.titleDialog && this.state.titleDialog !== " " && this.state.titleDialog !== undefined ? this.state.titleDialog : (this.props.titleDialog ? this.props.titleDialog : " ")}
                                </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            {
                                <Grid
                                    style={{ height: '500px' }}
                                    data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                    filter={this.state.filter}
                                    onRowClick={e => { this.selectCode(e, "name+code") }}
                                    selectedField="selected"
                                    rowRender={this.rowRender}
                                    navigatable={true}
                                    onNavigationAction={this.handleNavigationAction}
                                    onKeyDown={this.handleKeyDownAction}>
                                    {/*Imported_Records_ID,Import_Name,File_Name,File_Rec_Count,Date,Time,Time_Finished,
                                     * User,Status,Records_Processed,New_Patient_Count,New_Primary_Count,New_Contact_Count*/}
                                    <GridColumn field="code" filter="text" title="Id" style={{ width: "10px" }} />
                                    <GridColumn field="name" filter="text" title="Import Name" style={{ width: "50px" }} />
                                    <GridColumn field="fileName" filter="text" title="File Name" style={{ width: "50px" }} />
                                    <GridColumn field="fileRecCount" filter="text" title="Recs in File" style={{ width: "50px" }} />
                                    <Column field="date" filter="text" title="Date" style={{ width: "20%", textAlign: "center" }} cell={KendoGridDateCell} format="{MM/dd/yyyy}" />
                                    <GridColumn field="time" filter="text" title="Start" style={{ width: "20%", textAlign: "center" }} />
                                    <GridColumn field="timeFinished" filter="text" title="End" style={{ width: "20%", textAlign: "center" }} />
                                    <GridColumn field="user" filter="text" title="User" style={{ width: "50px" }} />
                                    <GridColumn field="status" filter="text" title="Status" style={{ width: "50px" }} />
                                    <GridColumn field="recordsProcessed" filter="text" title="Recs Processed" style={{ width: "50px", textAlign: "right" }} />
                                    <GridColumn field="newPatientCount" filter="text" title="New Pat" style={{ width: "30px", textAlign: "right" }} />
                                    <GridColumn field="newPrimaryCount" filter="text" title="New Prim" style={{ width: "30px", textAlign: "right" }} />
                                    <GridColumn field="newContactCount" filter="text" title="New Cont" style={{ width: "30px", textAlign: "right" }} />
                                </Grid>
                            }
                        </div>
                    </Draggable>
                    );
            }
            else
            {
            commonLookup = (this.props.dataSource === "Icd9_Codes" || this.props.dataSource === "Icd10_Codes") ?
                (
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableDialogCls" id={"commonLookup_" + this.props.field}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">
                                    {this.props.titleDialog}
                                </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <Grid
                                style={{ height: '500px' }}
                                data={this.state.codes != undefined && this.state.codes.length !== 0 ? this.state.codes : null}

                                filter={this.state.filter}
                                filterable
                                filterOperators={this.state.filterOperators}
                                onFilterChange={e => { this.pageChange(e, true) }}
                                pageable
                                onPageChange={e => { this.pageChange(e, false) }}
                                scrollable="scrollable"
                                sortable
                                sort={this.state.sort}
                                onSortChange={e => { this.pageChange(e, "sorting") }}
                                skip={this.state.skip}
                                take={this.state.take}
                                total={this.state.total}

                                onRowClick={e => { this.selectCode(e, "code") }}//onRowClick={this.handleRowClick}

                                selectedField="selected"
                                rowRender={this.rowRender}
                                navigatable={true}
                                onNavigationAction={this.handleNavigationAction}

                                onKeyDown={this.handleKeyDownAction}
                            >
                                <GridColumn field="code" filter="text" title="Code" style={{ width: "30%" }} />
                                <GridColumn field="name" filter="text" title="Description" style={{ width: "70%" }} />
                            </Grid>
                            <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#c5ddfe" }}>
                                {this.props.addNew && this.props.addNew !== "" ? <button onClick={this.addNewItem}>{this.props.addNew}</button> : ""}
                                <button className="ErsGrBlueButton" onClick={this.props.closeCode}>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                )
                :
                this.props.dataSource === "RF_Labels"
                    ?
                    <Draggable handle={this.props.shownType == "dropdown" ? ".k-grid-header_freeze" : ".k-window-titlebar"}>
                        <div id={"commonLookup_" + this.props.field}
                            className={this.props.shownType == "dropdown" ? "dragableDialogCls dropdownType" + (this.props.displayingCodeOnly ? "Standard" : "") : "dragableDialogCls"}
                            style={_styleAttr}
                        >
                            <div className={this.props.shownType == "dropdown" ? "hidden" : "k-window-titlebar k-dialog-titlebar k-header"}>
                                <div className="k-window-title k-dialog-title">
                                    {this.state.titleDialog && this.state.titleDialog !== " " && this.state.titleDialog !== undefined ? this.state.titleDialog : (this.props.titleDialog ? this.props.titleDialog : " ")}
                                </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                    </a>
                                </div>
                            </div>

                            <Grid
                                style={{ height: '500px' }}
                                data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                filter={this.state.filter}
                                //filterable
                                onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                onRowClick={e => { this.selectCode(e, "code+name") }}

                                selectedField="selected"
                                rowRender={this.rowRender}
                                navigatable={true}
                                onNavigationAction={this.handleNavigationAction}

                                onKeyDown={this.handleKeyDownAction}>
                                <GridColumn field="reportLabel" filterable={false} filter="text" title="Report Label" style={{ width: "40%" }} />
                                <Column field="requestDate" filter="text" title="Request Date" style={{ width: "20%", textAlign: "center" }} cell={KendoGridDateCell} format="{MM/dd/yyyy}" />
                                <GridColumn field="requestedBy" filterable={false} filter="text" title="Created By" style={{ width: "20%" }} />
                                <GridColumn field="numberOfRecords" filterable={false} filter="text" title="Number of Records" style={{ width: "20%", textAlign: "right" }} />
                            </Grid>
                        </div>
                    </Draggable>
                    :
                (
                    <Draggable handle={this.props.shownType == "dropdown" ? ".k-grid-header_freeze" : ".k-window-titlebar"}>
                            <div className={this.props.shownType == "dropdown" ? "dragableDialogCls dropdownType" + (this.props.displayingCodeOnly ? "Standard" : "") : "dragableDialogCls" + (this.props.dataSource === "Tnm_Lookup8th_Mv" ? " Tnm_Lookup8th_Mv" : "")} id={"commonLookup_" + this.props.field}
                            style={_styleAttr}
                        >
                            <div className={this.props.shownType == "dropdown" ? "hidden" : "k-window-titlebar k-dialog-titlebar k-header"}>
                                <div className="k-window-title k-dialog-title">
                                    {this.state.titleDialog && this.state.titleDialog !== " " && this.state.titleDialog !== undefined ? this.state.titleDialog : (this.props.titleDialog ? this.props.titleDialog : " ")}
                                </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} /*href="javascript:void(0)" role="button" aria-label="Close"*/ className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.closeCode(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            {
                                this.props.dataSource === "Letters" || this.props.dataSource === "Letters_2" || this.props.dataSource === "Letters_Fu" /*|| this.props.dataSource === "Letters_Form_Type"*/  || this.props.dataSource === "Hospital" ?
                                    (this.props.shownType == "dropdown" ?
                                            (this.props.dataSource === "Letters" || this.props.dataSource === "Letters_Fu" || this.props.acceptedDataSource === "Letters" ?
                                                <Grid
                                                    style={{ height: '500px' }}
                                                    data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                                    filter={this.state.filter}
                                                    //filterable
                                                    //onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                                    onRowClick={e => { this.selectCode(e, "name+code") }}//onRowClick={this.handleRowClick}

                                                    selectedField="selected"
                                                    rowRender={this.rowRender}
                                                    navigatable={true}
                                                    onNavigationAction={this.handleNavigationAction}

                                                    onKeyDown={this.handleKeyDownAction}>
                                                    <GridColumn field="name" filter="text" title={this.props.title1 ? this.props.title1 : (this.props.codeLabel ? this.props.codeLabel : "Form")} style={{ width: "200px" }} />
                                                    <GridColumn field="code" filter="text" title="Hosp Code" style={{ width: "50px" }} />
                                                </Grid>
                                                :
                                                <Grid
                                                    style={{ height: '500px' }}
                                                    data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                                    filter={this.state.filter}
                                                    //filterable
                                                    //onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                                    onRowClick={e => { this.selectCode(e, "code+name") }}//onRowClick={this.handleRowClick}

                                                    selectedField="selected"
                                                    rowRender={this.rowRender}
                                                    navigatable={true}
                                                    onNavigationAction={this.handleNavigationAction}

                                                    onKeyDown={this.handleKeyDownAction}>
                                                    <GridColumn field="code" filter="text" title="Hosp Code" style={{ width: "50px" }} />
                                                    <GridColumn field="name" filter="text" title="Hospital" style={{ width: "200px" }} />
                                                </Grid>
                                            )
                                        :
                                        <Grid
                                            style={{ height: '500px' }}
                                            data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                            filter={this.state.filter}
                                            filterable
                                            onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                            onRowClick={e => { this.selectCode(e, "name") }}//onRowClick={this.handleRowClick}

                                            selectedField="selected"
                                            rowRender={this.rowRender}
                                            navigatable={true}
                                            onNavigationAction={this.handleNavigationAction}

                                            onKeyDown={this.handleKeyDownAction}>
                                            <GridColumn field="name" filter="text" title="Letter" style={{ width: "70%" }} />
                                            <GridColumn field="code" filter="text" title="Hospital" style={{ width: "30%" }} />
                                        </Grid>
                                    )
                                    :
                                    this.props.dataSource === "Templates_FollowUp_Order" ?
                                        <Grid
                                            style={{ height: '500px' }}
                                            data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                            filter={this.state.filter}
                                            filterable
                                            onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                            onRowClick={e => { this.selectCode(e, "name") }}//onRowClick={this.handleRowClick}

                                            selectedField="selected"
                                            rowRender={this.rowRender}
                                            navigatable={true}
                                            onNavigationAction={this.handleNavigationAction}

                                            onKeyDown={this.handleKeyDownAction}>
                                            <GridColumn field="name" filter="text" title="Template Name" style={{ width: "70%" }} />
                                            <GridColumn field="code" filter="text" title="Template Hosp" style={{ width: "30%" }} />
                                        </Grid>
                                        :
                                        (
                                            this.props.displayingCodeOnly ?
                                                <Grid
                                                    style={{ height: '500px' }}
                                                    data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                                    filter={this.state.filter}
                                                    filterable={this.props.hideFilter ? false : true}
                                                    onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                                    onRowClick={e => { this.selectCode(e, this.props.dataSource == "View_Columns" ? "code+name" : "codeOnly") }}

                                                    selectedField="selected"
                                                    rowRender={this.rowRender}
                                                    navigatable={true}
                                                    onNavigationAction={this.handleNavigationAction}

                                                    onKeyDown={this.handleKeyDownAction}>
                                                        {/*//<GridColumn field="code" filterable={false} filter="text" title={this.props.hideFilter ? "" : "Code"} style={{ width: "100%" }} filterable={this.props.setNameAlias ? false : true} />  //Updated line of code below from Truc for Cross Tab - 05/10/2023*/}
                                                        <GridColumn field="code" filterable={false} filter="text" title={this.props.hideFilter ? "" : (this.props.codeLabel ? this.props.codeLabel : "Code")} style={{ width: "100%" }} filterable={this.props.setNameAlias ? false : true} />
                                                </Grid>
                                                    :
                                                    (this.props.dataSource === "Tnm_Lookup8th_Mv" ?
                                                        <Grid
                                                            style={{ height: '500px' }} className={(this.state.filter && this.state.filter.length > 0 ? "filterCls" : "") + (this.state.header && this.state.header.colNo ? " columnNoCls_" + this.state.header.colNo : "") }
                                                            data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                                            filter={this.state.filter}
                                                            filterable

                                                            onFilterChange={e => { this.setState({ filter: e.filter }) }}
                                                            //onFilterChange={e => { this.filterChange(e) }}

                                                            onRowClick={e => { this.selectCode(e, "code") }}//onRowClick={this.handleRowClick}

                                                            selectedField="selected"
                                                            rowRender={this.rowRender}
                                                            navigatable={true}
                                                            onNavigationAction={this.handleNavigationAction}

                                                            onKeyDown={this.handleKeyDownAction}>

                                                            {
                                                                /* Column 1 (TNM Popups) */
                                                                this.state.header ?
                                                                    (this.state.header.fieldExt1 ?
                                                                        <GridColumn field="fieldExt1" filter="text" title={this.state.header.fieldExt1} style={{ width: "25%" }} id={this.state.header.hinto1} /> :
                                                                        <GridColumn field="code" filter="text" title="Code" style={{ width: "25%" }} />) :
                                                                    <GridColumn field="code" filter="text" title="" style={{ width: "25%" }} />
                                                            }
                                                            {
                                                                /* Column 2 (TNM Popups) */
                                                                this.state.header ?
                                                                    (this.state.header.fieldExt2 ?
                                                                        <GridColumn field="fieldExt2" filter="text" title={this.state.header.fieldExt2} style={{ width: "25%" }} id={this.state.header.hinto2} /> :
                                                                        <GridColumn field="name" filter="text" title="Description" style={{ width: "25%" }} />) :
                                                                    <GridColumn field="name" filter="text" title="" style={{ width: "25%" }} />
                                                            }
                                                            {
                                                                /* Column 3 (TNM Popups) */
                                                                this.state.header && this.state.header.fieldExt3 ?
                                                                    (this.state.header.fieldExt3 ?
                                                                        <GridColumn field="fieldExt3" filter="text" title={this.state.header.fieldExt3} style={{ width: "25%" }} id={this.state.header.hinto3} /> :
                                                                        <GridColumn field="fieldExt3" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                            {
                                                                /* Column 4 (TNM Popups) */
                                                                this.state.header && this.state.header.fieldExt4 ?
                                                                    (this.state.header.fieldExt4 ?
                                                                        <GridColumn field="fieldExt4" filter="text" title={this.state.header.fieldExt4} style={{ width: "25%" }} id={this.state.header.hinto4} /> :
                                                                        <GridColumn field="fieldExt4" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                            {
                                                                /* Column 5 (TNM Group Popups) */
                                                                this.state.header && this.state.header.fieldExt5 ?
                                                                    (this.state.header.fieldExt5 ?
                                                                        <GridColumn field="fieldExt5" filter="text" title={this.state.header.fieldExt5} style={{ width: "25%" }} id={this.state.header.hinto5} /> :
                                                                        <GridColumn field="fieldExt5" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                            {
                                                                /* Column 6 (TNM Group Popups) */
                                                                this.state.header && this.state.header.fieldExt6 ?
                                                                    (this.state.header.fieldExt6 ?
                                                                        <GridColumn field="fieldExt6" filter="text" title={this.state.header.fieldExt6} style={{ width: "25%" }} id={this.state.header.hinto6} /> :
                                                                        <GridColumn field="fieldExt6" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                            {
                                                                /* Column 7 (TNM Group Popups) */
                                                                this.state.header && this.state.header.fieldExt7 ?
                                                                    (this.state.header.fieldExt7 ?
                                                                        <GridColumn field="fieldExt7" filter="text" title={this.state.header.fieldExt7} style={{ width: "25%" }} id={this.state.header.hinto7} /> :
                                                                        <GridColumn field="fieldExt7" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                            {
                                                                /* Column 8 (TNM Group Popups) */
                                                                this.state.header && this.state.header.fieldExt8 ?
                                                                    (this.state.header.fieldExt8 ?
                                                                        <GridColumn field="fieldExt8" filter="text" title={this.state.header.fieldExt8} style={{ width: "25%" }} id={this.state.header.hinto8} /> :
                                                                        <GridColumn field="fieldExt8" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                            {
                                                                /* Column 9 (TNM Group Popups) */
                                                                this.state.header && this.state.header.fieldExt9 ?
                                                                    (this.state.header.fieldExt9 ?
                                                                        <GridColumn field="fieldExt9" filter="text" title={this.state.header.fieldExt9} style={{ width: "25%" }} id={this.state.header.hinto9} /> :
                                                                        <GridColumn field="fieldExt9" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                            {
                                                                /* Column 10 (TNM Group Popups) */
                                                                this.state.header && this.state.header.fieldExt10 ?
                                                                    (this.state.header.fieldExt10 ?
                                                                        <GridColumn field="fieldExt10" filter="text" title={this.state.header.fieldExt10} style={{ width: "25%" }} id={this.state.header.hinto10} /> :
                                                                        <GridColumn field="fieldExt10" filter="text" title="" style={{ width: "25%" }} />) :
                                                                    null
                                                            }
                                                        </Grid>
                                                        :
                                                        (this.props.dataSource == "user_settings_state_metafiles_Mv" ?
                                                            <Grid
                                                                style={{ height: '500px' }}
                                                                data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                                                filter={this.state.filter}
                                                                filterable
                                                                onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                                                onRowClick={e => { this.selectCode(e, this.props.setNameAlias ? this.props.setNameAlias : "code") }}

                                                                selectedField="selected"
                                                                rowRender={this.rowRender}
                                                                navigatable={true}
                                                                onNavigationAction={this.handleNavigationAction}

                                                                onKeyDown={this.handleKeyDownAction}>
                                                                <GridColumn field="name" filter="text" title="" style={{ width: "70%" }} filterable={this.props.setNameAlias ? false : true} />
                                                            </Grid>
                                                                :
                                                            (this.props.dataSource === "Labels" ?
                                                                <Grid
                                                                    style={{ height: '500px' }}
                                                                    data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                                                    filter={this.state.filter}
                                                                    //filterable
                                                                    onFilterChange={e => { this.setState({ filter: e.filter }) }}
                                                                    onRowClick={e => { this.selectCode(e, "code+name") }}
                                                                    selectedField="selected"
                                                                    rowRender={this.rowRender}
                                                                    navigatable={true}
                                                                    onNavigationAction={this.handleNavigationAction}
                                                                    onKeyDown={this.handleKeyDownAction}>
                                                                    <GridColumn field="label" filterable={false} filter="text" title={this.props.aliasNo == "FU" ? "Selection Label" : "Label"} style={{ width: "60%" }} />
                                                                    <Column field="requestDate" filter="text" title={this.props.aliasNo == "FU" ? "Last Saved" : "Last Selected"} style={{ width: "10%", textAlign: "center" }} cell={KendoGridDateCell} format="{MM/dd/yyyy}" />
                                                                    <GridColumn field="requestedBy" filterable={false} filter="text" title={this.props.aliasNo == "FU" ? "Selected By" : "Created By"} style={{ width: "15%" }} />
                                                                    <GridColumn field="numberOfRecords" filterable={false} filter="text" title={this.props.aliasNo == "FU" ? "Records" : "Number of Records"} style={{ width: "15%", textAlign: "right" }} />
                                                                </Grid>
                                                                :
                                                            <Grid
                                                                style={{ height: '500px' }}
                                                                data={this.state.codes && this.state.codes.length !== 0 ? filterBy(this.state.codes, this.state.filter) : null}
                                                                filter={this.state.filter}
                                                                filterable
                                                                onFilterChange={e => { this.setState({ filter: e.filter }) }}

                                                                onRowClick={e => { this.selectCode(e, this.props.setNameAlias ? this.props.setNameAlias : "code") }}//onRowClick={this.handleRowClick}

                                                                selectedField="selected"
                                                                rowRender={this.rowRender}
                                                                navigatable={true}
                                                                onNavigationAction={this.handleNavigationAction}

                                                                onKeyDown={this.handleKeyDownAction}>
                                                                <GridColumn field="code" filter="text" title={this.props.codeLabel ? this.props.codeLabel : "Code"} style={{ width: "30%" }} filterable={this.props.setNameAlias ? false : true} />
                                                                <GridColumn field="name" filter="text" title={this.props.descriptionLabel ? this.props.descriptionLabel : "Description"} style={{ width: "70%" }} filterable={this.props.setNameAlias ? false : true} />
                                                            {
                                                                /* Crstar Import screen -> Select an Import Option dropdown::Column 3 (File Type) */
                                                                this.props.dataSource == "x_Merge_Options" ?
                                                                    <GridColumn field="description" filter="text" title="File Type" style={{ width: "100%" }} filterable={this.props.setNameAlias ? false : true} /> :
                                                                    null
                                                            }
                                                            </Grid>
                                                            )
                                                        )
                                                    )
                                        )
                            }
                            <div style={{ padding: "10px", textAlign: "center", backgroundColor: "#c5ddfe", display: this.props.setNameAlias || this.props.shownType == "dropdown"  ? "none" : "" }}>
                                {this.props.addNew && this.props.addNew !== "" ? <button onClick={this.addNewItem}>{this.props.addNew}</button> : ""}
                                <button className="ErsGrBlueButton" onClick={this.props.closeCode}>Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                );
            }
        }

        return  <div>
                    <div className={this.props.shownType == "dropdown" ? "fake_popup_cls hidden" : "fake_popup_cls"}></div>
                    {commonLookup}
                </div>
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        pageLoaded: state.patientAbstract.pageLoaded,
        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
        focusingFieldInGlobalStore: state.patientAbstract.focusingFieldInGlobalStore,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CommonLookup);