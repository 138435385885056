/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./BreastProgramMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import axios from "../../../../axios-instance";
import classnames from "classnames";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";

class BreastProgramMaintenance extends Component {

    // At Breast Program page, before initial page -> run this store procedure to get data:
    // Exec Abstract_Select 'value of CID'

    state = {
        activeTab: "1",
        bAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'napbcForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty napbcForm back to what it was on load
        initialNapbcForm: null,
        napbcForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        mode: "1",//only for test
        fieldInFocus: "mammoDate",
        isHelpVisible: false,
        isDialogVisible: true,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        focusingField: null,
    }

    reloadPageAfterSave = false;

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = () => {
        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.napbcForm && this.props.selectedNapbcRecord) {
            this.setFormData();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.reloadPageAfterSave && !this.props.processingAndMovingPage) {
            this.props.selectNapbcRecord(this.props.selectedPrimarySummary);
            this.reloadPageAfterSave = false;
        }

        if ((!this.state.napbcForm && this.props.selectedNapbcRecord)
            || (prevProps.selectedNapbcRecord && prevProps.selectedNapbcRecord !== this.props.selectedNapbcRecord)
            || (this.props.initialAbstractPages && this.props.selectedNapbcRecord)
        ) {
            this.setFormData();
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.napbcForm && this.state.bAllItemFields
        ) {
            console.log("[BPM] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    bAllItemFields: { ...this.state.bAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    napbcForm: { ...this.state.napbcForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    bAllItemFields: { ...this.state.bAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    napbcForm: { ...this.state.napbcForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Napbc", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });
    }
    
    setFormData = () => {
        this.setState({
            napbcForm: {
                ...this.props.selectedNapbcRecord,
                ["mammoDate"]: (this.props.selectedNapbcRecord != null && this.props.selectedNapbcRecord.mammoDate !== null && this.props.selectedNapbcRecord.mammoDate !== "" ? moment(new Date(this.props.selectedNapbcRecord.mammoDate)).format("MM/DD/YYYY") : ""),
                ["ultrasoundDate"]: (this.props.selectedNapbcRecord != null && this.props.selectedNapbcRecord.ultrasoundDate !== null && this.props.selectedNapbcRecord.ultrasoundDate !== "" ? moment(new Date(this.props.selectedNapbcRecord.ultrasoundDate)).format("MM/DD/YYYY") : ""),
                ["mriDate"]: (this.props.selectedNapbcRecord != null && this.props.selectedNapbcRecord.mriDate !== null && this.props.selectedNapbcRecord.mriDate !== "" ? moment(new Date(this.props.selectedNapbcRecord.mriDate)).format("MM/DD/YYYY") : ""),
                ["plasticSurgRefDate"]: (this.props.selectedNapbcRecord != null && this.props.selectedNapbcRecord.plasticSurgRefDate !== null && this.props.selectedNapbcRecord.plasticSurgRefDate !== "" ? moment(new Date(this.props.selectedNapbcRecord.plasticSurgRefDate)).format("MM/DD/YYYY") : ""),
                ["screeningDate"]: (this.props.selectedNapbcRecord != null && this.props.selectedNapbcRecord.screeningDate !== null && this.props.selectedNapbcRecord.screeningDate !== "" ? moment(new Date(this.props.selectedNapbcRecord.screeningDate)).format("MM/DD/YYYY") : ""),
                ["dateScpGeneratedBpm"]: (this.props.selectedNapbcRecord != null && this.props.selectedNapbcRecord.dateScpGeneratedBpm !== null && this.props.selectedNapbcRecord.dateScpGeneratedBpm !== "" ? moment(new Date(this.props.selectedNapbcRecord.dateScpGeneratedBpm)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.bAllItemFields && this.props.selectedNapbcRecord) {
            let fields = {};
            Object.keys(this.props.selectedNapbcRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ bAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);

        return false;
    }

    toggleTab = (event, tab) => {
        event.preventDefault();

        this.setState({ activeTab: tab });
        var _this = this;
        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("bpmStd52Field1_no") ? document.getElementById("bpmStd52Field1_no").focus() : "";
            }, 0);
        } else if (tab === "2") {
            setTimeout(function () {
                document.getElementById("bpmStd513Field1_no") ? document.getElementById("bpmStd513Field1_no").focus() : "";
            }, 0);
        } else if (tab === "3") {
            setTimeout(function () {
                document.getElementById("mammoDate") ? document.getElementById("mammoDate").focus() : "";
            }, 0);
        }

        return false;
    }

    saveNapbc = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.bAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let napbcFormData = this.state.napbcForm;
            napbcFormData.cid = this.props.selectedMedicalRecord.cid;

            this.props.saveNapbc(napbcFormData);
            this.reloadPageAfterSave = true;
            return false;
        }
    }

    saveTextDialog = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            let textDialogData = this.state.textDialogForm;
            this.props.saveTextDialog(textDialogData);
        }
    }

    resetNapbc = () => {
        this.setState({
            napbcForm: { ...this.state.initialNapbcForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = () => {
        this.setState({ isHelpVisible: false });
        return false;
    }

    setChartOption = (event) => {
        let curValue = this.state.napbcForm[event.target.name];
        let newValue = null;

        if (curValue === null || curValue === "") {
            newValue = event.target.value;
        } else {
            newValue = (curValue != event.target.value ? event.target.value : null)
        }

        this.setState({
            napbcForm: {
                ...this.state.napbcForm,
                // [event.target.name]: event.target.value
                [event.target.name]: newValue
            },
            isFormDirty: true,
        });
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "" && event.target.name !== "referToSupport") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else {
            this.setState({ bAllItemFields: { ...this.state.bAllItemFields, [event.target.name]: "" } });
        }

        let fName = event.target.name;
        if (fName === "mammoDate" || fName === "ultrasoundDate" || fName === "mriDate"
            || fName === "plasticSurgRefDate" || fName === "screeningDate" || fName === "dateScpGeneratedBpm") {
            let thisValue = event.target.value;
            let thisDate = this.BPM_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate != thisValue) {
                this.setState({
                    napbcForm: {
                        ...this.state.napbcForm,
                        [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }
        }
    }
    // --END

    BPM_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        // adding rather
        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }
        //

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    handleFormChange = (event) => {
        var napbcForm = this.state.napbcForm;
        if (event.target.name && (
            event.target.name.indexOf("diagnosisDate") != -1
            || event.target.name.indexOf("dateScpGeneratedBpm") != -1//
            || event.target.name.indexOf("ncdSurgDtSumm") != -1
            || event.target.name.indexOf("mriDate") != -1//
            || event.target.name.indexOf("initialRxDate") != -1
            || event.target.name.indexOf("screeningDate") != -1 )//
        ) {
            napbcForm["hasChangedDate"] = "1";
        }
        console.log(napbcForm);

        if (event.target2) {
            this.setState({
                napbcForm: {
                    ...napbcForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true
            });
        } else {
            this.setState({
                napbcForm: {
                    ...napbcForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true
            });
        }
    }

    keydownHandler = (event) => {
        if (event.key === "Tab" && !event.shiftKey && event.target.name === "radOncoGuidlinesFollowedBpm") {
            document.getElementById("linkOfBPMChartAuditTab2").click();
        }
        if (event.key === "Tab" && event.shiftKey && event.target.name === "surgIncludeErPrHer2Bpm") {
            document.getElementById("linkOfBPMChartAuditTab").click();
        }
    }

    render() {
        const breastProgramMaintenance = this.state.napbcForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveNapbc} id="napbcForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="_row">
                            <div className="__frameInner">
                                <Nav tabs>
                                    <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(event) => { this.toggleTab(event, "1") }} href="#" id="linkOfBPMChartAuditTab">
                                        BPM Chart Audit
                                    </NavLink>
                                    <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(event) => { this.toggleTab(event, "2") }} href="#" id="linkOfBPMChartAuditTab2">
                                        BPM Chart Audit 2
                                    </NavLink>
                                    <NavLink className={classnames({ active: this.state.activeTab === "3" })} onClick={(event) => { this.toggleTab(event, "3") }} href="#" id="linkOfBPMServicesTab">
                                        Continuum of Care Services
                                    </NavLink>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <div className="_row" id="bpmTab1Row">
                                            <div className="emptyLine"></div>
                                            <div className="col-sm-6">
                                                <div>
                                                    <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd52">Std 5.2</label>
                                                    <label className="stdDesc" id="bpmStd52Desc">Diagnostic Imaging of the Breast and Axilla</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd52Lb1" >Image and Pathology Concordance</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field1_no" //* 4566 *
                                                        checked={this.state.napbcForm.imgpathConcordanceBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="imgpathConcordanceBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field1_yes"
                                                        checked={this.state.napbcForm.imgpathConcordanceBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="imgpathConcordanceBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field1_na"
                                                        checked={this.state.napbcForm.imgpathConcordanceBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="imgpathConcordanceBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field1_unk"
                                                        checked={this.state.napbcForm.imgpathConcordanceBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="imgpathConcordanceBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd52Lb2" >Bx Path results w/ pt</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field2_no" //* 4567 *
                                                        checked={this.state.napbcForm.bxpathResultsBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="bxpathResultsBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field2_yes"
                                                        checked={this.state.napbcForm.bxpathResultsBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="bxpathResultsBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field2_na"
                                                        checked={this.state.napbcForm.bxpathResultsBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="bxpathResultsBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd52Field2_unk"
                                                        checked={this.state.napbcForm.bxpathResultsBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="bxpathResultsBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd55">Std 5.5</label>
                                                    <label className="stdDesc" id="bpmStd55Desc">Genetic Evaluation and Management</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd55Lb1" >Personalized genetic risk assessment and eval</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field1_no" //* 4568 *
                                                        checked={this.state.napbcForm.docGeneticRiskEvalBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="docGeneticRiskEvalBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field1_yes"
                                                        checked={this.state.napbcForm.docGeneticRiskEvalBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="docGeneticRiskEvalBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field1_na"
                                                        checked={this.state.napbcForm.docGeneticRiskEvalBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="docGeneticRiskEvalBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field1_unk"
                                                        checked={this.state.napbcForm.docGeneticRiskEvalBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="docGeneticRiskEvalBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd55Lb2" >Documentation of pertinent family Hx</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field2_no" //* 4569 *
                                                        checked={this.state.napbcForm.docFamilyHxBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="docFamilyHxBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field2_yes"
                                                        checked={this.state.napbcForm.docFamilyHxBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="docFamilyHxBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field2_na"
                                                        checked={this.state.napbcForm.docFamilyHxBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="docFamilyHxBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field2_unk"
                                                        checked={this.state.napbcForm.docFamilyHxBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="docFamilyHxBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd55Lb3" >Genetic eval and testing discussed with Pt</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field3_no" //* 4570 *
                                                        checked={this.state.napbcForm.geneticEvalWpatientBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="geneticEvalWpatientBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field3_yes"
                                                        checked={this.state.napbcForm.geneticEvalWpatientBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="geneticEvalWpatientBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field3_na"
                                                        checked={this.state.napbcForm.geneticEvalWpatientBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="geneticEvalWpatientBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field3_unk"
                                                        checked={this.state.napbcForm.geneticEvalWpatientBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="geneticEvalWpatientBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd55Lb4" >Discussion of family members appropriate for testing</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field4_no" //* 4571 *
                                                        checked={this.state.napbcForm.familyTestingBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="familyTestingBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field4_yes"
                                                        checked={this.state.napbcForm.familyTestingBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="familyTestingBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field4_na"
                                                        checked={this.state.napbcForm.familyTestingBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="familyTestingBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd55Field4_unk"
                                                        checked={this.state.napbcForm.familyTestingBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="familyTestingBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd56">Std 5.6</label>
                                                    <label className="stdDesc" id="bpmStd56Desc">Eval and Tx Planning for Newly Dx Cancer Patient</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd56Lb1" >Clinical Staging documented and discussed before tx</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field1_no" //* 4572 *
                                                        checked={this.state.napbcForm.stageAssignedBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="stageAssignedBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field1_yes"
                                                        checked={this.state.napbcForm.stageAssignedBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="stageAssignedBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field1_na"
                                                        checked={this.state.napbcForm.stageAssignedBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="stageAssignedBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field1_unk"
                                                        checked={this.state.napbcForm.stageAssignedBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="stageAssignedBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd56Lb2" >Biopsy slides reviewed prior to treatment</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field2_no" //* 4573 *
                                                        checked={this.state.napbcForm.bxReviewBeforeTxBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="bxReviewBeforeTxBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field2_yes"
                                                        checked={this.state.napbcForm.bxReviewBeforeTxBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="bxReviewBeforeTxBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field2_na"
                                                        checked={this.state.napbcForm.bxReviewBeforeTxBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="bxReviewBeforeTxBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field2_unk"
                                                        checked={this.state.napbcForm.bxReviewBeforeTxBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="bxReviewBeforeTxBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd56Lb3" >For Pt w/ no slide review - discussed w/ Pt</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field3_no" //* 4574 *
                                                        checked={this.state.napbcForm.unreviwedBxWpatientBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="unreviwedBxWpatientBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field3_yes"
                                                        checked={this.state.napbcForm.unreviwedBxWpatientBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="unreviwedBxWpatientBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field3_na"
                                                        checked={this.state.napbcForm.unreviwedBxWpatientBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="unreviwedBxWpatientBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field3_unk"
                                                        checked={this.state.napbcForm.unreviwedBxWpatientBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="unreviwedBxWpatientBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd56Lb4" >Imaging reviewed or redone by facility</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field4_no" //* 4575 *
                                                        checked={this.state.napbcForm.imgReviewRedoneBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="imgReviewRedoneBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field4_yes"
                                                        checked={this.state.napbcForm.imgReviewRedoneBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="imgReviewRedoneBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field4_na"
                                                        checked={this.state.napbcForm.imgReviewRedoneBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="imgReviewRedoneBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field4_unk"
                                                        checked={this.state.napbcForm.imgReviewRedoneBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="imgReviewRedoneBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd56Lb5" >Metastatic Workup completed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field5_no" //* 4576 *
                                                        checked={this.state.napbcForm.metastaticCompleteBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="metastaticCompleteBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field5_yes"
                                                        checked={this.state.napbcForm.metastaticCompleteBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="metastaticCompleteBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field5_na"
                                                        checked={this.state.napbcForm.metastaticCompleteBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="metastaticCompleteBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field5_unk"
                                                        checked={this.state.napbcForm.metastaticCompleteBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="metastaticCompleteBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd56Lb6" >Laboratory workup completed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field6_no" //* 4577 *
                                                        checked={this.state.napbcForm.laboratoryCompleteBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="laboratoryCompleteBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field6_yes"
                                                        checked={this.state.napbcForm.laboratoryCompleteBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="laboratoryCompleteBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field6_na"
                                                        checked={this.state.napbcForm.laboratoryCompleteBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="laboratoryCompleteBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd56Field6_unk"
                                                        checked={this.state.napbcForm.laboratoryCompleteBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="laboratoryCompleteBpm" value="Unk" />
                                                </div>
                                            </div>

                                            <div className="col-sm-6" id="bpmTab1RightColumn">
                                                <div>
                                                    <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd57">Std 5.7</label>
                                                    <label className="stdDesc" id="bpmStd57Desc">Comprehensive Eval of Pt Factors Before Treatment</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd57Lb1" >Pre-Treatment Evaluation Completed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd57Field1_no" //* 4601 *
                                                        checked={this.state.napbcForm.pretxEvaluationCompletedBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="pretxEvaluationCompletedBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd57Field1_yes"
                                                        checked={this.state.napbcForm.pretxEvaluationCompletedBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="pretxEvaluationCompletedBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd57Field1_na"
                                                        checked={this.state.napbcForm.pretxEvaluationCompletedBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="pretxEvaluationCompletedBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd57Field1_unk"
                                                        checked={this.state.napbcForm.pretxEvaluationCompletedBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="pretxEvaluationCompletedBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd58">Std 5.8</label>
                                                    <label className="stdDesc" id="bpmStd58Desc">Patient Navigation</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd58Lb1" >Patient provided a navigator through journey</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd58Field1_no" //* 4578 *
                                                        checked={this.state.napbcForm.ptProvidNavigatorBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="ptProvidNavigatorBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd58Field1_yes"
                                                        checked={this.state.napbcForm.ptProvidNavigatorBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="ptProvidNavigatorBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd58Field1_na"
                                                        checked={this.state.napbcForm.ptProvidNavigatorBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="ptProvidNavigatorBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd58Field1_unk"
                                                        checked={this.state.napbcForm.ptProvidNavigatorBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="ptProvidNavigatorBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd59">Std 5.9</label>
                                                    <label className="stdDesc" id="bpmStd59Desc">Surgical Care</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd59Lb1" >Evidence-based guidelines followed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field1_no" //* 4579 *
                                                        checked={this.state.napbcForm.surgGuidlinesFollowedBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="surgGuidlinesFollowedBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field1_yes"
                                                        checked={this.state.napbcForm.surgGuidlinesFollowedBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="surgGuidlinesFollowedBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field1_na"
                                                        checked={this.state.napbcForm.surgGuidlinesFollowedBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="surgGuidlinesFollowedBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field1_unk"
                                                        checked={this.state.napbcForm.surgGuidlinesFollowedBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="surgGuidlinesFollowedBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd59Lb2" >Pre-op & post-op pt education provided</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field2_no" //* 4580 *
                                                        checked={this.state.napbcForm.prepostEducationBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="prepostEducationBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field2_yes"
                                                        checked={this.state.napbcForm.prepostEducationBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="prepostEducationBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field2_na"
                                                        checked={this.state.napbcForm.prepostEducationBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="prepostEducationBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field2_unk"
                                                        checked={this.state.napbcForm.prepostEducationBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="prepostEducationBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd59Lb3" >Pre-op & post-op functional assessment & referrals provided</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field3_no" //* 4581 *
                                                        checked={this.state.napbcForm.prepostReferralBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="prepostReferralBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field3_yes"
                                                        checked={this.state.napbcForm.prepostReferralBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="prepostReferralBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field3_na"
                                                        checked={this.state.napbcForm.prepostReferralBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="prepostReferralBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field3_unk"
                                                        checked={this.state.napbcForm.prepostReferralBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="prepostReferralBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd59Lb4" >Proper pain management strategies utilized</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field4_no" //* 4582 *
                                                        checked={this.state.napbcForm.painManagmentStratBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="painManagmentStratBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field4_yes"
                                                        checked={this.state.napbcForm.painManagmentStratBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="painManagmentStratBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field4_na"
                                                        checked={this.state.napbcForm.painManagmentStratBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="painManagmentStratBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd59Field4_unk"
                                                        checked={this.state.napbcForm.painManagmentStratBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="painManagmentStratBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd510">Std 5.10</label>
                                                    <label className="stdDesc" id="bpmStd510Desc">Reconstructive Surgery</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd510Lb1" >Pre-op referral offered for reconstruction/plastic surgeon</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field1_no" //* 4583 *
                                                        checked={this.state.napbcForm.preopReconDiscussBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="preopReconDiscussBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field1_yes"
                                                        checked={this.state.napbcForm.preopReconDiscussBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="preopReconDiscussBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field1_na"
                                                        checked={this.state.napbcForm.preopReconDiscussBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="preopReconDiscussBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field1_unk"
                                                        checked={this.state.napbcForm.preopReconDiscussBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="preopReconDiscussBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd510Lb2" >Multidisciplinary input on impact of reconstruction</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field2_no" //* 4584 *
                                                        checked={this.state.napbcForm.multiInputReconstructBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="multiInputReconstructBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field2_yes"
                                                        checked={this.state.napbcForm.multiInputReconstructBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="multiInputReconstructBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field2_na"
                                                        checked={this.state.napbcForm.multiInputReconstructBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="multiInputReconstructBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd510Field2_unk"
                                                        checked={this.state.napbcForm.multiInputReconstructBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="multiInputReconstructBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd511">Std 5.11</label>
                                                    <label className="stdDesc" id="bpmStd511Desc">Medical Oncology</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd511Lb1" >Care provided per evidence-based guidelines</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field1_no" //* 4585 *
                                                        checked={this.state.napbcForm.medOncoGuidlinesFollowedBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="medOncoGuidlinesFollowedBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field1_yes"
                                                        checked={this.state.napbcForm.medOncoGuidlinesFollowedBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="medOncoGuidlinesFollowedBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field1_na"
                                                        checked={this.state.napbcForm.medOncoGuidlinesFollowedBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="medOncoGuidlinesFollowedBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field1_unk"
                                                        checked={this.state.napbcForm.medOncoGuidlinesFollowedBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="medOncoGuidlinesFollowedBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd511Lb2" >Exercise therapy recommendations discussed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field2_no" //* 4586 *
                                                        checked={this.state.napbcForm.exerciseRecomendationsBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="exerciseRecomendationsBpm" value="No" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field2_yes"
                                                        checked={this.state.napbcForm.exerciseRecomendationsBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="exerciseRecomendationsBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field2_na"
                                                        checked={this.state.napbcForm.exerciseRecomendationsBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="exerciseRecomendationsBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd511Field2_unk"
                                                        checked={this.state.napbcForm.exerciseRecomendationsBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="exerciseRecomendationsBpm" value="Unk" />
                                                </div>

                                            </div>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <div className="_row" id="bpmTab2Row">
                                            <div className="emptyLine"></div>
                                            <div className="col-sm-6">
                                                <div>
                                                    <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd512">Std 5.12</label>
                                                    <label className="stdDesc" id="bpmStd512Desc">Radiation Oncology</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd512Lb1" >Care provided per evidence-based guidelines</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd512Field1_no" //* 4587 *
                                                        checked={this.state.napbcForm.radOncoGuidlinesFollowedBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="radOncoGuidlinesFollowedBpm" value="No" onKeyDown={this.keydownHandler} />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd512Field1_yes"
                                                        checked={this.state.napbcForm.radOncoGuidlinesFollowedBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="radOncoGuidlinesFollowedBpm" value="Yes" onKeyDown={this.keydownHandler} />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd512Field1_na"
                                                        checked={this.state.napbcForm.radOncoGuidlinesFollowedBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="radOncoGuidlinesFollowedBpm" value="N/A" onKeyDown={this.keydownHandler} />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd512Field1_unk"
                                                        checked={this.state.napbcForm.radOncoGuidlinesFollowedBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="radOncoGuidlinesFollowedBpm" value="Unk" onKeyDown={this.keydownHandler} />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd513">Std 5.13</label>
                                                    <label className="stdDesc" id="bpmStd513Desc">Surgical Pathology</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd513Lb1" >ER/PR/HER2 results in definitive surg path rpt</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd513Field1_no" //* 4588 *
                                                        checked={this.state.napbcForm.surgIncludeErPrHer2Bpm === "No"} onFocus={this.handleOnFocus}
                                                        name="surgIncludeErPrHer2Bpm" value="No" autoFocus onKeyDown={this.keydownHandler} />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd513Field1_yes"
                                                        checked={this.state.napbcForm.surgIncludeErPrHer2Bpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="surgIncludeErPrHer2Bpm" value="Yes" onKeyDown={this.keydownHandler} />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd513Field1_na"
                                                        checked={this.state.napbcForm.surgIncludeErPrHer2Bpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="surgIncludeErPrHer2Bpm" value="N/A" onKeyDown={this.keydownHandler} />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd513Field1_unk"
                                                        checked={this.state.napbcForm.surgIncludeErPrHer2Bpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="surgIncludeErPrHer2Bpm" value="Unk" onKeyDown={this.keydownHandler} />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd514">Std 5.14</label>
                                                    <label className="stdDesc" id="bpmStd514Desc">Breast Cancer Staging Using the AJCC System</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd514Lb1" >Path/post therapy staging documented and discussed w/pt</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd514Field1_no" //* 4589 *
                                                        checked={this.state.napbcForm.pathStagePosttxPatientBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="pathStagePosttxPatientBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd514Field1_yes"
                                                        checked={this.state.napbcForm.pathStagePosttxPatientBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="pathStagePosttxPatientBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd514Field1_na"
                                                        checked={this.state.napbcForm.pathStagePosttxPatientBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="pathStagePosttxPatientBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd514Field1_unk"
                                                        checked={this.state.napbcForm.pathStagePosttxPatientBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="pathStagePosttxPatientBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd515">Std 5.15</label>
                                                    <label className="stdDesc" id="bpmStd515Desc">Survivorship</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd515Lb1" >Persistent symptoms reviewed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field1_no" //* 4590 *
                                                        checked={this.state.napbcForm.persistentSymptomsBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="persistentSymptomsBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field1_yes"
                                                        checked={this.state.napbcForm.persistentSymptomsBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="persistentSymptomsBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field1_na"
                                                        checked={this.state.napbcForm.persistentSymptomsBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="persistentSymptomsBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field1_unk"
                                                        checked={this.state.napbcForm.persistentSymptomsBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="persistentSymptomsBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd515Lb2" >Functional status addressed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field2_no" //* 4591 *
                                                        checked={this.state.napbcForm.functionalStatusBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="functionalStatusBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field2_yes"
                                                        checked={this.state.napbcForm.functionalStatusBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="functionalStatusBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field2_na"
                                                        checked={this.state.napbcForm.functionalStatusBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="functionalStatusBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field2_unk"
                                                        checked={this.state.napbcForm.functionalStatusBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="functionalStatusBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd515Lb3" >Social and behavioral health assessed</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field3_no" //* 4592 *
                                                        checked={this.state.napbcForm.socialBehaveioralBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="socialBehaveioralBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field3_yes"
                                                        checked={this.state.napbcForm.socialBehaveioralBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="socialBehaveioralBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field3_na"
                                                        checked={this.state.napbcForm.socialBehaveioralBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="socialBehaveioralBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd515Field3_unk"
                                                        checked={this.state.napbcForm.socialBehaveioralBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="socialBehaveioralBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd515">Std 5.16</label>
                                                    <label className="stdDesc" id="bpmStd516Desc">Surveillance</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd516Lb1" >Surveillance followed according to evidence-based guidelines</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field1_no" //* 4593 *
                                                        checked={this.state.napbcForm.surveillanceGuidlinesFollowedBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="surveillanceGuidlinesFollowedBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field1_yes"
                                                        checked={this.state.napbcForm.surveillanceGuidlinesFollowedBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="surveillanceGuidlinesFollowedBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field1_na"
                                                        checked={this.state.napbcForm.surveillanceGuidlinesFollowedBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="surveillanceGuidlinesFollowedBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field1_unk"
                                                        checked={this.state.napbcForm.surveillanceGuidlinesFollowedBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="surveillanceGuidlinesFollowedBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd516Lb2" >Long-term and late effects addressed appropriately</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field2_no" //* 4594 *
                                                        checked={this.state.napbcForm.longtermLateeffectsBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="longtermLateeffectsBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field2_yes"
                                                        checked={this.state.napbcForm.longtermLateeffectsBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="longtermLateeffectsBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field2_na"
                                                        checked={this.state.napbcForm.longtermLateeffectsBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="longtermLateeffectsBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd516Field2_unk"
                                                        checked={this.state.napbcForm.longtermLateeffectsBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="longtermLateeffectsBpm" value="Unk" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6" id="bpmTab2RightColumn">
                                                <div>
                                                    <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                </div>
                                                <div>
                                                    <label className="stdID" id="bpmStd">Std 2.4</label>
                                                    <label className="stdDesc" id="bpmStd24Desc">Multidisciplinary Breast Care Conference</label>
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd24Lb1" >Prospective Presentation</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field1_no" //* 4602 *
                                                        checked={this.state.napbcForm.mbccProspectiveBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="mbccProspectiveBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field1_yes"
                                                        checked={this.state.napbcForm.mbccProspectiveBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="mbccProspectiveBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field1_na"
                                                        checked={this.state.napbcForm.mbccProspectiveBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="mbccProspectiveBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field1_unk"
                                                        checked={this.state.napbcForm.mbccProspectiveBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="mbccProspectiveBpm" value="Unk" />
                                                </div>
                                                <div>
                                                    <label className="stdField"  id="bpmStd24Lb2" >Retrospective presentation</label>
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field2_no" //* 4603 *
                                                        checked={this.state.napbcForm.mbccRetrospectiveBpm === "No"} onFocus={this.handleOnFocus}
                                                        name="mbccRetrospectiveBpm" value="No" autoFocus />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field2_yes"
                                                        checked={this.state.napbcForm.mbccRetrospectiveBpm === "Yes"} onFocus={this.handleOnFocus}
                                                        name="mbccRetrospectiveBpm" value="Yes" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field2_na"
                                                        checked={this.state.napbcForm.mbccRetrospectiveBpm === "N/A"} onFocus={this.handleOnFocus}
                                                        name="mbccRetrospectiveBpm" value="N/A" />
                                                    <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="bpmStd24Field2_unk"
                                                        checked={this.state.napbcForm.mbccRetrospectiveBpm === "Unk"} onFocus={this.handleOnFocus}
                                                        name="mbccRetrospectiveBpm" value="Unk" />
                                                </div>
                                                <div className="emptyLine"></div>
                                                <div className="bpmTab2TextAreas">
                                                    <label className="bCare">Barriers to Care</label>
                                                    <Input type="textarea" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} field="barriersCareBpm" lineStyle="oneLine"
                                                        labelText="" labelWidth="0px" width="500px" height="100px"
                                                        value={this.state.napbcForm.barriersCareBpm} itemNbr="4595"
                                                    />
                                                </div>
                                                <div className="bpmTab2TextAreas">
                                                    <label className="BPMAudit">BPM Audit Notes</label>
                                                    <Input type="textarea" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange} field="bpmAuditNotes" lineStyle="oneLine"
                                                        labelText="" labelWidth="0px" width="500px" height="100px"
                                                        value={this.state.napbcForm.bpmAuditNotes} itemNbr="4596"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>

                                    <TabPane tabId="3">
                                        <div className="row">
                                            <div className="col-sm-3 bpmCol1">
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Mammogram Date" field="mammoDate" lineStyle="oneLine"
                                                    //value={this.state.napbcForm.mammoDate != null && this.state.napbcForm.mammoDate !== "" ? moment(new Date(this.state.napbcForm.mammoDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.napbcForm.mammoDate}
                                                    labelWidth="150px" itemNbr="3053" autoFocus
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Mammogram Date" field="mammoDate"
                                                    value={this.state.napbcForm.mammoDate}
                                                    maxLength="10" aliasNo="3053" labelWidth="170px" width="100px"
                                                    lineStyle="oneLine" itemNbr="3053"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="40px"
                                                    labelText="Bilateral Mammogram" field="bilatMammo" lineStyle="oneLine"
                                                    value={this.state.napbcForm.bilatMammo ? this.state.napbcForm.bilatMammo : this.state.napbcForm.bilatMammo}
                                                    dataSource="Global_Dir_Mv" aliasNo="3052" titleDialog="Bilateral Mammogram" labelWidth="210px"
                                                    inValid={this.state.bAllItemFields["bilatMammo"]} itemNbr="3052"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["bilatMammo"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Type of Mammogram" field="mammoType" lineStyle="oneLine"
                                                    value={this.state.napbcForm.mammoType ? this.state.napbcForm.mammoType : this.state.napbcForm.mammoType}
                                                    dataSource="Global_Dir_Mv" aliasNo="4393" titleDialog="Type of Mammogram" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["mammoType"]} itemNbr="4393"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mammoType"] : ""}
                                                />
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Ultrasound Date" field="ultrasoundDate" lineStyle="oneLine"
                                                    //value={this.state.napbcForm.ultrasoundDate != null && this.state.napbcForm.ultrasoundDate !== "" ? moment(new Date(this.state.napbcForm.ultrasoundDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.napbcForm.ultrasoundDate}
                                                    labelWidth="150px" itemNbr="4394"
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Ultrasound Date" field="ultrasoundDate"
                                                    value={this.state.napbcForm.ultrasoundDate}
                                                    maxLength="10" aliasNo="4394" labelWidth="170px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4394"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Diagnostic Ultrasound" field="ultrasoundDiagnostic" lineStyle="oneLine"
                                                    value={this.state.napbcForm.ultrasoundDiagnostic ? this.state.napbcForm.ultrasoundDiagnostic : this.state.napbcForm.ultrasoundDiagnostic}
                                                    dataSource="Global_Dir_Mv" aliasNo="4395" titleDialog="Diagnostic Ultrasound" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["ultrasoundDiagnostic"]} itemNbr="4395"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ultrasoundDiagnostic"] : ""}
                                                />
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Mri Date" field="mriDate" lineStyle="oneLine"
                                                    //value={this.state.napbcForm.mriDate != null && this.state.napbcForm.mriDate !== "" ? moment(new Date(this.state.napbcForm.mriDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.napbcForm.mriDate}
                                                    labelWidth="150px" itemNbr="4396"
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Mri Date" field="mriDate"
                                                    value={this.state.napbcForm.mriDate}
                                                    maxLength="10" aliasNo="4396" labelWidth="170px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4396"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="MRI of Breast" field="mriBreast" lineStyle="oneLine"
                                                    value={this.state.napbcForm.mriBreast ? this.state.napbcForm.mriBreast : this.state.napbcForm.mriBreast}
                                                    dataSource="Global_Dir_Mv" aliasNo="4397" titleDialog="MRI of Breast" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["mriBreast"]} itemNbr="4397"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["mriBreast"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Outside Path Review" field="outsidePathReview" lineStyle="oneLine"
                                                    value={this.state.napbcForm.outsidePathReview ? this.state.napbcForm.outsidePathReview : this.state.napbcForm.outsidePathReview}
                                                    dataSource="Global_Dir_Mv" aliasNo="4403" titleDialog="Outside Path Review" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["outsidePathReview"]} itemNbr="4403"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["outsidePathReview"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Initial Biopsy Type" field="needleBxType" lineStyle="oneLine"
                                                    value={this.state.napbcForm.needleBxType ? this.state.napbcForm.needleBxType : this.state.napbcForm.needleBxType}
                                                    dataSource="Global_Dir_Mv" aliasNo="3660" titleDialog="Initial Biopsy Type" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["needleBxType"]} itemNbr="3660"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["needleBxType"] : ""}
                                                />
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Plastic Surg Referral Date" field="plasticSurgRefDate" lineStyle="oneLine"
                                                    //value={this.state.napbcForm.plasticSurgRefDate != null && this.state.napbcForm.plasticSurgRefDate !== "" ? moment(new Date(this.state.napbcForm.plasticSurgRefDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.napbcForm.plasticSurgRefDate}
                                                    labelWidth="150px" itemNbr="4404"
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Plastic Surg Referral Date" field="plasticSurgRefDate"
                                                    value={this.state.napbcForm.plasticSurgRefDate}
                                                    maxLength="10" aliasNo="4404" labelWidth="170px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4404"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Pre-op Plastic Surg Consult" field="prePlasticSurg" lineStyle="oneLine"
                                                    value={this.state.napbcForm.prePlasticSurg ? this.state.napbcForm.prePlasticSurg : this.state.napbcForm.prePlasticSurg}
                                                    dataSource="Global_Dir_Mv" aliasNo="3662" titleDialog="Pre-op Plastic Surg Consult" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["prePlasticSurg"]} itemNbr="3662"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["prePlasticSurg"] : ""}
                                                />
                                                <Input type="Physician" focused={this.handleOnFocus} changed={this.handleFormChange} width="70px"
                                                    labelText="Plastic Surgeon" field="plasticSurgeon" lineStyle="oneLine"
                                                    value={this.state.napbcForm.plasticSurgeon ? this.state.napbcForm.plasticSurgeon : this.state.napbcForm.plasticSurgeon}
                                                    dataSource="Physicians" titleDialog="Plastic Surgeon" labelWidth="180px"
                                                    inValid={this.state.bAllItemFields["plasticSurgeon"]} itemNbr="4405"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["plasticSurgeon"] : ""}
                                                />
                                                <Input type="label" changed={this.handleFormChange} labelWidth="110px" width="160px"
                                                    labelText="Surgeon Name" field="plasticSurgeonName" lineStyle="oneLine"
                                                    value={this.state.napbcForm.plasticSurgeonName} labelMainStyle="right"
                                                />
                                            </div>
                                            <div className="col-sm-3 bpmCol2">
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Genetic Referral for Counseling" field="geneticCounsel" lineStyle="oneLine"
                                                    value={this.state.napbcForm.geneticCounsel ? this.state.napbcForm.geneticCounsel : this.state.napbcForm.geneticCounsel}
                                                    dataSource="Global_Dir_Mv" aliasNo="3929" titleDialog="Genetic Referral for Counseling" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["geneticCounsel"]} itemNbr="3929"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["geneticCounsel"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Genetic Testing Results" field="geneticTestResults" lineStyle="oneLine"
                                                    value={this.state.napbcForm.geneticTestResults ? this.state.napbcForm.geneticTestResults : this.state.napbcForm.geneticTestResults}
                                                    dataSource="Global_Dir_Mv" aliasNo="4406" titleDialog="Genetic Testing Results" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["geneticTestResults"]} itemNbr="4406"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["geneticTestResults"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Fertility Consult" field="fertilityConsult" lineStyle="oneLine"
                                                    value={this.state.napbcForm.fertilityConsult ? this.state.napbcForm.fertilityConsult : this.state.napbcForm.fertilityConsult}
                                                    dataSource="Global_Dir_Mv" aliasNo="4407" titleDialog="Fertility Consult" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["fertilityConsult"]} itemNbr="4407"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fertilityConsult"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Gravida" field="gravida" lineStyle="oneLine"
                                                    value={this.state.napbcForm.gravida ? this.state.napbcForm.gravida : this.state.napbcForm.gravida}
                                                    dataSource="Global_Dir_Mv" aliasNo="4408" titleDialog="Gravida" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["gravida"]} itemNbr="4408"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gravida"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Para" field="para" lineStyle="oneLine"
                                                    value={this.state.napbcForm.para ? this.state.napbcForm.para : this.state.napbcForm.para}
                                                    dataSource="Global_Dir_Mv" aliasNo="4409" titleDialog="Para" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["para"]} itemNbr="4409"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["para"] : ""}
                                                />
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Screening Date" field="screeningDate" lineStyle="oneLine"
                                                    //value={this.state.napbcForm.screeningDate != null && this.state.napbcForm.screeningDate !== "" ? moment(new Date(this.state.napbcForm.screeningDate)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.napbcForm.screeningDate}
                                                    labelWidth="150px" itemNbr="4416"
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Screening Date" field="screeningDate"
                                                    value={this.state.napbcForm.screeningDate}
                                                    maxLength="10" aliasNo="4416" labelWidth="170px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4416"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Screening Results" field="screeningResults" lineStyle="oneLine"
                                                    value={this.state.napbcForm.screeningResults ? this.state.napbcForm.screeningResults : this.state.napbcForm.screeningResults}
                                                    dataSource="Global_Dir_Mv" aliasNo="4417" titleDialog="Screening Results" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["screeningResults"]} itemNbr="4417"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["screeningResults"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Eval of Non Malignant DZ Performed" field="evalNonMaligDzPerform" lineStyle="oneLine"
                                                    value={this.state.napbcForm.evalNonMaligDzPerform ? this.state.napbcForm.evalNonMaligDzPerform : this.state.napbcForm.evalNonMaligDzPerform}
                                                    dataSource="Global_Dir_Mv" aliasNo="4410" titleDialog="Eval of Non Malignant DZ Performed" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["evalNonMaligDzPerform"]} itemNbr="4410"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["evalNonMaligDzPerform"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Breast Conserving Surgery Offered" field="breastConserving" lineStyle="oneLine"
                                                    value={this.state.napbcForm.breastConserving ? this.state.napbcForm.breastConserving : this.state.napbcForm.breastConserving}
                                                    dataSource="Global_Dir_Mv" aliasNo="3663" titleDialog="Breast Conserving Surgery Offered" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["breastConserving"]} itemNbr="3663"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["breastConserving"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Margin Status" field="marginStatus" lineStyle="oneLine"
                                                    value={this.state.napbcForm.marginStatus ? this.state.napbcForm.marginStatus : this.state.napbcForm.marginStatus}
                                                    dataSource="Global_Dir_Mv" aliasNo="4411" titleDialog="Margin Status" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["marginStatus"]} itemNbr="4411"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["marginStatus"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Sentinel Node Biopsy Offered" field="sentNodeBxOffered" lineStyle="oneLine"
                                                    value={this.state.napbcForm.sentNodeBxOffered ? this.state.napbcForm.sentNodeBxOffered : this.state.napbcForm.sentNodeBxOffered}
                                                    dataSource="Global_Dir_Mv" aliasNo="3664" titleDialog="Sentinel Node Biopsy Offered" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["sentNodeBxOffered"]} itemNbr="3664"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["sentNodeBxOffered"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} width="110px"
                                                    labelText="Reason SLN Bx Not Offered" field="reasonSlnBxNotOffered" labelWidth="160px"
                                                    value={this.state.napbcForm.reasonSlnBxNotOffered} itemNbr="4412"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["reasonSlnBxNotOffered"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Type of Node Procedure" field="typeNodeProc" lineStyle="oneLine"
                                                    value={this.state.napbcForm.typeNodeProc ? this.state.napbcForm.typeNodeProc : this.state.napbcForm.typeNodeProc}
                                                    dataSource="Global_Dir_Mv" aliasNo="4413" titleDialog="Type of Node Procedure" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["typeNodeProc"]} itemNbr="4413"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["typeNodeProc"] : ""}
                                                />
                                            </div>
                                            <div className="col-sm-3 bpmCol3">
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Patient Navigation Offered" field="ptNavOffered" lineStyle="oneLine"
                                                    value={this.state.napbcForm.ptNavOffered ? this.state.napbcForm.ptNavOffered : this.state.napbcForm.ptNavOffered}
                                                    dataSource="Global_Dir_Mv" aliasNo="3665" titleDialog="Patient Navigation Offered" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["ptNavOffered"]} itemNbr="3665"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ptNavOffered"] : ""}
                                                />
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange} width="150px"
                                                    labelText="Navigator Name" field="navigatorName" lineStyle="oneLine" labelWidth="120px"
                                                    value={this.state.napbcForm.navigatorName} itemNbr="3666"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["navigatorName"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Navigator Position" field="navigatorPosition" lineStyle="oneLine"
                                                    value={this.state.napbcForm.navigatorPosition ? this.state.napbcForm.navigatorPosition : this.state.napbcForm.navigatorPosition}
                                                    dataSource="Global_Dir_Mv" aliasNo="4414" titleDialog="Navigator Position" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["navigatorPosition"]} itemNbr="4414"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["navigatorPosition"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="SCP Eligible" field="scpEligibleBpm" lineStyle="oneLine"
                                                    value={this.state.napbcForm.scpEligibleBpm ? this.state.napbcForm.scpEligibleBpm : this.state.napbcForm.scpEligibleBpm}
                                                    dataSource="Global_Dir_Mv" aliasNo="4418" titleDialog="SCP Eligible" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["scpEligibleBpm"]} itemNbr="4418"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["scpEligibleBpm"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="SCP Generated" field="scpGeneratedBpm" lineStyle="oneLine"
                                                    value={this.state.napbcForm.scpGeneratedBpm ? this.state.napbcForm.scpGeneratedBpm : this.state.napbcForm.scpGeneratedBpm}
                                                    dataSource="Global_Dir_Mv" aliasNo="4419" titleDialog="SCP Generated" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["scpGeneratedBpm"]} itemNbr="4419"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["scpGeneratedBpm"] : ""}
                                                />
                                                {/*
                                                <Input type="datePicker" focused={this.handleOnFocus} changed={this.handleFormChange} width="200px"
                                                    labelText="Date SCP Generated/Delivered" field="dateScpGeneratedBpm" lineStyle="oneLine"
                                                    //value={this.state.napbcForm.dateScpGeneratedBpm != null && this.state.napbcForm.dateScpGeneratedBpm !== "" ? moment(new Date(this.state.napbcForm.dateScpGeneratedBpm)).format("MM/DD/YYYY") : ""}
                                                    value={this.state.napbcForm.dateScpGeneratedBpm}
                                                    labelWidth="150px" itemNbr="4420"
                                                />
                                                */}
                                                <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="Date SCP Generated/Delivered" field="dateScpGeneratedBpm"
                                                    value={this.state.napbcForm.dateScpGeneratedBpm}
                                                    maxLength="10" aliasNo="4420" labelWidth="170px" width="100px"
                                                    lineStyle="oneLine" itemNbr="4420"
                                                    lostfocused={this.handleLostFocus}
                                                    placeholder="mm/dd/yyyy"
                                                />
                                                <Input type="label" changed={this.handleFormChange} labelText="Days Dx to SCP Gen/Delivered" field="daysDxToScpGeneratedBpm"
                                                    value={this.state.napbcForm.daysDxToScpGeneratedBpm} labelWidth="220px" width="50px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Tx Guidelines Used" field="rxGuidelinesUsed" lineStyle="oneLine"
                                                    value={this.state.napbcForm.rxGuidelinesUsed ? this.state.napbcForm.rxGuidelinesUsed : this.state.napbcForm.rxGuidelinesUsed}
                                                    dataSource="Global_Dir_Mv" aliasNo="3667" titleDialog="Tx Guidelines Used" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["rxGuidelinesUsed"]} itemNbr="3667"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["rxGuidelinesUsed"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="CAP Guidelines Followed" field="capGuidelinesFollowed" lineStyle="oneLine"
                                                    value={this.state.napbcForm.capGuidelinesFollowed ? this.state.napbcForm.capGuidelinesFollowed : this.state.napbcForm.capGuidelinesFollowed}
                                                    dataSource="Global_Dir_Mv" aliasNo="3668" titleDialog="CAP Guidelines Followed" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["capGuidelinesFollowed"]} itemNbr="3668"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["capGuidelinesFollowed"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Clinical Trial Placement" field="clinicalTrialPlacement" lineStyle="oneLine"
                                                    value={this.state.napbcForm.clinicalTrialPlacement ? this.state.napbcForm.clinicalTrialPlacement : this.state.napbcForm.clinicalTrialPlacement}
                                                    dataSource="Global_Dir_Mv" aliasNo="3669" titleDialog="Clinical Trial Placement" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["clinicalTrialPlacement"]} itemNbr="3669"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["clinicalTrialPlacement"] : ""}
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="30px"
                                                    labelText="Follow Up Surveillance Plan Followed" field="fuSurveilFollowed" lineStyle="oneLine"
                                                    value={this.state.napbcForm.fuSurveilFollowed ? this.state.napbcForm.fuSurveilFollowed : this.state.napbcForm.fuSurveilFollowed}
                                                    dataSource="Global_Dir_Mv" aliasNo="3670" titleDialog="Follow Up Surveillance Plan Followed" labelWidth="220px"
                                                    inValid={this.state.bAllItemFields["fuSurveilFollowed"]} itemNbr="3670"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fuSurveilFollowed"] : ""}
                                                />
                                                <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="40px"
                                                    labelText="Referred to Support/Rehab Services" field="referToSupport" lineStyle="oneLine"
                                                    value={this.state.napbcForm.referToSupport}
                                                    dataSource="Global_Dir_Mv" aliasNo="3672" titleDialog="Refer To Support" labelWidth="210px"
                                                    inValid={this.state.bAllItemFields["referToSupport"]} itemNbr="3672"
                                                    lostfocused={this.handleLostFocus} ignoreCheckValid={true}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referToSupport"] : ""}
                                                    isMultiSelection={true}
                                                />
                                            </div>
                                            <div className="col-sm-3 bpmCol4">
                                                <Input type="label" changed={this.handleFormChange}
                                                    labelText="Days from Diagnosis to Biopsy" field="daysDxToBx"
                                                    value={this.state.napbcForm.daysDxToBx} itemNbr="" labelWidth="240px" width="50px"
                                                />
                                                <Input type="label" changed={this.handleFormChange}
                                                    labelText="Days from Biopsy to MRI" field="daysBxToMri"
                                                    value={this.state.napbcForm.daysBxToMri} itemNbr="" labelWidth="240px" width="50px"
                                                />
                                                <Input type="label" changed={this.handleFormChange}
                                                    labelText="Days from MRI to First Tx Date" field="daysMriToInitTx"
                                                    value={this.state.napbcForm.daysMriToInitTx} itemNbr="" labelWidth="240px" width="50px"
                                                />
                                                <Input type="label" changed={this.handleFormChange}
                                                    labelText="Days from Biopsy to First Tx Date" field="daysBxToInitTx"
                                                    value={this.state.napbcForm.daysBxToInitTx} itemNbr="" labelWidth="240px" width="50px"
                                                />
                                                <Input type="label" changed={this.handleFormChange}
                                                    labelText="Days from Screening to Diagnosis Date" field="daysScreeningToDx"
                                                    value={this.state.napbcForm.daysScreeningToDx} itemNbr="" labelWidth="240px" width="50px"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="140px"
                                                    labelText={this.state.napbcForm.napbcUserLblText1 ? this.state.napbcForm.napbcUserLblText1 : "BMP User 1"}
                                                    field="napbcUser1" lineStyle="oneLine"
                                                    value={this.state.napbcForm.napbcUser1 ? this.state.napbcForm.napbcUser1 : this.state.napbcForm.napbcUser1}
                                                    dataSource="Global_Dir_Mv" aliasNo="3673" titleDialog="BMP User 1" labelWidth="150px"
                                                    inValid={this.state.bAllItemFields["napbcUser1"]} itemNbr="3673"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["napbcUser1"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="140px"
                                                    labelText={this.state.napbcForm.napbcUserLblText2 ? this.state.napbcForm.napbcUserLblText2 : "BMP User 2"}
                                                    field="napbcUser2" lineStyle="oneLine"
                                                    value={this.state.napbcForm.napbcUser2 ? this.state.napbcForm.napbcUser2 : this.state.napbcForm.napbcUser2}
                                                    dataSource="Global_Dir_Mv" aliasNo="3674" titleDialog="BMP User 2" labelWidth="150px"
                                                    inValid={this.state.bAllItemFields["napbcUser2"]} itemNbr="3674"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["napbcUser2"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="140px"
                                                    labelText={this.state.napbcForm.napbcUserLblText3 ? this.state.napbcForm.napbcUserLblText3 : "BMP User 3"}
                                                    field="napbcUser3" lineStyle="oneLine"
                                                    value={this.state.napbcForm.napbcUser3 ? this.state.napbcForm.napbcUser3 : this.state.napbcForm.napbcUser3}
                                                    dataSource="Global_Dir_Mv" aliasNo="3675" titleDialog="BPM User 3" labelWidth="150px"
                                                    inValid={this.state.bAllItemFields["napbcUser3"]} itemNbr="3675"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["napbcUser3"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="140px"
                                                    labelText={this.state.napbcForm.napbcUserLblText4 ? this.state.napbcForm.napbcUserLblText4 : "BMP User 4"}
                                                    field="napbcUser4" lineStyle="oneLine"
                                                    value={this.state.napbcForm.napbcUser4 ? this.state.napbcForm.napbcUser4 : this.state.napbcForm.napbcUser4}
                                                    dataSource="Global_Dir_Mv" aliasNo="3676" titleDialog="BPM User 4" labelWidth="150px"
                                                    inValid={this.state.bAllItemFields["napbcUser4"]} itemNbr="3676"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["napbcUser4"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange} width="140px"
                                                    labelText={this.state.napbcForm.napbcUserLblText5 ? this.state.napbcForm.napbcUserLblText5 : "BMP User 5"}
                                                    field="napbcUser5" lineStyle="oneLine"
                                                    value={this.state.napbcForm.napbcUser5 ? this.state.napbcForm.napbcUser5 : this.state.napbcForm.napbcUser5}
                                                    dataSource="Global_Dir_Mv" aliasNo="3677" titleDialog="BPM User 5" labelWidth="150px"
                                                    inValid={this.state.bAllItemFields["napbcUser5"]} itemNbr="3677"
                                                    lostfocused={this.handleLostFocus}
                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["napbcUser5"] : ""}
                                                    flag="ERS_UserDefinedMaintenance"
                                                />
                                                <Input type="textarea" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                    labelText="BPM Text" field="bpmText" labelWidth="70px" width="240px" height="100px"
                                                    value={this.state.napbcForm.bpmText} itemNbr="4415" lineStyle="oneLine"
                                                />
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetNapbc} formId="napbcForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />

                </div>
            )
            : <p className="data_loading_page">Breast Program data loading...</p>;
        return (
            <React.Fragment>
                {breastProgramMaintenance}

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                            /*
                            <Dialog
                                title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                                onClose={this.props.closeTextDialog}
                                width={950} height={"auto"}
                                className={classes.title}>*/
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                    {/*</Dialog>*/}
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

BreastProgramMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedNapbcRecord: state.patientAbstract.selectedNapbcRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        processingAndMovingPage: state.patientAbstract.processingAndMovingPage,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveNapbc: (napbcData) => dispatch(actionCreators.saveNapbc(napbcData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        selectNapbcRecord: (medial) => dispatch(actionCreators.selectNapbcRecord(medial)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BreastProgramMaintenance);