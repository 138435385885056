/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import axios from "../../../../axios-instance";
import "../PatientMaintenance/PatientMaintenance.css";
import "./CancerProgramMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import moment from "moment";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import classnames from "classnames";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";

class CancerProgramMaintenance extends Component {

    // At Cancer Program page, before initial page -> run this store procedure to get data:
    // Exec Abstract_Select 'value of CID'

    state = {
        activeTab: "1",
        cAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'cocForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty cocForm back to what it was on load
        initialCocForm: null,
        cocForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "cpmStd44Field1_no",
        //fieldInFocus: "ptNavProcess",
        isHelpVisible: false,
        isDialogVisible: true,
        focusingField: null,

        cocCancerConfMv: null,
        cocCancerConfMvItemFields: null,
        cocCancerConfMvLoading: false,

        cocClinicalTrialMv: null,
        cocClinicalTrialMvItemFields: null,
        cocClinicalTrialMvLoading: false,

        isShowConfirmDeleteMsg: false,
        confirmed: null,
        monitoring: null,

        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,

        cocCancerConfMvMaxlengthInput: null,
        cocClinicalTrialMvMaxlengthInput: null,
        cocCancerConfMvSelectedRowIndex: 0,//#PR00237 - because when initial page, it's focusing first row
        cocClinicalTrialSelectedRowIndex: -1,
    }

    reloadPageAfterSave = false;

    setCancerConfMvSelectionRow = (event, index) => {
        this.setState({ cocCancerConfMvSelectedRowIndex: index });
    }
    setClinicalTrialSelectionRow = (event, index) => {
        this.setState({ cocClinicalTrialSelectedRowIndex: index });
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    hideConfirmDeleteMsg = (event) => {
        this.setState({ isShowConfirmDeleteMsg: false });

        if (event.target.value === "yes" && this.state.monitoring) {
            switch (this.state.monitoring) {
                case "CocCancerConfMv":
                    this.deleteCocCancerConfMvGrid();
                    break;
                case "CocClinicalTrialMv":
                    this.deleteCocClinicalTrialMvGrid();
                    break;
                default:
                    break;
            }
        }
        return false;
    }

    closeWarningDialog = () => {
        this.setState({ isDialogVisible: false });
        return false;
    }

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);

        return false;
    }

    componentDidMount() {
        if (!this.state.cocForm && this.props.selectedCocRecord) {
            this.setFormData();
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.reloadPageAfterSave && !this.props.processingAndMovingPage) {
            this.props.selectCocRecord(this.props.selectedPrimarySummary);
            this.reloadPageAfterSave = false;
        }

        if ((!this.state.cocForm && this.props.selectedCocRecord)
            || (prevProps.selectedCocRecord && prevProps.selectedCocRecord !== this.props.selectedCocRecord)
            || (this.props.initialAbstractPages && this.props.selectedCocRecord)
        ) {
            this.setFormData();
        }

        // CocCancerConfMv List
        if (this.props.selectedPrimarySummary && !this.state.cocCancerConfMv && !this.state.cocCancerConfMvLoading) {
            this.setState({ cocCancerConfMvLoading: true });
            axios.post(this.props.clientgroup + "/GetCocCancerConfMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ cocCancerConfMv: res.data.cocCancerConfMvList });
                        
                        if (!this.state.cocCancerConfMvItemFields && res.data.cocCancerConfMvList) {
                            let objArr = [];
                            res.data.cocCancerConfMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ cocCancerConfMvItemFields: objArr });

                            // get maxlength for cocCancerConfMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Coc_CancerConf_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            cocCancerConfMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });
                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }
        // CocClinicalTrialMv List
        if (this.props.selectedPrimarySummary && !this.state.cocClinicalTrialMv && !this.state.cocClinicalTrialMvLoading) {
            this.setState({ cocClinicalTrialMvLoading: true });
            axios.post(this.props.clientgroup + "/GetCocClinicalTrialMvList", { cId: this.props.selectedPrimarySummary.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        this.setState({ cocClinicalTrialMv: res.data.cocClinicalTrialMvList });
                        
                        if (!this.state.cocClinicalTrialMvItemFields && res.data.cocClinicalTrialMvList) {
                            let objArr = [];
                            res.data.cocClinicalTrialMvList.map((obj) => {
                                let fields = {};
                                Object.keys(obj).map((t) => {
                                    fields[t] = "";
                                });
                                objArr.push(fields);
                            });

                            this.setState({ cocClinicalTrialMvItemFields: objArr });

                            // get maxlength for cocClinicalTrialMv grid
                            axios.post(this.props.clientgroup + "/GetTableStructure", { table: "Coc_ClinicalTrial_Mv", accessToken: localStorage.getItem('token') })
                                .then(res => {
                                    //update new accessToken again
                                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                                    if (newAccessToken) {
                                        localStorage.setItem('token', newAccessToken);
                                    }

                                    if (res.data !== null && res.data.result !== "") {
                                        let maxLengthValue = res.data.result;
                                        let maxLengthItem = objArr && objArr[0] ? objArr[0] : [];

                                        maxLengthValue = maxLengthValue.split(',');
                                        for (var i = 0; i < maxLengthValue.length; i++) {
                                            let obj = maxLengthValue[i].split(':');
                                            maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                                        }

                                        this.setState({
                                            cocClinicalTrialMvMaxlengthInput: { ...maxLengthItem }
                                        });
                                    }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    /*
                                    var btnLogout = document.getElementById("logoutButtonArea");
                                    if (btnLogout) {
                                        btnLogout.querySelector("a").click();
                                    }
                                    */
                                    //@TODO:...

                                    return false;
                                });

                            //update new accessToken again
                            let newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            };

                            this.setState({
                                hasSetMaxLength: true,
                                tableStructureList: res ? res.data.result : null
                            });

                        };
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    //@TODO:...

                    return false;
                });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem
            && this.state.cocForm && this.state.cAllItemFields && !this.props.processingAndMovingPage
        ) {
            console.log("[CancerProgram] = componentDidUpdate");
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                // updating the grid
                if (this.props.currentSelectedIndex !== -1) {
                    // cocClinicalTrialMv grid
                    this.setState(prevState => {
                        const newItems = [...prevState.cocClinicalTrialMvItemFields];
                        if (newItems[this.props.currentSelectedIndex]) {
                            newItems[this.props.currentSelectedIndex][this.props.currentSelectedField] = "";
                        }

                        const cocClinicalTrialMv = [...prevState.cocClinicalTrialMv];
                        if (cocClinicalTrialMv[this.props.currentSelectedIndex]) {
                            cocClinicalTrialMv[this.props.currentSelectedIndex][this.props.currentSelectedField + "Name"] = this.props.commonLookupItem.name;
                        }

                        return { cocClinicalTrialMvItemFields: newItems, cocClinicalTrialMv: cocClinicalTrialMv };
                    });
                } else {
                    this.setState({
                        cAllItemFields: { ...this.state.cAllItemFields, [this.props.currentSelectedField]: "" },
                        // And also update name again
                        cocForm: { ...this.state.cocForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    });
                }
            } else {
                this.setState({
                    cAllItemFields: { ...this.state.cAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    cocForm: { ...this.state.cocForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null) {
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Coc", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                //@TODO:...

                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res && res.data ? res.data.result : null
        });
    }

    // CocCancerConfMv grid - lostfocus
    handleCocCancerConfMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== ""
            && event.target.name !== "attendees" && event.target.name !== "txInitBeforePresentation" && event.target.name !== "txRecommendByMultiTeam")
        {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.cocCancerConfMv];
                        newItems[index][name] = value;
                        return { cocCancerConfMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.cocCancerConfMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { cocCancerConfMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.cocCancerConfMvItemFields];
                    newItems[index][name] = "";
                    return { cocCancerConfMvItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            this.setState(prevState => {
                const newItems = [...prevState.cocCancerConfMvItemFields];
                newItems[index][name] = "";
                return { cocCancerConfMvItemFields: newItems };
            });
        }
    }

    // onchange
    handleCocCancerConfMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => {
            const newItems = [...prevState.cocCancerConfMv];
            newItems[index][name] = value;
            return { cocCancerConfMv: newItems, isFormDirty: true };
        });
    }

    addNewCocCancerConfMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.cocCancerConfMv];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.cocCancerConfMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { cocCancerConfMv: newItems, cocCancerConfMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteLastCocCancerConfMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.cocCancerConfMvSelectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.cocCancerConfMv.length) {
            selectedRow = this.state.cocCancerConfMv.length - 1;
        }

        if (this.state.cocCancerConfMv[selectedRow] && this.state.cocCancerConfMv[selectedRow]["id"] !== undefined && this.state.cocCancerConfMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "CocCancerConfMv" });
        } else {
            this.deleteCocCancerConfMvGrid();
        }
        return false;
    }
    deleteCocCancerConfMvGrid = () => {
        let selectedRow = this.state.cocCancerConfMvSelectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.cocCancerConfMv.length) {
            selectedRow = this.state.cocCancerConfMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.cocCancerConfMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.cocCancerConfMv];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.cocCancerConfMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.cocCancerConfMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { cocCancerConfMv: newItems, cocCancerConfMvItemFields: newFields, cocCancerConfMvSelectedRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    // CocClinicalTrialMv grid - lostfocus
    handleCocClinicalTrialMvLostfocus = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;

        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            this.props.checkValidInput(name, value, table, key, index);

            //#20210125 - START
        } else if (event.target.hasAttribute && event.target.hasAttribute('data-isdatefield') && event.target.value !== "") {
            let mdy = event.target.getAttribute('data-isdatefield');
            let isvalid = true;
            if (mdy == 'day' && value != '99' && !(1 <= value && value <= 31)) {
                isvalid = false;
            } else if (mdy == 'month' && value != '99' && !(1 <= value && value <= 12)) {
                isvalid = false;
            } else if (mdy == 'year' && value != '9999' && !(1900 <= value && value <= 9999)) {
                let value = event.target.value;
                if (value.length == 2) {
                    let currentYear = (new Date().getFullYear() + "");
                    let yyVal = currentYear.substr(2, 2);
                    if (yyVal < value) {
                        value = (currentYear.substr(0, 2) - 1) + value;
                    } else {
                        value = currentYear.substr(0, 2) + value;
                    }

                    this.setState(prevState => {
                        const newItems = [...prevState.cocClinicalTrialMv];
                        newItems[index][name] = value;
                        return { cocClinicalTrialMv: newItems };
                    });
                } else {
                    isvalid = false;
                }
            }

            if (!isvalid) {
                this.setState(prevState => {
                    const newItems = [...prevState.cocClinicalTrialMvItemFields];
                    newItems[index][name] = "IN_VALID";
                    return { cocClinicalTrialMvItemFields: newItems };
                });
            } else {
                this.setState(prevState => {
                    const newItems = [...prevState.cocClinicalTrialMvItemFields];
                    newItems[index][name] = "";
                    return { cocClinicalTrialMvItemFields: newItems };
                });
            }
            //#20210125 - END

        } else {
            //let cocClinicalTrialMv = this.state.cocClinicalTrialMv;
            this.setState(prevState => {
                const newItems = [...prevState.cocClinicalTrialMv];
                newItems[index][name + "Name"] = "";//also clear values on name fields

                const newFields = [...prevState.cocClinicalTrialMvItemFields];
                newFields[index][name] = "";

                return { cocClinicalTrialMv: newItems, cocClinicalTrialMvItemFields: newFields };
            });
        }
    }
    // onchange
    handleCocClinicalTrialMvGridChange = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        if (event.target2) {
            const name2 = event.target2.name;
            const value2 = event.target2.value;
            this.setState(prevState => {
                const newItems = [...prevState.cocClinicalTrialMv];
                newItems[index][name] = value;
                newItems[index][name2] = value2;
                return { cocClinicalTrialMv: newItems, isFormDirty: true };
            });
        } else {
            this.setState(prevState => {
                const newItems = [...prevState.cocClinicalTrialMv];
                newItems[index][name] = value;
                return { cocClinicalTrialMv: newItems, isFormDirty: true };
            });
        }
    }

    addNewCocClinicalTrialMv = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.cocClinicalTrialMv];
            const firstItem = newItems[0];

            const newObj = {};
            Object.keys(firstItem).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newObj[t] = 0;
                } else {
                    newObj[t] = "";
                }
            });
            newItems.push(newObj);

            // 
            const newFields = [...this.state.cocClinicalTrialMvItemFields];
            const firstField = newFields[0];

            const newField = {};
            Object.keys(firstField).map((t, k) => {
                if (t === "id" || t === "cid" || t === "pid") {
                    newField[t] = 0;
                } else {
                    newField[t] = "";
                }
            });
            newFields.push(newField);
            return { cocClinicalTrialMv: newItems, cocClinicalTrialMvItemFields: newFields };
        });

        this.setState({ isFormDirty: true });
    }

    deleteLastCocClinicalTrialMv = (event) => {
        event.preventDefault();

        let selectedRow = this.state.cocClinicalTrialSelectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.cocClinicalTrialMv.length) {
            selectedRow = this.state.cocClinicalTrialMv.length - 1;
        }

        if (this.state.cocClinicalTrialMv[selectedRow] && this.state.cocClinicalTrialMv[selectedRow]["id"] !== undefined && this.state.cocClinicalTrialMv[selectedRow]["id"] !== 0) {
            this.setState({ isShowConfirmDeleteMsg: true, monitoring: "CocClinicalTrialMv" });
        } else {
            this.deleteCocClinicalTrialMvGrid();
        }
        return false;
    }
    deleteCocClinicalTrialMvGrid = () => {
        let selectedRow = this.state.cocClinicalTrialSelectedRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.cocClinicalTrialMv.length) {
            selectedRow = this.state.cocClinicalTrialMv.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.cocClinicalTrialMv];
            if (newItems.length === 1) {
                const _newItems = [...this.state.cocClinicalTrialMv];
                const firstItems = _newItems[0];

                const newObj = {};
                Object.keys(firstItems).map((t, k) => {
                    if (t === "id" || t === "pid" || t === "cid") {
                        //newObj[t] = 0;
                    } else {
                        newObj[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newObj);
            } else {
                newItems.splice(selectedRow, 1);
            }

            // clear invalid class
            const newFields = [...prevState.cocClinicalTrialMvItemFields];
            if (newFields.length === 1) {
                const _newFields = [...this.state.cocClinicalTrialMvItemFields];
                const firstField = _newFields[0];

                const newField = {};
                Object.keys(firstField).map((t, k) => {
                    newField[t] = "";
                });
                newFields.splice(selectedRow, 1);
                newFields.push(newField);
            } else {
                newFields.splice(selectedRow, 1);
            }

            if (selectedRow >= newFields.length) {
                selectedRow = newFields.length - 1;
            }

            return { cocClinicalTrialMv: newItems, cocClinicalTrialMvItemFields: newFields, cocClinicalTrialSelectedRowIndex: selectedRow };
        });

        this.setState({ isFormDirty: true });
    }

    setFormData = () => {
        this.setState({
            cocForm: {
                ...this.props.selectedCocRecord,
                ["dateScpGenerated"]: (this.props.selectedCocRecord != null && this.props.selectedCocRecord.dateScpGenerated !== null && this.props.selectedCocRecord.dateScpGenerated !== "" ? moment(new Date(this.props.selectedCocRecord.dateScpGenerated)).format("MM/DD/YYYY") : ""),
                ["dateQaPerformed"]: (this.props.selectedCocRecord != null && this.props.selectedCocRecord.dateQaPerformed !== null && this.props.selectedCocRecord.dateQaPerformed !== "" ? moment(new Date(this.props.selectedCocRecord.dateQaPerformed)).format("MM/DD/YYYY") : ""),
                ["screenDate"]: (this.props.selectedCocRecord != null && this.props.selectedCocRecord.screenDate !== null && this.props.selectedCocRecord.screenDate !== "" ? moment(new Date(this.props.selectedCocRecord.screenDate)).format("MM/DD/YYYY") : ""),
            },
            isFormDirty: this.props.isFormDirty
        });

        if (!this.state.cAllItemFields && this.props.selectedCocRecord) {
            let fields = {};
            Object.keys(this.props.selectedCocRecord).map((t) => {
                fields[t] = "";
            });

            this.setState({ cAllItemFields: fields, pageMaxlengthInput: fields });
        };

        this.props.setInitialAbstractPages(false);
        
        return false;
    }

    toggleTab = (event, tab) => {
        event.preventDefault();

        this.setState({ activeTab: tab });
        var _this = this;
        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("cpmStd44Field1_no") ? document.getElementById("cpmStd44Field1_no").focus() : "";
            }, 0);
        } else if (tab === "2") {
            setTimeout(function () {
                document.getElementById("ptNavProcess") ? document.getElementById("ptNavProcess").focus() : "";
            }, 0);
        } else if (tab === "3") {
            setTimeout(function () {
                document.getElementById("ccDateMonth0") ? document.getElementById("ccDateMonth0").focus() : "";
                // also reset the index for first focusing row
                _this.setState({ cocCancerConfMvSelectedRowIndex: 0 });
            }, 0);
        }

        return false;
    }

    // Add clear and check validate of date fields function -- START
    clearInvalidOfDateFields = (formItems, field, onGrid, index) => {
        let tYear = field + "Year";
        let tMonth = field + "Month";
        let tDay = field + "Day";
        if (onGrid && onGrid === "cocCancerConfMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.cocCancerConfMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { cocCancerConfMvItemFields: newItems };
            });
        } else if (onGrid && onGrid === "cocClinicalTrialMvList") {
            this.setState(prevState => {
                let newItems = [...prevState.cocClinicalTrialMvItemFields];
                newItems[index][tYear] = "";
                newItems[index][tMonth] = "";
                newItems[index][tDay] = "";
                return { cocClinicalTrialMvItemFields: newItems };
            });
        } else {
            this.setState({
                cAllItemFields: {
                    ...this.state.cAllItemFields,
                    [tYear]: "",
                    [tMonth]: "",
                    [tDay]: "",
                }
            });
        }
        formItems[tYear] = "";
        formItems[tMonth] = "";
        formItems[tDay] = "";
    }

    checkValidateOfDateFields = (pFormData, field, onGrid, index) => {
        let isValidDateInput = true;

        var tYear = field + "Year";
        var tMonth = field + "Month";
        var tDay = field + "Day";

        // Check valid of date in February
        var year = pFormData[tYear] ? pFormData[tYear] : "";
        var month = pFormData[tMonth] ? pFormData[tMonth] : "";
        var day = pFormData[tDay] ? pFormData[tDay] : "";

        pFormData[field] = new Date(year + "-" + month + "-" + day);

        if (year !== "" && month !== "" && day !== "") {
            var _day, A = (month + '/' + day + '/' + year).match(/[1-9][\d]*/g);
            try {
                A[0] -= 1;
                _day = new Date(+A[2], A[0], +A[1]);
                if ((_day.getMonth() == A[0] && _day.getDate() == A[1])
                                /*|| year == '9999'*/ || month == '99' || day == '99') {
                } else {
                    isValidDateInput = false;

                    if (onGrid && onGrid === "cocCancerConfMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.cocCancerConfMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { cocCancerConfMvItemFields: newItems };
                        });
                    } else if (onGrid && onGrid === "cocClinicalTrialMvList") {
                        this.setState(prevState => {
                            const newItems = [...prevState.cocClinicalTrialMvItemFields];
                            newItems[index][tYear] = "IN_VALID";
                            newItems[index][tMonth] = "IN_VALID";
                            newItems[index][tDay] = "IN_VALID";
                            return { cocClinicalTrialMvItemFields: newItems };
                        });
                    } else {
                        this.setState({
                            cAllItemFields: {
                                ...this.state.cAllItemFields,
                                [tYear]: "IN_VALID",
                                [tMonth]: "IN_VALID",
                                [tDay]: "IN_VALID",
                            }
                        });
                    }
                }
            } catch (er) {
                isValidDateInput = false;
            }
        }

        return isValidDateInput;
    }
    // -- END

    saveCoc = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.cAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        // CocCancerConfMv grid
        if (isValid) {
            const pItems = this.state.cocCancerConfMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field === "ccDate" || field === "CcDate") {
                        this.clearInvalidOfDateFields(prm, field, "cocCancerConfMvList", index);
                    }

                    if (prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        // CocClinicalTrialMv grid
        if (isValid) {
            const pItems = this.state.cocClinicalTrialMvItemFields;
            pItems.map((prm, index) => {
                Object.keys(prm).map((field) => {
                    // clear IN_VALID flag for all date fields
                    if (field == "accrualDate" || field == "ctrialStartDate") {
                        this.clearInvalidOfDateFields(prm, field, "cocClinicalTrialMvList", index);
                    }

                    // must check trackPhysName field by duplicated in letters grid
                    if (field !== "trackPhysName" && prm[field] === "IN_VALID") {
                        return isValid = false;
                    }
                });
            });
        }

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let cocFormData = this.state.cocForm;
            let cocCancerConfMvList = this.state.cocCancerConfMv;
            let cocClinicalTrialMvList = this.state.cocClinicalTrialMv;

            let isValidDateInput = true;

            //#20210125 - START
            cocCancerConfMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t === "ccDate" || t === "CcDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "cocCancerConfMvList", index);
                    }
                });
            });
            cocClinicalTrialMvList.map((obj, index) => {
                Object.keys(obj).map((t) => {
                    if (t == "accrualDate" || t == "ctrialStartDate") {
                        isValidDateInput = isValidDateInput && this.checkValidateOfDateFields(obj, t, "cocClinicalTrialMvList", index);
                    }
                });
            });

            if (!isValidDateInput && !allowSaveAnyway) return false;

            cocFormData.cocCancerConfMvList = cocCancerConfMvList;
            cocFormData.cocClinicalTrialMvList = cocClinicalTrialMvList;

            if (this.props.selectedPrimarySummary && (!cocFormData.cid || cocFormData.cid == 0)) {
                cocFormData.cid = this.props.selectedPrimarySummary.cid;
            }

            if (cocFormData["dateScpGenerated"] == "Invalid date") {
                cocFormData["dateScpGenerated"] = null;
            }
            if (cocFormData["dateQaPerformed"] == "Invalid date") {
                cocFormData["dateQaPerformed"] = null;
            }
            if (cocFormData["screenDate"] == "Invalid date") {
                cocFormData["screenDate"] = null;
            }

            this.props.saveCoc(cocFormData);
            this.reloadPageAfterSave = true;
            return false;
        }
    }

    saveTextDialog = (event) => {
        event.preventDefault();

        // TODO Form validation
        if (true) {
            let textDialogData = this.state.textDialogForm;
            this.props.saveTextDialog(textDialogData);
        }
    }

    extraAction = (index) => {
        switch (index) {
            case 1:
                alert(1);
                break;
            case 2:
                alert(2);
                break;
            default:
                break;
        }
    }

    resetCoc = () => {
        this.setState({
            cocForm: { ...this.state.initialCocForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        //event.preventDefault();
        this.setState({ fieldInFocus: event.target.name, focusingField: event.target });
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        this.setState({ isHelpVisible: true });
        return false;
    }

    closeCodeDialog = () => {
        this.setState({ isHelpVisible: false });
        return false;
    }

    setChartOption = (event) => {
        let curValue = this.state.cocForm[event.target.name];
        let newValue = null;

        if (curValue === null || curValue === "") {
            newValue = event.target.value;
        } else {
            newValue = (curValue != event.target.value ? event.target.value : null)
        }

        this.setState({
            cocForm: {
                ...this.state.cocForm,
                // [event.target.name]: event.target.value
                [event.target.name]: newValue
            },
            isFormDirty: true,
        });
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "" && event.target.name !== "refSupportServ") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            this.props.checkValidInput(name, value, table, key, -1, flag);
        } else {
            this.setState({ cAllItemFields: { ...this.state.cAllItemFields, [event.target.name]: "" } });
        }

        let fName = event.target.name;
        if (fName === "dateScpGenerated" || fName === "dateQaPerformed" || fName === "screenDate") {
            let thisValue = event.target.value;
            let thisDate = this.Coc_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            if (thisDate && thisDate != undefined && thisDate != "" && thisDate != thisValue) {
                this.setState({
                    cocForm: {
                        ...this.state.cocForm,
                        [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY")
                    }
                });
            }
        }
    }
    // --END

    Coc_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        // adding rather
        if (myDate.length > 10) {
            myDate = myDate.substr(0, 10);
        }
        //

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    handleFormChange = (event) => {
        var cocForm = this.state.cocForm;
        if (event.target.name && (
            event.target.name.indexOf("dateScpGenerated") != -1
            || event.target.name.indexOf("dateQaPerformed") != -1
            || event.target.name.indexOf("screenDate") != -1 )//
        ) {
            // Hold
            cocForm["hasChangedDateCoc"] = "1";
        }
        console.log(cocForm);

        if (event.target2) {
            this.setState({ 
                cocForm: { ...cocForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value 
                },
                isFormDirty: true 
            });
        } else {
            this.setState({
                cocForm: { ...cocForm,
                    [event.target.name]: event.target.value
                },
                isFormDirty: true 
            });
        }
    }

    keydownHandler = (event) => {
        if (event.key === "Tab" && !event.shiftKey && event.target.name === "cpmAuditNotes") {
            document.getElementById("linkOfCCServicesTab").click();
        }
    }

    render() {
        let confirmDeleteMsg;
        {
            confirmDeleteMsg = this.state.isShowConfirmDeleteMsg ?
                /*
                <Dialog title="Delete Row"
                    onClose={this.hideConfirmDeleteMsg}
                    width={300} height={"auto"}
                    className={classes.title}>*/
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Delete Row</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmDeleteMsg(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>OK to delete row?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={this.hideConfirmDeleteMsg} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmDeleteMsg} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                        {/*</Dialog>*/}
                    </Draggable>
                </div>
                : null
        }

        const CancerProgramMaintenance = this.state.cocForm && !this.props.isPatientLoading ?
            (
                <div className="container-fluid">
                    {this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus} focusingField={this.state.focusingField} closeCode={this.closeCodeDialog} />}

                    <form onSubmit={this.saveCoc} id="cocForm" className="DialogForm TabParentPage">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo">
                                    <button type="button" onClick={this.getHelp}><img src="https://crstar.ers-can.com/images/$sys_question.png.a5image"/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        //this.props.selectedPatient.dob != null && this.props.selectedPatient.dob !== "" ? moment(new Date(this.props.selectedPatient.dob)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        //this.props.selectedPrimarySummary.diagnosisDate != null && this.props.selectedPrimarySummary.diagnosisDate !== "" ? moment(new Date(this.props.selectedPrimarySummary.diagnosisDate)).format("MM/DD/YYYY") : ""
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>

                        <div className="emptyLine"></div>

                        <div className="_row">   
                            <div className="__frameInner">
                                {/*<div className="header">&nbsp;</div>*/}
                                <div style={{ /*border: "1px solid #eee", marginBottom: "15px"*/ }}>
                                    <Nav tabs>
                                        <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={(event) => { this.toggleTab(event, "1") }} href="#" id="linkOfCOCChartAuditTab">
                                            CPM Chart Audit
                                        </NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={(event) => { this.toggleTab(event, "2") }} href="#" id="linkOfCCServicesTab">
                                            Continuum of Care Services
                                        </NavLink>
                                        <NavLink className={classnames({ active: this.state.activeTab === "3" })} onClick={(event) => { this.toggleTab(event, "3") }} href="#" id="linkOfCCClinicalTrialsTab">
                                            Cancer Conferences & Clinical Trials
                                        </NavLink>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>

                                        <TabPane tabId="1">
                                            <div className="_row" id="cpmTab1Row">
                                                <div className="emptyLine"></div>
                                                <div className="col-sm-6">
                                                    <div>
                                                        <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdID" id="cpmStd44">Std 4.4</label>
                                                        <label className="stdDesc" id="cpmStd44Desc">Genetic Counseling and Risk Assessment</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd44Lb1" >Genetic Counseling Recommended</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field1_no" //* 4371 *
                                                            checked={this.state.cocForm.geneticCounselRecommend === "No"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRecommend" value="No" autoFocus />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field1_yes"
                                                            checked={this.state.cocForm.geneticCounselRecommend === "Yes"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRecommend" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field1_na"
                                                            checked={this.state.cocForm.geneticCounselRecommend === "N/A"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRecommend" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field1_unk"
                                                            checked={this.state.cocForm.geneticCounselRecommend === "Unk"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRecommend" value="Unk" />
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd44Lb2" >Genetic Counseling Ref Made</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field2_no" //* 4372 *
                                                            checked={this.state.cocForm.geneticCounselRefMade === "No"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRefMade" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field2_yes"
                                                            checked={this.state.cocForm.geneticCounselRefMade === "Yes"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRefMade" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field2_na"
                                                            checked={this.state.cocForm.geneticCounselRefMade === "N/A"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRefMade" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field2_unk"
                                                            checked={this.state.cocForm.geneticCounselRefMade === "Unk"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselRefMade" value="Unk" />
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd44Lb3" >Genetic Counseling Appointment Completed</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field3_no" //* 4373 *
                                                            checked={this.state.cocForm.geneticCounselCompleted === "No"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselCompleted" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field3_yes"
                                                            checked={this.state.cocForm.geneticCounselCompleted === "Yes"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselCompleted" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field3_na"
                                                            checked={this.state.cocForm.geneticCounselCompleted === "N/A"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselCompleted" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd44Field3_unk"
                                                            checked={this.state.cocForm.geneticCounselCompleted === "Unk"} onFocus={this.handleOnFocus}
                                                            name="geneticCounselCompleted" value="Unk" />
                                                    </div>
                                                    <div>
                                                        <label className="stdID" id="cpmStd51">Std 5.1</label>
                                                        <label className="stdDesc" id="cpmStd51Desc">College of American Path Synoptic Reporting</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd51Lb" >CAP Guidelines Followed</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd51Field_no" //* 3648 *
                                                            checked={this.state.cocForm.capProtocolsFollowed === "No"} onFocus={this.handleOnFocus}
                                                            name="capProtocolsFollowed" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd51Field_yes"
                                                            checked={this.state.cocForm.capProtocolsFollowed === "Yes"} onFocus={this.handleOnFocus}
                                                            name="capProtocolsFollowed" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd51Field_na"
                                                            checked={this.state.cocForm.capProtocolsFollowed === "N/A"} onFocus={this.handleOnFocus}
                                                            name="capProtocolsFollowed" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd51Field_unk"
                                                            checked={this.state.cocForm.capProtocolsFollowed === "Unk"} onFocus={this.handleOnFocus}
                                                            name="capProtocolsFollowed" value="Unk" />
                                                    </div>
                                                    <div>
                                                        <label className="stdID" id="cpmStd52">Std 5.2</label>
                                                        <label className="stdDesc" id="cpmStd52Desc">Psychosocial Distress Screening</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd52Lb" >Psychosocial Distress Screening Performed</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd52Field_no" //* 4377 *
                                                            checked={this.state.cocForm.psychosocialDistressScreening === "No"} onFocus={this.handleOnFocus}
                                                            name="psychosocialDistressScreening" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd52Field_yes"
                                                            checked={this.state.cocForm.psychosocialDistressScreening === "Yes"} onFocus={this.handleOnFocus}
                                                            name="psychosocialDistressScreening" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd52Field_na"
                                                            checked={this.state.cocForm.psychosocialDistressScreening === "N/A"} onFocus={this.handleOnFocus}
                                                            name="psychosocialDistressScreening" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd52Field_unk"
                                                            checked={this.state.cocForm.psychosocialDistressScreening === "Unk"} onFocus={this.handleOnFocus}
                                                            name="psychosocialDistressScreening" value="Unk" />
                                                    </div>

                                                    <div>
                                                        <label className="stdID" id="cpmStd53">Std 5.3</label>
                                                        <label className="stdDesc" id="cpmStd53Desc">Sentinel Node Biopsy for Breast Cancer</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd53Lb" >Procedure Performed and documented appropriately</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd53Field_no" //* 4422 *
                                                            checked={this.state.cocForm.synopticOpReportBreastSlnb === "No"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastSlnb" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd53Field_yes"
                                                            checked={this.state.cocForm.synopticOpReportBreastSlnb === "Yes"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastSlnb" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd53Field_na"
                                                            checked={this.state.cocForm.synopticOpReportBreastSlnb === "N/A"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastSlnb" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd53Field_unk"
                                                            checked={this.state.cocForm.synopticOpReportBreastSlnb === "Unk"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastSlnb" value="Unk" />
                                                    </div>

                                                    <div>
                                                        <label className="stdID" id="cpmStd54">Std 5.4</label>
                                                        <label className="stdDesc" id="cpmStd54Desc">Axillary LND for Breast Cancer</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd54Lb" >Procedure Performed and documented appropriately</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd54Field_no" //* 4378 *
                                                            checked={this.state.cocForm.synopticOpReportBreastLnProc === "No"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastLnProc" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd54Field_yes"
                                                            checked={this.state.cocForm.synopticOpReportBreastLnProc === "Yes"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastLnProc" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd54Field_v2"
                                                            checked={this.state.cocForm.synopticOpReportBreastLnProc === "N/A"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastLnProc" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd54Field_v3"
                                                            checked={this.state.cocForm.synopticOpReportBreastLnProc === "Unk"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportBreastLnProc" value="Unk" />
                                                    </div>

                                                    <div>
                                                        <label className="stdID" id="cpmStd55">Std 5.5</label>
                                                        <label className="stdDesc" id="cpmStd55Desc">Wide Local Excision for Primary Cutaneous Melanoma</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd55Lb" >Procedure Performed and documented appropriately</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd55Field_no" //* 4379 *
                                                            checked={this.state.cocForm.synopticOpReportMelanoma === "No"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportMelanoma" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd55Field_yes"
                                                            checked={this.state.cocForm.synopticOpReportMelanoma === "Yes"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportMelanoma" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd55Field_na"
                                                            checked={this.state.cocForm.synopticOpReportMelanoma === "N/A"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportMelanoma" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd55Field_unk"
                                                            checked={this.state.cocForm.synopticOpReportMelanoma === "Unk"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportMelanoma" value="Unk" />
                                                    </div>

                                                </div>
                                                <div className="col-sm-6" id="cpmTab1RightColumn">
                                                    <div>
                                                        <label className="stdHeader">No&nbsp;&nbsp;Yes&nbsp;&nbsp;N/A&nbsp;&nbsp;Unk</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdID" id="cpmStd56">Std 5.6</label>
                                                        <label className="stdDesc" id="cpmStd56Desc">Colon Resection</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd56Lb" >Procedure Performed and documented appropriately</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd56Field_no" //* 4380 *
                                                            checked={this.state.cocForm.synopticOpReportColonResection === "No"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportColonResection" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd56Field_yes"
                                                            checked={this.state.cocForm.synopticOpReportColonResection === "Yes"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportColonResection" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd56Field_na"
                                                            checked={this.state.cocForm.synopticOpReportColonResection === "N/A"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportColonResection" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd56Field_unk"
                                                            checked={this.state.cocForm.synopticOpReportColonResection === "Unk"} onFocus={this.handleOnFocus}
                                                            name="synopticOpReportColonResection" value="Unk" />
                                                    </div>

                                                    <div>
                                                        <label className="stdID" id="cpmStd57">Std 5.7</label>
                                                        <label className="stdDesc" id="cpmStd57Desc">Total Mesorectal Excision</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd57Lb" >Procedure Performed and documented appropriately</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd57Field_no" //* 4381 *
                                                            checked={this.state.cocForm.totalMesorectalExcsAchRectal === "No"} onFocus={this.handleOnFocus}
                                                            name="totalMesorectalExcsAchRectal" value="No" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd57Field_yes"
                                                            checked={this.state.cocForm.totalMesorectalExcsAchRectal === "Yes"} onFocus={this.handleOnFocus}
                                                            name="totalMesorectalExcsAchRectal" value="Yes" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd57Field_na"
                                                            checked={this.state.cocForm.totalMesorectalExcsAchRectal === "N/A"} onFocus={this.handleOnFocus}
                                                            name="totalMesorectalExcsAchRectal" value="N/A" />
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd57Field_unk"
                                                            checked={this.state.cocForm.totalMesorectalExcsAchRectal === "Unk"} onFocus={this.handleOnFocus}
                                                            name="totalMesorectalExcsAchRectal" value="Unk" />
                                                    </div>

                                                    <div>
                                                        <label className="stdID" id="cpmStd58">Std 5.8</label>
                                                        <label className="stdDesc" id="cpmStd58Desc">Pulmonary Resection</label>
                                                    </div>
                                                    <div>
                                                        <label className="stdField"  id="cpmStd58Lb" >Procedure Performed and documented appropriately</label>
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd58Field_no" //* 4382 *
                                                            checked={this.state.cocForm.pulmonaryResectionIncSufficientLn === "No"} onFocus={this.handleOnFocus}
                                                            name="pulmonaryResectionIncSufficientLn" value="No" />  
                                                            {/* onKeyDown={this.keydownHandler} /> */}
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd58Field_yes"
                                                            checked={this.state.cocForm.pulmonaryResectionIncSufficientLn === "Yes"} onFocus={this.handleOnFocus}
                                                            name="pulmonaryResectionIncSufficientLn" value="Yes" />
                                                            {/* onKeyDown={this.keydownHandler} /> */}
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd58Field_na"
                                                            checked={this.state.cocForm.pulmonaryResectionIncSufficientLn === "N/A"} onFocus={this.handleOnFocus}
                                                            name="pulmonaryResectionIncSufficientLn" value="N/A" />
                                                            {/* onKeyDown={this.keydownHandler} /> */}
                                                        <input type="radio" onClick={this.setChartOption} onChange={this.setChartOption} id="cpmStd58Field_unk"
                                                            checked={this.state.cocForm.pulmonaryResectionIncSufficientLn === "Unk"} onFocus={this.state.handleOnFocus}
                                                            name="pulmonaryResectionIncSufficientLn" value="Unk" /> 
                                                            {/* onKeyDown={this.keydownHandler} /> */}
                                                    </div>
                                                    <div className="emptyLine"></div>
                                                    <div>
                                                        <label className="CPMAudit">CPM Audit Notes</label>
                                                        <Input type="textarea" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} field="cpmAuditNotes" lineStyle="oneLine"
                                                            labelText="" labelWidth="0px" width="500px" height="100px"
                                                            value={this.state.cocForm.cpmAuditNotes} itemNbr="4555"
                                                            onKeyDown={this.keydownHandler}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="2"> 
                                            <div className="row">
                                                <div className="col-sm-4 __tabInnerCls">
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="Patient Nav Process" field="ptNavProcess" lineStyle="oneLine"
                                                            value={this.state.cocForm.ptNavProcess ? this.state.cocForm.ptNavProcess : this.state.cocForm.PtNavProcess}
                                                            dataSource="Global_Dir_Mv" aliasNo="3651" titleDialog="Pt Nav Process"
                                                            inValid={this.state.cAllItemFields["ptNavProcess"]} itemNbr="3651"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ptNavProcess"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="input" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="210px"
                                                            labelText="Navigator Name" field="navigatorNameCpm" lineStyle="oneLine"
                                                            value={this.state.cocForm.navigatorNameCpm} itemNbr="4366"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["navigatorNameCpm"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="Surv Care Plan Eligible" field="scpEligible" lineStyle="oneLine"
                                                            value={this.state.cocForm.scpEligible ? this.state.cocForm.scpEligible : this.state.cocForm.ScpEligible}
                                                            dataSource="Global_Dir_Mv" aliasNo="4369" titleDialog="SCP Eligible"
                                                            inValid={this.state.cAllItemFields["scpEligible"]} itemNbr="4369"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["scpEligible"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="Survivorship Care Plan Gen" field="survCarePlan" lineStyle="oneLine"
                                                            value={this.state.cocForm.survCarePlan ? this.state.cocForm.survCarePlan : this.state.cocForm.SurvCarePlan}
                                                            dataSource="Global_Dir_Mv" aliasNo="3652" titleDialog="Surv Care Plan"
                                                            inValid={this.state.cAllItemFields["survCarePlan"]} itemNbr="3652"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["survCarePlan"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Date SCP Generated/Delivered" field="dateScpGenerated"
                                                            value={this.state.cocForm.dateScpGenerated}
                                                            maxLength="10" aliasNo="4367" labelWidth="200px" width="100px"
                                                            lineStyle="oneLine" itemNbr="4367"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="label" changed={this.handleFormChange} lineStyle="oneLine"
                                                            labelText="Days Dx to SCP Gen/Delivered" field="daysDxToScpGenerated"
                                                            value={this.state.cocForm.daysDxToScpGenerated} labelWidth="200px" width="55px" height="25px" />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeMultiSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="200px"
                                                            labelText="Ref to Support/Rehab Serv" field="refSupportServ" lineStyle="oneLine"
                                                            value={this.state.cocForm.refSupportServ ? this.state.cocForm.refSupportServ : this.state.cocForm.RefSupportServ}
                                                            dataSource="Global_Dir_Mv" aliasNo="3653" titleDialog="Ref Support Serv"
                                                            inValid={this.state.cAllItemFields["refSupportServ"]} itemNbr="3653"
                                                            lostfocused={this.handleLostFocus} ignoreCheckValid={true}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["refSupportServ"] : ""}
                                                            isMultiSelection={true}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="Pall Care Services" field="pallCareServices" lineStyle="oneLine"
                                                            value={this.state.cocForm.pallCareServices ? this.state.cocForm.pallCareServices : this.state.cocForm.pallCareServices}
                                                            dataSource="Global_Dir_Mv" aliasNo="4370" titleDialog="Pall Care Services"
                                                            inValid={this.state.cAllItemFields["pallCareServices"]} itemNbr="4370"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pallCareServices"] : ""}
                                                        />
                                                    </div>
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="Genetic Counsel Recommend" field="geneticCounselRecommend" lineStyle="oneLine"
                                                            value={this.state.cocForm.geneticCounselRecommend ? this.state.cocForm.geneticCounselRecommend : this.state.cocForm.geneticCounselRecommend}
                                                            dataSource="Global_Dir_Mv" aliasNo="4371" titleDialog="Genetic Counsel Recommend"
                                                            inValid={this.state.cAllItemFields["geneticCounselRecommend"]} itemNbr="4371"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["geneticCounselRecommend"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="Genetic Counsel Ref Made" field="geneticCounselRefMade" lineStyle="oneLine"
                                                            value={this.state.cocForm.geneticCounselRefMade ? this.state.cocForm.geneticCounselRefMade : this.state.cocForm.geneticCounselRefMade}
                                                            dataSource="Global_Dir_Mv" aliasNo="4372" titleDialog="Genetic Counsel Ref Made"
                                                            inValid={this.state.cAllItemFields["geneticCounselRefMade"]} itemNbr="4372"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["geneticCounselRefMade"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="Genetic Counsel Completed" field="geneticCounselCompleted" lineStyle="oneLine"
                                                            value={this.state.cocForm.geneticCounselCompleted ? this.state.cocForm.geneticCounselCompleted : this.state.cocForm.geneticCounselCompleted}
                                                            dataSource="Global_Dir_Mv" aliasNo="4373" titleDialog="Genetic Counsel Completed"
                                                            inValid={this.state.cAllItemFields["geneticCounselCompleted"]} itemNbr="4373"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["geneticCounselCompleted"] : ""}
                                                        />
                                                    </div> */}
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="200px" width="30px"
                                                            labelText="QA Status" field="qaStatus" lineStyle="oneLine"
                                                            value={this.state.cocForm.qaStatus ? this.state.cocForm.qaStatus : this.state.cocForm.qaStatus}
                                                            dataSource="Global_Dir_Mv" aliasNo="267" titleDialog="Qa Status"
                                                            inValid={this.state.cAllItemFields["qaStatus"]} itemNbr="267"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["qaStatus"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Date QA Performed" field="dateQaPerformed"
                                                            value={this.state.cocForm.dateQaPerformed}
                                                            maxLength="10" aliasNo="4374" labelWidth="200px" width="100px"
                                                            lineStyle="oneLine" itemNbr="4374"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 __tabInnerCls" id="cpm_middle_item_cls">
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Screening Date" field="screenDate"
                                                            value={this.state.cocForm.screenDate}
                                                            maxLength="10" aliasNo="813" labelWidth="225px" width="100px"
                                                            lineStyle="oneLine" itemNbr="813"
                                                            lostfocused={this.handleLostFocus}
                                                            placeholder="mm/dd/yyyy"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="40px"
                                                            labelText="Screening Type" field="screeningType" lineStyle="oneLine"
                                                            value={this.state.cocForm.screeningType ? this.state.cocForm.screeningType : this.state.cocForm.screeningType}
                                                            dataSource="Global_Dir_Mv" aliasNo="4375" titleDialog="Screening Type"
                                                            inValid={this.state.cAllItemFields["screeningType"]} itemNbr="4375"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["screeningType"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Screen Results" field="screenResult" lineStyle="oneLine"
                                                            value={this.state.cocForm.screenResult ? this.state.cocForm.screenResult : this.state.cocForm.screenResult}
                                                            dataSource="Global_Dir_Mv" aliasNo="782" titleDialog="Screen Result"
                                                            inValid={this.state.cAllItemFields["screenResult"]} itemNbr="782"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["screenResult"] : ""}
                                                        />
                                                    </div>
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="CAP Guidelines Followed" field="capProtocolsFollowed" lineStyle="oneLine"
                                                            value={this.state.cocForm.capProtocolsFollowed ? this.state.cocForm.capProtocolsFollowed : this.state.cocForm.capProtocolsFollowed}
                                                            dataSource="Global_Dir_Mv" aliasNo="3648" titleDialog="Cap Protocols Followed"
                                                            inValid={this.state.cAllItemFields["capProtocolsFollowed"]} itemNbr="3648"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["capProtocolsFollowed"] : ""}
                                                        />
                                                    </div> */}
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Path Report Reviewed by Committee"
                                                            field="pathReportReviewed" lineStyle="oneLine"
                                                            value={this.state.cocForm.pathReportReviewed ? this.state.cocForm.pathReportReviewed : this.state.cocForm.pathReportReviewed}
                                                            dataSource="Global_Dir_Mv" aliasNo="4376" titleDialog="Path Report Reviewed"
                                                            inValid={this.state.cAllItemFields["pathReportReviewed"]} itemNbr="4376"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pathReportReviewed"] : ""}
                                                        />
                                                    </div>
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Psychosocial Distress Screening"
                                                            field="psychosocialDistressScreening" lineStyle="oneLine"
                                                            value={this.state.cocForm.psychosocialDistressScreening ? this.state.cocForm.psychosocialDistressScreening : this.state.cocForm.psychosocialDistressScreening}
                                                            dataSource="Global_Dir_Mv" aliasNo="4377" titleDialog="Psychosocial Distress Screening"
                                                            inValid={this.state.cAllItemFields["psychosocialDistressScreening"]} itemNbr="4377"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["psychosocialDistressScreening"] : ""}
                                                        />
                                                    </div> */}
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Synoptic Op Report Breast SLNB"
                                                            field="synopticOpReportBreastSlnb" lineStyle="oneLine"
                                                            value={this.state.cocForm.synopticOpReportBreastSlnb ? this.state.cocForm.synopticOpReportBreastSlnb : this.state.cocForm.synopticOpReportBreastSlnb}
                                                            dataSource="Global_Dir_Mv" aliasNo="4422" titleDialog="Synoptic Op Report Breast Slnb"
                                                            inValid={this.state.cAllItemFields["synopticOpReportBreastSlnb"]} itemNbr="4422"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["synopticOpReportBreastSlnb"] : ""}
                                                        />
                                                    </div> */}
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Synoptic Op Report Breast LN Proc"
                                                            field="synopticOpReportBreastLnProc" lineStyle="oneLine"
                                                            value={this.state.cocForm.synopticOpReportBreastLnProc ? this.state.cocForm.synopticOpReportBreastLnProc : this.state.cocForm.synopticOpReportBreastLnProc}
                                                            dataSource="Global_Dir_Mv" aliasNo="4378" titleDialog="Synoptic Op Report Breast LN Proc"
                                                            inValid={this.state.cAllItemFields["synopticOpReportBreastLnProc"]} itemNbr="4378"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["synopticOpReportBreastLnProc"] : ""}
                                                        />
                                                    </div> */}
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Synoptic Op Report Melanoma"
                                                            field="synopticOpReportMelanoma" lineStyle="oneLine"
                                                            value={this.state.cocForm.synopticOpReportMelanoma ? this.state.cocForm.synopticOpReportMelanoma : this.state.cocForm.synopticOpReportMelanoma}
                                                            dataSource="Global_Dir_Mv" aliasNo="4379" titleDialog="Synoptic Op Report Melanoma"
                                                            inValid={this.state.cAllItemFields["synopticOpReportMelanoma"]} itemNbr="4379"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["synopticOpReportMelanoma"] : ""}
                                                        />
                                                    </div> */}
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Synoptic Op Report Colon Resection"
                                                            field="synopticOpReportColonResection" lineStyle="oneLine"
                                                            value={this.state.cocForm.synopticOpReportColonResection ? this.state.cocForm.synopticOpReportColonResection : this.state.cocForm.synopticOpReportColonResection}
                                                            dataSource="Global_Dir_Mv" aliasNo="4380" titleDialog="Synoptic Op Report Colon Resection"
                                                            inValid={this.state.cAllItemFields["synopticOpReportColonResection"]} itemNbr="4380"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["synopticOpReportColonResection"] : ""}
                                                        />
                                                    </div> */}
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Total Mesorectal Excs Ach Rectal"
                                                            field="totalMesorectalExcsAchRectal" lineStyle="oneLine"
                                                            value={this.state.cocForm.totalMesorectalExcsAchRectal ? this.state.cocForm.totalMesorectalExcsAchRectal : this.state.cocForm.totalMesorectalExcsAchRectal}
                                                            dataSource="Global_Dir_Mv" aliasNo="4381" titleDialog="Total Mesorectal Excs Ach Rectal"
                                                            inValid={this.state.cAllItemFields["totalMesorectalExcsAchRectal"]} itemNbr="4381"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["totalMesorectalExcsAchRectal"] : ""}
                                                        />
                                                    </div> */}
                                                    {/* <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Pulmonary Resection Inc Sufficient LN"
                                                            field="pulmonaryResectionIncSufficientLn" lineStyle="oneLine"
                                                            value={this.state.cocForm.pulmonaryResectionIncSufficientLn ? this.state.cocForm.pulmonaryResectionIncSufficientLn : this.state.cocForm.pulmonaryResectionIncSufficientLn}
                                                            dataSource="Global_Dir_Mv" aliasNo="4382" titleDialog="Pulmonary Resection Inc Sufficient Ln"
                                                            inValid={this.state.cAllItemFields["pulmonaryResectionIncSufficientLn"]} itemNbr="4382"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pulmonaryResectionIncSufficientLn"] : ""}
                                                        />
                                                    </div> */}
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="225px" width="30px"
                                                            labelText="Patient Included in Coc Study"
                                                            field="ptIncCocStudy" lineStyle="oneLine"
                                                            value={this.state.cocForm.ptIncCocStudy ? this.state.cocForm.ptIncCocStudy : this.state.cocForm.ptIncCocStudy}
                                                            dataSource="Global_Dir_Mv" aliasNo="4383" titleDialog="Pt Inc Coc Study"
                                                            inValid={this.state.cAllItemFields["ptIncCocStudy"]} itemNbr="4383"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ptIncCocStudy"] : ""}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-sm-4 __tabInnerCls">
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="140px" width="165px"
                                                            labelText={this.state.cocForm.cocUserLblText1 ? this.state.cocForm.cocUserLblText1 : "CPM User 1"}
                                                            field="cocUser1" lineStyle="oneLine"
                                                            value={this.state.cocForm.cocUser1 ? this.state.cocForm.cocUser1 : this.state.cocForm.cocUser1}
                                                            dataSource="Global_Dir_Mv" aliasNo="3654" titleDialog="CPM User 1"
                                                            inValid={this.state.cAllItemFields["cocUser1"]} itemNbr="3654"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cocUser1"] : ""}
                                                            flag="ERS_UserDefinedMaintenance"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="140px" width="165px"
                                                            labelText={this.state.cocForm.cocUserLblText2 ? this.state.cocForm.cocUserLblText2 : "CPM User 2"}
                                                            field="cocUser2" lineStyle="oneLine"
                                                            value={this.state.cocForm.cocUser2 ? this.state.cocForm.cocUser2 : this.state.cocForm.cocUser2}
                                                            dataSource="Global_Dir_Mv" aliasNo="3655" titleDialog="CPM User 2"
                                                            inValid={this.state.cAllItemFields["cocUser2"]} itemNbr="3655"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cocUser2"] : ""}
                                                            flag="ERS_UserDefinedMaintenance"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="140px" width="165px"
                                                            labelText={this.state.cocForm.cocUserLblText3 ? this.state.cocForm.cocUserLblText3 : "CPM User 3"}
                                                            field="cocUser3" lineStyle="oneLine"
                                                            value={this.state.cocForm.cocUser3 ? this.state.cocForm.cocUser3 : this.state.cocForm.cocUser3}
                                                            dataSource="Global_Dir_Mv" aliasNo="3656" titleDialog="CPM User 3"
                                                            inValid={this.state.cAllItemFields["cocUser3"]} itemNbr="3656"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cocUser3"] : ""}
                                                            flag="ERS_UserDefinedMaintenance"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="140px" width="165px"
                                                            labelText={this.state.cocForm.cocUserLblText4 ? this.state.cocForm.cocUserLblText4 : "CPM User 4"}
                                                            field="cocUser4" lineStyle="oneLine"
                                                            value={this.state.cocForm.cocUser4 ? this.state.cocForm.cocUser4 : this.state.cocForm.cocUser}
                                                            dataSource="Global_Dir_Mv" aliasNo="3657" titleDialog="CPM User 4"
                                                            inValid={this.state.cAllItemFields["cocUser4"]} itemNbr="3657"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cocUser4"] : ""}
                                                            flag="ERS_UserDefinedMaintenance"
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="140px" width="165px"
                                                            labelText={this.state.cocForm.cocUserLblText5 ? this.state.cocForm.cocUserLblText5 : "CPM User 5"}
                                                            field="cocUser5" lineStyle="oneLine"
                                                            value={this.state.cocForm.cocUser5 ? this.state.cocForm.cocUser5 : this.state.cocForm.cocUser5}
                                                            dataSource="Global_Dir_Mv" aliasNo="3658" titleDialog="CPM User 5"
                                                            inValid={this.state.cAllItemFields["cocUser5"]} itemNbr="3658"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cocUser5"] : ""}
                                                            flag="ERS_UserDefinedMaintenance"
                                                        />
                                                    </div>
                                                    <div style={{ marginRight: "15px" }}>
                                                        <Input type="textarea" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} field="cpmText" lineStyle="oneLine"
                                                            labelText="CPM Text" labelWidth="100px" width="300px" height="100px"
                                                            value={this.state.cocForm.cpmText} itemNbr="4384"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="3">
                                            <div className="row">
                                                <div className="col-sm-12 cocCancerConfMvListArea">
                                                    {/*<table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                <th style={{ width: "129px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="<br>Cancer Conf Date" field="ccDate"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="<br>Discip" field="discipline"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="<br>CC Pres" field="presAtCc"
                                                                    /></th>
                                                                <th style={{ width: "145px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="<br>Attendees" field="attendees"
                                                                    /></th>
                                                                <th style={{ width: "220px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="<br>Cc Comments" field="ccComments"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Stage Comp" field="stagingCompleteness"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Clinical<br>Stage<br>Disc" field="cStageDiscussed"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Path<br>Stage<br>Disc" field="pStageDiscussed"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Prognost<br>Indicat<br>Disc" field="ssfDiscussed"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="National<br>Guide<br>Disc" field="nationalGuidelinesDiscussed"
                                                                    /></th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Clinical<br>Trial<br>Disc at CC" field="clinicalTrialDiscussedAtCc"
                                                                    /></th>
                                                                <th style={{ width: "50px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Genetic Ref<br>Disc" field="geneticRefDiscussed"
                                                                    /></th>
                                                                <th style={{ width: "55px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Support Care Ref Disc" field="supportCareRefDiscussed"
                                                                    /></th>
                                                                <th style={{ width: "65px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Tx Init Before Present" field="txInitBeforePresentation"
                                                                    /></th>
                                                                <th style={{ width: "65px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Tx Rec by Multidiscip Team" field="txRecommendByMultiTeam"
                                                                    /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                            <tr>
                                                                <th style={{ width: "15px" }} colSpan="16">&nbsp;</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    */}
                                                    <div className="__innerBody">
                                                        <table>
                                                            <thead style={{ /*visibility: "hidden", lineHeight: "0px"*/ }}>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "129px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="<br>Cancer Conf Date" field="ccDate"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="<br>Discip" field="discipline"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="<br>CC Pres" field="presAtCc"
                                                                        /></th>
                                                                    <th style={{ width: "145px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="<br>Attendees" field="attendees"
                                                                        /></th>
                                                                    <th style={{ width: "220px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="<br>Cc Comments" field="ccComments"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Stage Comp" field="stagingCompleteness"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Clinical<br>Stage<br>Disc" field="cStageDiscussed"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Path<br>Stage<br>Disc" field="pStageDiscussed"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Prognost<br>Indicat<br>Disc" field="ssfDiscussed"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="National<br>Guide<br>Disc" field="nationalGuidelinesDiscussed"
                                                                        /></th>
                                                                    <th style={{ width: "60px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Clinical<br>Trial<br>Disc at CC" field="clinicalTrialDiscussedAtCc"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto" noSpaceChar={true}
                                                                            labelText="Genetic Ref<br>Disc" field="geneticRefDiscussed"
                                                                        /></th>
                                                                    <th style={{ width: "55px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto" noSpaceChar={true}
                                                                            labelText="Support Care Ref Disc" field="supportCareRefDiscussed"
                                                                        /></th>
                                                                    <th style={{ width: "65px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto" noSpaceChar={true}
                                                                            labelText="Tx Init Before Present" field="txInitBeforePresentation"
                                                                        /></th>
                                                                    <th style={{ width: "65px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto" noSpaceChar={true}
                                                                            labelText="Tx Rec by Multidiscip Team" field="txRecommendByMultiTeam"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.cocCancerConfMvItemFields
                                                                    && this.state.cocCancerConfMv !== null && this.state.cocCancerConfMv.length > 0
                                                                    ? this.state.cocCancerConfMv.map((prm, index) => (
                                                                        <tr onClick={(e) => { this.setCancerConfMvSelectionRow(e, index) }} className={this.state.cocCancerConfMvSelectedRowIndex === index ? "selectedRowCls" : ""} key={index}>
                                                                            <td style={{ width: "15px" }}>
                                                                                {index + 1}
                                                                            </td>
                                                                            <td>
                                                                                <Input type="date" focused={this.handleOnFocus}
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="ccDate" lineStyle="oneLine" itemNbr="268"
                                                                                    value={prm.ccDate != null && prm.ccDate !== "" ? moment(new Date(prm.ccDate)).format("MM/DD/YYYY") : ""}
                                                                                    rowIndex={index}//keyId={"ccDate" + index}
                                                                                    //#20210125 - START
                                                                                    // Add day/month/year values
                                                                                    inValidDay={this.state.cocCancerConfMvItemFields[index]["ccDateDay"]}
                                                                                    inValidMonth={this.state.cocCancerConfMvItemFields[index]["ccDateMonth"]}
                                                                                    inValidYear={this.state.cocCancerConfMvItemFields[index]["ccDateYear"]}
                                                                                    dayVal={prm.ccDateDay}
                                                                                    monthVal={prm.ccDateMonth}
                                                                                    yearVal={prm.ccDateYear}
                                                                                    //#20210125 - END
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    labelWidth="0px" monthWidth="35px" dayWidth="35px" yearWidth="40px"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="discipline" value={prm.discipline}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Discipline" aliasNo="4385" itemNbr="4385"
                                                                                    lineStyle="oneLine"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["discipline"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="2"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["discipline"] : ""}
                                                                                    keyId={"discipline" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="presAtCc" value={prm.presAtCc} lineStyle="oneLine"
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Pres At Cc" aliasNo="784" itemNbr="784"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["presAtCc"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["presAtCc"] : ""}
                                                                                    keyId={"presAtCc" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeMultiSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="125px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="attendees" value={prm.attendees}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Attendees" aliasNo="3645" itemNbr="3645"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["attendees"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }} ignoreCheckValid={true}
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["attendees"] : ""}
                                                                                    keyId={"attendees" + index}
                                                                                    rowIndex={index}
                                                                                    isMultiSelection={true}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="220px" itemNbr="258"//Help ID
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="ccComments" value={prm.ccComments}
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["ccComments"] : ""}
                                                                                    keyId={"ccComments" + index}
                                                                                    rowIndex={index}
                                                                                    hasDoubleClickEvent="warning" maxlen="1000" mainLabelText="Cc Comments"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="stagingCompleteness" value={prm.stagingCompleteness}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Staging Completeness" aliasNo="3142" itemNbr="3142"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["stagingCompleteness"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["stagingCompleteness"] : ""}
                                                                                    keyId={"stagingCompleteness" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="cStageDiscussed" value={prm.cStageDiscussed} 
                                                                                    dataSource="Global_Dir_Mv" titleDialog="C Stage Discussed" aliasNo="3141" itemNbr="3141"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["cStageDiscussed"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["cStageDiscussed"] : ""}
                                                                                    keyId={"cStageDiscussed" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="pStageDiscussed" value={prm.pStageDiscussed}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Pathologic Stage Discussed" aliasNo="4386" itemNbr="4386"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["pStageDiscussed"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["pStageDiscussed"] : ""}
                                                                                    keyId={"pStageDiscussed" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="ssfDiscussed" value={prm.ssfDiscussed}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Ssf Discussed" aliasNo="3647" itemNbr="3647"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["ssfDiscussed"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["ssfDiscussed"] : ""}
                                                                                    keyId={"ssfDiscussed" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="nationalGuidelinesDiscussed" value={prm.nationalGuidelinesDiscussed}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="National Guidelines Discussed" aliasNo="3646" itemNbr="3646"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["nationalGuidelinesDiscussed"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["nationalGuidelinesDiscussed"] : ""}
                                                                                    keyId={"nationalGuidelinesDiscussed" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="clinicalTrialDiscussedAtCc" value={prm.clinicalTrialDiscussedAtCc}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Clinical Trial Discussed at Cancer Conference" aliasNo="4387" itemNbr="4387"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["clinicalTrialDiscussedAtCc"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["clinicalTrialDiscussedAtCc"] : ""}
                                                                                    keyId={"clinicalTrialDiscussedAtCc" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="geneticRefDiscussed" value={prm.geneticRefDiscussed}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Genetics Referral Discussed" aliasNo="4388" itemNbr="4388"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["geneticRefDiscussed"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["geneticRefDiscussed"] : ""}
                                                                                    keyId={"geneticRefDiscussed" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="35px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="supportCareRefDiscussed" value={prm.supportCareRefDiscussed}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Supportive Care Referral Discussed" aliasNo="4389" itemNbr="4389"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["supportCareRefDiscussed"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }}
                                                                                    //maxLength="1"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["supportCareRefDiscussed"] : ""}
                                                                                    keyId={"supportCareRefDiscussed" + index}
                                                                                    rowIndex={index}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeMultiSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="45px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="txInitBeforePresentation" value={prm.txInitBeforePresentation}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Treatment Initiated Before Presentation" aliasNo="4390" itemNbr="4390"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["txInitBeforePresentation"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }} ignoreCheckValid={true}
                                                                                    //maxLength="100"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["txInitBeforePresentation"] : ""}
                                                                                    keyId={"txInitBeforePresentation" + index}
                                                                                    rowIndex={index}
                                                                                    isMultiSelection={true}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeMultiSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="45px"
                                                                                    changed={(e) => { this.handleCocCancerConfMvGridChange(e, index) }}
                                                                                    labelText="" field="txRecommendByMultiTeam" value={prm.txRecommendByMultiTeam}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Tx Recommended by Multidisciplinary Team" aliasNo="4391" itemNbr="4391"
                                                                                    inValid={this.state.cocCancerConfMvItemFields[index]["txRecommendByMultiTeam"]}
                                                                                    lostfocused={(e) => { this.handleCocCancerConfMvLostfocus(e, index) }} ignoreCheckValid={true}
                                                                                    //maxLength="100"
                                                                                    maxLength={this.state.cocCancerConfMvMaxlengthInput ? this.state.cocCancerConfMvMaxlengthInput["txRecommendByMultiTeam"] : ""}
                                                                                    keyId={"txRecommendByMultiTeam" + index}
                                                                                    rowIndex={index}
                                                                                    isMultiSelection={true}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )) : <tr><td colSpan="16">No records available</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="contactBtnArea right">
                                                        <button onClick={this.addNewCocCancerConfMv}>Add</button>
                                                        <button onClick={this.deleteLastCocCancerConfMv}>Delete</button>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="195px" width="35px"
                                                            labelText="Consideration For Clinical Trials" field="considerationClinicalTrial" lineStyle="oneLine"
                                                            value={this.state.cocForm.considerationClinicalTrial ? this.state.cocForm.considerationClinicalTrial : this.state.cocForm.ConsiderationClinicalTrial}
                                                            dataSource="Global_Dir_Mv" aliasNo="3649"
                                                            titleDialog="Consideration Clinical Trials"
                                                            inValid={this.state.cAllItemFields["considerationClinicalTrial"]} itemNbr="3649"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["considerationClinicalTrial"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="160px" width="35px"
                                                            labelText="Participation In Trial" field="prtInProtocol" lineStyle="oneLine"
                                                            value={this.state.cocForm.prtInProtocol ? this.state.cocForm.prtInProtocol : this.state.cocForm.PrtInProtocol}
                                                            dataSource="Global_Dir_Mv" aliasNo="772"
                                                            titleDialog="Prt In Protocol"
                                                            inValid={this.state.cAllItemFields["prtInProtocol"]} itemNbr="772"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["prtInProtocol"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange} labelWidth="118px" width="30px"
                                                            labelText="Protocol Eligible" field="protocolElig" lineStyle="oneLine"
                                                            value={this.state.cocForm.protocolElig ? this.state.cocForm.protocolElig : this.state.cocForm.ProtocolElig}
                                                            dataSource="Global_Dir_Mv" aliasNo="788"
                                                            titleDialog="Protocol Elig"
                                                            inValid={this.state.cAllItemFields["protocolElig"]} itemNbr="788"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["protocolElig"] : ""}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="emptyLine"></div>

                                            <div className="row">
                                                <div className="col-sm-12 cocClinicalTrialMvListArea">
                                                    {/*<table>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: "15px" }}>&nbsp;</th>
                                                                <th style={{ width: "123px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Accrual Date" field="accrualDate"
                                                                    /></th>
                                                                <th style={{ width: "75px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Study Type" field="studyTypeEligAccrual"
                                                                    /></th>
                                                                <th style={{ width: "123px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Trial Start Date" field="ctrialStartDate"
                                                                    /></th>
                                                                <th style={{ width: "140px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Trial Name" field="trialName"
                                                                    /></th>
                                                                <th style={{ width: "60px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Fol Stat" field="followingStatus"
                                                                    /></th>
                                                                <th style={{ width: "100px" }}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Tracking Phys" field="trackPhys"
                                                                    /></th>
                                                                <th style={{ width: "175px" }}>Physician Name</th>
                                                                <th style={{ width: "180px"}}>
                                                                    <Input type="gridHeader" labelWidth="auto"
                                                                        labelText="Reason Withdrawn From Trials" field="rsnWithdrawn"
                                                                    /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                            <tr>
                                                                <th style={{ width: "15px" }} colSpan="9">&nbsp;</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    */}
                                                    <div className="__innerBody">
                                                        <table>
                                                            <thead style={{ /*visibility: "hidden", lineHeight: "0px"*/ }}>
                                                                <tr>
                                                                    <th style={{ width: "15px" }}>&nbsp;</th>
                                                                    <th style={{ width: "123px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Accrual Date" field="accrualDate"
                                                                        /></th>
                                                                    <th style={{ width: "80px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Study Type" field="studyTypeEligAccrual"
                                                                        /></th>
                                                                    <th style={{ width: "123px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Trial Start Date" field="ctrialStartDate"
                                                                        /></th>
                                                                    <th style={{ width: "140px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Trial Name" field="trialName"
                                                                        /></th>
                                                                    <th style={{ width: "70px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Fol Stat" field="followingStatus"
                                                                        /></th>
                                                                    <th style={{ width: "100px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Tracking Phys" field="trackPhys"
                                                                        /></th>
                                                                    <th style={{ width: "175px" }}>Physician Name</th>
                                                                    <th style={{ width: "180px" }}>
                                                                        <Input type="gridHeader" labelWidth="auto"
                                                                            labelText="Reason Withdrawn From Trials" field="rsnWithdrawn"
                                                                        /></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.cocClinicalTrialMvItemFields
                                                                    && this.state.cocClinicalTrialMv !== null && this.state.cocClinicalTrialMv.length > 0
                                                                    ? this.state.cocClinicalTrialMv.map((prm, index) => (
                                                                        <tr onClick={(e) => { this.setClinicalTrialSelectionRow(e, index) }} className={this.state.cocClinicalTrialSelectedRowIndex === index ? "selectedRowCls" : ""} key={index}>
                                                                            <td style={{ width: "15px" }}>
                                                                                {index + 1}
                                                                            </td>
                                                                            <td>
                                                                                <Input type="date" focused={this.handleOnFocus}
                                                                                    labelWidth="0px" monthWidth="35px" dayWidth="35px" yearWidth="45px" width="125px"
                                                                                    changed={(e) => { this.handleCocClinicalTrialMvGridChange(e, index) }}
                                                                                    labelText="" field="accrualDate" lineStyle="oneLine" itemNbr="1817"
                                                                                    value={prm.accrualDate != null && prm.accrualDate !== "" ? moment(new Date(prm.accrualDate)).format("MM/DD/YYYY") : ""}
                                                                                    rowIndex={index}//keyId={"accrualDate" + index}
                                                                                    //#20210125 - START
                                                                                    // Add day/month/year values
                                                                                    inValidDay={this.state.cocClinicalTrialMvItemFields[index]["accrualDateDay"]}
                                                                                    inValidMonth={this.state.cocClinicalTrialMvItemFields[index]["accrualDateMonth"]}
                                                                                    inValidYear={this.state.cocClinicalTrialMvItemFields[index]["accrualDateYear"]}
                                                                                    dayVal={prm.accrualDateDay}
                                                                                    monthVal={prm.accrualDateMonth}
                                                                                    yearVal={prm.accrualDateYear}
                                                                                    //#20210125 - END
                                                                                    lostfocused={(e) => { this.handleCocClinicalTrialMvLostfocus(e, index) }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                    labelWidth="0px" width="75px"
                                                                                    changed={(e) => { this.handleCocClinicalTrialMvGridChange(e, index) }}
                                                                                    labelText="" field="studyTypeEligAccrual" value={prm.studyTypeEligAccrual}
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Type of Research Study Eligible for Accrual" aliasNo="4392" itemNbr="4392"
                                                                                    lineStyle="oneLine"
                                                                                    inValid={this.state.cocClinicalTrialMvItemFields[index]["studyTypeEligAccrual"]}
                                                                                    //maxLength="2"
                                                                                    maxLength={this.state.cocClinicalTrialMvMaxlengthInput ? this.state.cocClinicalTrialMvMaxlengthInput["studyTypeEligAccrual"] : ""}
                                                                                    keyId={"studyTypeEligAccrual" + index}
                                                                                    rowIndex={index}
                                                                                    lostfocused={(e) => { this.handleCocClinicalTrialMvLostfocus(e, index) }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="date" focused={this.handleOnFocus}
                                                                                    labelWidth="0px" monthWidth="35px" dayWidth="35px" yearWidth="45px" width="125px"
                                                                                    changed={(e) => { this.handleCocClinicalTrialMvGridChange(e, index) }}
                                                                                    labelText="" field="ctrialStartDate" lineStyle="oneLine" itemNbr="1818"
                                                                                    value={prm.ctrialStartDate != null && prm.ctrialStartDate !== "" ? moment(new Date(prm.ctrialStartDate)).format("MM/DD/YYYY") : ""}
                                                                                    rowIndex={index}//keyId={"accrualDate" + index}
                                                                                    //#20210125 - START
                                                                                    // Add day/month/year values
                                                                                    inValidDay={this.state.cocClinicalTrialMvItemFields[index]["ctrialStartDateDay"]}
                                                                                    inValidMonth={this.state.cocClinicalTrialMvItemFields[index]["ctrialStartDateMonth"]}
                                                                                    inValidYear={this.state.cocClinicalTrialMvItemFields[index]["ctrialStartDateYear"]}
                                                                                    dayVal={prm.ctrialStartDateDay}
                                                                                    monthVal={prm.ctrialStartDateMonth}
                                                                                    yearVal={prm.ctrialStartDateYear}
                                                                                    //#20210125 - END
                                                                                    lostfocused={(e) => { this.handleCocClinicalTrialMvLostfocus(e, index) }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    labelWidth="0px" width="140px"
                                                                                    changed={(e) => { this.handleCocClinicalTrialMvGridChange(e, index) }}
                                                                                    labelText="" field="trialName" value={prm.trialName}
                                                                                    //maxLength="100"
                                                                                    maxLength={this.state.cocClinicalTrialMvMaxlengthInput ? this.state.cocClinicalTrialMvMaxlengthInput["trialName"] : ""}
                                                                                    keyId={"trialName" + index}
                                                                                    rowIndex={index} itemNbr="1819"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="codeSelect" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handleCocClinicalTrialMvGridChange(e, index) }}
                                                                                    labelText="" field="followingStatus" value={prm.followingStatus}
                                                                                    labelWidth="0px" width="60px"
                                                                                    dataSource="Global_Dir_Mv" titleDialog="Following Status" aliasNo="1820" itemNbr="1820"
                                                                                    inValid={this.state.cocClinicalTrialMvItemFields[index]["followingStatus"]}
                                                                                    //maxLength="2"
                                                                                    maxLength={this.state.cocClinicalTrialMvMaxlengthInput ? this.state.cocClinicalTrialMvMaxlengthInput["followingStatus"] : ""}
                                                                                    keyId={"followingStatus" + index}
                                                                                    rowIndex={index}
                                                                                    lostfocused={(e) => { this.handleCocClinicalTrialMvLostfocus(e, index) }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="Physician" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handleCocClinicalTrialMvGridChange(e, index) }}
                                                                                    labelText="" field="trackPhys" value={prm.trackPhys}
                                                                                    labelWidth="0px" width="100px"
                                                                                    dataSource="Physicians" titleDialog="Lookup" itemNbr="1821"
                                                                                    inValid={this.state.cocClinicalTrialMvItemFields[index]["trackPhys"]}
                                                                                    //maxLength="15"
                                                                                    maxLength={this.state.cocClinicalTrialMvMaxlengthInput ? this.state.cocClinicalTrialMvMaxlengthInput["trackPhys"] : ""}
                                                                                    keyId={"trackPhys" + index}
                                                                                    rowIndex={index}
                                                                                    lostfocused={(e) => { this.handleCocClinicalTrialMvLostfocus(e, index) }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="label" lineStyle="oneLine" labelMainStyle="cellOnGrid"
                                                                                    labelWidth="0px" width="175px" height="23px"
                                                                                    labelText="" field="trackPhysName" value={prm.trackPhysName}
                                                                                    keyId={"trackPhysName" + index}
                                                                                    rowIndex={index} />
                                                                            </td>
                                                                            <td>
                                                                                <Input type="input" focused={this.handleOnFocus} lineStyle="oneLine"
                                                                                    changed={(e) => { this.handleCocClinicalTrialMvGridChange(e, index) }}
                                                                                    labelWidth="0px" width="180px"
                                                                                    labelText="" field="rsnWithdrawn" value={prm.rsnWithdrawn}
                                                                                    //maxLength="100" 
                                                                                    maxLength={this.state.cocClinicalTrialMvMaxlengthInput ? this.state.cocClinicalTrialMvMaxlengthInput["rsnWithdrawn"] : ""}
                                                                                    keyId={"rsnWithdrawn" + index}
                                                                                    rowIndex={index} itemNbr="3650"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    )) : <tr><td colSpan="9">No records available</td></tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="contactBtnArea right">
                                                        <button onClick={this.addNewCocClinicalTrialMv}>Add</button>
                                                        <button onClick={this.deleteLastCocClinicalTrialMv}>Delete</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>

                                </div>
                            </div>
                        </div>
                    </form>
                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetCoc} formId="cocForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">Cancer Program data loading...</p>;
        return (
            <React.Fragment>
                <React.Fragment>
                    {confirmDeleteMsg}
                </React.Fragment>

                <React.Fragment>
                    {CancerProgramMaintenance}
                </React.Fragment>

                <div id="textareaEditorForItemsCls">
                    {
                        this.props.isShowTextDialogVisible ?
                        /*
                        <Dialog
                            title="Diagnosis Text &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatment &amp; Other Text"
                            onClose={this.props.closeTextDialog}
                            width={950} height={"auto"}
                            className={classes.title}>*/
                        <div className="CRStar_Window">
                            <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                <div className="dragableWindowCls">
                                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                                        <div className="k-window-title k-dialog-title">
                                            <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                        </div>
                                        <div className="k-window-actions k-dialog-actions">
                                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                            </a>
                                        </div>
                                    </div>
                                    <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                </div>
                                {/*</Dialog>*/}
                            </Draggable>
                        </div>
                        : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

CancerProgramMaintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedCocRecord: state.patientAbstract.selectedCocRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        processingAndMovingPage: state.patientAbstract.processingAndMovingPage,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveCoc: (formData) => dispatch(actionCreators.saveCoc(formData)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),

        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),
        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),

        selectCocRecord: (medial) => dispatch(actionCreators.selectCocRecord(medial)),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CancerProgramMaintenance);