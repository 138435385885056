export const setRapidAbstract = "SET_RAPID_ABSTRACT";
export const setInquiringMode = "SET_INQUIRING_MODE";
export const setItemNbr = "SET_ITEM_NBR";
export const showPageLoading = "SHOW_PAGE_LOADING";
export const clearAndResetDataWhenPageLoading = "CLEAR_AND_RESET_DATA_WHEN_PAGE_LOADING";
export const setUserId = "SET_USER_ID";
export const setErsClientGroup = "SET_ERS_CLIENT_GROUP";
export const setAllowSaveAnyway = "SET_ALLOW_SAVE_ANYWAY";
export const setChildrenOpening = "SET_CHILDREN_OPENING";
export const resetCurrentSelectedIndex = "RESET_CURRENT_SELECTED_INDEX";
export const showOrHideLoading = "SHOW_OR_HIDE_LOADING";
export const showingHelpInfo = "SHOWING_HELP_INFO";
export const updateDiagnosisInfo = "UPDATE_DIAGNOSIS_INFO";
export const setHandlerControlItem = "SET_HANDLER_CONTROL_ITEM";
export const storeToGlobal = "STORE_TO_GLOBAL";
export const showSessionTimeoutMsg = "SHOW_SESSION_TIMEOUT_MSG";
export const resetDeletedDataSuccess = "RESET_DELETED_DATA_SUCCESS";

/* Patient Abstract */
export const selectPatient = "SELECT_PATIENT";
export const selectMedicalRecord = "SELECT_MEDICAL_RECORD";
export const setCurrentDialog = "SET_CURRENT_DIALOG";
export const setEnabledStatus = "SET_ENABLED_STATUS";
export const setPageLoaded = "SET_PAGE_LOADED";
export const setTabName = "SET_TAB_NAME";
export const setControlName = "SET_CONTROL_NAME";
export const setAlphaUrl = "SET_ALPHA_URL";

export const setPatientMenu = "SET_PATIENT_MENU";
export const getPatientMenu = "GET_PATIENT_MENU";
export const setRoutingPage = "SET_ROUTING_PAGE";
export const addTabItems = "ADD_TAB_ITEMS";
export const removeTabItems = "REMOVE_TAB_ITEMS";
export const selectTabItems = "SELECT_TAB_ITEMS";
export const closeOtherTabItems = "CLOSE_OTHER_TAB_ITEMS";
export const getAvailableItems = "GET_AVAILABLE_ITEMS";
export const getAvailableItemsSuccess = "GET_AVAILABLE_ITEMS_SUCCESS";
export const getAvailableItemsFail = "GET_AVAILABLE_ITEMS_FAIL";

export const setParentWindowVisible = "SET_PARENT_WINDOW_VISIBLE";
export const setChildrenWindowVisible = "SET_CHILDREN_WINDOW_VISIBLE";
export const clearSelectedMedicalRecord = "CLEAR_SELECTED_MEDICAL_RECORD";

export const setChildDialog = "SET_CHILD_DIALOG";
export const setChildDialogVisible = "SET_CHILD_DIALOG_VISIBLE";
export const setChangePwdDialogVisible = "SET_CHANGE_PWD_DIALOG_VISIBLE";
export const setTwoFactorAuthDialogVisible = "SET_TWO_FACTOR_AUTH_DIALOG_VISIBLE";
export const getQRCode = "GET_QR_CODE";

export const confirmSecurityCode = "CONFIRM_SECURITY_CODE";
export const confirmSecurityCodeStart = "CONFIRM_SECURITY_CODE_START";
export const confirmSecurityCodeSuccess = "CONFIRM_SECURITY_CODE_SUCCESS";
export const confirmSecurityCodeFail = "CONFIRM_SECURITY_CODE_FAIL";

//export const saveSelectedPatientId = 'SAVE_SELECTED_PATIENT_ID';
export const getCommonColorsSuccess = "GET_COMMON_COLORS_SUCCESS";
export const getCommonColorsFail = "GET_COMMON_COLORS_FAIL";

export const showingMessagePatientAbstracts = "SHOWING_MESSAGE_PATIENT_ABSTRACTS";
export const latestFocusedField = "LATEST_FOCUSED_FIELD";

export const selectedPatientOnGrid = "SELECTED_PATIENT_ON_GRID";
/* Patient Maintenance */
export const getPatientDataStart = "GET_PATIENT_DATA_START";
export const getPatientDataSuccess = "GET_PATIENT_DATA_SUCCESS";
export const getPatientDataFail = "GET_PATIENT_DATA_FAIL";

export const savePatientDataStart = "SAVE_PATIENT_DATA_START";
export const savePatientDataSuccess = "SAVE_PATIENT_DATA_SUCCESS";
export const savePatientDataFail = "SAVE_PATIENT_DATA_FAIL";

export const deletePrimaryItem = "DELETE_PRIMARY_ITEM";
export const deletePrimaryItemStart = "DELETE_PRIMARY_ITEM_START";
export const deletePrimaryItemSuccess = "DELETE_PRIMARY_ITEM_SUCCESS";
export const deletePrimaryItemFail = "DELETE_PRIMARY_ITEM_FAIL";

/* Login */
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const MULTI_GROUP = 'MULTI_GROUP';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';

export const INQUIRING_MODE = 'INQUIRING_MODE';
export const AVAILABLE_HOSPS_DATA_OF_USER = 'AVAILABLE_HOSPS_DATA_OF_USER';
export const CUSTOMIZATIONS_OF_USER = 'CUSTOMIZATIONS_OF_USER';

/* Forgot Password */
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const DO_START = 'DO_START';
export const DO_SUCCESS = 'DO_SUCCESS';
export const DO_FAIL = 'DO_FAIL';

/* Diagnosis Maintenance */
export const getDiagnosisDataStart = "GET_DIAGNOSIS_DATA_START";
export const getDiagnosisDataSuccess = "GET_DIAGNOSIS_DATA_SUCCESS";
export const getDiagnosisDataFail = "GET_DIAGNOSIS_DATA_FAIL";

export const saveDiagnosisDataStart = "SAVE_DIAGNOSIS_DATA_START";
export const saveDiagnosisDataSuccess = "SAVE_DIAGNOSIS_DATA_SUCCESS";
export const saveDiagnosisDataFail = "SAVE_DIAGNOSIS_DATA_FAIL";
export const setDiagnosisDataDefault = "SET_DIAGNOSIS_DATA_DEFAULT";

/* Hospital Lookup */
export const getHospitalDataStart = "GET_HOSPITAL_DATA_START";
export const getHospitalDataSuccess = "GET_HOSPITAL_DATA_SUCCESS";
export const getHospitalDataFail = "GET_HOSPITAL_DATA_FAIL";

export const saveHospitalDataStart = "SAVE_HOSPITAL_DATA_START";
export const saveHospitalDataSuccess = "SAVE_HOSPITAL_DATA_SUCCESS";
export const saveHospitalDataFail = "SAVE_HOSPITAL_DATA_FAIL";

export const deleteHospitalDataStart = "DELETE_HOSPITAL_DATA_START";
export const deleteHospitalDataSuccess = "DELETE_HOSPITAL_DATA_SUCCESS";
export const deleteHospitalDataFail = "DELETE_HOSPITAL_DATA_FAIL";

/* Physicians Lookup */
export const getPhysiciansDataStart = "GET_PHYSICIANS_DATA_START";
export const getPhysiciansDataSuccess = "GET_PHYSICIANS_DATA_SUCCESS";
export const getPhysiciansDataFail = "GET_PHYSICIANS_DATA_FAIL";

export const savePhysiciansDataStart = "SAVE_PHYSICIANS_DATA_START";
export const savePhysiciansDataSuccess = "SAVE_PHYSICIANS_DATA_SUCCESS";
export const savePhysiciansDataFail = "SAVE_PHYSICIANS_DATA_FAIL";

export const deletePhysiciansDataStart = "DELETE_PHYSICIANS_DATA_START";
export const deletePhysiciansDataSuccess = "DELETE_PHYSICIANS_DATA_SUCCESS";
export const deletePhysiciansDataFail = "DELETE_PHYSICIANS_DATA_FAIL";

/* Registrars Lookup */
export const getRegistrarsDataStart = "GET_REGISTRARS_DATA_START";
export const getRegistrarsDataSuccess = "GET_REGISTRARS_DATA_SUCCESS";
export const getRegistrarsDataFail = "GET_REGISTRARS_DATA_FAIL";

export const saveRegistrarsDataStart = "SAVE_REGISTRARS_DATA_START";
export const saveRegistrarsDataSuccess = "SAVE_REGISTRARS_DATA_SUCCESS";
export const saveRegistrarsDataFail = "SAVE_REGISTRARS_DATA_FAIL";

export const deleteRegistrarsDataStart = "DELETE_REGISTRARS_DATA_START";
export const deleteRegistrarsDataSuccess = "DELETE_REGISTRARS_DATA_SUCCESS";
export const deleteRegistrarsDataFail = "DELETE_REGISTRARS_DATA_FAIL";

/* GlobalDir Lookup */
export const getGlobalDirDataStart = "GET_GLOBAL_DIR_DATA_START";
export const getGlobalDirDataSuccess = "GET_GLOBAL_DIR_DATA_SUCCESS";
export const getGlobalDirDataFail = "GET_GLOBAL_DIR_DATA_FAIL";

export const saveGlobalDirDataStart = "SAVE_GLOBAL_DIR_DATA_START";
export const saveGlobalDirDataSuccess = "SAVE_GLOBAL_DIR_DATA_SUCCESS";
export const saveGlobalDirDataFail = "SAVE_GLOBAL_DIR_DATA_FAIL";

export const deleteGlobalDirDataStart = "DELETE_GLOBAL_DIR_DATA_START";
export const deleteGlobalDirDataSuccess = "DELETE_GLOBAL_DIR_DATA_SUCCESS";
export const deleteGlobalDirDataFail = "DELETE_GLOBAL_DIR_DATA_FAIL";

/* Others */
export const closeAddNewDialog = "CLOSE_ADD_NEW_DIALOG";
export const clearFormInput = "CLEAR_FORM_INPUT";
export const deleteItemInput = "DELETE_ITEM_INPUT";

/* Diagnosis Text */
export const showTextDialogStart = "SHOW_TEXT_DIALOG_START";
export const showTextDialogSuccess = "SHOW_TEXT_DIALOG_SUCCESS";
export const showTextDialogFail = "SHOW_TEXT_DIALOG_FAIL";

export const saveTextDialogStart = "SAVE_TEXT_DIALOG_START";
export const saveTextDialogSuccess = "SAVE_TEXT_DIALOG_SUCCESS";
export const saveTextDialogFail = "SAVE_TEXT_DIALOG_FAIL";

export const closeTextDialog = "CLOSE_TEXT_DIALOG";
export const setInitialPageVisible = "SET_INITIAL_PAGE_VISIBLE";
export const setInitialAbstractPages = "SET_INITIAL_ABSTRACT_PAGES";
/* Staging Maintenance */
export const getStagingDataStart = "GET_STAGING_DATA_START";
export const getStagingDataSuccess = "GET_STAGING_DATA_SUCCESS";
export const getStagingDataFail = "GET_STAGING_DATA_FAIL";

export const saveStagingDataStart = "SAVE_STAGING_DATA_START";
export const saveStagingDataSuccess = "SAVE_STAGING_DATA_SUCCESS";
export const saveStagingDataFail = "SAVE_STAGING_DATA_FAIL";
export const clearSelectedStagingRecord = "CLEAR_SELECTED_STAGING_RECORD";

/* Check valid input of codeSelect fields */
export const checkCodeSelectDataStart = "CHECK_CODE_SELECT_DATA_START";
export const checkCodeSelectDataSuccess = "CHECK_CODE_SELECT_DATA_SUCCESS";
export const checkCodeSelectDataFail = "CHECK_CODE_SELECT_DATA_FAIL";
export const clearCommonLookupItem = "CLEAR_COMMON_LOOKUP_ITEM";
export const clearCommonLookupItem2 = "CLEAR_COMMON_LOOKUP_ITEM2";
export const clearAddNewUserFlag = "CLEAR_ADD_NEW_USER_FLAG";

/* Treatment Summary */
export const getTreatmentDataStart = "GET_TREATMENT_DATA_START";
export const getTreatmentDataSuccess = "GET_TREATMENT_DATA_SUCCESS";
export const getTreatmentDataFail = "GET_TREATMENT_DATA_FAIL";

export const saveTreatmentDataStart = "SAVE_TREATMENT_DATA_START";
export const saveTreatmentDataSuccess = "SAVE_TREATMENT_DATA_SUCCESS";
export const saveTreatmentDataFail = "SAVE_TREATMENT_DATA_FAIL";

/* Surgery Maintenance */
export const getSurgeryDataStart = "GET_SURGERY_DATA_START";
export const getSurgeryDataSuccess = "GET_SURGERY_DATA_SUCCESS";
export const getSurgeryDataFail = "GET_SURGERY_DATA_FAIL";

export const saveSurgeryDataStart = "SAVE_SURGERY_DATA_START";
export const saveSurgeryDataSuccess = "SAVE_SURGERY_DATA_SUCCESS";
export const saveSurgeryDataFail = "SAVE_SURGERY_DATA_FAIL";

/* Outcomes Maintenance */
export const getOutcomesDataStart = "GET_OUTCOMES_DATA_START";
export const getOutcomesDataSuccess = "GET_OUTCOMES_DATA_SUCCESS";
export const getOutcomesDataFail = "GET_OUTCOMES_DATA_FAIL";

export const saveOutcomesDataStart = "SAVE_OUTCOMES_DATA_START";
export const saveOutcomesDataSuccess = "SAVE_OUTCOMES_DATA_SUCCESS";
export const saveOutcomesDataFail = "SAVE_OUTCOMES_DATA_FAIL";

/* Alpha System Link */
export const GET_ALPHA_SYSTEM_PATH = 'GET_ALPHA_SYSTEM_PATH';
/* Case Admin Maintenance */
export const getCaseAdminDataStart = "GET_CASE_ADMIN_DATA_START";
export const getCaseAdminDataSuccess = "GET_CASE_ADMIN_DATA_SUCCESS";
export const getCaseAdminDataFail = "GET_CASE_ADMIN_DATA_FAIL";

export const saveCaseAdminDataStart = "SAVE_CASE_ADMIN_DATA_START";
export const saveCaseAdminDataSuccess = "SAVE_CASE_ADMIN_DATA_SUCCESS";
export const saveCaseAdminDataFail = "SAVE_CASE_ADMIN_DATA_FAIL";

/* Clinical Maintenance */
export const getClinicalDataStart = "GET_CLINICAL_DATA_START";
export const getClinicalDataSuccess = "GET_CLINICAL_DATA_SUCCESS";
export const getClinicalDataFail = "GET_CLINICAL_DATA_FAIL";

export const saveClinicalDataStart = "SAVE_CLINICAL_DATA_START";
export const saveClinicalDataSuccess = "SAVE_CLINICAL_DATA_SUCCESS";
export const saveClinicalDataFail = "SAVE_CLINICAL_DATA_FAIL";

/* Research Maintenance */
export const getResearchDataStart = "GET_RESEARCH_DATA_START";
export const getResearchDataSuccess = "GET_RESEARCH_DATA_SUCCESS";
export const getResearchDataFail = "GET_RESEARCH_DATA_FAIL";

export const saveResearchDataStart = "SAVE_RESEARCH_DATA_START";
export const saveResearchDataSuccess = "SAVE_RESEARCH_DATA_SUCCESS";
export const saveResearchDataFail = "SAVE_RESEARCH_DATA_FAIL";

/* User Defined Maintenance */
export const getUserDefinedDataStart = "GET_USER_DEFINED_DATA_START";
export const getUserDefinedDataSuccess = "GET_USER_DEFINED_DATA_SUCCESS";
export const getUserDefinedDataFail = "GET_USER_DEFINED_DATA_FAIL";

export const saveUserDefinedDataStart = "SAVE_USER_DEFINED_DATA_START";
export const saveUserDefinedDataSuccess = "SAVE_USER_DEFINED_DATA_SUCCESS";
export const saveUserDefinedDataFail = "SAVE_USER_DEFINED_DATA_FAIL";

/* CPM Maintenance - COC */
export const getCpmCocDataStart = "GET_CPM_COC_DATA_START";
export const getCpmCocDataSuccess = "GET_CPM_COC_DATA_SUCCESS";
export const getCpmCocDataFail = "GET_CPM_COC_DATA_FAIL";

export const saveCpmCocDataStart = "SAVE_CPM_COC_DATA_START";
export const saveCpmCocDataSuccess = "SAVE_CPM_COC_DATA_SUCCESS";
export const saveCpmCocDataFail = "SAVE_CPM_COC_DATA_FAIL";

/* BPM Maintenance - Napbc */
export const getBpmNapbcDataStart = "GET_BPM_NAPBC_DATA_START";
export const getBpmNapbcDataSuccess = "GET_BPM_NAPBC_DATA_SUCCESS";
export const getBpmNapbcDataFail = "GET_BPM_NAPBC_DATA_FAIL";

export const saveBpmNapbcDataStart = "SAVE_BPM_NAPBC_DATA_START";
export const saveBpmNapbcDataSuccess = "SAVE_BPM_NAPBC_DATA_SUCCESS";
export const saveBpmNapbcDataFail = "SAVE_BPM_NAPBC_DATA_FAIL";

/* RPM Maintenance - RectalProgram */
export const getRectalProgramDataStart = "GET_RECTAL_PROGRAM_DATA_START";
export const getRectalProgramDataSuccess = "GET_RECTAL_PROGRAM_DATA_SUCCESS";
export const getRectalProgramDataFail = "GET_RECTAL_PROGRAM_DATA_FAIL";

export const saveRectalProgramDataStart = "SAVE_RECTAL_PROGRAM_DATA_START";
export const saveRectalProgramDataSuccess = "SAVE_RECTAL_PROGRAM_DATA_SUCCESS";
export const saveRectalProgramDataFail = "SAVE_RECTAL_PROGRAM_DATA_FAIL";

/* State Specific Maintenance */
export const getStateFieldsDataStart = "GET_STATE_FIELDS_DATA_START";
export const getStateFieldsDataSuccess = "GET_STATE_FIELDS_DATA_SUCCESS";
export const getStateFieldsDataFail = "GET_STATE_FIELDS_DATA_FAIL";

export const saveStateFieldsDataStart = "SAVE_STATE_FIELDS_DATA_START";
export const saveStateFieldsDataSuccess = "SAVE_STATE_FIELDS_DATA_SUCCESS";
export const saveStateFieldsDataFail = "SAVE_STATE_FIELDS_DATA_FAIL";

/* Retired Maintenance */
export const getRetiredDataStart = "GET_RETIRED_DATA_START";
export const getRetiredDataSuccess = "GET_RETIRED_DATA_SUCCESS";
export const getRetiredDataFail = "GET_RETIRED_DATA_FAIL";

export const saveRetiredDataStart = "SAVE_RETIRED_DATA_START";
export const saveRetiredDataSuccess = "SAVE_RETIRED_DATA_SUCCESS";
export const saveRetiredDataFail = "SAVE_RETIRED_DATA_FAIL";

/* Others */
export const resetHospitalInfo = "RESET_HOSPITAL_INFO";
export const currentShowingState = "CURRENT_SHOWING_STATE";
export const currentShowingHospitalVersion = "CURRENT_SHOWING_HOSPITAL_VERSION";

/* SystemAdministration::SecurityMaintenance::UserMaintenance */
export const getUserInfoDataStart = "GET_USER_INFO_DATA_START";
export const getUserInfoDataSuccess = "GET_USER_INFO_DATA_SUCCESS";
export const getUserInfoDataFail = "GET_USER_INFO_DATA_FAIL";

export const saveUserInfoDataStart = "SAVE_USER_INFO_DATA_START";
export const saveUserInfoDataSuccess = "SAVE_USER_INFO_DATA_SUCCESS";
export const saveUserInfoDataFail = "SAVE_USER_INFO_DATA_FAIL";

export const deleteUserInfoDataStart = "DELETE_USER_INFO_DATA_START";
export const deleteUserInfoDataSuccess = "DELETE_USER_INFO_DATA_SUCCESS";
export const deleteUserInfoDataFail = "DELETE_USER_INFO_DATA_FAIL";

export const changePasswordDataStart = "CHANGE_PASSWORD_DATA_START";
export const changePasswordDataSuccess = "CHANGE_PASSWORD_DATA_SUCCESS";
export const changePasswordDataFail = "CHANGE_PASSWORD_DATA_FAIL";
export const setErrorMessage = "SET_ERROR_MESSAGE";
export const clearErrorMessage = "CLEAR_ERROR_MESSAGE";

export const twoFactorAuthDataStart = "TWO_FACTOR_AUTH_DATA_START";
export const twoFactorAuthDataSuccess = "TWO_FACTOR_AUTH_DATA_SUCCESS";
export const twoFactorAuthDataFail = "TWO_FACTOR_AUTH_DATA_FAIL";

/* SystemAdministration::SecurityMaintenance::UserSecurity */
export const getUserSecurityDataStart = "GET_USER_SECURITY_DATA_START";
export const getUserSecurityDataSuccess = "GET_USER_SECURITY_DATA_SUCCESS";
export const getUserSecurityDataFail = "GET_USER_SECURITY_DATA_FAIL";

export const saveUserSecurityDataStart = "SAVE_USER_SECURITY_DATA_START";
export const saveUserSecurityDataSuccess = "SAVE_USER_SECURITY_DATA_SUCCESS";
export const saveUserSecurityDataFail = "SAVE_USER_SECURITY_DATA_FAIL";

/* SystemAdministration::SecurityMaintenance::PasswordPolicyMaintenance */
export const selectedUserOnGrid = "SELECTED_USER_ON_GRID";
export const loadPwdPolicyInfoStart = "LOAD_PWD_POLICY_INFO_START";
export const loadPwdPolicyInfoSuccess = "LOAD_PWD_POLICY_INFO_SUCCESS";
export const loadPwdPolicyInfoFail = "LOAD_PWD_POLICY_INFO_FAIL";

export const savePwdPolicyInfoStart = "SAVE_PWD_POLICY_INFO_START";
export const savePwdPolicyInfoSuccess = "SAVE_PWD_POLICY_INFO_SUCCESS";
export const savePwdPolicyInfoFail = "SAVE_PWD_POLICY_INFO_FAIL";

export const deletePwdPolicyInfoStart = "DELETE_PWD_POLICY_INFO_START";
export const deletePwdPolicyInfoSuccess = "DELETE_PWD_POLICY_INFO_SUCCESS";
export const deletePwdPolicyInfoFail = "DELETE_PWD_POLICY_INFO_FAIL";

// 
export const showLockedUserStart = "SHOW_LOCKED_USER_START";
export const showLockedUserSuccess = "SHOW_LOCKED_USER_SUCCESS";
export const showLockedUserFail = "SHOW_LOCKED_USER_FAIL";

export const clearLockedUserStart = "CLEAR_LOCKED_USER_START";
export const clearLockedUserSuccess = "CLEAR_LOCKED_USER_SUCCESS";
export const clearLockedUserFail = "CLEAR_LOCKED_USER_FAIL";

export const setTfaPopupTitle = "SET_TFA_POPUP_TITLE";
export const getTokenOnSession = "GET_TOKEN_ON_SESSION";
export const getTokenOnSessionSuccess = "GET_TOKEN_ON_SESSION_SUCCESS";
export const getTokenOnSessionFail = "GET_TOKEN_ON_SESSION_FAIL";

export const resetAllStoringOfUser = "RESET_ALL_STORING_OF_USER";
export const clearAllScreenDataOnTab = "RESET_ALL_SCREEN_DATA_ON_TAB";

/* v21CompatibilityFeature */
export const v21CompatibilityFeatureStart = "V21_COMPATIBILITY_FEATURE_START";
export const v21CompatibilityFeatureSuccess = "V21_COMPATIBILITY_FEATURE_SUCCESS";
export const v21CompatibilityFeatureFail = "V21_COMPATIBILITY_FEATURE_FAIL";

export const saveV21CompatibilityFeatureStart = "SAVE_V21_COMPATIBILITY_FEATURE_START";
export const saveV21CompatibilityFeatureSuccess = "SAVE_V21_COMPATIBILITY_FEATURE_SUCCESS";
export const saveV21CompatibilityFeatureFail = "SAVE_V21_COMPATIBILITY_FEATURE_FAIL";
export const closeV21CompatibilityFeature = "CLOSE_V21_COMPATIBILITY_FEATURE";
/* Select A Pop */
export const selectAPopLabelDataStart = "SELECT_A_POP_LABEL_DATA_START";
export const selectAPopLabelDataSuccess = "SELECT_A_POP_LABEL_DATA_SUCCESS";
export const selectAPopLabelDataFail = "SELECT_A_POP_LABEL_DATA_FAIL";

export const saveAPopLabelDataStart = "SAVE_A_POP_LABEL_DATA_START";
export const saveAPopLabelDataSuccess = "SAVE_A_POP_LABEL_DATA_SUCCESS";
export const saveAPopLabelDataFail = "SAVE_A_POP_LABEL_DATA_FAIL";

export const deleteAPopLabelDataStart = "DELETE_A_POP_LABEL_DATA_START";
export const deleteAPopLabelDataSuccess = "DELETE_A_POP_LABEL_DATA_SUCCESS";
export const deleteAPopLabelDataFail = "DELETE_A_POP_LABEL_DATA_FAIL";

/* Graph An Item */
export const runGraphForPopulationStart = "RUN_GRAPH_FOR_POPULATION_START";
export const runGraphForPopulationSuccess = "RUN_GRAPH_FOR_POPULATION_SUCCESS";
export const runGraphForPopulationFail = "RUN_GRAPH_FOR_POPULATION_FAIL";

/* Generate Physicians Roster */
export const generatePhysiciansRosterDataStart = "GENERATE_PHYSICIANS_ROSTER_DATA_START";
export const generatePhysiciansRosterDataSuccess = "GENERATE_PHYSICIANS_ROSTER_DATA_SUCCESS";
export const generatePhysiciansRosterDataFail = "GENERATE_PHYSICIANS_ROSTER_DATA_FAIL";

export const runPhysiciansRosterStart = "RUN_PHYSICIANS_ROSTER_START";
export const runPhysiciansRosterSuccess = "RUN_PHYSICIANS_ROSTER_SUCCESS";
export const runPhysiciansRosterFail = "RUN_PHYSICIANS_ROSTER_FAIL";

/* Initialize Accession Numbers */
export const loadAccNbrsMaintDataStart = "LOAD_ACC_NBRS_MAINT_DATA_START";
export const loadAccNbrsMaintDataSuccess = "LOAD_ACC_NBRS_MAINT_DATA_SUCCESS";
export const loadAccNbrsMaintDataFail = "LOAD_ACC_NBRS_MAINT_DATA_FAIL";
export const loadAccNbrsMaintInitData = "LOAD_ACC_NBRS_MAINT_INIT_DATA";

export const saveAccNbrsMaintStart = "SAVE_ACC_NBRS_MAINT_START";
export const saveAccNbrsMaintSuccess = "SAVE_ACC_NBRS_MAINT_SUCCESS";
export const saveAccNbrsMaintFail = "SAVE_ACC_NBRS_MAINT_FAIL";

export const deleteAccNbrsMaintStart = "DELETE_ACC_NBRS_MAINT_START";
export const deleteAccNbrsMaintSuccess = "DELETE_ACC_NBRS_MAINT_SUCCESS";
export const deleteAccNbrsMaintFail = "DELETE_ACC_NBRS_MAINT_FAIL";

/* Maintain Follow-up Order */
export const loadFollowUpOrderDataStart = "LOAD_FOLLOW_UP_ORDER_DATA_START";
export const loadFollowUpOrderDataSuccess = "LOAD_FOLLOW_UP_ORDER_DATA_SUCCESS";
export const loadFollowUpOrderDataFail = "LOAD_FOLLOW_UP_ORDER_DATA_FAIL";

export const saveFollowUpOrderStart = "SAVE_FOLLOW_UP_ORDER_START";
export const saveFollowUpOrderSuccess = "SAVE_FOLLOW_UP_ORDER_SUCCESS";
export const saveFollowUpOrderFail = "SAVE_FOLLOW_UP_ORDER_FAIL";

export const deleteFollowUpOrderStart = "DELETE_FOLLOW_UP_ORDER_START";
export const deleteFollowUpOrderSuccess = "DELETE_FOLLOW_UP_ORDER_SUCCESS";
export const deleteFollowUpOrderFail = "DELETE_FOLLOW_UP_ORDER_FAIL";

/* GenEdits */
export const loadGenEditsOnInitial = "LOAD_GEN_EDITS_ON_INITIAL";

export const loadGenEditsDataStart = "LOAD_GEN_EDITS_DATA_START";
export const loadGenEditsDataSuccess = "LOAD_GEN_EDITS_DATA_SUCCESS";
export const loadGenEditsDataFail = "LOAD_GEN_EDITS_DATA_FAIL";

export const saveGenEditsStart = "SAVE_GEN_EDITS_START";
export const saveGenEditsSuccess = "SAVE_GEN_EDITS_SUCCESS";
export const saveGenEditsFail = "SAVE_GEN_EDITS_FAIL";

/* Dashboard Settings */
export const loadDashboardSettingsDataStart = "LOAD_DASHBOARD_SETTINGS_DATA_START";
export const loadDashboardSettingsDataSuccess = "LOAD_DASHBOARD_SETTINGS_DATA_SUCCESS";
export const loadDashboardSettingsDataFail = "LOAD_DASHBOARD_SETTINGS_DATA_FAIL";

export const saveDashboardSettingsStart = "SAVE_DASHBOARD_SETTINGS_START";
export const saveDashboardSettingsSuccess = "SAVE_DASHBOARD_SETTINGS_SUCCESS";
export const saveDashboardSettingsFail = "SAVE_DASHBOARD_SETTINGS_FAIL";

/* Delete Abstract * /
export const loadPatientAbsInfoDataStart = "LOAD_PATIENT_ABS_DATA_START";
export const loadPatientAbsInfoDataSuccess = "LOAD_PATIENT_ABS_DATA_SUCCESS";
export const loadPatientAbsInfoDataFail = "LOAD_PATIENT_ABS_DATA_FAIL";
*/

export const deletePatientAbsInfoStart = "DELETE_PATIENT_ABS_START";
export const deletePatientAbsInfoSuccess = "DELETE_PATIENT_ABS_SUCCESS";
export const deletePatientAbsInfoFail = "DELETE_PATIENT_ABS_FAIL";

/* Copy Abstract */
export const copyItemInputStart = "COPY_ITEM_INPUT_START";
export const copyItemInputSuccess = "COPY_ITEM_INPUT_SUCCESS";
export const copyItemInputFail = "COPY_ITEM_INPUT_FAIL";

/* Print Abstracts */
export const loadPatientAbsInfoDataStart = "LOAD_PATIENT_ABS_DATA_START";
export const loadPatientAbsInfoDataSuccess = "LOAD_PATIENT_ABS_DATA_SUCCESS";
export const loadPatientAbsInfoDataFail = "LOAD_PATIENT_ABS_DATA_FAIL";

export const runPrintAbstractStart = "RUN_PRINT_ABSTRACT_START";
export const runPrintAbstractSuccess = "RUN_PRINT_ABSTRACT_SUCCESS";
export const runPrintAbstractFail = "RUN_PRINT_ABSTRACT_FAIL";

/* Print Tx Summary */
export const runPrintTxSummaryStart = "RUN_PRINT_TX_SUMMARY_START";
export const runPrintTxSummarySuccess = "RUN_PRINT_TX_SUMMARY_SUCCESS";
export const runPrintTxSummaryFail = "RUN_PRINT_TX_SUMMARY_FAIL";

/* Print QA Form */
export const runPrintQAFormStart = "RUN_PRINT_QA_FORM_START";
export const runPrintQAFormSuccess = "RUN_PRINT_QA_FORM_SUCCESS";
export const runPrintQAFormFail = "RUN_PRINT_QA_FORM_FAIL";

/* Print SCP Form */
export const runGenSurvivorshipCarePlanStart = "RUN_GEN_SURVIVORSHIP_CARE_PLAN_START";
export const runGenSurvivorshipCarePlanSuccess = "RUN_GEN_SURVIVORSHIP_CARE_PLAN_SUCCESS";
export const runGenSurvivorshipCarePlanFail = "RUN_GEN_SURVIVORSHIP_CARE_PLAN_FAIL";

/* Report File */
export const selectRfLabelsDataStart = "SELECT_RF_LABELS_DATA_START";
export const selectRfLabelsDataSuccess = "SELECT_RF_LABELS_DATA_SUCCESS";
export const selectRfLabelsDataFail = "SELECT_RF_LABELS_DATA_FAIL";

export const saveRfLabelsDataStart = "SAVE_RF_LABELS_DATA_START";
export const saveRfLabelsDataSuccess = "SAVE_RF_LABELS_DATA_SUCCESS";
export const saveRfLabelsDataFail = "SAVE_RF_LABELS_DATA_FAIL";

export const deleteRfLabelsDataStart = "DELETE_RF_LABELS_DATA_START";
export const deleteRfLabelsDataSuccess = "DELETE_RF_LABELS_DATA_SUCCESS";
export const deleteRfLabelsDataFail = "DELETE_RF_LABELS_DATA_FAIL";

/* List For a Population */
export const clearAllExportData = "CLEAR_ALL_EXPORT_DATA";
export const selectReportLabelsDataStart = "SELECT_REPORT_LABELS_DATA_START";
export const selectReportLabelsDataSuccess = "SELECT_REPORT_LABELS_DATA_SUCCESS";
export const selectReportLabelsDataFail = "SELECT_REPORT_LABELS_DATA_FAIL";

export const saveReportLabelsDataStart = "SAVE_REPORT_LABELS_DATA_START";
export const saveReportLabelsDataSuccess = "SAVE_REPORT_LABELS_DATA_SUCCESS";
export const saveReportLabelsDataFail = "SAVE_REPORT_LABELS_DATA_FAIL";

export const deleteReportLabelsDataStart = "DELETE_REPORT_LABELS_DATA_START";
export const deleteReportLabelsDataSuccess = "DELETE_REPORT_LABELS_DATA_SUCCESS";
export const deleteReportLabelsDataFail = "DELETE_REPORT_LABELS_DATA_FAIL";

/* Accession Register */
export const generateAccessionRegisterDataStart = "GENERATE_ACCESSION_REGISTER_DATA_START";
export const generateAccessionRegisterDataSuccess = "GENERATE_ACCESSION_REGISTER_DATA_SUCCESS";
export const generateAccessionRegisterDataFail = "GENERATE_ACCESSION_REGISTER_DATA_FAIL";

export const runAccessionRegisterStart = "RUN_ACCESSION_REGISTER_START";
export const runAccessionRegisterSuccess = "RUN_ACCESSION_REGISTER_SUCCESS";
export const runAccessionRegisterFail = "RUN_ACCESSION_REGISTER_FAIL";

/* Request Log */
export const generateRequestLogDataStart = "GENERATE_REQUEST_LOG_DATA_START";
export const generateRequestLogDataSuccess = "GENERATE_REQUEST_LOG_DATA_SUCCESS";
export const generateRequestLogDataFail = "GENERATE_REQUEST_LOG_DATA_FAIL";

export const runRequestLogStart = "RUN_REQUEST_LOG_START";
export const runRequestLogSuccess = "RUN_REQUEST_LOG_SUCCESS";
export const runRequestLogFail = "RUN_REQUEST_LOG_FAIL";

/* Site Distribution */
export const generateSiteDistributionDataStart = "GENERATE_SITE_DISTRIBUTION_DATA_START";
export const generateSiteDistributionDataSuccess = "GENERATE_SITE_DISTRIBUTION_DATA_SUCCESS";
export const generateSiteDistributionDataFail = "GENERATE_SITE_DISTRIBUTION_DATA_FAIL";

export const runSiteDistributionStart = "RUN_SITE_DISTRIBUTION_START";
export const runSiteDistributionSuccess = "RUN_SITE_DISTRIBUTION_SUCCESS";
export const runSiteDistributionFail = "RUN_SITE_DISTRIBUTION_FAIL";

// State Export screen
export const runStateExportFormStart = "RUN_STATE_EXPORT_FORM_START";
export const runStateExportFormSuccess = "RUN_STATE_EXPORT_FORM_SUCCESS";
export const runStateExportFormFail = "RUN_STATE_EXPORT_FORM_FAIL";

// NCDB Export screen
export const runNcdbExportFormStart = "RUN_NCDB_EXPORT_FORM_START";
export const runNcdbExportFormSuccess = "RUN_NCDB_EXPORT_FORM_SUCCESS";
export const runNcdbExportFormFail = "RUN_NCDB_EXPORT_FORM_FAIL";

/* Site Distribution */
export const generateTreatmentCombinationDataStart = "GENERATE_TREATMENT_COMBINATION_DATA_START";
export const generateTreatmentCombinationDataSuccess = "GENERATE_TREATMENT_COMBINATION_DATA_SUCCESS";
export const generateTreatmentCombinationDataFail = "GENERATE_TREATMENT_COMBINATION_DATA_FAIL";

export const runTreatmentCombinationStart = "RUN_TREATMENT_COMBINATION_START";
export const runTreatmentCombinationSuccess = "RUN_TREATMENT_COMBINATION_SUCCESS";
export const runTreatmentCombinationFail = "RUN_TREATMENT_COMBINATION_FAIL";

/* Productivity */
export const generateProductivityDataStart = "GENERATE_PRODUCTIVITY_DATA_START";
export const generateProductivityDataSuccess = "GENERATE_PRODUCTIVITY_DATA_SUCCESS";
export const generateProductivityDataFail = "GENERATE_PRODUCTIVITY_DATA_FAIL";

export const runProductivityStart = "RUN_PRODUCTIVITY_START";
export const runProductivitySuccess = "RUN_PRODUCTIVITY_SUCCESS";
export const runProductivityFail = "RUN_PRODUCTIVITY_FAIL";

/* Quality Measures */
export const generateQualityMeasuresDataStart = "GENERATE_QUALITY_MEASURES_DATA_START";
export const generateQualityMeasuresDataSuccess = "GENERATE_QUALITY_MEASURES_DATA_SUCCESS";
export const generateQualityMeasuresDataFail = "GENERATE_QUALITY_MEASURES_DATA_FAIL";

export const runQualityMeasuresStart = "RUN_QUALITY_MEASURES_START";
export const runQualityMeasuresSuccess = "RUN_QUALITY_MEASURES_SUCCESS";
export const runQualityMeasuresFail = "RUN_QUALITY_MEASURES_FAIL";

/* CPM Report */
export const generateCPMReportDataStart = "GENERATE_CPM_REPORT_DATA_START";
export const generateCPMReportDataSuccess = "GENERATE_CPM_REPORT_DATA_SUCCESS";
export const generateCPMReportDataFail = "GENERATE_CPM_REPORT_DATA_FAIL";

export const runCPMReportStart = "RUN_CPM_REPORT_START";
export const runCPMReportSuccess = "RUN_CPM_REPORT_SUCCESS";
export const runCPMReportFail = "RUN_CPM_REPORT_FAIL";

/* Timeliness */
export const generateTimelinessDataStart = "GENERATE_TIMELINESS_DATA_START";
export const generateTimelinessDataSuccess = "GENERATE_TIMELINESS_DATA_SUCCESS";
export const generateTimelinessDataFail = "GENERATE_TIMELINESS_DATA_FAIL";

export const runTimelinessStart = "RUN_TIMELINESS_START";
export const runTimelinessSuccess = "RUN_TIMELINESS_SUCCESS";
export const runTimelinessFail = "RUN_TIMELINESS_FAIL";

/* TNM Graph */
export const generateTNMGraphDataStart = "GENERATE_TNM_GRAPH_DATA_START";
export const generateTNMGraphDataSuccess = "GENERATE_TNM_GRAPH_DATA_SUCCESS";
export const generateTNMGraphDataFail = "GENERATE_TNM_GRAPH_DATA_FAIL";

export const runTNMGraphStart = "RUN_TNM_GRAPH_START";
export const runTNMGraphSuccess = "RUN_TNM_GRAPH_SUCCESS";
export const runTNMGraphFail = "RUN_TNM_GRAPH_FAIL";

/* Cross Tab */
export const generateCrossTabDataStart = "GENERATE_CROSS_TAB_DATA_START";
export const generateCrossTabDataSuccess = "GENERATE_CROSS_TAB_DATA_SUCCESS";
export const generateCrossTabDataFail = "GENERATE_CROSS_TAB_DATA_FAIL";

export const runCrossTabStart = "RUN_CROSS_TAB_START";
export const runCrossTabSuccess = "RUN_CROSS_TAB_SUCCESS";
export const runCrossTabFail = "RUN_CROSS_TAB_FAIL";

export const loadCrossTabDataStart = "LOAD_CROSS_TAB_DATA_START";
export const loadCrossTabDataSuccess = "LOAD_CROSS_TAB_DATA_SUCCESS";
export const loadCrossTabDataFail = "LOAD_CROSS_TAB_DATA_FAIL";

export const deleteCrossTabLabelDataStart = "DELETE_CROSS_TAB_LABEL_DATA_START";
export const deleteCrossTabLabelDataSuccess = "DELETE_CROSS_TAB_LABEL_DATA_SUCCESS";
export const deleteCrossTabLabelDataFail = "DELETE_CROSS_TAB_LABEL_DATA_FAIL";

/* Clear Populations */
export const deletePopulationLabelsDataStart = "DELETE_POP_LABELS_DATA_START";
export const deletePopulationLabelsDataSuccess = "DELETE_POP_LABELS_DATA_SUCCESS";
export const deletePopulationLabelsDataFail = "DELETE_POP_LABELS_DATA_FAIL";

/* Work Queue */
export const loadWorkQueueDataStart = "LOAD_WORK_QUEUE_DATA_START";
export const loadWorkQueueDataSuccess = "LOAD_WORK_QUEUE_DATA_SUCCESS";
export const loadWorkQueueDataFail = "LOAD_WORK_QUEUE_DATA_FAIL";

/* Follow-up/QA Letters */
export const loadLettersMaintDataStart = "LOAD_LETTERS_MAINT_DATA_START";
export const loadLettersMaintDataSuccess = "LOAD_LETTERS_MAINT_DATA_SUCCESS";
export const loadLettersMaintDataFail = "LOAD_LETTERS_MAINT_DATA_FAIL";

export const saveLettersMaintStart = "SAVE_LETTERS_MAINT_START";
export const saveLettersMaintSuccess = "SAVE_LETTERS_MAINT_SUCCESS";
export const saveLettersMaintFail = "SAVE_LETTERS_MAINT_FAIL";

export const deleteLettersMaintStart = "DELETE_LETTERS_MAINT_START";
export const deleteLettersMaintSuccess = "DELETE_LETTERS_MAINT_SUCCESS";
export const deleteLettersMaintFail = "DELETE_LETTERS_MAINT_FAIL";

export const previewLettersMaintStart = "PREVIEW_LETTERS_MAINT_START";
export const previewLettersMaintSuccess = "PREVIEW_LETTERS_MAINT_SUCCESS";
export const previewLettersMaintFail = "PREVIEW_LETTERS_MAINT_FAIL";

/* Custom Forms */
export const loadCustomFormsMaintDataStart = "LOAD_CUSTOM_FORMS_MAINT_DATA_START";
export const loadCustomFormsMaintDataSuccess = "LOAD_CUSTOM_FORMS_MAINT_DATA_SUCCESS";
export const loadCustomFormsMaintDataFail = "LOAD_CUSTOM_FORMS_MAINT_DATA_FAIL";

export const saveCustomFormsMaintStart = "SAVE_CUSTOM_FORMS_MAINT_START";
export const saveCustomFormsMaintSuccess = "SAVE_CUSTOM_FORMS_MAINT_SUCCESS";
export const saveCustomFormsMaintFail = "SAVE_CUSTOM_FORMS_MAINT_FAIL";

export const deleteCustomFormsMaintStart = "DELETE_CUSTOM_FORMS_MAINT_START";
export const deleteCustomFormsMaintSuccess = "DELETE_CUSTOM_FORMS_MAINT_SUCCESS";
export const deleteCustomFormsMaintFail = "DELETE_CUSTOM_FORMS_MAINT_FAIL";

export const previewCustomFormsMaintStart = "PREVIEW_CUSTOM_FORMS_MAINT_START";
export const previewCustomFormsMaintSuccess = "PREVIEW_CUSTOM_FORMS_MAINT_SUCCESS";
export const previewCustomFormsMaintFail = "PREVIEW_CUSTOM_FORMS_MAINT_FAIL";

/* Import Configuration */
export const loadImportConfigDataStart = "LOAD_IMPORT_CONFIG_DATA_START";
export const loadImportConfigDataSuccess = "LOAD_IMPORT_CONFIG_DATA_SUCCESS";
export const loadImportConfigDataFail = "LOAD_IMPORT_CONFIG_DATA_FAIL";

export const saveImportConfigDataStart = "SAVE_IMPORT_CONFIG_DATA_START";
export const saveImportConfigDataSuccess = "SAVE_IMPORT_CONFIG_DATA_SUCCESS";
export const saveImportConfigDataFail = "SAVE_IMPORT_CONFIG_DATA_FAIL";

export const deleteImportConfigDataStart = "DELETE_IMPORT_CONFIG_DATA_START";
export const deleteImportConfigDataSuccess = "DELETE_IMPORT_CONFIG_DATA_SUCCESS";
export const deleteImportConfigDataFail = "DELETE_IMPORT_CONFIG_DATA_FAIL";

/* Database Validation */
export const databaseValidationInfoDataStart = "DATABASE_VALIDATION_INFO_DATA_START";
export const databaseValidationInfoDataSuccess = "DATABASE_VALIDATION_INFO_DATA_SUCCESS";
export const databaseValidationInfoDataFail = "DATABASE_VALIDATION_INFO_DATA_FAIL";

export const runDatabaseValidationStart = "RUN_DATABASE_VALIDATION_START";
export const runDatabaseValidationSuccess = "RUN_DATABASE_VALIDATION_SUCCESS";
export const runDatabaseValidationFail = "RUN_DATABASE_VALIDATION_FAIL";

/* Global Change */
export const runGlobalChangeStart = "RUN_GLOBAL_CHANGE_START";
export const runGlobalChangeSuccess = "RUN_GLOBAL_CHANGE_SUCCESS";
export const runGlobalChangeFail = "RUN_GLOBAL_CHANGE_FAIL";

/* Mass Delete */
export const massDeleteInfoStart = "MASS_DELETE_INFO_START";
export const massDeleteInfoSuccess = "MASS_DELETE_INFO_SUCCESS";
export const massDeleteInfoFail = "MASS_DELETE_INFO_FAIL";

export const runMassDeleteStart = "RUN_MASS_DELETE_START";
export const runMassDeleteSuccess = "RUN_MASS_DELETE_SUCCESS";
export const runMassDeleteFail = "RUN_MASS_DELETE_FAIL";

/* Generate Follow-up List and Letters */
export const loadFollowUpLettersStart = "LOAD_FOLLOW_UP_LETTERS_START";
export const loadFollowUpLettersSuccess = "LOAD_FOLLOW_UP_LETTERS_SUCCESS";
export const loadFollowUpLettersFail = "LOAD_FOLLOW_UP_LETTERS_FAIL";

export const runFollowUpLettersStart = "RUN_FOLLOW_UP_LETTERS_START";
export const runFollowUpLettersSuccess = "RUN_FOLLOW_UP_LETTERS_SUCCESS";
export const runFollowUpLettersFail = "RUN_FOLLOW_UP_LETTERS_FAIL";

/* ZipCodes Lookup */
export const getZipCodesDataStart = "GET_ZIPCODES_DATA_START";
export const getZipCodesDataSuccess = "GET_ZIPCODES_DATA_SUCCESS";
export const getZipCodesDataFail = "GET_ZIPCODES_DATA_FAIL";

export const saveZipCodesDataStart = "SAVE_ZIPCODES_DATA_START";
export const saveZipCodesDataSuccess = "SAVE_ZIPCODES_DATA_SUCCESS";
export const saveZipCodesDataFail = "SAVE_ZIPCODES_DATA_FAIL";

export const deleteZipCodesDataStart = "DELETE_ZIPCODES_DATA_START";
export const deleteZipCodesDataSuccess = "DELETE_ZIPCODES_DATA_SUCCESS";
export const deleteZipCodesDataFail = "DELETE_ZIPCODES_DATA_FAIL";
