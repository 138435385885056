/* eslint-disable */
//Window Title: Generate Follow-up Calculations
//Window height: 600px, width: 1250px
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../store/PatientAbstract";
import Input from "../UI/Input";
import axios from "../../axios-instance";
import Draggable from 'react-draggable';
import "./GenFollowUpLetters.css";

class GenFollowUpCalcs extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        showMessageError: false,
        hasError: false,
        fieldInFocus: null,
        focusingField: null,
        loaded: false,
        isCheckedPediatricFacilities: false,
        isCheckedDeathList: false,
        isCheckedCreateLostPopulation: false,
        selectedReportingDevice: "Screen",
        calcRangeMsg: "",
    }

    sortOrderOptions = [
        "Alphabetic by Last Name, First Name",
        "Numeric by Social Security Number",
        "Numeric by Medical Record Number",
        "By Date of Last Contact",
        "By Date of Diagnosis",
        "By Hospital Code"
    ];

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "Pediatric_Facilities") {
            const checked = this.state.isCheckedPediatricFacilities;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedPediatricFacilities: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "Death_List") {
            const checked = this.state.isCheckedDeathList;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedDeathList: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "Create_Lost_Population") {
            const checked = this.state.isCheckedCreateLostPopulation;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedCreateLostPopulation: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }

    handleOnFocus = (event, index) => {
        event.preventDefault();
        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target
        });
        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({
            showMessageError: false
        });
        this.props.setChildrenOpening(false);
        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);
        return false;
    }

    _componentDidUnmount() {
        document.getElementById("startTime") ? document.getElementById("startTime").text = "" : null;
        document.getElementById("endTime") ? document.getElementById("endTime").text = "" : null;
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ allowedHosps: null });
        this.initClock();
    }

    componentDidMount() {
        //document.title = "Generate Follow-up Calculations";

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        this.setState({ isLoading: true, loaded: false });

        if (!this.state.dialogForm && this.props.initialPageVisible) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        setTimeout(function () {
            document.getElementById("BUTTON_NEWRECORD_FK") ? document.getElementById("BUTTON_NEWRECORD_FK").click() : null;
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if (this.state.error && this.state.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true
            });
            document.querySelector('#BUTTON_RUN') ? document.querySelector('#BUTTON_RUN').focus() : null;
            return false;
        }

        if (!this.state.dialogForm && this.props.initialPageVisible) {
            this.setFormData();
        }

        //
        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        // Processing output...
        var dataForm = this.state.dialogForm;
        if (dataForm && dataForm["endTime"] && dataForm["endTime"] != "" && !this.state.completed) {
            if (dataForm["reportingDevice"] == "Excel" && !this.downloadFile && !this.state.completed && dataForm["reportFileName"]) {
                this.downloadFileFnc(dataForm["reportFileName"]);
                this.setState({ completed: true });
                this.downloadFile = true;
            } else if (dataForm["reportingDevice"] == "Screen" && !this.completed) {
                this.drawingHtmlTable(dataForm);
            }
        }
    }

    setFormData = () => {
        var calcRangeMsg = "";
        var today = new Date();
        var beginYear = today.getFullYear() - 17;
        var endYear = today.getFullYear() - 3;

        var refYear = this.state.dialogForm && this.state.dialogForm["referenceYear"] ? this.state.dialogForm["referenceYear"] : "";
        if (refYear && refYear > beginYear && refYear <= today.getFullYear()) {
            beginYear = refYear;
        }

        calcRangeMsg = 'Follow-Up Calculations from: ' + beginYear + '-' + endYear;

        let fields = { calcRangeMsg: calcRangeMsg };

        this.setState({
            initialDialogForm: fields,
            dialogForm: { ...fields },
            isFormDirty: false,
            reload: false,
            hasError: false,
            loaded: true,
            showClockTimer: false,
            calcRangeMsg: calcRangeMsg,
        });
    }

    handleFormChange = (event) => {
        if (event.target2) {
            if (event.target2.name === "hospitalCodeName") {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value,
                    },
                    isFormDirty: true,
                });
                return false;
            }
        }

        var calcRangeMsg = this.state.dialogForm["calcRangeMsg"] ? this.state.dialogForm["calcRangeMsg"] : "";//Calc_Range_Message
        if (event.target.name === "referenceYear") {
            var today = new Date();
            var beginYear = today.getFullYear() - 17;
            var endYear = today.getFullYear() - 3;
            var refYear = event.target.value;

            if (refYear > beginYear && refYear <= today.getFullYear()) {
                beginYear = refYear;
            }

            calcRangeMsg = 'Follow-Up Calculations from: ' + beginYear + '-' + endYear;
            document.getElementById("calcRangeMsg") ? document.getElementById("calcRangeMsg").style.display = "" : null;
        }

        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value,
                ["calcRangeMsg"]: calcRangeMsg
            },
            isFormDirty: true,
            calcRangeMsg: calcRangeMsg,
        });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "" && event.target.name == "hospitalCode") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            if (event.target.name == "hospitalCode" && event.target.value == "") {
                document.getElementById("hospitalCodeName") ? document.getElementById("hospitalCodeName").innerHTML = "" : null;
            }
        }
    }

    closeWindow = (e) => {
        e.preventDefault();

        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null
        });

        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });
            return false;
        }, 1000);
    }

    FollowUp_Calcs_Report() {
        var dataForm = this.state.dialogForm;
        dataForm["userId"] = this.props.userId;
        dataForm["clientAlias"] = this.props.clientgroup;
        dataForm["reportingDevice"] = this.state.selectedReportingDevice ? this.state.selectedReportingDevice : "Screen";
        dataForm["sortOrder"] = this.state.dialogForm["sortOrder"] ? this.state.dialogForm["sortOrder"] : "Alphabetic by Last Name, First Name";
        dataForm["pediatricFacilities"] = this.state.isCheckedPediatricFacilities ? "true" : "false";
        //dataForm["deathList"] = this.state.isCheckedDeathList ? "true" : "false";
        dataForm["createLostPopulation"] = this.state.isCheckedCreateLostPopulation ? "true" : "false";
        dataForm["reportFileName"] = null;
        //dataForm["fnDeathList"] = null;

        //
        var _this = this;
        let url = this.props.clientgroup + "/FollowUpCalculations";
        axios.post(url, { followUpLetters: dataForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var fu = res && res.data && res.data.followUpLetters ? res.data.followUpLetters : [];
                if (res && res.data && !res.data.error) {
                    //@TODO: drawing html output or download excel file
                    _this.setState({
                        dialogForm: { ...fu }
                    });
                } else {
                    _this.setState({
                        dialogForm: { ...fu }
                    });
                }
                _this.setState({ showClockTimer: false, completed: false });
            }).catch(error => {
                console.log(error);
                _this.setState({ showClockTimer: false });
            });

        this.downloadFile = false;//reset this flag to get download new file
        return false;
    }

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var _this = this;
        setTimeout(function () {
            _this.FollowUp_Calcs_Report();
            document.getElementById("outputDataHandler2") ? document.getElementById("outputDataHandler2").value = "" : null;

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });
            return false;
        }, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);
        return false;
    }

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckStatusFu";
        axios.post(url, { accessToken: localStorage.getItem('token') })
            .then(res => {
                if (res && res.data && res.data.error) {
                    //eval(res.data);
                    _this.setState({
                        showMessageError: true,
                        errorMessage: res.data.message
                    });
                } else if (res && res.data && res.data.fnDisplay) {
                    //
                    console.clear();
                    console.log("CheckStatusFu::Completed");
                }
            }).catch(error => {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
        return false;
    }
    downloadFileFnc(fname) {
        const link = document.createElement('a');
        link.href = this.props.clientgroup + "/" + fname;
        link.setAttribute('download', fname);
        document.body.appendChild(link);
        link.click();
        link.remove();
        return false;
    }
    //
    downloadFileFnc2(fileName) {
        var url = this.props.clientgroup + "/DownloadFileFu";
        var followUpLetters = {
            userId: this.props.userId,
            fileName: fileName
        };

        var _this = this;
        axios.post(url, {
            followUpLetters: followUpLetters,
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            _this.downloadFile = true;
            return false;
        });
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
        return;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);
        return false;
    }

    refreshFormFnc() {
        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            isFormDirty: false,
            reload: false,
            isCheckedPediatricFacilities: false,
            isCheckedDeathList: false,
            isCheckedCreateLostPopulation: false,
            selectedReportingDevice: "Screen",
            calcRangeMsg: "",
        });
        setTimeout(function () {
            document.getElementById("calcRangeMsg") ? document.getElementById("calcRangeMsg").style.display = "none" : null;
            document.getElementById("populationLabel") ? document.getElementById("populationLabel").focus() : "";
        }, 500);
    }

    newRecord(event) {
        event.preventDefault();
        this.refreshFormFnc();
        return false;
    }

    exitScreen = (event) => {
        event.preventDefault();
        this.props.setInitialPageVisible(false);
        this.refreshFormFnc();
        return false;
    }

    downloadDeathlist = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }

        var fileUrl = this.state.dialogForm["Fn_DeathList"];
        if (!fileUrl || fileUrl == "null") fileUrl = "";

        var url = this.props.clientgroup + "/DownloadFile";
        var _this = this;
        axios.post(url, {
            urlOutputFile: fileUrl,
            method: 'POST',
            responseType: 'blob',//important
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;

            // get file name from url
            var filename = fileUrl.substring(fileUrl.lastIndexOf('\\') + 1);
            console.log("filename=" + filename);

            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            _this.downloadFile = true;
            return false;
        }).catch(function (error) {
            console.log(error);
        });

        return false;
    }

    selectionReportingDevice = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        var fs = event.target;
        var fc = fs ? fs.name : null;

        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedReportingDevice: event.target.value,
            dialogForm: dialogFormData,
            fieldInFocus: fc,
            focusingField: fs,
            isFormDirty: this.state.selectedReportingDevice != event.target.value ? true : this.state.isFormDirty,
        });

        this.props.storeToGlobal(null);
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        return false;
    }

    drawingHtmlTable(reportDataInfo) {
        this.setState({
            radioOptions: "Letters",
            //
            isDrawingHtmlTable: (reportDataInfo.fnDisplay && reportDataInfo.fnDisplay != "") || (reportDataInfo.urlList && reportDataInfo.urlList != ""),
            radioListLetters: "Letters",
            fnDisplay: reportDataInfo.fnDisplay,
            fnExcel: reportDataInfo.fnExcel,
            htmlOutputOfList: reportDataInfo.htmlOutputOfList,
            htmlOutputOfLetters: reportDataInfo.htmlOutputOfLetters,
            reportFileName: reportDataInfo.reportFileName,
            completed: true
        });

        setTimeout(function () {
            document.getElementById("fuReportOption_Id") ? document.getElementById("fuReportOption_Id").click() : null;

            if (document.getElementById("outputDataHandler2") && document.getElementById("outputDataHandler2").value == "1") {
                document.getElementById("outputDataHandler2") ? document.getElementById("outputDataHandler2").value = "0" : null;
                return false;
            } else {
                document.getElementById("outputDataHandler2") ? document.getElementById("outputDataHandler2").value = "1" : null;
            }

            var reportDisplay = document.getElementById("displayReportIframe");
            if (reportDisplay && reportDisplay.contentDocument) {
                reportDisplay.contentDocument.src = "about:blank";
                reportDisplay.contentDocument.write("");
                reportDisplay.contentDocument.write(reportDataInfo.htmlOutputOfLetters);
            }
            var reportListDisplay = document.getElementById("displayReportListIframe");
            if (reportListDisplay && reportListDisplay.contentDocument) {
                reportListDisplay.contentDocument.src = "about:blank";
                reportListDisplay.contentDocument.write("");
                reportListDisplay.contentDocument.write(reportDataInfo.htmlOutputOfList);
            }
        }, 100);

        return false;
    };

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false });
        return false;
    }

    printTable = () => {
        var printStyle = document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : "";
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>' + (this.props.pTitle ? this.props.pTitle : "Follow-Up Letters") + '</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';
        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';
        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    selectionFuReport = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            radioListLetters: event.target.value,
            dialogForm: dialogFormData
        });

        setTimeout(function () {
            document.getElementById("option1").click();
            return false;
        }, 0);
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Warning</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls" dangerouslySetInnerHTML={{ __html: this.state.errorMessage ? this.state.errorMessage : this.props.errorMessage }}></label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please Wait&nbsp;&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton" value="no">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div >
                : null;
        }

        var Report_Url;
        var reportFile = this.state.radioListLetters ? this.state.radioListLetters : "Letters";
        if (reportFile == "Letters") {
            Report_Url = this.state.htmlOutputOfLetters;
        } else {
            Report_Url = this.state.htmlOutputOfList;
        }
        const _radioListLetters = this.state.radioListLetters ? this.state.radioListLetters : "Letters";
        var reportContent;
        {
            reportContent = this.state.isDrawingHtmlTable && this.state.fnDisplay ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className={reportFile == "Letters" ? "dragableSmallWindowCls" : "dragableLargestWindowCls"}
                            id={reportFile == "Letters" ? "letterPreviewLostFuModal" : "dataExportMainPopup_LostFU"}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Lost to Follow-Up Report</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="printOnQaForm" id="printAbtractsMainTable"
                                style={{ overflowY: this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "auto" }}>
                                {/*Drawing table here*/}

                                <div id="useForPrintHtml" className="hidden">
                                    <div dangerouslySetInnerHTML={{ __html: Report_Url }} className="fu_calcs_reporting" />
                                </div>

                                <iframe id="displayReportIframe" className={_radioListLetters == "Letters" ? "" : "hidden"} />
                                <iframe id="displayReportListIframe" className={_radioListLetters == "List" ? "" : "hidden"} />

                                <input id="outputDataHandler2" value="0" type="hidden" />
                            </div>

                            <div className="emptyLine"></div>

                            <div className="emptyLine hidden" style={{ display: "none" }}>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option1" id="option1"
                                            checked={this.state.selectedOption === 'option1'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 1
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option2" id="option2"
                                            checked={this.state.selectedOption === 'option2'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 2
                                        </label>
                                </div>
                            </div>

                            <div className="" style={{ padding: "10px" }}>
                                <div className="row">
                                    <div className="col-sm-3" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable} autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" />
                                            Print</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "5px" }}
                                            onClick={this.hideDrawingHtmlTable} value="exit">
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" />
                                            Exit</button>
                                    </div>
                                    <div className="col-sm-6" style={{ display: "flex" }} id="stateExportRadioOptions">
                                        <label style={{ width: "auto", height: "0px" }}>
                                            <input type="radio" onChange={(e) => { this.selectionFuReport(e) }}
                                                checked={_radioListLetters == "Letters" ? true : false}
                                                name="radioFuReport" field="radioFuReport" id="fuReportOption_Id" value="Letters" width="240px"
                                                style={{ cursor: "pointer", fontWeight: "bold", marginRight: "-35px" }} />
                                        Follow-Up Report</label>
                                        <label style={{ width: "auto", height: "0px" }}>
                                            <input type="radio" onChange={(e) => { this.selectionFuReport(e) }}
                                                checked={_radioListLetters == "List" ? true : false}
                                                name="radioFuReport" field="radioFuReport" value="List"
                                                style={{ cursor: "pointer", fontWeight: "bold", marginRight: "-35px" }} />
                                        Lost List</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null;
        }

        const dialogForm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="genFollowUpCalcsMainPage" style={{ height: "580px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form onSubmit={this.javascript_void_fnc} id="genFollowUpCalcs" className="genFollowUpCalcs">
                                        <div id="inputAreaCls">
                                            <div style={{ width: "100%" }}>
                                                <div className="_frameInner">
                                                    <div className="emptyLine"></div>
                                                    <div className="row">
                                                        <div className="col-sm-5">
                                                            <Input type="LabelPopup" focused={this.handleOnFocus}
                                                                changed={this.handleFormChange} width="320px"
                                                                labelText="Population Label" autoFocus
                                                                field="populationLabel" lineStyle="oneLine"
                                                                value={this.state.dialogForm["populationLabel"]}
                                                                dataSource="Labels" ignoredValidMsg={true}
                                                                titleDialog="Population Labels" labelWidth="110px"
                                                                lostfocused={this.handleLostFocus}
                                                            />
                                                            <input type="hidden" id="populationLabelHidden" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-2">
                                                            <Input type="input" changed={this.handleFormChange}
                                                                focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                labelText="Reference Year" field="referenceYear"
                                                                labelWidth="107px" width="65px"
                                                                value={this.state.dialogForm["referenceYear"]}
                                                            />
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <Input type="label" lineStyle="oneLine"
                                                                labelText="" field="calcRangeMsg"
                                                                labelWidth="0px" width="250px"
                                                                value={this.state.dialogForm["calcRangeMsg"] ? this.state.dialogForm["calcRangeMsg"] : this.state.calcRangeMsg} />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-5" style={{ display: "flex" }}>
                                                            <Input type="Hospital" focused={this.handleOnFocus}
                                                                changed={this.handleFormChange}//Hosp_Nbr (C)|Equals any of|Allowed_hosps
                                                                labelText="Hospital(s)" field="hospitalCode"
                                                                lostfocused={this.handleLostFocus}
                                                                value={this.state.dialogForm["hospitalCode"]} codeLength="2"//Hospital_Code<Code,Hospital_Name<Hosp_Name
                                                                dataSource="Hospital" titleDialog="Hospital Lookup"
                                                                labelWidth="110px" width="110px" lineStyle="oneLine"
                                                            />
                                                            <Input type="label" changed={this.handleFormChange}
                                                                labelText="" field="hospitalCodeName"
                                                                value={this.state.dialogForm["hospitalCodeName"]}
                                                                width="400px" labelWidth="10px" lineStyle="oneLine"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-7">
                                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                                changed={this.handleFormChange}
                                                                labelText="Sort Order"
                                                                field="sortOrder" itemNbr=""
                                                                value={this.state.dialogForm["sortOrder"] ? this.state.dialogForm["sortOrder"] : "Alphabetic by Last Name, First Name"}
                                                                dataSource="" titleDialog=""
                                                                labelWidth="110px" width="250px" lineStyle="oneLine"
                                                                lostfocused={this.handleLostFocus}
                                                                options={this.sortOrderOptions}
                                                                keyId="sortOrder0" focusingField={this.state.focusingField}
                                                                shownType="dropdown" rowIndex="0" displayingCodeOnly={true}
                                                                hideFilter={true} limitByWidth={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="emptyLine"></div>
                                                    <div className="row">
                                                        <div className="col-sm-5 checkboxTypeCls">
                                                            <Input type="checkbox" lineStyle="oneLine"
                                                                labelText="Pediatric Facilities (Exclude patients greater than 26 years of age)"
                                                                field="Pediatric_Facilities" labelWidth="450px"
                                                                labelStyle="lblOnCheckbox" width="20px"
                                                                value={this.state.isCheckedPediatricFacilities ? "1" : "0"}
                                                                checked={this.state.isCheckedPediatricFacilities ? true : false}
                                                                clicked={this.toggleChecked} lblColor={"#960000"}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*}
                                                    <div className="emptyLine"></div>
                                                    <div className="row">
                                                        <div className="col-sm-2 checkboxTypeCls">
                                                            <Input type="checkbox" lineStyle="oneLine"
                                                                labelText="Death List Export" field="Death_List"
                                                                labelWidth="150px"
                                                                labelStyle="lblOnCheckbox" width="20px"
                                                                value={this.state.isCheckedDeathList ? "1" : "0"}
                                                                checked={this.state.isCheckedDeathList ? true : false}
                                                                clicked={this.toggleChecked} lblColor={"#960000"}
                                                            />
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <button id="BUTTON_DOWNLOAD" type="button"
                                                                style={{ position: "absolute", marginTop: "-3px" }}
                                                                className={this.state.Fn_Deathlist && this.state.Fn_Deathlist != "" ? "ScreenButton" : "hidden"}
                                                                onClick={(e) => { this.downloadDeathlist(e) }}>
                                                                <img id="IMAGE_BUTTON_DOWNLOAD" src="https://crstar.ers-can.com/images/$$web.download.png.a5image" />
                                                            Download Deathlist</button>
                                                        </div>
                                                    </div>
                                                    {*/}
                                                    <div className="emptyLine"></div>
                                                    <div className="row">
                                                        <div className="col-sm-5 checkboxTypeCls">
                                                            <Input type="checkbox" lineStyle="oneLine"
                                                                labelText="Create Population of Lost Cases" field="Create_Lost_Population"
                                                                labelWidth="300px"
                                                                labelStyle="lblOnCheckbox" width="20px"
                                                                value={this.state.isCheckedCreateLostPopulation ? "1" : "0"}
                                                                checked={this.state.isCheckedCreateLostPopulation ? true : false}
                                                                clicked={this.toggleChecked} lblColor={"#960000"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="emptyLine"></div>
                                                    <div className="row" style={{ visibility: this.state.isCheckedCreateLostPopulation ? "" : "hidden" }}>
                                                        <div className="col-sm-5">
                                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                                changed={this.handleFormChange}
                                                                labelText="Lost Population Label" field="lostPopLabel" lineStyle="oneLine"
                                                                value={this.state.dialogForm["lostPopLabel"]} labelWidth="130px" width="250px"
                                                                lostfocused={this.handleLostFocus} focusingField={this.state.focusingField}
                                                                dataSource="Labels" titleDialog="" aliasNo="FU" limitByWidth={false} ignoredValidMsg={true}
                                                                shownType="dropdown" keyId="lostPopLabel0" rowIndex="0" codeLabel="Selection Label"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="emptyLine"></div>
                                                    <div className="row">
                                                        <div className="col-sm-5"><strong>Send Report To:</strong></div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-3">
                                                            <label style={{ width: "240px", height: "0px" }}>
                                                                <input type="radio" onClick={this.selectionReportingDevice} onChange={(e) => { this.selectionReportingDevice(e) }}
                                                                    checked={this.state.selectedReportingDevice === "Excel"}
                                                                    name="sendReportToExcel" field="sendReportTo" value="Excel"
                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Excel
                                                                        </label>
                                                            <br />
                                                            <label style={{ width: "240px", height: "0px" }}>
                                                                <input type="radio" onClick={this.selectionReportingDevice} onChange={(e) => { this.selectionReportingDevice(e) }}
                                                                    checked={this.state.selectedReportingDevice === "Screen"}
                                                                    name="sendReportToScreen" field="sendReportTo" value="Screen"
                                                                    style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-15px" }} />
                                                                                Screen
                                                                        </label>
                                                        </div>
                                                    </div>
                                                    <div className="emptyLine"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="emptyLine"></div>
                                    <div className="emptyLine"></div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm["startTime"] ? this.state.dialogForm["startTime"] : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm["endTime"] ? this.state.dialogForm["endTime"] : "-"}
                                            />
                                        </div>
                                    </div>
                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RUN"
                                                        className={this.state.dialogForm["referenceYear"] || this.state.dialogForm["populationLabel"] ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        disabled={this.state.dialogForm["referenceYear"] || this.state.dialogForm["populationLabel"] ? false : true}//if(Ref_Year <> "" or Population_Label <> "",1=1,1=2)
                                                        onClick={(e) => { this.showEvent(e) }}>
                                                        <img id="BUTTON_RUN_ICON"
                                                            src={this.state.dialogForm["referenceYear"] || this.state.dialogForm["populationLabel"] ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>Run
                                                    </button>
                                                </div>
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RESET"
                                                        className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        disabled={this.state.isFormDirty ? false : true}
                                                        onClick={(e) => { this.newRecord(e) }}>
                                                        <img id="BUTTON_RESET_ICON"
                                                            src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>New
                                                    </button>
                                                </div>

                                                <div className="hidden">
                                                    <button id="BUTTON_NEWRECORD_FK" onClick={(e) => { this.newRecord(e) }} ></button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_EXIT"
                                                        className="ErsGrBlueButton"
                                                        onClick={(e) => { this.exitScreen(e) }}>
                                                        <img id="BUTTON_EXIT_ICON"
                                                            src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image"
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>Exit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                : <p>Generate Follow-up Calculations page loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>

                <React.Fragment>
                    {reportContent}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

GenFollowUpCalcs.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        clientgroup: state.auth.clientgroup,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        selectedHospital: state.patientAbstract.selectedHospital,
        userId: state.auth.userId,
        showReport: state.patientAbstract.showReport,
        isDownloadFile: state.patientAbstract.isDownloadFile,
        alphaUrl: state.routing.location ? state.routing.location.pathname : "",
        previewing: state.patientAbstract.previewing,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenFollowUpCalcs);