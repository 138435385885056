/* eslint-disable */
import * as actionTypes from "./actions/actionTypes";
import * as dialogTypes from "../components/AbstractManagement/maintDialogs";
import axios from "../axios-instance";
import ConfigData from './config.json';

// Once a patient is selected their Id is stored in application state and their Maintenance window is opened
// Then a Med Rec is selected, which will also be stored and then used to proceed through the various Diagnosis, Staging, etc. windows
const initialState = {
    rapidAbstract: false,//
    inquiringMode: false,//
    waitingFor: false,
    selectedPatient: null,
    isPatientLoading: false,
    processingAndMovingPage: false,
    isPatientSaving: false,
    selectedMedicalRecord: null,
    selectedDiagnosisRecord: null,
    currentDialog: dialogTypes.LOOKUP,
    isAddNew: true,
    patientId: 0,
    error: false,
    errorMessage: null,
    selectedPrimarySummary: null,
    isDialogLoading: false,
    isAddNewDialogVisible: false,//Case::Add New
    isAddNewDialogVisible2: false,//Case::Not Exist
    isShowTextDialogVisible: false,
    isV21CompatibilityVisible: false,
    passwordHadChanged: false,
    savedPatientMaitenance: false,

    selectedHospital: null,//On top menu
    selectedHospitalOnModal: null,
    selectedPhysicianOnModal: null,
    selectedRegistrarOnModal: null,

    selectedGlobalDir: null,
    selectedGlobalDirOnModal: null,

    currentSelectedField: null,
    commonLookupItem: null,
    currentSelectedIndex: -1,

    commonLookupItem2: null,

    isChildDialogVisible: false,
    childDialog: dialogTypes.SURGERY,
    isChildrenLoading: false,
    isChildrenSaving: false,

    isFormDirty: true,

    isParentWindowVisible: false,
    isChangePwdDialogVisible: false,
    isTwoFactorAuthDialogVisible: true,
    isAuth2FaDialogVisible: false,

    alphaSystemUrlBaseUrl: ConfigData.ERS_SITE_URL,
    routingPage: dialogTypes.LOOKUP,
    tabItems: [dialogTypes.LOOKUP],

    //tabStatuses: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    tabStatuses: [true, false, false, false, false, false, false, false, false, false, false, false, false, false, false],

    isPatientMenu: false,
    pageLoaded: false,
    commonColors: null,
    availableItems: null,
    availableMenuGroups: null,
    alphaUrl: null,
    showingTabName: null,
    selectedPatientOnGrid: null,
    selectedPatientIndex: 0,
    selectedCocRecord: null,
    currentShowingState: null,
    currentShowingHospitalVersion: null,
    logedOnUserId: null,
    ersClientGroup: null,

    // Security Maintenance
    selectedUserOnGrid: null,
    selectedUserInfo: null,
    selectedUser: null,
    isSecurityAdminLoading: false,
    isSecurityAdminSaving: false,
    selectedUserRecord: null,
    isAddNewUser: false,
    userId: 0,
    selectedUserSecurityInfo: null,

    user2Fa: null,
    confirmSecurityCode: false,

    /*
    currentDialog2: dialogTypes.USER_LOGINS,
    routingPage2: dialogTypes.USER_LOGINS,
    */
    tabItems2: [dialogTypes.USER_LOGINS],
    //lockedUsers: null,
    tfaPopupTitle: "Configure ",
    tfaConfigTitle: false,
    tokenOnSession: null,
    changedUser: false,

    // Set this flag to allow the screen ignore the validations and save anyway.
    allowSaveAnyway: false,
    childrenOpening: false,
    showingHelpInfo: false,
    sessionExipred: false,
    itemNbr: 0,

    savingDiagnosisCompleted: true,
    handlerControlItem: null,
    initialPageVisible: false,

    selectedAccRegInfo: null, //To Do - Update object to an empty object or array
};

export const actionCreators = {
    setHandlerControlItem: (item) => ({ type: actionTypes.setHandlerControlItem, item }),
    setRapidAbstract: (value) => ({ type: actionTypes.setRapidAbstract, value }),
    setInquiringMode: (value) => ({ type: actionTypes.setInquiringMode, value }),
    setItemNbr: (value) => ({ type: actionTypes.setItemNbr, value }),
    showPageLoading: (value) => ({ type: actionTypes.showPageLoading, value }),
    clearAndResetDataWhenPageLoading: (value) => ({ type: actionTypes.clearAndResetDataWhenPageLoading, value }),
    setPatientMenu: (value) => ({ type: actionTypes.setPatientMenu, value }),
    setRoutingPage: (dialogType) => ({ type: actionTypes.setRoutingPage, dialogType }),
    addTabItems: (dialogType, id) => ({ type: actionTypes.addTabItems, dialogType, id }),
    removeTabItems: (dialogType, index) => ({ type: actionTypes.removeTabItems, dialogType, index }),
    selectTabItems: (dialogType, index) => ({ type: actionTypes.selectTabItems, dialogType, index }),
    closeOtherTabItems: (flag) => ({ type: actionTypes.closeOtherTabItems, flag }),
    setEnabledStatus: (dialogType, flag) => ({ type: actionTypes.setEnabledStatus, dialogType, flag }),
    setCurrentDialog: (dialogType) => ({ type: actionTypes.setCurrentDialog, dialogType }),
    setPageLoaded: (value) => ({ type: actionTypes.setPageLoaded, value }),
    setAlphaUrl: (url) => ({ type: actionTypes.setAlphaUrl, url }),
    setTabName: (name) => ({ type: actionTypes.setTabName, name }),
    setControlName: (name) => ({ type: actionTypes.setControlName, name }),
    setUserId: (uid) => ({ type: actionTypes.setUserId, uid }),
    setErsClientGroup: (clientGroup) => ({ type: actionTypes.setErsClientGroup, clientGroup }),
    setAllowSaveAnyway: (flag) => ({ type: actionTypes.setAllowSaveAnyway, flag }),
    setChildrenOpening: (flag) => ({ type: actionTypes.setChildrenOpening, flag }),
    resetCurrentSelectedIndex: (idx) => ({ type: actionTypes.resetCurrentSelectedIndex, idx }),
    showOrHideLoading: (flag) => ({ type: actionTypes.showOrHideLoading, flag }),
    showingHelpInfo: (flag) => ({ type: actionTypes.showingHelpInfo, flag }),
    storeToGlobal: (fName) => ({ type: actionTypes.storeToGlobal, fName }),
    latestFocusedField: (fId) => ({ type: actionTypes.latestFocusedField, fId }),

    showSessionTimeoutMsg: (flag) => ({ type: actionTypes.showSessionTimeoutMsg, flag }),
    resetDeletedDataSuccess: (flag) => ({ type: actionTypes.resetDeletedDataSuccess, flag }),

    updateDiagnosisInfo: (name, value) => ({ type: actionTypes.updateDiagnosisInfo, name, value }),

    getTokenOnSession: (uid) => (dispatch) => {
        let url = "ers/GetTokenOnSession";
        axios.post(url, { email: uid, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.getTokenOnSessionSuccess, token: res.data.result });
                } else {
                    dispatch({ type: actionTypes.getTokenOnSessionFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getTokenOnSessionFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    getAvailableItems: (uid) => (dispatch) => {
        // Also get all color of fields from [Global_Dir]
        axios.post(initialState["ersClientGroup"] + "/GetCommonColors", { email: initialState["logedOnUserId"], accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    console.log("[Abs] availableItems=" + res.data.availableItems);
                    dispatch({
                        type: actionTypes.getAvailableItemsSuccess,
                        //colors: res.data.commonLookupList,
                        availableItems: res.data.availableItems,
                        availableMenuGroups: res.data.availableMenuGroups,
                    });
                } else {
                    dispatch({
                        type: actionTypes.getCommonColorsFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }*/
                return false;
            });
    },

    setTfaPopupTitle: (title, form) => (dispatch) => {
        dispatch({ type: actionTypes.setTfaPopupTitle, title });

        // 
        if (form) {
            let url = "ers/ScannedQrCodeImg";
            axios.post(url, { user2Fa: form, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && !res.data.error) {
                        //dispatch({ type: actionTypes.confirmSecurityCodeSuccess, user2Fa: res.data.user2Fa });
                    } else {
                        //dispatch({ type: actionTypes.confirmSecurityCodeFail });
                    }
                }).catch(error => {
                    //dispatch({ type: actionTypes.confirmSecurityCodeFail });
                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });

                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    return false;
                });
        }
    },

    setParentWindowVisible: (flag) => ({ type: actionTypes.setParentWindowVisible, flag }),
    setChildrenWindowVisible: (flag) => ({ type: actionTypes.setChildrenWindowVisible, flag }),

    setChildDialog: (dialogType) => ({ type: actionTypes.setChildDialog, dialogType }),

    setChildDialogVisible: (flag) => ({ type: actionTypes.setChildDialogVisible, flag }),
    setChangePwdDialogVisible: (flag) => ({ type: actionTypes.setChangePwdDialogVisible, flag }),
    setTwoFactorAuthDialogVisible: (flag) => ({ type: actionTypes.setTwoFactorAuthDialogVisible, flag }),

    confirmSecurityCode: (form) => (dispatch) => {
        dispatch({ type: actionTypes.confirmSecurityCodeStart, form });
        let url = "ers/ConfirmSecurityCode";
        axios.post(url, { user2Fa: form, user2FaRequest: form, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.confirmSecurityCodeSuccess, user2Fa: res.data.user2FaRequest });
                } else {
                    dispatch({ type: actionTypes.confirmSecurityCodeFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.confirmSecurityCodeFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    resetHospitalInfo: (hospNbr) => ({ type: actionTypes.resetHospitalInfo, hospNbr }),
    currentShowingState: (state) => ({ type: actionTypes.currentShowingState, state }),
    currentShowingHospitalVersion: (version) => ({ type: actionTypes.currentShowingHospitalVersion, version }),

    selectedPatientOnGrid: (patient, index = 0) => (dispatch) => {
        dispatch({ type: actionTypes.selectedPatientOnGrid, patient: patient, index: index });
    },

    showLockedUsers: (group) => (dispatch) => {
        dispatch({ type: actionTypes.showLockedUserStart });

        axios.post("ers/GetLockedUsers", { clientGroup: group, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.showLockedUserSuccess, userList: res.data.userList });
                } else {
                    dispatch({ type: actionTypes.showLockedUserFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    clearLockedUser: (uid) => (dispatch) => {
        dispatch({ type: actionTypes.clearLockedUserStart, uid: uid });
        axios.post("ers/ClearLockedUser", { uId: uid, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.clearLockedUserSuccess, userList: res.data.userList });
                } else {
                    dispatch({ type: actionTypes.clearLockedUserFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    //#prj_abstract_validation_fixes    
    clearErrorMsgOfAbstracts: () => (dispatch) => {
        dispatch({
            type: actionTypes.showingMessagePatientAbstracts,
            error: false,
            errorMsgOfAbstracts: null,
            showMsgOfAbstracts: null,
        });
    },
    showingMessagePatientAbstracts: (err, code, msg) => (dispatch) => {
        dispatch({
            type: actionTypes.showingMessagePatientAbstracts,
            error: err,
            errorMsgOfAbstracts: code == "ABS",
            showMsgOfAbstracts: code == "ABS" ? msg : null,
        });
    },

    //Fixing #PR00256
    selectPatientLookupView: (patientId) => (dispatch) => {
        dispatch({ type: actionTypes.getPatientDataStart, patientId: patientId, isAddNew: false });

        var _prm = { patientId: patientId, accessToken: localStorage.getItem('token') };
        axios.post(initialState["ersClientGroup"] + "/GetPatientLookupViewById", _prm)
            .then(res => {
                if (!res.data.error) {
                    dispatch({ type: actionTypes.selectedPatientOnGrid, patient: res.data.patientLookupView });
                } else {
                    // @TODO: show error message if processing is failed?!
                    dispatch({
                        type: actionTypes.showingMessagePatientAbstracts,
                        error: true,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }

                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    selectPatient: (patientId, isAddNew, onTab) => (dispatch) => {
        if (!onTab) {
            dispatch({ type: actionTypes.getPatientDataStart, patientId: patientId, isAddNew: isAddNew });
            dispatch({ type: actionTypes.clearSelectedMedicalRecord });
        } else {
            //dispatch({ type: actionTypes.getPatientDataStart, patientId: patientId, isAddNew: false });
        }

        var _param = { patientId: patientId, accessToken: localStorage.getItem('token') };
        axios.post(initialState["ersClientGroup"] + "/GetPatient", _param)
            .then(res => {
                if (!res.data.error) {
                    dispatch({ type: actionTypes.getPatientDataSuccess, patient: res.data.patient });
                } else {
                    dispatch({
                        type: actionTypes.getPatientDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }

                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                return false;
            });

        if (!onTab) {
            var _param2 = { email: initialState["logedOnUserId"], accessToken: localStorage.getItem('token') };
            // Also get all color of fields from [Global_Dir]
            axios.post(initialState["ersClientGroup"] + "/GetCommonColors", _param2)
                .then(res => {
                    if (!res.data.error) {
                        console.log("[Abs] availableItems=" + res.data.availableItems);
                        dispatch({
                            type: actionTypes.getCommonColorsSuccess,
                            colors: res.data.commonLookupList,
                            availableItems: res.data.availableItems,
                            availableMenuGroups: res.data.availableMenuGroups,
                        });
                    } else {
                        dispatch({
                            type: actionTypes.getCommonColorsFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }

                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
        }
    },

    savePatient: (patient, isAddNew, patientId, moveToNextPage) => (dispatch) => {
        dispatch({ type: actionTypes.savePatientDataStart, patient: patient });
        let url = initialState["ersClientGroup"] + "/UpdatePatient";
        if (patient.pid === 0 && isAddNew) {
            url = initialState["ersClientGroup"] + "/CreatePatient";
        } else {
            if (patient.pid === 0 && patientId !== 0) {
                patient.pid = patientId;
            }
        }

        axios.post(url, { patient: patient, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.savePatientDataSuccess, patient: res.data.patient });
                    /*
                    // case of click SELECT on grid - with new record of PrimarySummary
                    if (moveToNextPage) {
                        var medical = {
                            cid: res.data.patient.cid,
                            pid: patientId
                        };
                        this.selectMedicalRecord(medical, patientId);

                        this.setDialog(dialogTypes.DIAGNOSIS);

                        // Enable all tabs in maintDialogs.js
                        let currentShowingState = this.state.currentShowingState;
                        dialogTypes.dialogDisplays.map((dialog, index) => {
                            // check if whether the state fields have a state specific screen?
                            if (dialog === dialogTypes.STATE_SPECIFIC) {
                                if (stateMaint.stateSpecificScreens.includes(currentShowingState)) {
                                    this.setEnabledStatus(dialog);
                                } else {
                                    this.setEnabledStatus(dialog, false);
                                }
                            } else {
                                this.setEnabledStatus(dialog);
                            }
                        });
                    }
                    */
                } else {
                    dispatch({
                        type: actionTypes.savePatientDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.savePatientDataFail, error: error });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Diagnosis Maintenance
    selectMedicalRecord: (medical, patientId) => (dispatch) => {
        //console.log("[selectMedicalRecord]::Diagnosis Maintenance", medical, patientId);
        // with new item
        if (medical.pid == "0" && patientId != "0") {
            medical.pid = patientId;

            dispatch({ type: actionTypes.getDiagnosisDataStart, medical });

            let url = initialState["ersClientGroup"] + "/GetDiagnosis";
            axios.post(url, { diagnosis: medical, accessToken: localStorage.getItem('token') })
                .then(res => {
                    if (!res.data.error) {
                        if (medical.cid == "0") {
                            medical.cid = res.data.diagnosis.cid;
                        }
                        dispatch({ type: actionTypes.getDiagnosisDataSuccess, diagnosis: res.data.diagnosis, medical: medical });
                    } else {
                        dispatch({
                            type: actionTypes.getDiagnosisDataFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }

                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }
                }).catch(error => {
                    dispatch({ type: actionTypes.getDiagnosisDataFail });

                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
        } else {
            dispatch({ type: actionTypes.getDiagnosisDataStart, medical });

            let url = initialState["ersClientGroup"] + "/GetDiagnosis";
            axios.post(url, { diagnosis: medical, accessToken: localStorage.getItem('token') })
                .then(res => {
                    if (!res.data.error) {
                        if (medical.cid == "0") {
                            medical.cid = res.data.diagnosis.cid;
                        }
                        dispatch({ type: actionTypes.getDiagnosisDataSuccess, diagnosis: res.data.diagnosis, medical: medical });
                    } else {
                        dispatch({
                            type: actionTypes.getDiagnosisDataFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }

                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }
                }).catch(error => {
                    dispatch({ type: actionTypes.getDiagnosisDataFail });

                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    //redirect to Login page by clicking the Logout button
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
        }
    },
    setDiagnosisDataDefault: () => (dispatch) => {
        dispatch({ type: actionTypes.setDiagnosisDataDefault });
    },
    saveDiagnosis: (diagnosis) => (dispatch) => {
        dispatch({ type: actionTypes.saveDiagnosisDataStart, diagnosis: diagnosis });
        let url = initialState["ersClientGroup"] + "/UpdateDiagnosis";
        axios.post(url, { diagnosis: diagnosis, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveDiagnosisDataSuccess, diagnosis: res.data.diagnosis });
                } else {
                    dispatch({
                        type: actionTypes.saveDiagnosisDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveDiagnosisDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Hospital
    selectHospitalRecord: (hospNbr, _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.getHospitalDataStart, isNotExist: _isNotExist ? true : false });

        axios.post(initialState["ersClientGroup"] + "/GetHospital", { hospNbr: hospNbr, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getHospitalDataSuccess, hospital: res.data.hospital });
                } else {
                    dispatch({ type: actionTypes.getHospitalDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveHospital: (hospital) => (dispatch) => {
        dispatch({ type: actionTypes.saveHospitalDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateHospital";

        //axios.post(url, { hospital: hospital })
        axios.post(url, { hospital: hospital, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveHospitalDataSuccess, hospital: res.data.hospital });
                } else {
                    dispatch({ type: actionTypes.saveHospitalDataFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveHospitalDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Physicians
    selectPhysicianRecord: (physiciansId, _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.getPhysiciansDataStart, isNotExist: _isNotExist ? true : false });

        axios.post(initialState["ersClientGroup"] + "/GetPhysicians",
            { physiciansId: physiciansId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getPhysiciansDataSuccess, physicians: res.data.physicians });
                } else {
                    dispatch({ type: actionTypes.getPhysiciansDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    savePhysician: (physicians) => (dispatch) => {
        dispatch({ type: actionTypes.savePhysiciansDataStart });
        let url = initialState["ersClientGroup"] + "/UpdatePhysicians";

        //axios.post(url, { physicians: physicians })
        axios.post(url, { physicians: physicians, accessToken: localStorage.getItem('token') })
            .then(res => {
                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.savePhysiciansDataSuccess, physicians: res.data.physicians });
                } else {
                    dispatch({ type: actionTypes.savePhysiciansDataFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.savePhysiciansDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Registrars
    selectRegistrarRecord: (initials, _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.getRegistrarsDataStart, isNotExist: _isNotExist ? true : false });

        axios.post(initialState["ersClientGroup"] + "/GetRegistrars", { initials: initials, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getRegistrarsDataSuccess, registrars: res.data.registrars });
                } else {
                    dispatch({ type: actionTypes.getRegistrarsDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    // Registrars opened from Lookup grid
    selectRegistrarRecordById: (id) => (dispatch) => {
        dispatch({ type: actionTypes.getRegistrarsDataStart, isNotExist: false });

        axios.post(initialState["ersClientGroup"] + "/GetRegistrarsById",
            { idNo: id, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
                if (!res.data.error) {
                    dispatch({ type: actionTypes.getRegistrarsDataSuccess, registrars: res.data.registrars });
                } else {
                    dispatch({ type: actionTypes.getRegistrarsDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveRegistrar: (registrars) => (dispatch) => {
        dispatch({ type: actionTypes.saveRegistrarsDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateRegistrars";

        //axios.post(url, { registrars: registrars })
        axios.post(url, { registrars: registrars, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveRegistrarsDataSuccess, registrars: res.data.registrars });
                } else {
                    dispatch({ type: actionTypes.saveRegistrarsDataFail, message: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveRegistrarsDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    //Zip Codes
    selectZipCodesRecord: (zipCode, _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.getZipCodesDataStart, isNotExist: _isNotExist ? true : false });

        axios.post(initialState["ersClientGroup"] + "/GetZipCodes", { code: zipCode, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getZipCodesDataSuccess, zipCodes: res.data.zipCodes });
                } else {
                    dispatch({ type: actionTypes.getZipCodesDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    // ZipCodes opened from Lookup grid
    selectZipCodesRecordById: (id) => (dispatch) => {
        dispatch({ type: actionTypes.getZipCodesDataStart, isNotExist: false });

        axios.post(initialState["ersClientGroup"] + "/GetZipCodesById",
            { idNo: id, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
                if (!res.data.error) {
                    dispatch({ type: actionTypes.getZipCodesDataSuccess, zipCodes: res.data.zipCodes });
                } else {
                    dispatch({ type: actionTypes.getZipCodesDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveZipCodes: (zipCodes) => (dispatch) => {
        dispatch({ type: actionTypes.saveZipCodesDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateZipCodes";

        axios.post(url, { zipCodes: zipCodes, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveZipCodesDataSuccess, zipCodes: res.data.zipCodes });
                } else {
                    dispatch({ type: actionTypes.saveZipCodesDataFail, message: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveZipCodesDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Global Dir
    selectGlobalDirRecord: (itemNbr, _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.getGlobalDirDataStart, isNotExist: _isNotExist ? true : false });

        axios.post(initialState["ersClientGroup"] + "/GetGlobalDir",
            { itemNbr: itemNbr, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getGlobalDirDataSuccess, globalDir: res.data.globalDir });
                } else {
                    dispatch({ type: actionTypes.getGlobalDirDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    saveGlobalDir: (globalDir) => (dispatch) => {
        dispatch({ type: actionTypes.saveGlobalDirDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateGlobalDir";

        axios.post(url, { globalDir: globalDir, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveGlobalDirDataSuccess, globalDir: res.data.globalDir });
                } else {
                    dispatch({ type: actionTypes.saveGlobalDirDataFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveGlobalDirDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    closeAddNewDialog: () => ({ type: actionTypes.closeAddNewDialog }),

    clearFormInput: (formId) => ({
        type: actionTypes.clearFormInput, formId: formId
    }),

    loadAccNbrsMaintInit: () => ({ type: actionTypes.loadAccNbrsMaintInitData }),

    deleteItemInput: (formId, idNo = "0", cid) => (dispatch) => {
        dispatch({
            type: formId === "Hospital" ? actionTypes.deleteHospitalDataStart
                : formId === "Physician" ? actionTypes.deletePhysiciansDataStart
                    : formId === "Registrar" ? actionTypes.deleteRegistrarsDataStart
                        : formId === "AccNbrs" ? actionTypes.deleteAccNbrsMaintStart
                            : formId === "FollowUpOrder" ? actionTypes.deleteFollowUpOrderStart
                                : formId === "DeletePatientAbs" ? actionTypes.deletePatientAbsInfoStart
                                    : formId === "UserInfo" ? actionTypes.deleteUserInfoDataStart
                                        : formId === "LettersMaint" || formId === "CustomFormsMaint" ? actionTypes.deleteLettersMaintStart
                                            : formId === "CRStarImportOption" ? actionTypes.deleteImportConfigDataStart
                                                : formId === "ZipCodes" ? actionTypes.deleteZipCodesDataStart : actionTypes.deleteItemInput,
            hasPs: cid && cid != "" && cid != undefined ? true : false
        });

        // DeleteHospitalById
        //let url = "/CommonLookup/Delete" + formId + "ById?IdNo=" + idNo;

        let url = (formId === "UserInfo" ? "ers" : initialState["ersClientGroup"]) + "/Delete" + formId;
        if (formId === "DeletePatientAbs") {
            url = initialState["ersClientGroup"] + "/" + formId;
        }
        let param = null;
        if (formId === "Hospital") {
            param = { HospNbr: idNo };
        } else if (formId === "Physician") {
            param = { PhysiciansId: idNo };
        } else if (formId === "Registrar") {
            param = { registrarsId: idNo };
        } else if (formId === "UserInfo") {
            param = { email: idNo, clientAlias: initialState["ersClientGroup"] };
        } else if (formId === "AccNbrs") {
            param = { id: idNo };
        } else if (formId === "FollowUpOrder") {
            param = { id: idNo };
        } else if (formId === "DeletePatientAbs") {
            param = { pid: idNo, cid: cid && cid != "" && cid != undefined ? cid : 0 };
        } else if (formId === "LettersMaint" || formId === "CustomFormsMaint") {
            param = { id: idNo };
        } else if (formId === "CRStarImportOption") {
            param = { importId: idNo, mergeOptionsId: idNo };
        } else if (formId === "ZipCodes") {
            param = { zipCodesId: idNo };
        }

        param["accessToken"] = localStorage.getItem('token');

        axios.post(url, param)
            .then(res => {
                if (res && res.data && !res.data.error) {
                    if (formId === "Hospital") {
                        dispatch({ type: actionTypes.deleteHospitalDataSuccess, hospital: res.data.hospital });
                    } else if (formId === "Physician") {
                        dispatch({ type: actionTypes.deletePhysiciansDataSuccess, physicians: res.data.physicians });
                    } else if (formId === "Registrar") {
                        dispatch({ type: actionTypes.deleteRegistrarsDataSuccess, registrars: res.data.registrars });
                    } else if (formId === "UserInfo") {
                        dispatch({ type: actionTypes.deleteUserInfoDataSuccess, userInfo: res.data.userInfo });

                        // Also delete user of specific database (by clientgroup)
                        url = initialState["ersClientGroup"] + "/Delete" + formId;
                        axios.post(url, param)
                            .then(res => {
                                if (res && res.data && !res.data.error) {
                                    console.log("[DeleteUserInfo]... to specific database successful");
                                    //dispatch({ type: actionTypes.deleteUserInfoDataSuccess, userInfo: res.data.userInfo });
                                } else {
                                    console.log("[DeleteUserInfo]... to specific database failed");
                                    //dispatch({ type: actionTypes.deleteUserInfoDataFail });
                                }
                            }).catch(error => {
                                console.log("[DeleteUserInfo]... to specific database error");
                                //dispatch({ type: actionTypes.deleteUserInfoDataFail });

                                console.log(error);
                                dispatch({
                                    type: actionTypes.showSessionTimeoutMsg,
                                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                                });
                                /*
                                var btnLogout = document.getElementById("logoutButtonArea");
                                if (btnLogout) {
                                    btnLogout.querySelector("a").click();
                                }
                                */
                                return false;
                            });
                    } else if (formId === "AccNbrs") {
                        dispatch({ type: actionTypes.deleteAccNbrsMaintSuccess, accNbrs: res.data.accNbrs });
                    } else if (formId === "FollowUpOrder") {
                        dispatch({ type: actionTypes.deleteFollowUpOrderSuccess, followUpOrder: res.data.templatesFollowUpOrder });
                    } else if (formId === "DeletePatientAbs") {
                        dispatch({ type: actionTypes.deletePatientAbsInfoSuccess, patientAbsInfo: res.data.patient, hasPs: cid && cid != "" && cid != undefined ? true : false });
                    } else if (formId === "LettersMaint" || formId === "CustomFormsMaint") {
                        dispatch({ type: actionTypes.deleteLettersMaintSuccess, letters: res.data.letters });
                    } else if (formId === "CRStarImportOption") {
                        dispatch({ type: actionTypes.deleteImportConfigDataSuccess, xMergeOptions: res.data.mergeOptions });
                    } else if (formId === "ZipCodes") {
                        dispatch({ type: actionTypes.deleteZipCodesDataSuccess, zipCodes: res.data.zipCodes });
                    }
                } else {
                    dispatch({
                        type: formId === "Hospital" ? actionTypes.deleteHospitalDataFail
                            : formId === "Physician" ? actionTypes.deletePhysiciansDataFail
                                : formId === "Registrar" ? actionTypes.deleteRegistrarsDataFail
                                    : formId === "AccNbrs" ? actionTypes.deleteAccNbrsMaintFail
                                        : formId === "FollowUpOrder" ? actionTypes.deleteFollowUpOrderFail
                                            : formId === "DeletePatientAbs" ? actionTypes.deletePatientAbsInfoFail
                                                : formId === "UserInfo" ? actionTypes.deleteUserInfoDataFail
                                                    : formId === "LettersMaint" || formId === "CustomFormsMaint" ? actionTypes.deleteLettersMaintFail
                                                        : formId === "CRStarImportOption" ? actionTypes.deleteImportConfigDataFail
                                                            : formId === "ZipCodes" ? actionTypes.deleteZipCodesDataFail : actionTypes.deleteItemInput,
                        hasPs: cid && cid != "" && cid != undefined ? true : false
                    });
                }
            }).catch(error => {
                dispatch({
                    type: formId === "Hospital" ? actionTypes.deleteHospitalDataFail
                        : formId === "Physician" ? actionTypes.deletePhysiciansDataFail
                            : formId === "Registrar" ? actionTypes.deleteRegistrarsDataFail
                                : formId === "AccNbrs" ? actionTypes.deleteAccNbrsMaintFail
                                    : formId === "FollowUpOrder" ? actionTypes.deleteFollowUpOrderFail
                                        : formId === "DeletePatientAbs" ? actionTypes.deletePatientAbsInfoFail
                                            : formId === "UserInfo" ? actionTypes.deleteUserInfoDataFail
                                                : formId === "LettersMaint" || formId === "CustomFormsMaint" ? actionTypes.deleteLettersMaintFail
                                                    : formId === "CRStarImportOption" ? actionTypes.deleteImportConfigDataFail
                                                        : formId === "ZipCodes" ? actionTypes.deleteZipCodesDataFail : actionTypes.deleteItemInput,
                    hasPs: cid && cid != "" && cid != undefined ? true : false
                });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    copyItemInput: (formId, item) => (dispatch) => {
        dispatch({ type: actionTypes.copyItemInputStart });

        let url = initialState["ersClientGroup"] + "/" + formId;
        item["accessToken"] = localStorage.getItem('token');
        axios.post(url, item)
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.copyItemInputSuccess, patientAbsInfo: res.data.copyPatientAbs });
                    if (res.data.message && res.data.copiedStatus) {
                        if (res.data.copiedStatus == "Success") {
                            document.querySelector('.statusBarOnFooterCls') ? (document.querySelector('.statusBarOnFooterCls').innerHTML = res.data.message) : null;//
                            //disabled Save button (btnDeleteItem_Fake)
                            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').setAttribute("disabled", true) : null;//
                        } else if (res.data.copiedStatus == "Failed") {
                            document.querySelector('.statusBarOnFooterCls') ? (document.querySelector('.statusBarOnFooterCls').innerHTML = res.data.message) : null;//
                            document.getElementById('btnDeleteItem_Fake') ? document.getElementById('btnDeleteItem_Fake').removeAttribute("disabled") : null;//
                        }
                    }
                } else {
                    dispatch({ type: actionTypes.copyItemInputFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.copyItemInputFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    showTextDialog: (cId) => (dispatch) => {
        dispatch({ type: actionTypes.showTextDialogStart });

        if (cId == 0 && initialState.selectedPrimarySummary.cid != 0) {
            cId = initialState.selectedPrimarySummary.cid;
        }

        axios.post(initialState["ersClientGroup"] + "/GetDiagnosisText", { primarySummaryId: cId, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.showTextDialogSuccess, diagnosisRequest: res.data.diagnosisRequest });
                } else {
                    dispatch({ type: actionTypes.showTextDialogFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveTextDialog: (diagnosisText) => (dispatch) => {
        dispatch({ type: actionTypes.saveTextDialogStart });
        let url = initialState["ersClientGroup"] + "/SaveDiagnosisText";
        axios.post(url, { diagnosisRequest: diagnosisText, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                dispatch({ type: actionTypes.closeTextDialog });
            }).catch(error => {
                console.log("SaveDiagnosisText::Error", error);
                dispatch({ type: actionTypes.closeTextDialog });

                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    closeTextDialog: () => ({ type: actionTypes.closeTextDialog }),
    setInitialPageVisible: (flag) => ({ type: actionTypes.setInitialPageVisible, flag: flag }),
    setInitialAbstractPages: (flag) => ({ type: actionTypes.setInitialAbstractPages, flag: flag }),

    // v21CompatibilityFeature
    v21CompatibilityFeature: (cid) => (dispatch) => {
        dispatch({ type: actionTypes.v21CompatibilityFeatureStart });

        axios.post(initialState["ersClientGroup"] + "/GetV21Compatibility", { cid: cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                if (!res.data.error) {
                    dispatch({ type: actionTypes.v21CompatibilityFeatureSuccess, fc: res.data.fc });
                } else {
                    dispatch({
                        type: actionTypes.v21CompatibilityFeatureFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                console.log("v21CompatibilityFeature::Error", error);

                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveV21CompatibilityFeature: (fcData) => (dispatch) => {
        //dispatch({ type: actionTypes.saveV21CompatibilityFeatureStart });
        let url = initialState["ersClientGroup"] + "/UpdateV21Compatibility";
        axios.post(url, { fc: fcData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                dispatch({ type: actionTypes.closeV21CompatibilityFeature });
            }).catch(error => {
                console.log("saveV21CompatibilityFeature::Error", error);
                dispatch({ type: actionTypes.closeV21CompatibilityFeature });
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    closeV21CompatibilityFeature: () => ({ type: actionTypes.closeV21CompatibilityFeature }),

    // Staging Maintenance
    selectStagingRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getStagingDataStart, medial });
        /*
        // Fixing issue of StagingPre2018 screen - START
        // Also reload data of PrimarySummary & Diagnosis :/GetDiagnosis
        let url = initialState["ersClientGroup"] + "/GetDiagnosis";
        axios.post(url, { diagnosis: medial })
            .then(res => {
                if (!res.data.error) {
                    if (medial.cid == 0) {
                        medial.cid = res.data.diagnosis.cid;
                    }

                    //  topoCode
                    if (medial.topoCode !== res.data.diagnosis.topoCode && res.data.diagnosis.topoCode !== null && res.data.diagnosis.topoCode !== "") {
                        medial.topoCode !== res.data.diagnosis.topoCode;
                    }
                    //  siteCode
                    if (medial.siteCode !== res.data.diagnosis.siteCode && res.data.diagnosis.siteCode !== null && res.data.diagnosis.siteCode !== "") {
                        medial.siteCode !== res.data.diagnosis.siteCode;
                    }

                    dispatch({ type: actionTypes.getDiagnosisDataSuccess, diagnosis: res.data.diagnosis, medial: medial });
                } else {
                    dispatch({ type: actionTypes.getDiagnosisDataFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getDiagnosisDataFail });
            });
        // Fixing issue of StagingPre2018 screen - END
        */

        var alias = "";
        if (medial.diagnosisDateYear && medial.diagnosisDateYear >= 2018) {
            alias = "2018plus";
        }

        setTimeout(function () {
            axios.post(initialState["ersClientGroup"] + "/GetStaging", { primarySummaryId: medial.cid, alias: alias, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        dispatch({ type: actionTypes.getStagingDataSuccess, staging: res.data.staging });
                    } else {
                        dispatch({
                            type: actionTypes.getStagingDataFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
        }, 1500);
    },
    saveStaging: (staging, alias) => (dispatch) => {
        dispatch({ type: actionTypes.saveStagingDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateStaging";
        axios.post(url, { staging: staging, alias: alias, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveStagingDataSuccess, staging: res.data.staging });
                } else {
                    dispatch({
                        type: actionTypes.saveStagingDataFail,
                        staging: staging,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveStagingDataFail, staging: staging });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                //redirect to Login page by clicking the Logout button
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // @TODO - check validation of input's value
    checkValidInput: (field, value, table, key, index, flag, cid, pcy) => (dispatch) => {
        dispatch({ type: actionTypes.checkCodeSelectDataStart, field: field, index: index });

        if (table === "CstageLookupView") {
            //let url = initialState["ersClientGroup"] + "/GetCstageLookupItem?idValue=" + encodeURIComponent(value) + "&code=" + key + "&primarySummaryId=" + cid;
            axios.post(initialState["ersClientGroup"] + "/GetCstageLookupItem", { idValue: value, code: key, primarySummaryId: cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    if (!res.data.error) {
                        dispatch({ type: actionTypes.checkCodeSelectDataSuccess, commonLookupItem: res.data.commonLookupItem, field: field, index: index });
                    } else {
                        dispatch({
                            type: actionTypes.checkCodeSelectDataFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });

            return false;
        }

        // Staging screens
        else if (table === "Tnm_Html") {
            let url = initialState["ersClientGroup"] + "/GetTnmHtmlLookupItem";
            axios.post(url, { idValue: value, table: table, key: key, flag: flag, pcy: pcy, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        dispatch({ type: actionTypes.checkCodeSelectDataSuccess, commonLookupItem: res.data.commonLookupItem, field: field, index: index });
                    } else {
                        dispatch({
                            type: actionTypes.checkCodeSelectDataFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
            return false;
        }
        else if (table === "Tnm_Lookup8th_Mv") {
            //let url = initialState["ersClientGroup"] + "/GetCommonLookupItem2?idValue=" + encodeURIComponent(value) + "&table=" + table + "&key=" + key + "&flag=" + flag;
            let edition = document.getElementById("tnmEdNbr") && document.getElementById("tnmEdNbr").value != "" ? document.getElementById("tnmEdNbr").value : "08";
            let url = initialState["ersClientGroup"] + "/GetCommonLookupItem2";
            axios.post(url, { idValue, value, edition: edition, key: key, flag: flag, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        dispatch({ type: actionTypes.checkCodeSelectDataSuccess, commonLookupItem: res.data.commonLookupItem, field: field, index: index });
                    } else {
                        dispatch({
                            type: actionTypes.checkCodeSelectDataFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
            return false;
        }

        // Retired Maitenance screen
        else if (table === "SiteApprCodesPopup") {
            let url = initialState["ersClientGroup"] + "/GetSiteApprCodesItem";
            axios.post(url, { idValue: value, code: key, primarySummaryId: cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        dispatch({ type: actionTypes.checkCodeSelectDataSuccess, commonLookupItem: res.data.commonLookupItem, field: field, index: index });
                    } else {
                        dispatch({
                            type: actionTypes.checkCodeSelectDataFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
            return false;
        }

        //Fixing #PR00365
        if (table == "County_Codes") {
            const flgBk = flag;
            if (document.getElementById("ReferStateOfSelectedHospital")) {
                flag = document.getElementById("ReferStateOfSelectedHospital").value;
            }

            //ZipCodes screen
            if (document.getElementById("Tabid_Zip_Codes") && document.getElementById("Tabid_Zip_Codes").getAttribute("class").indexOf("active") != -1) {
                flag = flgBk;
            }
        } else if (table == "Global_Dir_Mv") {
            //Fixing #PR00424
            if (document.getElementById("frmUserDefinedMaintenance") && !flag) {
                flag = "ERS_UserDefinedMaintenance";
            }
        }

        let filterBy = document.getElementById(field + index) ? document.getElementById(field + index).getAttribute("data-filter-by") : (document.getElementById(field) ? document.getElementById(field).getAttribute("data-filter-by") : "");
        let url = (table === "Client_Group" ? "ers" : initialState["ersClientGroup"]) + "/GetCommonLookupItem";
        axios.post(url, {
            idValue: value,
            table: table,
            key: key,
            flag: flag,
            filterBy: filterBy,
            accessToken: localStorage.getItem('token'),
            clientAlias: initialState["ersClientGroup"]
        })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.checkCodeSelectDataSuccess, commonLookupItem: res.data.commonLookupItem, field: field, index: index });
                } else {
                    dispatch({
                        type: actionTypes.checkCodeSelectDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });

        return false;
    },

    clearCommonLookupItem: () => (dispatch) => {
        dispatch({ type: actionTypes.clearCommonLookupItem });
        return false;
    },
    clearCommonLookupItem2: () => (dispatch) => {
        dispatch({ type: actionTypes.clearCommonLookupItem2 });
        return false;
    },
    clearAddNewUserFlag: () => (dispatch) => {
        dispatch({ type: actionTypes.clearAddNewUserFlag });
        return false;
    },

    clearSelectedStagingRecord: () => (dispatch) => {
        dispatch({ type: actionTypes.clearSelectedStagingRecord });
        return false;
    },

    // Treatment Summary
    selectTreatmentRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getTreatmentDataStart, medial });

        axios.post(initialState["ersClientGroup"] + "/GetRxSummary", { primarySummaryId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getTreatmentDataSuccess, treatment: res.data.rxSummary, surgery: res.data.rxSummary });
                } else {
                    dispatch({
                        type: actionTypes.getTreatmentDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveTreatment: (treatment) => (dispatch) => {
        //console.log("Calling ...saveTreatment...");
        if (!treatment.cid || treatment.cid == 0)
            treatment.cid = initialState.selectedPrimarySummary ? initialState.selectedPrimarySummary.cid : 0;
        dispatch({ type: actionTypes.saveTreatmentDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateRxSummary";

        axios.post(url, { rxSummary: treatment, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveTreatmentDataSuccess, treatment: res.data.rxSummary, surgery: res.data.rxSummary });

                    /*
                    // Fixing #PR00258
                    axios.get(initialState["ersClientGroup"] + "/GetRxSummary?primarySummaryId=" + treatment.cid)
                        .then(res => {
                            if (!res.data.error) {
                                dispatch({
                                    type: actionTypes.getTreatmentDataSuccess,//
                                    treatment: res.data.rxSummary,
                                    surgery: res.data.rxSummary
                                });
                            } else {
                                //dispatch({ type: actionTypes.getTreatmentDataFail });
                            }
                        });
                    */
                } else {
                    dispatch({
                        type: actionTypes.saveTreatmentDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveTreatmentDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Surgery Maintenance
    selectSurgeryRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getSurgeryDataStart, medial });

        axios.post(initialState["ersClientGroup"] + "/GetRxSummary", { primarySummaryId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getSurgeryDataSuccess, surgery: res.data.rxSummary });
                } else {
                    dispatch({
                        type: actionTypes.getSurgeryDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveSurgery: (surgery) => (dispatch) => {
        //console.log("Calling ...saveSurgery...");
        dispatch({ type: actionTypes.saveSurgeryDataStart, surgery: surgery });
        let url = initialState["ersClientGroup"] + "/UpdateRxSummary";
        axios.post(url, { rxSummary: surgery, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveSurgeryDataSuccess, surgery: res.data.rxSummary });

                    // Fixing #PR00259
                    axios.post(initialState["ersClientGroup"] + "/GetRxSummary", { primarySummaryId: surgery.cid, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (!res.data.error) {
                                dispatch({
                                    type: actionTypes.getTreatmentDataSuccess,//getSurgeryDataSuccess
                                    treatment: res.data.rxSummary,
                                    surgery: res.data.rxSummary
                                });
                            } else {
                                //dispatch({ type: actionTypes.getTreatmentDataFail });
                                dispatch({
                                    type: actionTypes.showingMessagePatientAbstracts,
                                    error: true,
                                    errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                                    showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                                });
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            dispatch({
                                type: actionTypes.showSessionTimeoutMsg,
                                flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                            });
                            /*
                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            return false;
                        });
                } else {
                    dispatch({
                        type: actionTypes.saveSurgeryDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveSurgeryDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Outcomes Maintenance
    selectOutcomesRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getOutcomesDataStart, medial });

        axios.post(initialState["ersClientGroup"] + "/GetOutcomes", { primarySummaryId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getOutcomesDataSuccess, outcomes: res.data.outcomes });
                } else {
                    dispatch({
                        type: actionTypes.getOutcomesDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveOutcomes: (formData) => (dispatch) => {
        dispatch({ type: actionTypes.saveOutcomesDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateOutcomes";
        axios.post(url, { outcomes: formData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveOutcomesDataSuccess, outcomes: res.data.outcomes });
                } else {
                    dispatch({
                        type: actionTypes.saveOutcomesDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveOutcomesDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Case Admin Maintenance
    selectCaseAdminRecord: (medial, uid) => (dispatch) => {
        dispatch({ type: actionTypes.getCaseAdminDataStart, medial });
        axios.post(initialState["ersClientGroup"] + "/GetCaseAdmin",
            { cId: medial.cid, primarySummaryId: medial.pid, uid: uid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getCaseAdminDataSuccess, caseadmin: res.data.caseAdmin });
                } else {
                    dispatch({
                        type: actionTypes.getCaseAdminDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveCaseAdmin: (caseadmin) => (dispatch) => {
        dispatch({ type: actionTypes.saveCaseAdminDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateCaseAdmin";
        axios.post(url, { caseadmin: caseadmin, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveCaseAdminDataSuccess, caseadmin: res.data.caseAdmin });
                } else {
                    dispatch({
                        type: actionTypes.saveCaseAdminDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveCaseAdminDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Clinical Maintenance
    selectClinicalRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getClinicalDataStart, medial });
        let _prm = { cId: medial.cid, accessToken: localStorage.getItem('token') };

        axios.post(initialState["ersClientGroup"] + "/GetClinical", _prm)
            .then(res => {
                if (!res.data.error) {
                    dispatch({ type: actionTypes.getClinicalDataSuccess, clinical: res.data.clinical });
                } else {
                    dispatch({
                        type: actionTypes.getClinicalDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }

                let newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    saveClinical: (clinical) => (dispatch) => {
        dispatch({ type: actionTypes.saveClinicalDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateClinical";
        let _prm = { clinical: clinical, accessToken: localStorage.getItem('token') };

        //axios.post(url, { clinical: clinical })
        axios.post(url, _prm)
            .then(res => {
                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveClinicalDataSuccess, clinical: res.data.clinical });
                } else {
                    dispatch({
                        type: actionTypes.saveClinicalDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }

                let newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveClinicalDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    // Research Maintenance
    selectResearchRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getResearchDataStart, medial });

        axios.post(initialState["ersClientGroup"] + "/GetResearch", { cId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getResearchDataSuccess, research: res.data.research });
                } else {
                    dispatch({
                        type: actionTypes.getResearchDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveResearch: (research) => (dispatch) => {
        dispatch({ type: actionTypes.saveResearchDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateResearch";
        axios.post(url, { research: research, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveResearchDataSuccess, research: res.data.research });
                } else {
                    dispatch({
                        type: actionTypes.saveResearchDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveResearchDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    deletePrimaryItem: (pItem, transmitText) => (dispatch) => {
        dispatch({ type: actionTypes.deletePrimaryItemStart });

        if (transmitText && transmitText != "") {
            // Get_CA_Records
            let _url = initialState["ersClientGroup"] + "/GetCaRecords";
            let params = { pid: pItem.pid, cid: pItem.cid, transmitText: transmitText, accessToken: localStorage.getItem('token') };
            axios.post(_url, params)
                .then(res => {
                    console.log("Processing [Get_CA_Records] function is completed.");

                    //continue to delete primary
                    let url = initialState["ersClientGroup"] + "/DeletePrimarySummary";
                    axios.post(url, { primarySummaryId: pItem.cid, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (res && res.data && !res.data.error) {
                                dispatch({ type: actionTypes.deletePrimaryItemSuccess });
                            } else {
                                dispatch({
                                    type: actionTypes.deletePrimaryItemFail,
                                    errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                                    showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                                });
                            }
                        }).catch(error => {
                            dispatch({ type: actionTypes.deletePrimaryItemFail });

                            console.log(error);
                            dispatch({
                                type: actionTypes.showSessionTimeoutMsg,
                                flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                            });
                            /*
                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            */
                            return false;
                        });
                })
                .catch(function (error) {
                    console.log(error);
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
        } else {
            let url = initialState["ersClientGroup"] + "/DeletePrimarySummary";
            axios.post(url, { primarySummaryId: pItem.cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && !res.data.error) {
                        dispatch({ type: actionTypes.deletePrimaryItemSuccess });
                    } else {
                        dispatch({
                            type: actionTypes.deletePrimaryItemFail,
                            errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                            showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                        });
                    }
                }).catch(error => {
                    dispatch({ type: actionTypes.deletePrimaryItemFail });

                    console.log(error);
                    dispatch({
                        type: actionTypes.showSessionTimeoutMsg,
                        flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                    });
                    /*
                    var btnLogout = document.getElementById("logoutButtonArea");
                    if (btnLogout) {
                        btnLogout.querySelector("a").click();
                    }
                    */
                    return false;
                });
        }
    },

    // ADD - 2021/02/25 - START
    // CPM Maintenance
    selectCocRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getCpmCocDataStart, medial });
        axios.post(initialState["ersClientGroup"] + "/GetCoc", { cId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getCpmCocDataSuccess, coc: res.data.coc, medial: medial });
                } else {
                    dispatch({
                        type: actionTypes.getCpmCocDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getCpmCocDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveCoc: (formData) => (dispatch) => {
        dispatch({ type: actionTypes.saveCpmCocDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateCoc";
        axios.post(url, { coc: formData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveCpmCocDataSuccess, coc: res.data.coc });
                } else {
                    dispatch({
                        type: actionTypes.saveCpmCocDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveCpmCocDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },

    selectNapbcRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getBpmNapbcDataStart, medial });
        axios.post(initialState["ersClientGroup"] + "/GetNapbc", { cId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getBpmNapbcDataSuccess, napbc: res.data.napbc, medial: medial });
                } else {
                    dispatch({
                        type: actionTypes.getBpmNapbcDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getBpmNapbcDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveNapbc: (formData) => (dispatch) => {
        dispatch({ type: actionTypes.saveBpmNapbcDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateNapbc";
        axios.post(url, { napbc: formData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveBpmNapbcDataSuccess, napbc: res.data.napbc });
                } else {
                    dispatch({
                        type: actionTypes.saveBpmNapbcDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveBpmNapbcDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    selectRectalProgramRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getRectalProgramDataStart, medial });
        axios.post(initialState["ersClientGroup"] + "/GetRectalProgram", { cId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getRectalProgramDataSuccess, rectalProgram: res.data.rectalProgram, medial: medial });
                } else {
                    dispatch({
                        type: actionTypes.getRectalProgramDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getRectalProgramDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveRectalProgram: (formData) => (dispatch) => {
        dispatch({ type: actionTypes.saveRectalProgramDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateRectalProgram";
        axios.post(url, { rectalProgram: formData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveRectalProgramDataSuccess, rectalProgram: res.data.rectalProgram });
                } else {
                    dispatch({
                        type: actionTypes.saveRectalProgramDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveRectalProgramDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    // ADD - 2021/02/25 - END

    // ADD - 2021/02/24 - START
    // User Defined Maintenance
    selectUserDefinedRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getUserDefinedDataStart, medial });
        axios.post(initialState["ersClientGroup"] + "/GetUserDefined", { cId: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getUserDefinedDataSuccess, userDefined: res.data.userDefined, medial: medial });
                } else {
                    dispatch({
                        type: actionTypes.getUserDefinedDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getUserDefinedDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveUserDefined: (formData) => (dispatch) => {
        dispatch({ type: actionTypes.saveUserDefinedDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateUserDefined";
        axios.post(url, { userDefined: formData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveUserDefinedDataSuccess, userDefined: res.data.userDefined });
                } else {
                    dispatch({
                        type: actionTypes.saveUserDefinedDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveUserDefinedDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    // ADD - 2021/02/24 - END

    // ADD - 2021/03/08 - START
    // State Specific Maintenance
    selectStateFieldsRecord: (medial, nm) => (dispatch) => {
        nm = nm ? nm : "";
        dispatch({ type: actionTypes.getStateFieldsDataStart, medial });
        let _prm = { cId: medial.cid, accessToken: localStorage.getItem('token') };

        axios.post(initialState["ersClientGroup"] + "/GetStateFields" + nm, _prm)
            .then(res => {
                if (!res.data.error) {
                    dispatch({
                        type: actionTypes.getStateFieldsDataSuccess,
                        stateFields: nm === "Nm" ? res.data.stateFieldsNm : res.data.stateFields,
                        medial: medial
                    });
                } else {
                    dispatch({
                        type: actionTypes.getStateFieldsDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }

                let newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getStateFieldsDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveStateFields: (formData, nm) => (dispatch) => {
        nm = nm ? nm : "";
        dispatch({ type: actionTypes.saveStateFieldsDataStart });

        //
        var cId = formData.cid;
        if (cId == 0 && initialState.selectedPrimarySummary.cid != 0) {
            cId = initialState.selectedPrimarySummary.cid;
            formData.cid = cId;
        }

        let url = initialState["ersClientGroup"] + "/UpdateStateFields" + nm;
        let _prm = { ["stateFields" + nm]: formData, accessToken: localStorage.getItem('token') };
        axios.post(url, _prm)
            .then(res => {
                if (res && res.data && !res.data.error) {
                    dispatch({
                        type: actionTypes.saveStateFieldsDataSuccess,
                        stateFields: nm === "Nm" ? res.data.stateFieldsNm : res.data.stateFields,
                    });
                } else {
                    dispatch({
                        type: actionTypes.saveStateFieldsDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }

                let newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveStateFieldsDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                let btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    // ADD - 2021/03/08 - END

    // ADD - 2021/03/25 - START
    // Retired Maintenance
    selectedRetiredRecord: (medial) => (dispatch) => {
        dispatch({ type: actionTypes.getRetiredDataStart, medial });
        axios.post(initialState["ersClientGroup"] + "/GetRetired", { cid: medial.cid, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getRetiredDataSuccess, retired: res.data.retired, medial: medial });
                } else {
                    dispatch({
                        type: actionTypes.getRetiredDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.getRetiredDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    saveRetired: (formData) => (dispatch) => {
        dispatch({ type: actionTypes.saveRetiredDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateRetired";
        axios.post(url, { retired: formData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveRetiredDataSuccess, retired: res.data.retired });
                } else {
                    dispatch({
                        type: actionTypes.saveRetiredDataFail,
                        errorMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS",
                        showMsgOfAbstracts: res.data.error && res.data.errorCode == "ABS" ? res.data.errorMessage : null,
                    });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveRetiredDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    // ADD - 2021/03/25 - END

    // ============================ SYSTEM ADMINISTRATION ============================
    setErrorMessage: (msg) => (dispatch) => {
        dispatch({ type: actionTypes.setErrorMessage, errMsg: msg });
    },
    clearErrorMessage: () => (dispatch) => {
        dispatch({ type: actionTypes.clearErrorMessage });
    },
    selectedUserOnGrid: (userInfo) => (dispatch, getState) => {
        dispatch({ type: actionTypes.selectedUserOnGrid, user: userInfo });
    },
    selectUser: (uid) => (dispatch) => {
        dispatch({ type: actionTypes.getUserInfoDataStart });

        axios.post("ers/GetSelectedUser", { uid: uid, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getUserInfoDataSuccess, userInfo: res.data.user });
                } else {
                    dispatch({ type: actionTypes.getUserInfoDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // User Maintenance
    selectUserInfoRecord: (email, cligrp) => (dispatch) => {
        dispatch({ type: actionTypes.getUserInfoDataStart, email });

        axios.post("ers/GetUserInfo", { email: email, cg: cligrp, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getUserInfoDataSuccess, userInfo: res.data.websecurityUsers });
                    dispatch({ type: actionTypes.selectedUserOnGrid, user: res.data.websecurityUsers });
                } else {
                    dispatch({ type: actionTypes.getUserInfoDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveUserInfo: (userInfo) => (dispatch) => {
        dispatch({ type: actionTypes.saveUserInfoDataStart });

        let url = "ers/UpdateUserInfo";
        axios.post(url, { userInfo: userInfo, websecurityUsers: userInfo, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveUserInfoDataSuccess, userInfo: res.data.websecurityUsers });
                    dispatch({ type: actionTypes.selectedUserOnGrid, user: res.data.websecurityUsers });

                    // Also insert/update user of specific database (by clientgroup)
                    userInfo.ers0Alias = initialState["ersClientGroup"];
                    let url2 = userInfo.ers0Alias + "/UpdateUserInfo";
                    axios.post(url2, { userInfo: userInfo, websecurityUsers: userInfo, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (res && res.data && !res.data.error) {
                                console.log("[UpdateUserInfo]... insert/update user of specific database successful");
                                //dispatch({ type: actionTypes.saveUserInfoDataSuccess, userInfo: res.data.websecurityUsers });
                                //dispatch({ type: actionTypes.selectedUserOnGrid, user: res.data.websecurityUsers });
                            } else {
                                console.log("[UpdateUserInfo]... insert/update user of specific database failed");
                                //dispatch({ type: actionTypes.saveUserInfoDataFail, message: res.data.message });
                            }
                        }).catch(error => {
                            console.log("[UpdateUserInfo]... insert/update user of specific database error");
                            //dispatch({ type: actionTypes.saveUserInfoDataFail, message: "Failed!" });

                            console.log(error);
                            dispatch({
                                type: actionTypes.showSessionTimeoutMsg,
                                flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                            });

                            var btnLogout = document.getElementById("logoutButtonArea");
                            if (btnLogout) {
                                btnLogout.querySelector("a").click();
                            }
                            return false;
                        });
                } else {
                    dispatch({ type: actionTypes.saveUserInfoDataFail, message: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveUserInfoDataFail, message: "Failed!" });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    changePassword: (userInfo) => (dispatch) => {
        dispatch({ type: actionTypes.changePasswordDataStart });
        let url = "ers/ChangePassword";

        const fromPage = userInfo.FromPage;

        axios.post(url, { userInfo: userInfo, websecurityUsers: userInfo, ersWebsecurityUsers: userInfo, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.changePasswordDataSuccess, userInfo: res.data.websecurityUsers });

                    //const user = JSON.parse(localStorage.getItem('crstar_user'));
                    localStorage.setItem('user_hasChangePass', 'right!');

                    // Only set this to show message
                    if (fromPage === "Login") {
                        dispatch({ type: actionTypes.changePasswordDataFail, message: "Your password has changed." });
                        if (document.getElementById("btnBackToLoginPage")) {
                            document.getElementById("btnBackToLoginPage").click();
                        }
                    } else {
                        /*
                        dispatch({
                            type: actionTypes.setCurrentDialog, dialogType: dialogTypes.ALPHA_MENU
                        });
                        window.location.href = window.location.href;
                        */

                        // back to Login page
                        dispatch({ type: actionTypes.resetAllStoringOfUser });
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.href = '/login';

                        return true;
                    }
                } else {
                    dispatch({ type: actionTypes.changePasswordDataFail, message: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.changePasswordDataFail, message: "Failed!" });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    // Password Policy Maintenance
    loadPwdPolicy: (uid) => (dispatch) => {
        dispatch({ type: actionTypes.loadPwdPolicyInfoStart });

        axios.post("ers/LoadPwdPolicy", { userId: uid, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadPwdPolicyInfoSuccess, p2Info: res.data.p2Info });
                } else {
                    dispatch({ type: actionTypes.loadPwdPolicyInfoFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    savePwdPolicyInfo: (p2Info) => (dispatch) => {
        dispatch({ type: actionTypes.savePwdPolicyInfoStart });
        let url = "ers/UpdatePwdPolicyInfo";

        axios.post(url, { p2Info: p2Info, accessToken: localStorage.getItem('token'), clientAlias: initialState["ersClientGroup"] })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.savePwdPolicyInfoSuccess, p2Info: res.data.p2Info });
                } else {
                    dispatch({ type: actionTypes.savePwdPolicyInfoFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.savePwdPolicyInfoFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    // User Security
    selectUserSecurityRecord: (email) => (dispatch) => {
        dispatch({ type: actionTypes.getUserSecurityDataStart, email });

        axios.post(initialState["ersClientGroup"] + "/GetUserSecurity", { email: email, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.getUserSecurityDataSuccess, userSecurityInfo: res.data.userSecurityInfo });
                } else {
                    dispatch({ type: actionTypes.getUserSecurityDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    saveUserSecurity: (userSecurityInfo) => (dispatch) => {
        dispatch({ type: actionTypes.saveUserSecurityDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateUser";

        axios.post(url, { user: userSecurityInfo, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveUserSecurityDataSuccess, user: res.data.userSecurityInfo });
                } else {
                    dispatch({ type: actionTypes.saveUserSecurityDataFail })
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveUserSecurityDataFail });
                console.log(error);

                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // ===============================================================================
    resetAllStoringOfUser: () => (dispatch) => {
        dispatch({ type: actionTypes.resetAllStoringOfUser });
    },
    clearAllScreenDataOnTab: () => (dispatch) => {
        dispatch({ type: actionTypes.clearAllScreenDataOnTab });
    },

    // Global Change
    runGlobalChange: (globalChangeInfo) => (dispatch) => {
        dispatch({ type: actionTypes.runGlobalChangeStart });
        let url = initialState["ersClientGroup"] + "/RunGlobalChange";

        axios.post(url, { globalChange: globalChangeInfo, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runGlobalChangeSuccess, globalChangeInfo: res.data.globalChange });
                } else {
                    dispatch({ type: actionTypes.runGlobalChangeFail, msg: res.data.message, globalChangeInfo: globalChangeInfo });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runGlobalChangeFail, msg: "You do not have permission to run this Global Change." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    massDeleteInfo: () => (dispatch) => {
        dispatch({ type: actionTypes.massDeleteInfoStart });

        axios.post(initialState["ersClientGroup"] + "/MassDeleteInfo", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                //console.log("###Res: ", res)
                if (!res.data.error) {
                    dispatch({ type: actionTypes.massDeleteInfoSuccess, massDelete: res.data });
                } else {
                    dispatch({ type: actionTypes.massDeleteInfoFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runMassDelete: (massDelete) => (dispatch) => {
        dispatch({ type: actionTypes.runMassDeleteStart });
        let url = initialState["ersClientGroup"] + "/RunMassDelete";

        axios.post(url, { massDelete: massDelete, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                console.log("###Res: ", res)
                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runMassDeleteSuccess, massDelete: res.data.massDelete });
                } else {
                    dispatch({ type: actionTypes.runMassDeleteFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runMassDeleteFail, msg: "You do not have permission to run the Mass Delete." });
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    databaseValidationInfo: () => (dispatch) => {
        dispatch({ type: actionTypes.databaseValidationInfoDataStart });

        axios.post(initialState["ersClientGroup"] + "/DatabaseValidationInfo", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                console.log("###Res: ", res)
                if (!res.data.error) {
                    dispatch({ type: actionTypes.databaseValidationInfoDataSuccess, databaseValidation: res.data });
                } else {
                    dispatch({ type: actionTypes.databaseValidationInfoDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runDatabaseValidation: (DatabaseValidation) => (dispatch) => {
        dispatch({ type: actionTypes.runDatabaseValidationStart });
        let url = initialState["ersClientGroup"] + "/RunDatabaseValidation";

        axios.post(url, { DatabaseValidation: DatabaseValidation, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runDatabaseValidationSuccess, databaseValidation: res.data.databaseValidation });
                } else {
                    dispatch({ type: actionTypes.runDatabaseValidationFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runDatabaseValidationFail, msg: "You do not have permission to run this Database Validation Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Generate Physicians Roster screen
    generatePhysiciansRoster: () => (dispatch) => {
        dispatch({ type: actionTypes.generatePhysiciansRosterDataStart });

        axios.post(initialState["ersClientGroup"] + "/GeneratePhysiciansRoster", { accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.generatePhysiciansRosterDataSuccess, physiciansRoster: res.data.physicians });
                } else {
                    dispatch({ type: actionTypes.generatePhysiciansRosterDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    runPhysicianRoster: (physiciansRoster) => (dispatch) => {
        dispatch({ type: actionTypes.runPhysiciansRosterStart });
        let url = initialState["ersClientGroup"] + "/RunPhysicianRoster";

        //axios.post(url, { physiciansRoster: physiciansRoster })
        axios.post(url, { physiciansRoster: physiciansRoster, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runPhysiciansRosterSuccess, physiciansRoster: res.data.physiciansRoster });
                } else {
                    dispatch({ type: actionTypes.runPhysiciansRosterFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runPhysiciansRosterFail, msg: "You do not have permission to run this Physicians Roster." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Select A Population Label screen
    selectAPopLabel: (label, _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.selectAPopLabelDataStart, isNotExist: _isNotExist ? true : false });

        if (!label) label = "";

        axios.post(initialState["ersClientGroup"] + "/GetLabelPopInfo",
            { label: label, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.selectAPopLabelDataSuccess, pLabelInfo: res.data.labels });
                } else {
                    dispatch({ type: actionTypes.selectAPopLabelDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveAPopLabel: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.saveAPopLabelDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateLabelPopInfo";

        axios.post(url, { labels: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && (!res.data.error || (res.data.error && res.data.bypass))) {
                    dispatch({
                        type: actionTypes.saveAPopLabelDataSuccess,
                        pLabelForm: res.data.labels,
                        bypass: res.data.bypass, //errMsg: res.data.internalMessage
                    });
                    if (res.data.error && res.data.bypass) {
                        dispatch({
                            type: actionTypes.setErrorMessage,
                            //bypass: res.data.bypass,
                            errMsg: res.data.internalMessage
                        });
                    }
                } else {
                    dispatch({ type: actionTypes.saveAPopLabelDataFail, errMsg: res.data.internalMessage });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveAPopLabelDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });
    },
    deleteAPopLabel: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.deleteAPopLabelDataStart });
        let url = initialState["ersClientGroup"] + "/DeleteLabelPopInfo";

        axios.post(url, { labels: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.deleteAPopLabelDataSuccess, pLabelForm: res.data.labels });
                } else {
                    dispatch({
                        type: actionTypes.deleteAPopLabelDataFail,
                        errorMessage: res.data.message,
                        pLabelForm: pLabelForm//res.data.labels ? res.data.labels : pLabelForm
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.deleteAPopLabelDataFail,
                    errorMessage: error,
                    pLabelForm: pLabelForm
                });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    //End

    // Clear Populations
    deletePopulationLabels: (clearPopulations) => (dispatch) => {
        dispatch({ type: actionTypes.deletePopulationLabelsDataStart });
        let url = initialState["ersClientGroup"] + "/DeletePopLabels";

        axios.post(url, { labels: { labelIds: clearPopulations }, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.deletePopulationLabelsDataSuccess, pLabelForm: res.data.labels });
                } else {
                    dispatch({
                        type: actionTypes.deletePopulationLabelsDataFail,
                        errorMessage: res.data.message,
                        clearPopulations: res.data.labelIds ? res.data.labelIds : clearPopulations
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.deletePopulationLabelsDataFail,
                    errorMessage: error,
                    clearPopulations: clearPopulations
                });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runGraphForPopulation: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.runGraphForPopulationStart });
        let url = initialState["ersClientGroup"] + "/RunGraphForPopulation";

        axios.post(url, { labels: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runGraphForPopulationSuccess, pLabelForm: res.data.labels });
                } else {
                    dispatch({ type: actionTypes.runGraphForPopulationFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runGraphForPopulationFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    generateAccessionRegister: () => (dispatch) => {
        dispatch({ type: actionTypes.generateAccessionRegisterDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateAccessionRegister", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                console.log("###Res: ", res)
                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateAccessionRegisterDataSuccess, accessionRegister: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateAccessionRegisterDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runAccessionRegister: (accessionRegister) => (dispatch) => {
        dispatch({ type: actionTypes.runAccessionRegisterStart });
        let url = initialState["ersClientGroup"] + "/RunAccessionRegister";

        axios.post(url, { accessionRegister: accessionRegister, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runAccessionRegisterSuccess, accessionRegister: res.data.accessionRegister });
                } else {
                    dispatch({ type: actionTypes.runAccessionRegisterFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runAccessionRegisterFail, msg: "You do not have permission to run this Accession Register Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateRequestLog: () => (dispatch) => {
        dispatch({ type: actionTypes.generateRequestLogDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateRequestLog", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                console.log("###Res: ", res)
                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateRequestLogDataSuccess, requestLog: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateRequestLogDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runRequestLog: (requestLog) => (dispatch) => {
        dispatch({ type: actionTypes.runRequestLogStart });
        let url = initialState["ersClientGroup"] + "/RunRequestLog";

        axios.post(url, { requestLog: requestLog, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runRequestLogSuccess, requestLog: res.data.requestLog });
                } else {
                    dispatch({ type: actionTypes.runRequestLogFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runRequestLogFail, msg: "You do not have permission to run this Request Log Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateSiteDistribution: () => (dispatch) => {
        dispatch({ type: actionTypes.generateSiteDistributionDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateSiteDistribution", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                console.log("###Res: ", res)
                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateSiteDistributionDataSuccess, siteDistribution: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateSiteDistributionDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runSiteDistribution: (siteDistribution) => (dispatch) => {
        dispatch({ type: actionTypes.runSiteDistributionStart });
        let url = initialState["ersClientGroup"] + "/RunSiteDistribution";

        axios.post(url, { siteDistribution: siteDistribution, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runSiteDistributionSuccess, siteDistribution: res.data.siteDistribution });
                } else {
                    dispatch({ type: actionTypes.runSiteDistributionFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runSiteDistributionFail, msg: "You do not have permission to run this Site Distribution Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateTreatmentCombination: () => (dispatch) => {
        dispatch({ type: actionTypes.generateTreatmentCombinationDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateTreatmentCombination", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                console.log("###Res: ", res)
                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateTreatmentCombinationDataSuccess, treatmentCombination: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateTreatmentCombinationDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runTreatmentCombination: (treatmentCombination) => (dispatch) => {
        dispatch({ type: actionTypes.runTreatmentCombinationStart });
        let url = initialState["ersClientGroup"] + "/RunTreatmentCombination";

        axios.post(url, { treatmentCombination: treatmentCombination, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runTreatmentCombinationSuccess, treatmentCombination: res.data.treatmentCombination });
                } else {
                    dispatch({ type: actionTypes.runTreatmentCombinationFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runTreatmentCombinationFail, msg: "You do not have permission to run this Treatment Combination Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateProductivity: () => (dispatch) => {
        dispatch({ type: actionTypes.generateProductivityDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateProductivity", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateProductivityDataSuccess, productivity: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateProductivityDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runProductivity: (productivity) => (dispatch) => {
        dispatch({ type: actionTypes.runProductivityStart });
        let url = initialState["ersClientGroup"] + "/RunProductivity";

        axios.post(url, { productivity: productivity, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runProductivitySuccess, productivity: res.data.productivity });
                } else {
                    dispatch({ type: actionTypes.runProductivityFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runProductivityFail, msg: "You do not have permission to run this Productivity Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateQualityMeasures: () => (dispatch) => {
        dispatch({ type: actionTypes.generateQualityMeasuresDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateQualityMeasures", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateQualityMeasuresDataSuccess, qualityMeasures: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateQualityMeasuresDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runQualityMeasures: (qualityMeasures) => (dispatch) => {
        dispatch({ type: actionTypes.runQualityMeasuresStart });
        let url = initialState["ersClientGroup"] + "/RunQualityMeasures";

        axios.post(url, { qualityMeasures: qualityMeasures, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runQualityMeasuresSuccess, qualityMeasures: res.data.qualityMeasures });
                } else {
                    dispatch({ type: actionTypes.runQualityMeasuresFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runQualityMeasuresFail, msg: "You do not have permission to run these Quality Measures." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateCPMReport: () => (dispatch) => {
        dispatch({ type: actionTypes.generateCPMReportDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateCPMReport", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateCPMReportDataSuccess, cpmReport: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateCPMReportDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runCPMReport: (cpmReport) => (dispatch) => {
        dispatch({ type: actionTypes.runCPMReportStart });
        let url = initialState["ersClientGroup"] + "/RunCPMReport";

        axios.post(url, { cpmReport: cpmReport, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runCPMReportSuccess, cpmReport: res.data.cpmReport });
                } else {
                    dispatch({ type: actionTypes.runCPMReportFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runCPMReportFail, msg: "An Error occurred while atttempting to run the CPM Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },


    generateTimeliness: () => (dispatch) => {
        dispatch({ type: actionTypes.generateTimelinessDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateTimeliness", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateTimelinessDataSuccess, timeliness: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateTimelinessDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runTimeliness: (timeliness) => (dispatch) => {
        dispatch({ type: actionTypes.runTimelinessStart });
        let url = initialState["ersClientGroup"] + "/RunTimeliness";

        axios.post(url, { timeliness: timeliness, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runTimelinessSuccess, timeliness: res.data.timeliness });
                } else {
                    dispatch({ type: actionTypes.runTimelinessFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runTimelinessFail, msg: "You do not have permission to run this Timeliness Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateTNMGraph: () => (dispatch) => {
        dispatch({ type: actionTypes.generateTNMGraphDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateTNMGraph", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateTNMGraphDataSuccess, tnmGraph: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateTNMGraphDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runTNMGraph: (tnmGraph) => (dispatch) => {
        dispatch({ type: actionTypes.runTNMGraphStart });
        let url = initialState["ersClientGroup"] + "/RunTNMGraph";

        axios.post(url, { tnmGraph: tnmGraph, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runTNMGraphSuccess, tnmGraph: res.data.tnmGraph });
                } else {
                    dispatch({ type: actionTypes.runTNMGraphFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runTNMGraphFail, msg: "You do not have permission to run this TNM Graph Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    generateCrossTab: () => (dispatch) => {
        dispatch({ type: actionTypes.generateCrossTabDataStart });

        axios.post(initialState["ersClientGroup"] + "/GenerateCrossTab", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.generateCrossTabDataSuccess, crossTab: res.data.records });
                } else {
                    dispatch({ type: actionTypes.generateCrossTabDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runCrossTab: (crossTab) => (dispatch) => {
        dispatch({ type: actionTypes.runCrossTabStart });
        let url = initialState["ersClientGroup"] + "/RunCrossTab";

        axios.post(url, { crossTab: crossTab, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runCrossTabSuccess, crossTab: res.data.crossTab });
                } else {
                    dispatch({ type: actionTypes.runCrossTabFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runCrossTabFail, msg: "You do not have permission to run this Cross Tab Report." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    loadCrossTabData: (crossTab) => (dispatch) => {
        dispatch({ type: actionTypes.loadCrossTabDataStart });
        let url = initialState["ersClientGroup"] + "/LoadCrossTabData";

        axios.post(url, { crossTab: crossTab, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.loadCrossTabDataSuccess, crossTab: res.data.crossTab });
                } else {
                    dispatch({ type: actionTypes.loadCrossTabDataFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.loadCrossTabDataFail, msg: "Error when loading saved Cross Tab Report data." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    deleteCrossTabLabel: (crossTab) => (dispatch) => {
        dispatch({ type: actionTypes.deleteCrossTabLabelDataStart });
        let url = initialState["ersClientGroup"] + "/DeleteCrossTabLabel";

        axios.post(url, { crossTab: crossTab, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.deleteCrossTabLabelDataSuccess, crossTab: res.data.crossTab });
                } else {
                    dispatch({
                        type: actionTypes.deleteCrossTabLabelDataFail,
                        errorMessage: res.data.message,
                        crossTab: res.data.crossTab ? res.data.crossTab : null
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.deleteCrossTabLabelDataFail,
                    errorMessage: error,
                    crossTab: crossTab
                });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    runSurvivalByStageReport: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.runGraphForPopulationStart });
        let url = initialState["ersClientGroup"] + "/RunSurvivalByStageReport";

        axios.post(url, { labels: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runGraphForPopulationSuccess, pLabelForm: res.data.labels });
                } else {
                    dispatch({ type: actionTypes.runGraphForPopulationFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runGraphForPopulationFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    cancelSBSReport: () => (dispatch) => {
        let url = initialState["ersClientGroup"] + "/CancelSBSReport";
        axios.post(url, { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                } else {
                }
            }).catch(error => {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Initialize Accession Numbers screen
    loadAccNbrsMaint: () => (dispatch) => {
        dispatch({ type: actionTypes.loadAccNbrsMaintDataStart });

        axios.post(initialState["ersClientGroup"] + "/LoadAccNbrsMaint", { accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadAccNbrsMaintDataSuccess, accNbrs: res.data.accNbrs });
                } else {
                    dispatch({ type: actionTypes.loadAccNbrsMaintDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveAccNbrsMaint: (accNbrs) => (dispatch) => {
        dispatch({ type: actionTypes.saveAccNbrsMaintStart });
        let url = initialState["ersClientGroup"] + "/UpdateAccNbrs";
        axios.post(url, { accNbrs: accNbrs, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveAccNbrsMaintSuccess, accNbrs: res.data.accNbrs });
                } else {
                    dispatch({ type: actionTypes.saveAccNbrsMaintFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveAccNbrsMaintFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    deleteAccNbrsMaint: (key) => (dispatch) => {
        dispatch({ type: actionTypes.deleteAccNbrsMaintStart });
        let url = initialState["ersClientGroup"] + "/DeleteAccNbrs";

        //axios.post(url, { key: key })
        axios.post(url, { key: key, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.deleteAccNbrsMaintSuccess });
                } else {
                    dispatch({ type: actionTypes.deleteAccNbrsMaintFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.deleteAccNbrsMaintFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Maintain Follow-up Order screen
    loadFollowUpOrder: (tmplId) => (dispatch) => {
        dispatch({ type: actionTypes.loadFollowUpOrderDataStart });

        let url = initialState["ersClientGroup"] + "/LoadFollowUpOrder";
        let params = { tmplId: (tmplId ? tmplId : "0"), accessToken: localStorage.getItem('token') };

        axios.post(url, params)
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadFollowUpOrderDataSuccess, followUpOrder: res.data.templatesFollowUpOrder });
                } else {
                    dispatch({ type: actionTypes.loadFollowUpOrderDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveFollowUpOrder: (formTmpl) => (dispatch) => {
        dispatch({ type: actionTypes.saveFollowUpOrderStart });
        let url = initialState["ersClientGroup"] + "/UpdateFollowUpOrder";
        axios.post(url, { templatesFollowUpOrder: formTmpl, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveFollowUpOrderSuccess, followUpOrder: res.data.templatesFollowUpOrder });
                } else {
                    dispatch({ type: actionTypes.saveFollowUpOrderFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveFollowUpOrderFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    /*
    deleteFollowUpOrder: (tmplId) => (dispatch) => {
        dispatch({ type: actionTypes.deleteFollowUpOrderStart });
        let url = initialState["ersClientGroup"] + "/DeleteFollowUpOrder";

        axios.post(url, { id: tmplId })
            .then(res => {
                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.deleteFollowUpOrderSuccess, followUpOrder: res.data.templatesFollowUpOrder });
                } else {
                    dispatch({ type: actionTypes.deleteFollowUpOrderFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.deleteFollowUpOrderFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
            });
    },
    */

    // Generate Follow-up List and Letters screen
    loadFollowUpLetters: () => (dispatch) => {
        dispatch({ type: actionTypes.loadFollowUpLettersStart });

        axios.post(initialState["ersClientGroup"] + "/LoadFollowUpOrder", { accessToken: localStorage.getItem('token') })
            .then(res => {
                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadFollowUpLettersSuccess, followUpLetters: res.data.followUpLetters });
                } else {
                    dispatch({ type: actionTypes.loadFollowUpLettersFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
            });
    },
    runFollowUpLetters: (dataForm, flag) => (dispatch) => {
        dispatch({ type: actionTypes.runFollowUpLettersStart });
        let url = initialState["ersClientGroup"] + (flag == "demand" ? "/GenLettersDemand" : "/FollowUpLetters");

        axios.post(url, { followUpLetters: dataForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runFollowUpLettersSuccess, followUpLetters: res.data.followUpLetters });
                } else {
                    dispatch({ type: actionTypes.runFollowUpLettersFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runFollowUpLettersFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });
            });
    },

    // ===System Configuration menu===
    loadGenEditsOnInitial: () => ({ type: actionTypes.loadGenEditsOnInitial }),
    // GenEdits screen
    loadGenEditsInfo: (username) => (dispatch) => {
        dispatch({ type: actionTypes.loadGenEditsDataStart });

        axios.post(initialState["ersClientGroup"] + "/LoadGenEditsInfo", { username: username, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadGenEditsDataSuccess, genEdits: res.data.genEdits });
                } else {
                    dispatch({ type: actionTypes.loadGenEditsDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveGenEdits: (genEdits) => (dispatch) => {
        dispatch({ type: actionTypes.saveGenEditsStart });
        let url = initialState["ersClientGroup"] + "/UpdateGenEdits";
        axios.post(url, { genEdits: genEdits, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveGenEditsSuccess, genEdits: res.data.genEdits });
                } else {
                    dispatch({ type: actionTypes.saveGenEditsFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveGenEditsFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Dashboard Settings screen
    loadDashboardSettingsInfo: (username, hosp) => (dispatch) => {
        dispatch({ type: actionTypes.loadDashboardSettingsDataStart });

        axios.post(initialState["ersClientGroup"] + "/LoadDashboardSettingsInfo", { username: "MasterAdmin@ers-can.com", hosp: hosp, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadDashboardSettingsDataSuccess, dashboardSettings: res.data.dashboardSettings });
                } else {
                    dispatch({ type: actionTypes.loadDashboardSettingsDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveDashboardSettings: (dashboardSettings) => (dispatch) => {
        dispatch({ type: actionTypes.saveDashboardSettingsStart });
        let url = initialState["ersClientGroup"] + "/UpdateDashboardSettings";
        axios.post(url, { dashboardSettings: dashboardSettings, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveDashboardSettingsSuccess, dashboardSettings: res.data.dashboardSettings });
                } else {
                    dispatch({ type: actionTypes.saveDashboardSettingsFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveDashboardSettingsFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Delete Abstract screen
    loadPatientAbsInfo: (pid) => (dispatch) => {
        dispatch({ type: actionTypes.loadPatientAbsInfoDataStart });

        axios.post(initialState["ersClientGroup"] + "/LoadPatientAbsInfo", { pid: pid, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadPatientAbsInfoDataSuccess, patientAbsInfo: res.data.patient });
                } else {
                    dispatch({ type: actionTypes.loadPatientAbsInfoDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Report File screen
    selectRfLabels: (label, _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.selectRfLabelsDataStart, isNotExist: _isNotExist ? true : false });

        if (!label) label = "";

        axios.post(initialState["ersClientGroup"] + "/GetRfLabelsInfo", { label: label, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.selectRfLabelsDataSuccess, rfLabelsInfo: res.data.rfLabels });
                } else {
                    dispatch({ type: actionTypes.selectRfLabelsDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveRfLabels: (rfLabelsForm) => (dispatch) => {
        dispatch({ type: actionTypes.saveRfLabelsDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateRfLabelsInfo";

        axios.post(url, { rfLabels: rfLabelsForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveRfLabelsDataSuccess, rfLabelsForm: res.data.rfLabels });
                } else {
                    dispatch({ type: actionTypes.saveRfLabelsDataFail, rfLabelsForm: res.data.rfLabels });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveRfLabelsDataFail, rfLabelsForm: rfLabelsForm });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    deleteRfLabels: (rfLabelsForm) => (dispatch) => {
        dispatch({ type: actionTypes.deleteRfLabelsDataStart });
        let url = initialState["ersClientGroup"] + "/DeleteRfLabelsInfo";

        axios.post(url, { rfLabels: rfLabelsForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.deleteRfLabelsDataSuccess, rfLabelsForm: res.data.rfLabels });
                } else {
                    dispatch({
                        type: actionTypes.deleteRfLabelsDataFail,
                        errorMessage: res.data.message,
                        rfLabelsForm: res.data.rfLabels ? res.data.rfLabels : null
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.deleteRfLabelsDataFail,
                    errorMessage: error,
                    rfLabelsForm: rfLabelsForm
                });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    //End

    // List For a Population screen
    //clearAllExportData: () => ({ type: actionTypes.clearAllExportData }),
    clearAllExportData: () => (dispatch) => {
        dispatch({ type: actionTypes.clearAllExportData });
    },
    selectReportLabels: (label = "", _isNotExist = false) => (dispatch) => {
        dispatch({ type: actionTypes.selectReportLabelsDataStart, isNotExist: _isNotExist ? true : false });

        if (!label) label = "";

        axios.post(initialState["ersClientGroup"] + "/GetReportLabelsInfo", { label: label, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.selectReportLabelsDataSuccess, reportLabelsInfo: res.data.reportLabels });
                } else {
                    dispatch({ type: actionTypes.selectReportLabelsDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveReportLabels: (reportLabelsForm) => (dispatch) => {
        dispatch({ type: actionTypes.saveReportLabelsDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateReportLabelsInfo";

        axios.post(url, { reportLabels: reportLabelsForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveReportLabelsDataSuccess, reportLabelsForm: res.data.reportLabels });
                } else {
                    dispatch({ type: actionTypes.saveReportLabelsDataFail, reportLabelsForm: res.data.reportLabels, errMsg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveReportLabelsDataFail, reportLabelsForm: reportLabelsForm });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    deleteReportLabels: (reportLabelsForm) => (dispatch) => {
        dispatch({ type: actionTypes.deleteReportLabelsDataStart });
        let url = initialState["ersClientGroup"] + "/DeleteReportLabelsInfo";

        axios.post(url, { reportLabels: reportLabelsForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.deleteReportLabelsDataSuccess, reportLabelsForm: res.data.reportLabels });
                } else {
                    dispatch({
                        type: actionTypes.deleteReportLabelsDataFail,
                        errorMessage: res.data.message,
                        reportLabelsForm: res.data.reportLabels ? res.data.reportLabels : null
                    });
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.deleteReportLabelsDataFail,
                    errorMessage: error,
                    reportLabelsForm: reportLabelsForm
                });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    //End

    //State Export screen
    runStateExportForm: (printForm) => (dispatch) => {
        dispatch({ type: actionTypes.runStateExportFormStart });
        let url = initialState["ersClientGroup"] + "/RunStateExportForm";

        axios.post(url, { printForm: printForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runStateExportFormSuccess, printForm: res.data.printForm });
                } else {
                    dispatch({ type: actionTypes.runStateExportFormFail, msg: res.data.message });
                }
                return false;
            }).catch(error => {
                dispatch({ type: actionTypes.runStateExportFormFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    //btnLogout.querySelector("a").click();
                }
                return false;
            });
        return false;
    },

    //NCDB Export screen
    runNcdbRcrsExport: (printForm) => (dispatch) => {
        dispatch({ type: actionTypes.runNcdbExportFormStart });
        let url = initialState["ersClientGroup"] + "/RunNcdbRcrsExport";

        axios.post(url, { printForm: printForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runNcdbExportFormSuccess, printForm: res.data.printForm });
                } else {
                    dispatch({ type: actionTypes.runNcdbExportFormFail, msg: res.data.message });
                }
                return false;
            }).catch(error => {
                dispatch({ type: actionTypes.runNcdbExportFormFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    //btnLogout.querySelector("a").click();
                }
                return false;
            });
        return false;
    },

    //Print Abstracts
    runPrintAbstract: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.runPrintAbstractStart });
        let url = initialState["ersClientGroup"] + "/RunPrintAbstract";

        axios.post(url, { patient: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runPrintAbstractSuccess, pLabelForm: res.data.patient });
                } else {
                    dispatch({ type: actionTypes.runPrintAbstractFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runPrintAbstractFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    //Print Tx Summary
    runPrintTxSummary: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.runPrintTxSummaryStart });
        let url = initialState["ersClientGroup"] + "/RunPrintTxSummary";

        axios.post(url, { patient: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runPrintTxSummarySuccess, pLabelForm: res.data.patient });
                } else {
                    dispatch({ type: actionTypes.runPrintTxSummaryFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runPrintTxSummaryFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    //Print QA Form
    runPrintQAForm: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.runPrintQAFormStart });
        let url = initialState["ersClientGroup"] + "/RunPrintQAForm";

        axios.post(url, { patient: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runPrintQAFormSuccess, pLabelForm: res.data.patient });
                } else {
                    dispatch({ type: actionTypes.runPrintQAFormFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runPrintQAFormFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    //Print SCP Form
    runGenSurvivorshipCarePlan: (pLabelForm) => (dispatch) => {
        dispatch({ type: actionTypes.runPrintQAFormStart });
        let url = initialState["ersClientGroup"] + "/RunGenSurvivorshipCarePlan";

        //axios.post(url, { patient: pLabelForm })
        axios.post(url, { patient: pLabelForm, accessToken: localStorage.getItem('token') })
            .then(res => {
                //
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.runGenSurvivorshipCarePlanSuccess, pLabelForm: res.data.patient });
                } else {
                    dispatch({ type: actionTypes.runGenSurvivorshipCarePlanFail, msg: res.data.message });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.runGenSurvivorshipCarePlanFail, msg: "You do not have permission to run reports on this population." });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Work Queue screen
    loadWorkQueueData: (pid) => (dispatch) => {
        dispatch({ type: actionTypes.loadWorkQueueDataStart });

        let url = initialState["ersClientGroup"] + "/LoadWorkQueueData";
        let params = { accessToken: localStorage.getItem('token') };
        axios.post(url, params)
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadWorkQueueDataSuccess, workqueue: res.data.workQueueData });
                } else {
                    dispatch({ type: actionTypes.loadWorkQueueDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Follow-up/QA Letters screen
    loadLettersMaint: (name = "", hosp = "") => (dispatch) => {
        dispatch({ type: actionTypes.loadLettersMaintDataStart });
        /*
        var hospCode = document.getElementById("hospitalCode") ? document.getElementById("hospitalCode").value : "";
        if (hosp != "") {
            hospCode = hosp;
        }
        */

        axios.post(initialState["ersClientGroup"] + "/GetLetters", {
            letter: name,
            hosp: hosp,
            accessToken: localStorage.getItem('token')
        })
            .then(res => {
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadLettersMaintDataSuccess, letters: res.data.letters });
                } else {
                    dispatch({ type: actionTypes.loadLettersMaintDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveLettersMaint: (form) => (dispatch) => {
        dispatch({ type: actionTypes.saveLettersMaintStart });
        let url = initialState["ersClientGroup"] + "/UpdateLetters";
        //axios.post(url, { letters: form })
        axios.post(url, { letters: form, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveLettersMaintSuccess, letters: res.data.letters });
                } else {
                    dispatch({ type: actionTypes.saveLettersMaintFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveLettersMaintFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    previewLettersMaint: (form) => (dispatch) => {
        dispatch({ type: actionTypes.previewLettersMaintStart });
        let url = initialState["ersClientGroup"] + "/PreviewLetters";
        //axios.post(url, { letters: form })
        axios.post(url, { letters: form, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.previewLettersMaintSuccess, letters: res.data.letters });
                } else {
                    dispatch({ type: actionTypes.previewLettersMaintFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    // Custom Forms Maintenance screen
    loadCustomFormsMaint: (name = "", hosp = "") => (dispatch) => {
        dispatch({ type: actionTypes.loadCustomFormsMaintDataStart });
        /*
        var hospCode = document.getElementById("hospitalCode") ? document.getElementById("hospitalCode").value : "";
        if (hosp != "") {
            hospCode = hosp;
        }
        */
        axios.post(initialState["ersClientGroup"] + "/GetLetters", { letter: name, hosp: hosp, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadCustomFormsMaintDataSuccess, forms: res.data.letters });
                } else {
                    dispatch({ type: actionTypes.loadCustomFormsMaintDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveCustomFormsMaint: (form) => (dispatch) => {
        dispatch({ type: actionTypes.saveCustomFormsMaintStart });
        let url = initialState["ersClientGroup"] + "/UpdateLetters";
        axios.post(url, { letters: form, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveCustomFormsMaintSuccess, forms: res.data.letters });
                } else {
                    dispatch({ type: actionTypes.saveCustomFormsMaintFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveCustomFormsMaintFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    previewCustomFormsMaint: (form) => (dispatch) => {
        dispatch({ type: actionTypes.previewCustomFormsMaintStart });
        let url = initialState["ersClientGroup"] + "/PreviewForms";
        axios.post(url, { letters: form, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.previewCustomFormsMaintSuccess, forms: res.data.letters });
                } else {
                    dispatch({ type: actionTypes.previewCustomFormsMaintFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },

    //Import Configuration
    loadImportConfigInfo: (label = "") => (dispatch) => {
        dispatch({ type: actionTypes.loadImportConfigDataStart });

        axios.post(initialState["ersClientGroup"] + "/LoadImportConfig", { label: label, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    dispatch({ type: actionTypes.loadImportConfigDataSuccess, xMergeOptions: res.data.xMergeOptions });
                } else {
                    dispatch({ type: actionTypes.loadImportConfigDataFail });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
    saveImportConfig: (formData) => (dispatch) => {
        dispatch({ type: actionTypes.saveImportConfigDataStart });
        let url = initialState["ersClientGroup"] + "/UpdateImportConfig";
        axios.post(url, { mergeOptions: formData, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (res && res.data && !res.data.error) {
                    dispatch({ type: actionTypes.saveImportConfigDataSuccess, xMergeOptions: res.data.xMergeOptions });
                } else {
                    dispatch({ type: actionTypes.saveImportConfigDataFail });
                }
            }).catch(error => {
                dispatch({ type: actionTypes.saveImportConfigDataFail });

                console.log(error);
                dispatch({
                    type: actionTypes.showSessionTimeoutMsg,
                    flag: error && error.toString() == "Error: Request failed with status code 408" ? true : false
                });

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });
    },
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case actionTypes.setHandlerControlItem:
            return {
                ...state,
                handlerControlItem: action.item
            }
        case actionTypes.setRapidAbstract:
            return {
                ...state,
                rapidAbstract: action.value
            }
        case actionTypes.setInquiringMode:
            return {
                ...state,
                inquiringMode: action.value
            }
        case actionTypes.setItemNbr:
            return {
                ...state,
                itemNbr: action.value
            }
        case actionTypes.showPageLoading:
            initialState["waitingFor"] = action.value;
            return {
                ...state,
                waitingFor: action.value
            }
        case actionTypes.clearAndResetDataWhenPageLoading:
            initialState["isPatientLoading"] = action.value;
            initialState["processingAndMovingPage"] = action.value;
            return {
                ...state,
                isPatientLoading: action.value,
                processingAndMovingPage: action.value,
            }
        case actionTypes.selectedPatientOnGrid:
            return {
                ...state,
                selectedPatientOnGrid: action.patient,
                selectedPatientIndex: action.index,
                isPatientSaving: false,
                isFormDirty: false,
                isAddNew: false,
            }
        case actionTypes.getPatientDataStart:
            return {
                ...state,
                isPatientLoading: true,
                patientId: action.patientId,
                isAddNew: action.isAddNew,
            }
        case actionTypes.getPatientDataSuccess:
            return {
                ...state,
                selectedPatient: {
                    ...action.patient
                },
                isPatientLoading: false,
                patientId: action.patient.pid,
            }
        case actionTypes.getPatientDataFail:
            return {
                ...state,
                isPatientLoading: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        //#prj_abstract_validation_fixes
        case actionTypes.showingMessagePatientAbstracts:
            return {
                ...state,
                error: action.error,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.getCommonColorsSuccess:
            return {
                ...state,
                commonColors: {
                    ...action.colors
                },
                availableItems: action.availableItems,
                availableMenuGroups: action.availableMenuGroups,
            }
        case actionTypes.getCommonColorsFail:
            return {
                ...state,
                commonColors: null,
                availableItems: null,
                availableMenuGroups: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.savePatientDataStart:
            return {
                ...state,
                isPatientSaving: true,
                savedPatientMaitenance: false,
                selectedPatient: {
                    ...action.patient
                },
            }
        case actionTypes.savePatientDataSuccess:
            return {
                ...state,
                selectedPatient: {
                    ...action.patient
                },
                isPatientSaving: false,
                isFormDirty: false,
                isAddNew: false,
                patientId: action.patient.pid,
                error: false,
                savedPatientMaitenance: true,
            }
        case actionTypes.savePatientDataFail:
            console.log(action.error);
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                isFormDirty: true,
                sessionExipred: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.getDiagnosisDataStart:
            initialState["selectedMedicalRecord"] = null;
            return {
                ...state,
                selectedPrimarySummary: {
                    ...action.medical
                },
                selectedMedicalRecord: null,
                selectedDiagnosisRecord: null,//#20211217
                savingDiagnosisCompleted: false,
            }
        case actionTypes.getDiagnosisDataSuccess:
            var diagnosisFormData = { ...action.diagnosis };

            Object.keys(diagnosisFormData).map((t, k) => {
                if (t === "topoCode" || t === "histCode" || t === "ageDx") {
                    diagnosisFormData[t + "_bak"] = diagnosisFormData[t];
                }
            });

            initialState["selectedMedicalRecord"] = diagnosisFormData;

            return {
                ...state,
                selectedMedicalRecord: {
                    //...action.diagnosis,
                    ...diagnosisFormData
                },
                selectedDiagnosisRecord: {
                    ...action.diagnosis
                },//#20211217
                isPatientLoading: false,
                savingDiagnosisCompleted: false,
                selectedPrimarySummary: {
                    ...action.medical
                },
                // Fixing #PR00417 - START
                //clear all data of others
                selectedStagingRecord: null,
                selectedTreatmentRecord: null,
                selectedOutcomesRecord: null,
                selectedStateFieldsRecord: null,
                selectedCocRecord: null,
                selectedNapbcRecord: null,
                selectedRectalProgramRecord: null,
                selectedUserDefinedRecord: null,
                selectedCaseAdminRecord: null,
                selectedClinicalRecord: null,
                selectedResearchRecord: null,
                selectedRetiredRecord: null,
                //END
            }
        case actionTypes.getDiagnosisDataFail:
            initialState["selectedMedicalRecord"] = null;
            return {
                ...state,
                isPatientLoading: false,
                selectedMedicalRecord: null,
                selectedDiagnosisRecord: null,//#20211217
                savingDiagnosisCompleted: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        //
        case actionTypes.setDiagnosisDataDefault:
            const objDefault = {
                dxAddress: "UNKNOWN",
                dxCity: "UNKNOWN",
                dxState: "ZZ",
                dxZip: "99999",
                dxCounty: "999",
                dxCountry: "999",
                grade: "9",
                primPayerDx: "99",
                dxMaritalStatus: "9"
            };
            return {
                ...state,
                selectedMedicalRecord: {
                    ...action.diagnosis,
                    ...objDefault
                },
                selectedDiagnosisRecord: {
                    ...action.diagnosis,
                    ...objDefault
                },//#20211217
                isPatientLoading: false,
                isFormDirty: true
            }

        case actionTypes.saveDiagnosisDataStart:
            //initialState["selectedMedicalRecord"] = action.diagnosis;
            var _selectedMedicalRecord = initialState["selectedMedicalRecord"];
            _selectedMedicalRecord = {
                ..._selectedMedicalRecord,
                ...action.diagnosis
            };
            initialState["selectedMedicalRecord"] = _selectedMedicalRecord;
            return {
                ...state,
                //#PR00340 - START
                /*
                selectedMedicalRecord: {
                    ...action.diagnosis
                },
                */
                selectedMedicalRecord: _selectedMedicalRecord,
                //#PR00340 - END
                isPatientSaving: true,
                isFormDirty: false,
                savingDiagnosisCompleted: false,
                /*-------------------------*/
                selectedDiagnosisRecord: {
                    ...action.diagnosis
                },
                /*-------------------------*/
            }
        case actionTypes.saveDiagnosisDataSuccess:
            //initialState["selectedMedicalRecord"] = action.diagnosis;
            var _selectedMedicalRecord = initialState["selectedMedicalRecord"];
            _selectedMedicalRecord = {
                ..._selectedMedicalRecord,
                ...action.diagnosis
            };
            initialState["selectedMedicalRecord"] = _selectedMedicalRecord;
            return {
                ...state,
                /*
                selectedMedicalRecord: {
                    ...action.diagnosis
                },
                */
                selectedMedicalRecord: _selectedMedicalRecord,
                /*-------------------------*/
                selectedDiagnosisRecord: {
                    ...action.diagnosis
                },
                /*-------------------------*/
                isPatientSaving: false,
                isFormDirty: false,
                savingDiagnosisCompleted: true,
            }
        case actionTypes.saveDiagnosisDataFail:
            return {
                ...state,
                isFormDirty: true,
                /*
                // #set these values to get updating on view
                selectedMedicalRecord: {
                    ...action.diagnosis
                },//#
                */
                isPatientSaving: false,
                error: true,
                savingDiagnosisCompleted: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        // Hospital Start
        case actionTypes.getHospitalDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedHospitalOnModal: null,
                isAddNewDialogVisible: !action.isNotExist,
                isAddNewDialogVisible2: action.isNotExist,
                error: false,
                deletedDataSuccess: false,
            }
        case actionTypes.getHospitalDataSuccess:
            return {
                ...state,
                selectedHospitalOnModal: {
                    ...action.hospital
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.getHospitalDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedHospitalOnModal: null,
                error: true
            }
        case actionTypes.saveHospitalDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                deletedDataSuccess: false,
            }
        case actionTypes.saveHospitalDataSuccess:
            return {
                ...state,
                selectedHospitalOnModal: {
                    ...action.hospital
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                pageLoaded: false,
            }
        case actionTypes.saveHospitalDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isFormDirty: true,
                isPatientSaving: false,
            }

        case actionTypes.deleteHospitalDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedHospitalOnModal: null,
                isAddNewDialogVisible: true,
                deletedDataSuccess: false,
            }
        case actionTypes.deleteHospitalDataSuccess:
            return {
                ...state,
                selectedHospitalOnModal: {
                    ...action.hospital
                },
                isDialogLoading: false,
                isFormDirty: false,
                deletedDataSuccess: true,
                pageLoaded: false,
            }
        case actionTypes.deleteHospitalDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedHospitalOnModal: null,
                error: true,
                deletedDataSuccess: false,
            }
        // Hospital End

        // Physicians Start
        case actionTypes.getPhysiciansDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedPhysicianOnModal: null,
                isAddNewDialogVisible: !action.isNotExist,
                isAddNewDialogVisible2: action.isNotExist,
                error: false,
                deletedDataSuccess: false,
            }
        case actionTypes.getPhysiciansDataSuccess:
            return {
                ...state,
                selectedPhysicianOnModal: {
                    ...action.physicians
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.getPhysiciansDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedPhysicianOnModal: null,
                error: true
            }
        case actionTypes.savePhysiciansDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                deletedDataSuccess: false,
            }
        case actionTypes.savePhysiciansDataSuccess:
            return {
                ...state,
                selectedPhysicianOnModal: {
                    ...action.physicians
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                pageLoaded: false,
            }
        case actionTypes.savePhysiciansDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false,
                isFormDirty: true,
            }

        case actionTypes.deletePhysiciansDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedPhysicianOnModal: null,
                isAddNewDialogVisible: true,
                deletedDataSuccess: false,
            }
        case actionTypes.deletePhysiciansDataSuccess:
            return {
                ...state,
                selectedPhysicianOnModal: {
                    ...action.physicians
                },
                isDialogLoading: false,
                isFormDirty: false,
                deletedDataSuccess: true,
                pageLoaded: false,
            }
        case actionTypes.deletePhysiciansDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedPhysicianOnModal: null,
                error: true,
                deletedDataSuccess: false,
            }
        // Physicians End

        //--
        // Registrars Start
        case actionTypes.getRegistrarsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedRegistrarOnModal: null,
                isAddNewDialogVisible: !action.isNotExist,
                isAddNewDialogVisible2: action.isNotExist,
                error: false,
                deletedDataSuccess: false,
            }
        case actionTypes.getRegistrarsDataSuccess:
            return {
                ...state,
                selectedRegistrarOnModal: {
                    ...action.registrars
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.getRegistrarsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedRegistrarOnModal: null,
                error: true
            }
        case actionTypes.saveRegistrarsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                deletedDataSuccess: false,
            }
        case actionTypes.saveRegistrarsDataSuccess:
            return {
                ...state,
                selectedRegistrarOnModal: {
                    ...action.registrars
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                pageLoaded: false,
            }
        case actionTypes.saveRegistrarsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.message,
                isPatientSaving: false,
                isFormDirty: true,
            }

        case actionTypes.deleteRegistrarsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedRegistrarOnModal: null,
                isAddNewDialogVisible: true,
                deletedDataSuccess: false,
            }
        case actionTypes.deleteRegistrarsDataSuccess:
            return {
                ...state,
                selectedRegistrarOnModal: {
                    ...action.registrars
                },
                isDialogLoading: false,
                isFormDirty: false,
                deletedDataSuccess: true,
                pageLoaded: false,
            }
        case actionTypes.deleteRegistrarsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedRegistrarOnModal: null,
                error: true,
                deletedDataSuccess: false,
            }
        // Registrars End

        // GlobalDir Start
        case actionTypes.getGlobalDirDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedGlobalDirOnModal: null,
                isAddNewDialogVisible: !action.isNotExist,
                isAddNewDialogVisible2: action.isNotExist,
                error: false,
                deletedDataSuccess: false,
            }
        case actionTypes.getGlobalDirDataSuccess:
            return {
                ...state,
                selectedGlobalDirOnModal: {
                    ...action.globalDir
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.getGlobalDirDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedGlobalDirOnModal: null,
                error: true
            }
        case actionTypes.saveGlobalDirDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                deletedDataSuccess: false,
            }
        case actionTypes.saveGlobalDirDataSuccess:
            return {
                ...state,
                selectedGlobalDirOnModal: {
                    ...action.globalDir
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                pageLoaded: false,
            }
        case actionTypes.saveGlobalDirDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isFormDirty: true,
                isPatientSaving: false,
            }

        case actionTypes.deleteGlobalDirDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedGlobalDirOnModal: null,
                isAddNewDialogVisible: true,
                deletedDataSuccess: false,
            }
        case actionTypes.deleteGlobalDirDataSuccess:
            return {
                ...state,
                selectedGlobalDirOnModal: {
                    ...action.globalDir
                },
                isDialogLoading: false,
                isFormDirty: false,
                deletedDataSuccess: true,
                pageLoaded: false,
            }
        case actionTypes.deleteGlobalDirDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedGlobalDirOnModal: null,
                error: true,
                deletedDataSuccess: false,
            }
        // GlobalDir End

        case actionTypes.closeAddNewDialog:
            return {
                ...state,
                isAddNewDialogVisible: false,
                isAddNewDialogVisible2: false,
            }

        case actionTypes.showTextDialogStart:
            return {
                ...state,
                isShowTextDialogVisible: true
            }
        case actionTypes.showTextDialogSuccess:
            return {
                ...state,
                selectedDiagnosisText: {
                    ...action.diagnosisRequest
                },
                isDialogLoading: false,
            }
        case actionTypes.showTextDialogFail:
            return {
                ...state,
                selectedDiagnosisText: null,
                isDialogLoading: false,
            }
        case actionTypes.closeTextDialog:
            return {
                ...state,
                selectedDiagnosisText: null,
                isShowTextDialogVisible: false
            }
        case actionTypes.setInitialPageVisible:
            return {
                ...state,
                initialPageVisible: action.flag,
                accNbrsMaintInfo: null,
                patientAbsInfo: null,
                selectedPatientOnPopup: null,
                //selectedLabelPopInfo: null,
                selectedPatientOnGrid: null,
                selectedPatientIndex: 0,
            }
        case actionTypes.setInitialAbstractPages:
            return {
                ...state,
                initialAbstractPages: action.flag,
                isPatientSaving: false,
                isPatientLoading: false,
                waitingFor: false,
                /*
                accNbrsMaintInfo: null,
                patientAbsInfo: null,
                selectedPatientOnPopup: null,
                selectedPatientOnGrid: null,
                */
            }
        case actionTypes.v21CompatibilityFeatureStart:
            return {
                ...state,
                isV21CompatibilityVisible: true
            }
        case actionTypes.v21CompatibilityFeatureSuccess:
            return {
                ...state,
                v21CompatibilityFeature: {
                    ...action.fc
                },
                isDialogLoading: false,
            }
        case actionTypes.v21CompatibilityFeatureFail:
            return {
                ...state,
                v21CompatibilityFeature: null,
                isDialogLoading: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        case actionTypes.closeV21CompatibilityFeature:
            return {
                ...state,
                v21CompatibilityFeature: null,
                isV21CompatibilityVisible: false
            }

        case actionTypes.setRoutingPage:
            return {
                ...state,
                routingPage: action.dialogType
            }
        case actionTypes.setPatientMenu:
            console.log("isPatientMenu=" + action.value);
            return {
                ...state,
                isPatientMenu: action.value
            }
        // add new Tab into last
        case actionTypes.addTabItems:
            var newItems = [...state.tabItems];
            if (action.id) {
                let btnOnTab = { name: action.dialogType, id: action.id };
                if (newItems[0] == action.dialogType || newItems[0] == "ALPHA MENU" || newItems[0] == "Abstract") {
                    newItems.shift();
                    newItems.push(btnOnTab);
                } else if (newItems[0] && newItems[0].name && !newItems.find(obj => obj.name == action.dialogType))
                    newItems.push(btnOnTab);
            } else {
                if (!newItems.includes(action.dialogType))
                    newItems.push(action.dialogType);
                else if (newItems.find(obj => obj.name === action.dialogType)) {
                    var index = newItems.findIndex(obj => obj.name === action.dialogType);
                    newItems.splice(index, 1);
                }
            }

            return {
                ...state,
                tabItems: newItems
            }

        case actionTypes.closeOtherTabItems:
            /*
            var newItems = [...state.tabItems];
            if (!newItems.includes(action.dialogType))
                newItems.push(action.dialogType);
            */
            return {
                ...state,
                tabItems: []
            }

        case actionTypes.removeTabItems:
            var newItems = [];
            if (action.index === "2") {
                newItems = [...state.tabItems2]; // make a separate copy of the array
            } else {
                newItems = [...state.tabItems]; // make a separate copy of the array
            }

            if (newItems.includes(action.dialogType) && action.dialogType == dialogTypes.ALPHA_MENU) {
                var index = newItems.indexOf(action.dialogType);
                newItems.splice(index, 1);
            }

            //var lastDialog = newItems.slice(-1)[0];
            var firstTab_Lookup = dialogTypes.LOOKUP;
            if (action.index === "2") {
                firstTab_Lookup = dialogTypes.USER_LOGINS;
            } else {
                //
            }

            return {
                ...state,
                tabItems: newItems,
                currentDialog: firstTab_Lookup,//lastDialog
            }

        case actionTypes.selectTabItems:
            var newItems = [...state.tabItems];// make a separate copy of the array
            if (!newItems.includes(action.dialogType))
                newItems.push(action.dialogType);

            return {
                ...state,
                tabItems: newItems
            }
        case actionTypes.setAlphaUrl:
            return {
                ...state,
                alphaUrl: action.url,
                //selectedLabelPopInfo: null,
            }
        case actionTypes.setTabName:
            return {
                ...state,
                showingTabName: action.name
            }
        case actionTypes.setControlName:
            return {
                ...state,
                controlName: action.name
            }
        case actionTypes.setUserId:
            initialState["logedOnUserId"] = action.uid;
            return {
                ...state,
                logedOnUserId: action.uid
            }
        case actionTypes.setErsClientGroup:
            initialState["ersClientGroup"] = action.clientGroup;
            return {
                ...state,
                ersClientGroup: action.clientGroup
            }
        case actionTypes.setAllowSaveAnyway:
            initialState["allowSaveAnyway"] = action.flag;
            return {
                ...state,
                allowSaveAnyway: action.flag
            }
        case actionTypes.showSessionTimeoutMsg:
            initialState["showSessionTimeoutMsg"] = action.flag;
            return {
                ...state,
                showSessionTimeoutMsg: action.flag
            }
        case actionTypes.resetDeletedDataSuccess:
            initialState["deletedDataSuccess"] = action.flag;
            return {
                ...state,
                deletedDataSuccess: action.flag
            }

        case actionTypes.setChildrenOpening:
            initialState["childrenOpening"] = action.flag;
            return {
                ...state,
                childrenOpening: action.flag
            }
        case actionTypes.resetCurrentSelectedIndex:
            initialState["currentSelectedIndex"] = action.idx;
            return {
                ...state,
                currentSelectedIndex: action.idx
            }
        case actionTypes.storeToGlobal:
            initialState["storeToGlobal"] = action.fName;
            return {
                ...state,
                focusingFieldInGlobalStore: action.fName
            }
        case actionTypes.latestFocusedField:
            initialState["latestFocusedField"] = action.fId;
            return {
                ...state,
                latestFocusedField: action.fId
            }

        case actionTypes.setEnabledStatus:
            var tabItems = [...state.tabItems];
            var newStatuses = [...state.tabStatuses];

            if (tabItems.includes(action.dialogType)) {
                var index = tabItems.indexOf(action.dialogType);
                newStatuses[index] = action.flag != undefined ? action.flag : true;
            } else if (tabItems.find(obj => obj.name === action.dialogType)) {
                var index = tabItems.findIndex(obj => obj.name === action.dialogType);
                newStatuses[index] = action.flag != undefined ? action.flag : true;
            }

            return {
                ...state,
                tabStatuses: newStatuses
            }

        case actionTypes.setCurrentDialog:
            // Also set to enabled the selected tab
            var tabItems = [...state.tabItems];
            var newStatuses = [...state.tabStatuses];

            if (tabItems.includes(action.dialogType)) {
                var index = tabItems.indexOf(action.dialogType);
                newStatuses[index] = true;
            } else if (tabItems.find(obj => obj.name === action.dialogType)) {
                var index = tabItems.findIndex(obj => obj.name === action.dialogType);
                newStatuses[index] = true;
            }

            initialState["allowSaveAnyway"] = false;
            return {
                ...state,
                tabStatuses: newStatuses,
                currentDialog: action.dialogType,
                allowSaveAnyway: false,
            }
        case actionTypes.setPageLoaded:
            return {
                ...state,
                pageLoaded: action.value,
            }
        case actionTypes.setChildDialog:
            return {
                ...state,
                childDialog: action.dialogType,
            }
        case actionTypes.setChildDialogVisible:
            return {
                ...state,
                isChildDialogVisible: action.flag,
            }
        case actionTypes.setParentWindowVisible:
            return {
                ...state,
                isParentWindowVisible: action.flag
            }
        case actionTypes.setChangePwdDialogVisible:
            return {
                ...state,
                isChangePwdDialogVisible: action.flag,
            }
        case actionTypes.setTwoFactorAuthDialogVisible:
            return {
                ...state,
                isTwoFactorAuthDialogVisible: action.flag,
            }
        case actionTypes.clearSelectedMedicalRecord:
            initialState["selectedMedicalRecord"] = null;
            return {
                ...state,
                selectedMedicalRecord: null,
                selectedDiagnosisRecord: null,
                selectedPrimarySummary: null,
            }
        /*
        case actionTypes.setChildrenWindowVisible:
            return {
                ...state,
                isChildDialogVisible: action.flag,
                isAddNewDialogVisible: action.flag
            }
        */
        case actionTypes.clearFormInput:
        case actionTypes.deleteItemInput:
            return {
                ...state,
            }
        case actionTypes.resetHospitalInfo:
            return {
                ...state,
                selectedHospital: action.hospNbr,
            }
        case actionTypes.currentShowingState:
            return {
                ...state,
                currentShowingState: action.state,
            }
        case actionTypes.currentShowingHospitalVersion:
            return {
                ...state,
                currentShowingHospitalVersion: action.version,
            }

        // Staging START
        case actionTypes.getStagingDataStart:
            return {
                ...state,
                isPatientLoading: true,
                isPatientSaving: true,
            }
        case actionTypes.getStagingDataSuccess:
            return {
                ...state,
                selectedStagingRecord: {
                    ...action.staging
                },
                isPatientLoading: false,
                isPatientSaving: false,
            }
        case actionTypes.getStagingDataFail:
            return {
                ...state,
                isPatientLoading: false,
                isPatientSaving: false,
                selectedStagingRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveStagingDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveStagingDataSuccess:
            //#20220122 - START
            let _selectedMedicalRecord2 = initialState["selectedMedicalRecord"];
            let diagnosisFormData2 = {
                ..._selectedMedicalRecord2
            };
            Object.keys(diagnosisFormData2).map((t, k) => {
                if (t === "topoCode" || t === "histCode" || t === "ageDx") {
                    diagnosisFormData2[t + "_bak"] = diagnosisFormData2[t];
                    diagnosisFormData2[t + "Bak"] = diagnosisFormData2[t];
                } else if (t === "dxYear_bak" || t === "dxYearBak") {
                    const pr = initialState.selectedPrimarySummary;
                    diagnosisFormData2[t] = pr ? pr.diagnosisDateYear : "";
                }
            });
            initialState["selectedMedicalRecord"] = { ...diagnosisFormData2 };
            /*
            let _selectedPatient = initialState.selectedPatient;
            let patientMainForm = { ..._selectedPatient };
            Object.keys(patientMainForm).map((t) => {
                if (t === "sex_bak" || t === "sexBak") {
                    patientMainForm[t] = patientMainForm["sex"];
                }
            });
            initialState["selectedPatient"] = { ...patientMainForm };
            */
            //#20220122 - END

            return {
                ...state,
                selectedStagingRecord: {
                    ...action.staging
                },
                isPatientSaving: false,
                isFormDirty: false,
                selectedMedicalRecord: { ...diagnosisFormData2 },//#20220122
                //selectedPatient: { ...patientMainForm },
            }
        case actionTypes.saveStagingDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                isFormDirty: true,
                selectedStagingRecord: {
                    ...action.staging
                },
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        case actionTypes.clearSelectedStagingRecord:
            return {
                ...state,
                selectedStagingRecord: null,
            }
        // Staging END

        // Validation START
        case actionTypes.checkCodeSelectDataStart:
            return {
                ...state,
                currentSelectedField: action.field,
                currentSelectedIndex: action.index
            }
        case actionTypes.checkCodeSelectDataSuccess:
            return {
                ...state,
                commonLookupItem: {
                    ...action.commonLookupItem
                },
                commonLookupItem2: {
                    ...action.commonLookupItem
                },
                //currentSelectedField: action.field,
                //currentSelectedIndex: action.index
            }
        case actionTypes.checkCodeSelectDataFail:
            return {
                ...state,
                commonLookupItem: null,
                commonLookupItem2: null,
                //currentSelectedField: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        case actionTypes.clearCommonLookupItem:
            initialState["currentSelectedIndex"] = -1;
            return {
                ...state,
                commonLookupItem: null,
                //currentSelectedField: null,
                currentSelectedIndex: -1
            }
        case actionTypes.clearCommonLookupItem2:
            initialState["currentSelectedIndex"] = -1;
            return {
                ...state,
                commonLookupItem2: null,
                //currentSelectedField: null,
                currentSelectedIndex: -1
            }
        case actionTypes.clearAddNewUserFlag:
            return {
                ...state,
                isAddNewUser: false,
            }
        // Validation END

        // Treatment START
        case actionTypes.getTreatmentDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getTreatmentDataSuccess:
            return {
                ...state,
                selectedTreatmentRecord: {
                    ...action.treatment
                },
                selectedSurgeryRecord: {
                    ...action.surgery
                },
                isPatientLoading: false,
            }
        case actionTypes.getTreatmentDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedTreatmentRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveTreatmentDataStart:
            return {
                ...state,
                isPatientSaving: true,
                isFormDirty: true,
            }
        case actionTypes.saveTreatmentDataSuccess:
            return {
                ...state,
                selectedTreatmentRecord: {
                    ...action.treatment
                },
                selectedSurgeryRecord: {
                    ...action.surgery
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveTreatmentDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // Treatment END

        // Surgery START
        case actionTypes.getSurgeryDataStart:
            return {
                ...state,
                isChildrenLoading: true,
            }
        case actionTypes.getSurgeryDataSuccess:
            return {
                ...state,
                /*
                selectedTreatmentRecord: {
                    ...action.treatment
                },
                */
                selectedSurgeryRecord: {
                    ...action.surgery
                },
                isChildrenLoading: false,
                isFormDirty: false,
            }
        case actionTypes.getSurgeryDataFail:
            return {
                ...state,
                isChildrenLoading: false,
                selectedSurgeryRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveSurgeryDataStart:
            return {
                ...state,
                isChildrenSaving: true,
                selectedSurgeryRecord: {
                    ...action.surgery
                },
            }
        case actionTypes.saveSurgeryDataSuccess:
            return {
                ...state,
                /*
                selectedTreatmentRecord: {
                    ...action.treatment
                },
                selectedSurgeryRecord: {
                    ...action.surgery
                },
                */
                isChildrenSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveSurgeryDataFail:
            return {
                ...state,
                isChildrenSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // Surgery END

        // Outcomes START
        case actionTypes.getOutcomesDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getOutcomesDataSuccess:
            return {
                ...state,
                selectedOutcomesRecord: {
                    ...action.outcomes
                },
                isPatientLoading: false,
            }
        case actionTypes.getOutcomesDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedOutcomesRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveOutcomesDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveOutcomesDataSuccess:
            return {
                ...state,
                selectedOutcomesRecord: {
                    ...action.outcomes
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveOutcomesDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // Outcomes END

        // Case Admin START
        case actionTypes.getCaseAdminDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getCaseAdminDataSuccess:
            return {
                ...state,
                selectedCaseAdminRecord: {
                    ...action.caseadmin
                },
                isPatientLoading: false,
            }
        case actionTypes.getCaseAdminDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedCaseAdminRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveCaseAdminDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveCaseAdminDataSuccess:
            return {
                ...state,
                selectedCaseAdminRecord: {
                    ...action.caseadmin
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveCaseAdminDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // Case Admin END

        // Clinical START
        case actionTypes.getClinicalDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getClinicalDataSuccess:
            return {
                ...state,
                selectedClinicalRecord: {
                    ...action.clinical
                },
                isPatientLoading: false,
            }
        case actionTypes.getClinicalDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedClinicalRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveClinicalDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveClinicalDataSuccess:
            return {
                ...state,
                selectedClinicalRecord: {
                    ...action.clinical
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveClinicalDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // Clinical END

        // Research START
        case actionTypes.getResearchDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getResearchDataSuccess:
            return {
                ...state,
                selectedResearchRecord: {
                    ...action.research
                },
                isPatientLoading: false,
            }
        case actionTypes.getResearchDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedResearchRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveResearchDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveResearchDataSuccess:
            return {
                ...state,
                selectedResearchRecord: {
                    ...action.research
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveResearchDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // Research END

        case actionTypes.deletePrimaryItemStart:
            return {
                ...state,
                //isPrimaryDeletion: true
            }
        case actionTypes.deletePrimaryItemSuccess:
            return {
                ...state,
                error: false
            }
        case actionTypes.deletePrimaryItemFail:
            return {
                ...state,
                //isPrimaryDeletion: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        // ADD - 2021/02/24 - START
        // User Defined Maintenance
        case actionTypes.getUserDefinedDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getUserDefinedDataSuccess:
            return {
                ...state,
                selectedUserDefinedRecord: {
                    ...action.userDefined
                },
                isPatientLoading: false,
            }
        case actionTypes.getUserDefinedDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedUserDefinedRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveUserDefinedDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveUserDefinedDataSuccess:
            return {
                ...state,
                selectedUserDefinedRecord: {
                    ...action.userDefined
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveUserDefinedDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // ADD - 2021/02/24 - END

        // ADD - 2021/02/25 - START
        // CPM Maintenance Maintenance - COC
        case actionTypes.getCpmCocDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getCpmCocDataSuccess:
            return {
                ...state,
                selectedCocRecord: {
                    ...action.coc
                },
                isPatientLoading: false,
                isFormDirty: false,
            }
        case actionTypes.getCpmCocDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedCocRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveCpmCocDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveCpmCocDataSuccess:
            return {
                ...state,
                selectedCocRecord: {
                    ...action.coc
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveCpmCocDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // BPM Maintenance - Napbc
        case actionTypes.getBpmNapbcDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getBpmNapbcDataSuccess:
            return {
                ...state,
                selectedNapbcRecord: {
                    ...action.napbc
                },
                isPatientLoading: false,
            }
        case actionTypes.getBpmNapbcDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedNapbcRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveBpmNapbcDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveBpmNapbcDataSuccess:
            return {
                ...state,
                selectedNapbcRecord: {
                    ...action.napbc
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveBpmNapbcDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // RPM Maintenance - RectalProgram
        case actionTypes.getRectalProgramDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getRectalProgramDataSuccess:
            return {
                ...state,
                selectedRectalProgramRecord: {
                    ...action.rectalProgram
                },
                isPatientLoading: false,
            }
        case actionTypes.getRectalProgramDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedRectalProgramRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveRectalProgramDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveRectalProgramDataSuccess:
            return {
                ...state,
                selectedRectalProgramRecord: {
                    ...action.rectalProgram
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveRectalProgramDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // ADD - 2021/02/25 - END

        // ADD - 2021/03/08 - START
        // State Specific Maintenance
        case actionTypes.getStateFieldsDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getStateFieldsDataSuccess:
            return {
                ...state,
                selectedStateFieldsRecord: {
                    ...action.stateFields
                },
                isPatientLoading: false,
            }
        case actionTypes.getStateFieldsDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedStateFieldsRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveStateFieldsDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveStateFieldsDataSuccess:
            return {
                ...state,
                selectedStateFieldsRecord: {
                    ...action.stateFields
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveStateFieldsDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // ADD - 2021/03/08 - END

        // ADD - 2021/03/25 - START
        // Retired Maintenance
        case actionTypes.getRetiredDataStart:
            return {
                ...state,
                isPatientLoading: true,
            }
        case actionTypes.getRetiredDataSuccess:
            return {
                ...state,
                selectedRetiredRecord: {
                    ...action.retired
                },
                isPatientLoading: false,
            }
        case actionTypes.getRetiredDataFail:
            return {
                ...state,
                isPatientLoading: false,
                selectedRetiredRecord: null,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }

        case actionTypes.saveRetiredDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveRetiredDataSuccess:
            return {
                ...state,
                selectedRetiredRecord: {
                    ...action.retired
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveRetiredDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true,
                errorMsgOfAbstracts: action.errorMsgOfAbstracts,
                showMsgOfAbstracts: action.showMsgOfAbstracts,
            }
        // ADD - 2021/03/25 - END

        // ============================ SYSTEM ADMINISTRATION ============================
        case actionTypes.selectedUserOnGrid:
            return {
                ...state,
                selectedUserOnGrid: action.user,
                isDialogLoading: false,
                isFormDirty: false,
                isAddNewUser: false,
            }
        case actionTypes.getUserInfoDataStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true,
                //isAddNewUser: action.email === "" ? true : false,
                isAddNewUser: true
            }
        case actionTypes.getUserInfoDataSuccess:
            return {
                ...state,
                selectedUserInfo: {
                    ...action.userInfo
                },
                isDialogLoading: false,
                isPatientSaving: false,
                isAddNewUser: true,//action.userInfo.Userid === null || action.userInfo.userid === null ? true : false,
            }
        case actionTypes.getUserInfoDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedUserInfo: null,
                error: true,
                isPatientSaving: false,
                isAddNewUser: false,
            }
        case actionTypes.saveUserInfoDataStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.saveUserInfoDataSuccess:
            return {
                ...state,
                selectedUserInfo: {
                    ...action.userInfo
                },
                isPatientSaving: false,
                isDialogLoading: false,
                isFormDirty: false,
                pageLoaded: false,//reset this value to load data on parent page
                isAddNewUser: false,
            }
        case actionTypes.saveUserInfoDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.message,
                isPatientSaving: false,
                isAddNewUser: false,
            }

        case actionTypes.deleteUserInfoDataStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.deleteUserInfoDataSuccess:
            return {
                ...state,
                selectedUserInfo: {
                    ...action.userInfo
                },
                isDialogLoading: false,
                isPatientSaving: false,
                pageLoaded: false,//reset this value to load data on parent page
                isAddNewUser: true,
                selectedUserOnGrid: null
            }
        case actionTypes.deleteUserInfoDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedUserInfo: null,
                error: true,
                isPatientSaving: false,
                pageLoaded: false,//reset this value to load data on parent page
                isAddNewUser: false,
            }

        case actionTypes.changePasswordDataStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true,
                //isChangePwdDialogVisible: false,
            }
        case actionTypes.changePasswordDataSuccess:
            return {
                ...state,
                selectedUserInfo: {
                    ...action.userInfo
                },
                isDialogLoading: false,
                isFormDirty: false,
                //isChangePwdDialogVisible: true,
                isPatientSaving: false,
                isParentWindowVisible: false,
                error: false,
                errorMessage: null,
                //
                passwordHadChanged: true,
                isChangePwdDialogVisible: false,
            }
        case actionTypes.changePasswordDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.message,
                isPatientSaving: false
            }
        case actionTypes.clearErrorMessage:
            return {
                ...state,
                error: false,
                errorMessage: null,
            }
        case actionTypes.setErrorMessage:
            return {
                ...state,
                error: true,
                errorMessage: action.errMsg,
            }

        case actionTypes.twoFactorAuthDataStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.twoFactorAuthDataSuccess:
            return {
                ...state,
                selectedUserInfo: {
                    ...action.userInfo
                },
                isDialogLoading: false,
                isFormDirty: false,
                //isTwoFactorAuthDialogVisible: false,
                isPatientSaving: false
            }
        case actionTypes.twoFactorAuthDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }
        // ============================= User Security ========================================
        case actionTypes.getUserSecurityDataStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true,
                isAddNewDialogVisible: false,
                error: false,
            }
        case actionTypes.getUserSecurityDataSuccess:
            return {
                ...state,
                selectedUserSecurityInfo: {
                    ...action.userSecurityInfo
                },
                isDialogLoading: false,
                isPatientSaving: false,
                error: false,
                isAddNewDialogVisible: true,
            }
        case actionTypes.getUserSecurityDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedUserSecurityInfo: null,
                error: true,
                isPatientSaving: false,
            }

        case actionTypes.saveUserSecurityDataStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.saveUserSecurityDataSuccess:
            return {
                ...state,
                selectedUserSecurityInfo: {
                    ...action.userSecurityInfo
                },
                isPatientSaving: false,
                isDialogLoading: false,
                isFormDirty: false,
                pageLoaded: false,
                isAddNewDialogVisible: false,
                error: false,
            }
        case actionTypes.saveUserSecurityDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.message,
                isPatientSaving: false,
            }

        // ============================ PASSWORD POLICY MAINTENANCE ============================
        case actionTypes.loadPwdPolicyInfoStart:
            return {
                ...state,
                isDialogLoading: true,
                P2Info: null,
                isPatientSaving: true,
                selectedPwdPolicyInfo: null
            }
        case actionTypes.loadPwdPolicyInfoSuccess:
            return {
                ...state,
                selectedPwdPolicyInfo: {
                    ...action.p2Info
                },
                isDialogLoading: false,
                isPatientSaving: false
            }
        case actionTypes.loadPwdPolicyInfoFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedPwdPolicyInfo: null,
                error: true,
                isPatientSaving: false
            }
        case actionTypes.savePwdPolicyInfoStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.savePwdPolicyInfoSuccess:
            return {
                ...state,
                selectedPwdPolicyInfo: {
                    ...action.p2Info
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false
            }
        case actionTypes.savePwdPolicyInfoFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }

        case actionTypes.deletePwdPolicyInfoStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedPwdPolicyInfo: null,
                isPatientSaving: true
            }
        case actionTypes.deletePwdPolicyInfoSuccess:
            return {
                ...state,
                selectedPwdPolicyInfo: {
                    ...action.p2Info
                },
                isDialogLoading: false,
                isPatientSaving: false
            }
        case actionTypes.deletePwdPolicyInfoFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedPwdPolicyInfo: null,
                error: true,
                isPatientSaving: false
            }

        case actionTypes.showLockedUserStart:
            return {
                ...state,
                isDialogLoading: true,
                lockedUsers: null,
                isPatientSaving: true
            }
        case actionTypes.showLockedUserSuccess:
            return {
                ...state,
                lockedUsers: {
                    ...action.userList
                },
                isDialogLoading: false,
                isPatientSaving: false
            }
        case actionTypes.showLockedUserFail:
            return {
                ...state,
                isDialogLoading: false,
                lockedUsers: null,
                error: true,
                isPatientSaving: false
            }
        case actionTypes.clearLockedUserStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.clearLockedUserSuccess:
            return {
                ...state,
                lockedUsers: {
                    ...action.userList
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false
            }
        case actionTypes.clearLockedUserFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }


        case actionTypes.confirmSecurityCodeStart:
            return {
                ...state,
                isDialogLoading: true,
                confirmSecurityCode: false,
                isPatientSaving: true
            }
        case actionTypes.confirmSecurityCodeSuccess:
            return {
                ...state,
                user2Fa: action.user2Fa,
                isDialogLoading: false,
                isPatientSaving: false
            }
        case actionTypes.confirmSecurityCodeFail:
            return {
                ...state,
                isDialogLoading: false,
                user2Fa: null,
                error: true,
                isPatientSaving: false
            }
        case actionTypes.setTfaPopupTitle:
            return {
                ...state,
                tfaPopupTitle: action.title,
                tfaConfigTitle: action.title === "" ? true : false,
            }
        case actionTypes.getTokenOnSessionSuccess:
            return {
                ...state,
                tokenOnSession: action.token
            }
        case actionTypes.getTokenOnSessionFail:
            return {
                ...state,
                tokenOnSession: null
            }
        case actionTypes.showOrHideLoading:
            return {
                ...state,
                isPatientSaving: action.flag
            }
        case actionTypes.showingHelpInfo:
            return {
                ...state,
                showingHelpInfo: action.flag
            }
        case actionTypes.updateDiagnosisInfo:
            var _selectedMedicalRecord = initialState["selectedMedicalRecord"];

            var prevStateVal = _selectedMedicalRecord && _selectedMedicalRecord[action.name + "_bak"] ? _selectedMedicalRecord[action.name + "_bak"] : null;
            if (!prevStateVal) {
                prevStateVal = _selectedMedicalRecord && _selectedMedicalRecord[action.name] ? _selectedMedicalRecord[action.name] : null;
            }

            _selectedMedicalRecord = {
                ..._selectedMedicalRecord,
                //[action.name]: action.value,
                [action.name + "_bak"]: prevStateVal,
            };

            initialState["selectedMedicalRecord"] = _selectedMedicalRecord;
            return {
                ...state,
                selectedMedicalRecord: _selectedMedicalRecord,
            }
        // ===============================================================================
        case actionTypes.resetAllStoringOfUser:
            return {
                ...state,
                selectedPwdPolicyInfo: null,
                selectedUserInfo: null,
                rapidAbstract: false,//
                inquiringMode: false,//

                selectedPatient: null,
                isPatientLoading: false,
                isPatientSaving: false,
                selectedMedicalRecord: null,
                selectedDiagnosisRecord: null,//#20211217
                currentDialog: dialogTypes.LOOKUP,
                isAddNew: true,
                patientId: 0,
                error: false,
                errorMessage: null,
                selectedPrimarySummary: null,
                isDialogLoading: false,
                isAddNewDialogVisible: false,
                isAddNewDialogVisible2: false,
                isShowTextDialogVisible: false,
                selectedHospital: null,
                selectedHospitalOnModal: null,
                selectedPhysicianOnModal: null,
                selectedRegistrarOnModal: null,

                currentSelectedField: null,
                commonLookupItem: null,
                currentSelectedIndex: -1,

                commonLookupItem2: null,

                isChildDialogVisible: false,
                childDialog: dialogTypes.SURGERY,
                isChildrenLoading: false,
                isChildrenSaving: false,

                isFormDirty: true,

                isParentWindowVisible: false,
                isChangePwdDialogVisible: false,
                isTwoFactorAuthDialogVisible: true,
                isAuth2FaDialogVisible: false,

                alphaSystemUrlBaseUrl: ConfigData.ERS_SITE_URL,
                routingPage: dialogTypes.LOOKUP,
                tabItems: [dialogTypes.LOOKUP],

                //tabStatuses: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                tabStatuses: [true, false, false, false, false, false, false, false, false, false, false, false, false, false, false],

                isPatientMenu: false,
                pageLoaded: false,
                commonColors: null,
                availableItems: null,
                availableMenuGroups: null,
                alphaUrl: null,
                showingTabName: null,
                selectedPatientOnGrid: null,
                selectedPatientIndex: 0,
                selectedCocRecord: null,
                currentShowingState: null,
                currentShowingHospitalVersion: null,
                logedOnUserId: null,
                ersClientGroup: null,

                // Security Maintenance
                selectedUserOnGrid: null,
                selectedUserInfo: null,
                selectedUser: null,
                isSecurityAdminLoading: false,
                isSecurityAdminSaving: false,
                selectedUserRecord: null,
                isAddNewUser: false,
                userId: 0,

                selectedUserSecurityInfo: null,

                user2Fa: null,
                confirmSecurityCode: false,

                tabItems2: [dialogTypes.USER_LOGINS],
                //lockedUsers: null,
                tfaPopupTitle: "Configure ",
                tfaConfigTitle: false,
                tokenOnSession: null,
                changedUser: false,

                // Set this flag to allow the screen ignore the validations and save anyway.
                allowSaveAnyway: false,
                childrenOpening: false,
                showingHelpInfo: false,
                showSessionTimeoutMsg: false,
                deletedDataSuccess: false,
                initialPageVisible: false,

                //
                selectedRfLabelsPopInfo: null,//Admin->Report File
                importConfigInfo: null,
                selectedLabelPopInfo: null,
                selectedReportLabelsInfo: null,
            }

        case actionTypes.clearAllScreenDataOnTab:
            return {
                ...state,
                //
                selectedRfLabelsPopInfo: null,//Admin->Report File
                importConfigInfo: null,
                previewing: false,
                lettersMaintInfo: null,
                selectedPatientOnPopup: null,
                followUpLettersInfo: null,
                //selectedHospital: null, REA_Truc::Fixed error of the Dashboard Setting screen
            }

        // SelectAPop Start
        case actionTypes.selectAPopLabelDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                //isAddNewDialogVisible: !action.isNotExist,
                ///isAddNewDialogVisible2: action.isNotExist,
                error: false,
                showReport: false,
                isDownloadFile: false,
                selectedReportLabelsInfo: null,
            }
        case actionTypes.selectAPopLabelDataSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelInfo
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.selectAPopLabelDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedLabelPopInfo: null,
                error: true
            }
        case actionTypes.saveAPopLabelDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.saveAPopLabelDataSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm,
                    bypass: action.bypass,
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false,
            }
        case actionTypes.saveAPopLabelDataFail:
            return {
                ...state,
                isDialogLoading: false,
                isFormDirty: true,
                isPatientSaving: false,
                error: true,
                errorMessage: action.errMsg
            }

        case actionTypes.deleteAPopLabelDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                //isAddNewDialogVisible: true
            }
        case actionTypes.deleteAPopLabelDataSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                //selectedLabelPopInfo: null,
                isDialogLoading: false,
                error: false,
                errorMessage: null,
            }
        case actionTypes.deleteAPopLabelDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.errorMessage,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                //selectedLabelPopInfo: null,
            }
        // SelectAPop End

        // Clear Populations Start
        case actionTypes.deletePopulationLabelsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                //isAddNewDialogVisible: true
            }
        case actionTypes.deletePopulationLabelsDataSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                //selectedLabelPopInfo: null,
                isDialogLoading: false,
                error: false,
                errorMessage: null,
            }
        case actionTypes.deletePopulationLabelsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.errorMessage,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                //selectedLabelPopInfo: null,
            }
        // Clear Populations End

        case actionTypes.runGraphForPopulationStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.runGraphForPopulationSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
            }
        case actionTypes.runGraphForPopulationFail:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
            }

        // Generate Physicians Roster Start
        case actionTypes.generatePhysiciansRosterDataStart:
            return {
                ...state,
                isDialogLoading: true,
                physiciansRosterInfo: null,
                error: false,
                initialPageVisible: true,
            }
        case actionTypes.generatePhysiciansRosterDataSuccess:
            return {
                ...state,
                physiciansRosterInfo: {
                    ...action.physiciansRoster
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generatePhysiciansRosterDataFail:
            return {
                ...state,
                isDialogLoading: false,
                physiciansRosterInfo: null,
                error: true
            }
        case actionTypes.runPhysiciansRosterStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.runPhysiciansRosterSuccess:
            return {
                ...state,
                physiciansRosterInfo: {
                    ...action.physiciansRoster
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
            }
        case actionTypes.runPhysiciansRosterFail:
            return {
                ...state,
                physiciansRosterInfo: {
                    ...action.physiciansRoster
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
            }

        // Initialize Accession Numbers Start
        case actionTypes.loadAccNbrsMaintDataStart:
            return {
                ...state,
                isDialogLoading: true,
                accNbrsMaintInfo: null,
                error: false,
                isAddNewDialogVisible: true,
                initialPageVisible: true,
            }
        case actionTypes.loadAccNbrsMaintDataSuccess:
            return {
                ...state,
                accNbrsMaintInfo: {
                    ...action.accNbrs
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.loadAccNbrsMaintDataFail:
            return {
                ...state,
                isDialogLoading: false,
                accNbrsMaintInfo: null,
                error: true
            }
        case actionTypes.saveAccNbrsMaintStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.saveAccNbrsMaintSuccess:
            return {
                ...state,
                accNbrsMaintInfo: {
                    ...action.accNbrs
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false
            }
        case actionTypes.saveAccNbrsMaintFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }
        case actionTypes.deleteAccNbrsMaintStart:
            return {
                ...state,
                isDialogLoading: true,
                accNbrsMaintInfo: null,
                isPatientSaving: true
            }
        case actionTypes.deleteAccNbrsMaintSuccess:
            return {
                ...state,
                accNbrsMaintInfo: {
                    ...action.accNbrs
                },
                isDialogLoading: false,
                isPatientSaving: false
            }
        case actionTypes.deleteAccNbrsMaintFail:
            return {
                ...state,
                isDialogLoading: false,
                accNbrsMaintInfo: null,
                error: true,
                isPatientSaving: false
            }

        //!IMPORTANT for pages have initialPageVisible config
        case actionTypes.loadAccNbrsMaintInitData:
            return {
                ...state,
                isDialogLoading: false,
                accNbrsMaintInfo: null,
                genEditsInfo: null,
                dashboardSettingsInfo: null,
                error: false,
                isPatientSaving: false
            }

        // Maintain Follow-up Order Start
        case actionTypes.loadFollowUpOrderDataStart:
            return {
                ...state,
                isDialogLoading: true,
                followUpOrderInfo: null,
                error: false,
                isAddNewDialogVisible: true,
                //isV21CompatibilityVisible: true,
                initialPageVisible: true,

                patientAbsInfo: null,
                selectedPatientOnPopup: null,
                //selectedLabelPopInfo: null,
                selectedPatientOnGrid: null,
                selectedPatientIndex: 0,
            }
        case actionTypes.loadFollowUpOrderDataSuccess:
            return {
                ...state,
                followUpOrderInfo: {
                    ...action.followUpOrder
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.loadFollowUpOrderDataFail:
            return {
                ...state,
                isDialogLoading: false,
                followUpOrderInfo: null,
                error: true
            }
        case actionTypes.saveFollowUpOrderStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.saveFollowUpOrderSuccess:
            return {
                ...state,
                followUpOrderInfo: {
                    ...action.followUpOrder
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false
            }
        case actionTypes.saveFollowUpOrderFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }
        case actionTypes.deleteFollowUpOrderStart:
            return {
                ...state,
                isDialogLoading: true,
                followUpOrderInfo: null,
                isPatientSaving: true
            }
        case actionTypes.deleteFollowUpOrderSuccess:
            return {
                ...state,
                followUpOrderInfo: {
                    ...action.followUpOrder
                },
                isDialogLoading: false,
                isPatientSaving: false
            }
        case actionTypes.deleteFollowUpOrderFail:
            return {
                ...state,
                isDialogLoading: false,
                followUpOrderInfo: null,
                error: true,
                isPatientSaving: false
            }

        // GenEdits
        case actionTypes.loadGenEditsOnInitial:
            return {
                ...state,
                isDialogLoading: false,
                genEditsInfo: null,
                dashboardSettingsInfo: null,
                error: false,
                isPatientSaving: false
            }

        case actionTypes.loadGenEditsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                genEditsInfo: null,
                error: false,
                isAddNewDialogVisible: true,
                initialPageVisible: true,
            }
        case actionTypes.loadGenEditsDataSuccess:
            return {
                ...state,
                genEditsInfo: {
                    ...action.genEdits
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.loadGenEditsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                genEditsInfo: null,
                error: true
            }
        case actionTypes.saveGenEditsStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.saveGenEditsSuccess:
            return {
                ...state,
                genEditsInfo: {
                    ...action.genEdits
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false
            }
        case actionTypes.saveGenEditsFail:
            return {
                ...state,
                isDialogLoading: false,
                isFormDirty: true,
                error: true,
                isPatientSaving: false
            }

        // Dashboard Settings
        case actionTypes.loadDashboardSettingsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                dashboardSettingsInfo: null,
                error: false,
                isAddNewDialogVisible: true,
                initialPageVisible: true,
            }
        case actionTypes.loadDashboardSettingsDataSuccess:
            return {
                ...state,
                dashboardSettingsInfo: {
                    ...action.dashboardSettings
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.loadDashboardSettingsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                dashboardSettingsInfo: null,
                error: true
            }
        case actionTypes.saveDashboardSettingsStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true
            }
        case actionTypes.saveDashboardSettingsSuccess:
            return {
                ...state,
                dashboardSettingsInfo: {
                    ...action.dashboardSettings
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false
            }
        case actionTypes.saveDashboardSettingsFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }

        // Delete Abstract
        case actionTypes.loadPatientAbsInfoDataStart:
            return {
                ...state,
                isDialogLoading: true,
                patientAbsInfo: null,
                selectedPatientOnPopup: null,
                selectedPatientOnGrid: null,
                error: false,
                isAddNewDialogVisible: true,
                initialPageVisible: true,
                //selectedPatientOnGrid: null,
            }
        case actionTypes.loadPatientAbsInfoDataSuccess:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.patientAbsInfo
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false
            }
        case actionTypes.loadPatientAbsInfoDataFail:
            return {
                ...state,
                isDialogLoading: false,
                patientAbsInfo: null,
                error: true
            }
        case actionTypes.deletePatientAbsInfoStart:
            return {
                ...state,
                isDialogLoading: true,
                patientAbsInfo: null,
                isPatientSaving: true,
                isFormDirty: true,
                hasPs: action.hasPs,
            }
        case actionTypes.deletePatientAbsInfoSuccess:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.patientAbsInfo
                },
                isDialogLoading: false,
                isPatientSaving: false,
                deletedDataSuccess: true,
                isFormDirty: false,
                hasPs: action.hasPs,
            }
        case actionTypes.deletePatientAbsInfoFail:
            return {
                ...state,
                isDialogLoading: false,
                patientAbsInfo: null,
                error: true,
                isPatientSaving: false,
                hasPs: action.hasPs,
            }

        //
        case actionTypes.copyItemInputStart:
            return {
                ...state,
                isDialogLoading: true,
                patientAbsInfo: null,
                isPatientSaving: true
            }
        case actionTypes.copyItemInputSuccess:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.patientAbsInfo
                },
                isDialogLoading: false,
                isPatientSaving: false,
                deletedDataSuccess: true,
            }
        case actionTypes.copyItemInputFail:
            return {
                ...state,
                isDialogLoading: false,
                patientAbsInfo: null,
                error: true,
                isPatientSaving: false
            }

        // Report File Start
        case actionTypes.selectRfLabelsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                error: false,
                errorMessage: null,
            }
        case actionTypes.selectRfLabelsDataSuccess:
            return {
                ...state,
                selectedRfLabelsPopInfo: {
                    ...action.rfLabelsInfo
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.selectRfLabelsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedRfLabelsPopInfo: null,
                error: true
            }
        case actionTypes.saveRfLabelsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.saveRfLabelsDataSuccess:
            return {
                ...state,
                selectedRfLabelsPopInfo: {
                    ...action.rfLabelsForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
            }
        case actionTypes.saveRfLabelsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isFormDirty: true,
                isPatientSaving: false,
                selectedRfLabelsPopInfo: {
                    ...action.rfLabelsForm
                },
            }

        case actionTypes.deleteRfLabelsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedRfLabelsPopInfo: null,
            }
        case actionTypes.deleteRfLabelsDataSuccess:
            return {
                ...state,
                selectedRfLabelsPopInfo: {
                    ...action.rfLabelsForm
                },
                isDialogLoading: false,
                error: false,
                errorMessage: null,
            }
        case actionTypes.deleteRfLabelsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.errorMessage,
                selectedRfLabelsPopInfo: {
                    ...action.rfLabelsForm
                },
            }
        // Report File End

        // List Pop Start
        case actionTypes.clearAllExportData:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                error: false,
                showReport: false,
                isDownloadFile: false,
                selectedReportLabelsInfo: null,
            }
        case actionTypes.selectReportLabelsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                error: false,
                showReport: false,
                isDownloadFile: false,
                selectedReportLabelsInfo: null,
            }
        case actionTypes.selectReportLabelsDataSuccess:
            return {
                ...state,
                selectedReportLabelsInfo: {
                    ...action.reportLabelsInfo
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false
            }
        case actionTypes.selectReportLabelsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedReportLabelsInfo: null,
                error: true
            }
        case actionTypes.saveReportLabelsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showReport: false,
                isDownloadFile: false,
            }
        case actionTypes.saveReportLabelsDataSuccess:
            return {
                ...state,
                selectedReportLabelsInfo: {
                    ...action.reportLabelsForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showReport: action.reportLabelsForm && action.reportLabelsForm.endTime && action.reportLabelsForm.outputDevice == "Screen" ? true : false,
                isDownloadFile: action.reportLabelsForm && action.reportLabelsForm.endTime && action.reportLabelsForm.outputDevice !== "Screen" ? true : false,
            }
        case actionTypes.saveReportLabelsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isFormDirty: true,
                isPatientSaving: false,
                selectedReportLabelsInfo: {
                    ...action.reportLabelsForm
                },
                errorMessage: action.errMsg
            }

        case actionTypes.deleteReportLabelsDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedReportLabelsInfo: null,
            }
        case actionTypes.deleteReportLabelsDataSuccess:
            return {
                ...state,
                selectedReportLabelsInfo: {
                    ...action.reportLabelsForm
                },
                isDialogLoading: false,
                error: false,
                errorMessage: null,
            }
        case actionTypes.deleteReportLabelsDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.errorMessage,
                selectedReportLabelsInfo: {
                    ...action.reportLabelsForm
                },
            }
        // List Pop End

        //...
        case actionTypes.runAccessionRegisterStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runAccessionRegisterSuccess:
            return {
                ...state,
                accessionRegisterInfo: {
                    //...action.data
                    ...action.accessionRegister
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runAccessionRegisterFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                accessionRegisterErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        /*This version from Physician Roster*/
        case actionTypes.generateAccessionRegisterDataStart:
            return {
                ...state,
                isDialogLoading: true,
                accessionRegisterInfo: null,
                error: false,
                //initialPageVisible: true,
            }
        case actionTypes.generateAccessionRegisterDataSuccess:
            return {
                ...state,
                accessionRegisterInfo: {
                    ...action.accessionRegister
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateAccessionRegisterDataFail:
            return {
                ...state,
                isDialogLoading: false,
                accessionRegisterInfo: null,
                error: true
            }

        case actionTypes.runRequestLogStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runRequestLogSuccess:
            return {
                ...state,
                requestLogInfo: {
                    ...action.requestLog
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runRequestLogFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                requestLogErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateRequestLogDataStart:
            return {
                ...state,
                isDialogLoading: true,
                requestLogInfo: null,
                error: false,
                initialPageVisible: true,
            }
        case actionTypes.generateRequestLogDataSuccess:
            return {
                ...state,
                requestLogInfo: {
                    ...action.requestLog
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateRequestLogDataFail:
            return {
                ...state,
                isDialogLoading: false,
                requestLogInfo: null,
                error: true
            }

        //Site Distribution
        case actionTypes.runSiteDistributionStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runSiteDistributionSuccess:
            return {
                ...state,
                siteDistributionInfo: {
                    ...action.siteDistribution
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runSiteDistributionFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                siteDistributionErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateSiteDistributionDataStart:
            return {
                ...state,
                isDialogLoading: true,
                siteDistributionInfo: null,
                error: false,
                //initialPageVisible: true,
            }
        case actionTypes.generateSiteDistributionDataSuccess:
            return {
                ...state,
                siteDistributionInfo: {
                    ...action.siteDistribution
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateSiteDistributionDataFail:
            return {
                ...state,
                isDialogLoading: false,
                siteDistributionInfo: null,
                error: true
            }

        //Treatment Combination
        case actionTypes.runTreatmentCombinationStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runTreatmentCombinationSuccess:
            return {
                ...state,
                treatmentCombinationInfo: {
                    ...action.treatmentCombination
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runTreatmentCombinationFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                treatmentCombinationErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateTreatmentCombinationDataStart:
            return {
                ...state,
                isDialogLoading: true,
                treatmentCombinationInfo: null,
                error: false,
                initialPageVisible: true,
            }
        case actionTypes.generateTreatmentCombinationDataSuccess:
            return {
                ...state,
                treatmentCombinationInfo: {
                    ...action.treatmentCombination
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateTreatmentCombinationDataFail:
            return {
                ...state,
                isDialogLoading: false,
                treatmentCombinationInfo: null,
                error: true
            }

        //Productivity Reports Screen
        case actionTypes.runProductivityStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runProductivitySuccess:
            return {
                ...state,
                productivityInfo: {
                    ...action.productivity
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runProductivityFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                productivityErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateProductivityDataStart:
            return {
                ...state,
                isDialogLoading: true,
                productivityInfo: null,
                error: false,
                initialPageVisible: true,
            }
        case actionTypes.generateProductivityDataSuccess:
            return {
                ...state,
                productivityInfo: {
                    ...action.productivity
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateProductivityDataFail:
            return {
                ...state,
                isDialogLoading: false,
                productivityInfo: null,
                error: true
            }

        //Quality Measures Screen
        case actionTypes.runQualityMeasuresStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runQualityMeasuresSuccess:
            return {
                ...state,
                qualityMeasuresInfo: {
                    ...action.qualityMeasures
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runQualityMeasuresFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                qualityMeasuresErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateQualityMeasuresDataStart:
            return {
                ...state,
                isDialogLoading: true,
                qualityMeasuresInfo: null,
                error: false,
                initialPageVisible: true,
            }
        case actionTypes.generateQualityMeasuresDataSuccess:
            return {
                ...state,
                qualityMeasuresInfo: {
                    ...action.qualityMeasures
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateQualityMeasuresDataFail:
            return {
                ...state,
                isDialogLoading: false,
                qualityMeasuresInfo: null,
                error: true
            }


        //CPM Reports Screen
        case actionTypes.runCPMReportStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runCPMReportSuccess:
            return {
                ...state,
                cpmReportInfo: {
                    ...action.cpmReport
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runCPMReportFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                cpmReportErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateCPMReportDataStart:
            return {
                ...state,
                isDialogLoading: true,
                cpmReportInfo: null,
                error: false,
                /*initialPageVisible: true,*/
            }
        case actionTypes.generateCPMReportDataSuccess:
            return {
                ...state,
                cpmReportInfo: {
                    ...action.cpmReport
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateCPMReportDataFail:
            return {
                ...state,
                isDialogLoading: false,
                cpmReportInfo: null,
                error: true
            }


        //Timeliness Reports Screen
        case actionTypes.runTimelinessStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runTimelinessSuccess:
            return {
                ...state,
                timelinessInfo: {
                    ...action.timeliness
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runTimelinessFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                timelinessErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateTimelinessDataStart:
            return {
                ...state,
                isDialogLoading: true,
                timelinessInfo: null,
                error: false,
                initialPageVisible: true,
            }
        case actionTypes.generateTimelinessDataSuccess:
            return {
                ...state,
                timelinessInfo: {
                    ...action.timeliness
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateTimelinessDataFail:
            return {
                ...state,
                isDialogLoading: false,
                timelinessInfo: null,
                error: true
            }

        //TNM Graph Reports Screen
        case actionTypes.runTNMGraphStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runTNMGraphSuccess:
            return {
                ...state,
                tnmGraphInfo: {
                    ...action.tnmGraph
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runTNMGraphFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                tnmGraphErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateTNMGraphDataStart:
            return {
                ...state,
                isDialogLoading: true,
                tnmGraphInfo: null,
                error: false,
                /*initialPageVisible: true,*/
            }
        case actionTypes.generateTNMGraphDataSuccess:
            return {
                ...state,
                tnmGraphInfo: {
                    ...action.tnmGraph
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateTNMGraphDataFail:
            return {
                ...state,
                isDialogLoading: false,
                tnmGraphInfo: null,
                error: true
            }

        //Cross Tab Reports Screen
        case actionTypes.runCrossTabStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }

        case actionTypes.runCrossTabSuccess:
            return {
                ...state,
                crossTabInfo: {
                    ...action.crossTab
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }

        case actionTypes.runCrossTabFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                crossTabErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        case actionTypes.generateCrossTabDataStart:
            return {
                ...state,
                isDialogLoading: true,
                crossTabInfo: null,
                error: false,
                initialPageVisible: true,
            }
        case actionTypes.generateCrossTabDataSuccess:
            return {
                ...state,
                crossTabInfo: {
                    ...action.crossTab
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.generateCrossTabDataFail:
            return {
                ...state,
                isDialogLoading: false,
                crossTabInfo: null,
                error: true
            }

        case actionTypes.loadCrossTabDataStart:
            return {
                ...state,
                isDialogLoading: true,
                crossTabInfo: null,
            }
        case actionTypes.loadCrossTabDataSuccess:
            return {
                ...state,
                crossTabInfo: {
                    ...action.crossTab
                },
                isDialogLoading: false,
                error: false,
                errorMessage: null,
            }
        case actionTypes.loadCrossTabDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.errorMessage,
                crossTabInfo: {
                    ...action.crossTab
                },
            }

        case actionTypes.deleteCrossTabLabelDataStart:
            return {
                ...state,
                isDialogLoading: true,
                crossTabInfo: null,
            }
        case actionTypes.deleteCrossTabLabelDataSuccess:
            return {
                ...state,
                crossTabInfo: {
                    ...action.crossTab
                },
                isDialogLoading: false,
                error: false,
                errorMessage: null,
            }
        case actionTypes.deleteCrossTabLabelDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.errorMessage,
                crossTabInfo: {
                    ...action.crossTab
                },
            }

        // State Export screen
        case actionTypes.runStateExportFormStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showClockTimer: true,
            }
        case actionTypes.runStateExportFormSuccess:
            return {
                ...state,
                selectedReportLabelsInfo: {
                    ...action.printForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showClockTimer: false,
            }
        case actionTypes.runStateExportFormFail:
            return {
                ...state,
                selectedReportLabelsInfo: {
                    ...action.printForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
                showClockTimer: false,
            }

        // NCDB Export screen
        case actionTypes.runNcdbExportFormStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showClockTimer: true,
            }
        case actionTypes.runNcdbExportFormSuccess:
            return {
                ...state,
                selectedReportLabelsInfo: {
                    ...action.printForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showClockTimer: false,
            }
        case actionTypes.runNcdbExportFormFail:
            return {
                ...state,
                selectedReportLabelsInfo: {
                    ...action.printForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
                showClockTimer: false,
            }

        case actionTypes.runPrintAbstractStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showClockTimer: true,
            }
        case actionTypes.runPrintAbstractSuccess:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showClockTimer: false,
            }
        case actionTypes.runPrintAbstractFail:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
                showClockTimer: false,
            }

        // Print Tx Summary
        case actionTypes.runPrintTxSummaryStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showClockTimer: true,
            }
        case actionTypes.runPrintTxSummarySuccess:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showClockTimer: false,
            }
        case actionTypes.runPrintTxSummaryFail:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
                showClockTimer: false,
            }

        /*
        // SelectAPop Start
        case actionTypes.selectAPopLabelDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                //isAddNewDialogVisible: !action.isNotExist,
                ///isAddNewDialogVisible2: action.isNotExist,
                error: false
            }
        case actionTypes.selectAPopLabelDataSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelInfo
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.selectAPopLabelDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedLabelPopInfo: null,
                error: true
            }
        case actionTypes.saveAPopLabelDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.saveAPopLabelDataSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
            }
        case actionTypes.saveAPopLabelDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isFormDirty: true,
                isPatientSaving: false,
            }

        case actionTypes.deleteAPopLabelDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedLabelPopInfo: null,
                //isAddNewDialogVisible: true
            }
        case actionTypes.deleteAPopLabelDataSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                error: false,
                errorMessage: null,
            }
        case actionTypes.deleteAPopLabelDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.errorMessage,
                /*
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },* /
                selectedLabelPopInfo: null,
            }
        // SelectAPop End
        */

        case actionTypes.runGraphForPopulationStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.runGraphForPopulationSuccess:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
            }
        case actionTypes.runGraphForPopulationFail:
            return {
                ...state,
                selectedLabelPopInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
            }

        // Print QA Form
        case actionTypes.runPrintQAFormStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showClockTimer: true,
            }
        case actionTypes.runPrintQAFormSuccess:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showClockTimer: false,
            }
        case actionTypes.runPrintQAFormFail:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
                showClockTimer: false,
            }

        // Print SCP Form
        case actionTypes.runGenSurvivorshipCarePlanStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showClockTimer: true,
            }
        case actionTypes.runGenSurvivorshipCarePlanSuccess:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showClockTimer: false,
            }
        case actionTypes.runGenSurvivorshipCarePlanFail:
            return {
                ...state,
                patientAbsInfo: {
                    ...action.pLabelForm
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
                showClockTimer: false,
            }

        // Work Queue
        case actionTypes.loadWorkQueueDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.loadWorkQueueDataSuccess:
            return {
                ...state,
                workQueueData: {
                    ...action.workQueue
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }
        case actionTypes.loadWorkQueueDataFail:
            return {
                ...state,
                workQueueData: {
                    ...action.workQueue
                },
                isDialogLoading: false,
                error: true,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        // Follow-up/QA Letters
        case actionTypes.loadLettersMaintDataStart:
            return {
                ...state,
                isDialogLoading: true,
                //lettersMaintInfo: null,
                error: false,
                isAddNewDialogVisible: true,
                initialPageVisible: true,
                //customFormsMaintInfo: null,//clear forms
                //commonLookupItem: null
                previewing: false,
            }
        case actionTypes.loadLettersMaintDataSuccess:
            return {
                ...state,
                lettersMaintInfo: {
                    ...action.letters
                },
                previewing: false,
                isDialogLoading: false,
                isFormDirty: false,
                error: false
            }
        case actionTypes.loadLettersMaintDataFail:
            return {
                ...state,
                isDialogLoading: false,
                previewing: false,
                //lettersMaintInfo: null,
                error: true
            }
        case actionTypes.saveLettersMaintStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true,
                previewing: false,
                //commonLookupItem: null
            }
        case actionTypes.saveLettersMaintSuccess:
            return {
                ...state,
                lettersMaintInfo: {
                    ...action.letters
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false
            }
        case actionTypes.saveLettersMaintFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }
        case actionTypes.deleteLettersMaintStart:
            return {
                ...state,
                isDialogLoading: true,
                //lettersMaintInfo: null,
                deletedDataSuccess: false,
                previewing: false,
                isPatientSaving: true
            }
        case actionTypes.deleteLettersMaintSuccess:
            return {
                ...state,
                lettersMaintInfo: {
                    ...action.letters
                },
                customFormsMaintInfo: {
                    ...action.letters
                },
                isDialogLoading: false,
                deletedDataSuccess: true,
                isPatientSaving: false
            }
        case actionTypes.deleteLettersMaintFail:
            return {
                ...state,
                isDialogLoading: false,
                //lettersMaintInfo: null,
                error: true,
                isPatientSaving: false,
                pageLoaded: false,//reset this value to load data on parent page
            }
        case actionTypes.previewLettersMaintStart:
            return {
                ...state,
                //isDialogLoading: true,
                //lettersMaintInfo: null,
                error: false,
                //isAddNewDialogVisible: true,
                //initialPageVisible: true,
                previewing: false,
            }
        case actionTypes.previewLettersMaintSuccess:
            return {
                ...state,
                lettersMaintInfo: {
                    ...action.letters
                },
                previewing: true,
                error: false
            }
        case actionTypes.previewLettersMaintFail:
            return {
                ...state,
                previewing: false,
                error: true
            }

        //Custom Forms
        case actionTypes.loadCustomFormsMaintDataStart:
            return {
                ...state,
                isDialogLoading: true,
                error: false,
                isAddNewDialogVisible: true,
                initialPageVisible: true,
                //lettersMaintInfo: null,//clear letters
                //commonLookupItem: null
                previewing: false,
            }
        case actionTypes.loadCustomFormsMaintDataSuccess:
            return {
                ...state,
                customFormsMaintInfo: {
                    ...action.forms
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false
            }
        case actionTypes.loadCustomFormsMaintDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true
            }
        case actionTypes.saveCustomFormsMaintStart:
            return {
                ...state,
                isDialogLoading: true,
                isPatientSaving: true,
                //commonLookupItem: null
                previewing: false,
            }
        case actionTypes.saveCustomFormsMaintSuccess:
            return {
                ...state,
                customFormsMaintInfo: {
                    ...action.forms
                },
                isDialogLoading: false,
                isFormDirty: false,
                isPatientSaving: false
            }
        case actionTypes.saveCustomFormsMaintFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false
            }
        case actionTypes.deleteCustomFormsMaintStart:
            return {
                ...state,
                isDialogLoading: true,
                deletedDataSuccess: false,
                previewing: false,
                isPatientSaving: true
            }
        case actionTypes.deleteCustomFormsMaintSuccess:
            return {
                ...state,
                customFormsMaintInfo: {
                    ...action.forms
                },
                isDialogLoading: false,
                deletedDataSuccess: true,
                isPatientSaving: false
            }
        case actionTypes.deleteCustomFormsMaintFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                isPatientSaving: false,
                pageLoaded: false,//reset this value to load data on parent page
            }
        case actionTypes.previewCustomFormsMaintStart:
            return {
                ...state,
                error: false,
                previewing: false,
            }
        case actionTypes.previewCustomFormsMaintSuccess:
            return {
                ...state,
                customFormsMaintInfo: {
                    ...action.forms
                },
                previewing: true,
                error: false
            }
        case actionTypes.previewCustomFormsMaintFail:
            return {
                ...state,
                error: true
            }

        //
        case actionTypes.getAvailableItemsSuccess:
            return {
                ...state,
                //commonColors: {...action.colors},
                availableItems: action.availableItems,
                availableMenuGroups: action.availableMenuGroups,
            }
        case actionTypes.getAvailableItemsFail:
            return {
                ...state,
                //commonColors: null,
                availableItems: null,
                availableMenuGroups: null,
            }

        // Import Configuration
        case actionTypes.loadImportConfigDataStart:
            return {
                ...state,
                isDialogLoading: true,
                error: false,
                isAddNewDialogVisible: true,
                initialPageVisible: true,
                importConfigInfo: null,
            }
        case actionTypes.loadImportConfigDataSuccess:
            return {
                ...state,
                importConfigInfo: {
                    ...action.xMergeOptions
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false
            }
        case actionTypes.loadImportConfigDataFail:
            return {
                ...state,
                isDialogLoading: false,
                importConfigInfo: null,
                error: true
            }
        case actionTypes.saveImportConfigDataStart:
            return {
                ...state,
                isPatientSaving: true
            }
        case actionTypes.saveImportConfigDataSuccess:
            return {
                ...state,
                importConfigInfo: {
                    ...action.xMergeOptions
                },
                isPatientSaving: false,
                isFormDirty: false,
            }
        case actionTypes.saveImportConfigDataFail:
            return {
                ...state,
                isPatientSaving: false,
                error: true
            }
        case actionTypes.deleteImportConfigDataStart:
            return {
                ...state,
                isDialogLoading: true,
                importConfigInfo: null,
                isPatientSaving: true
            }
        case actionTypes.deleteImportConfigDataSuccess:
            return {
                ...state,
                importConfigInfo: {
                    ...action.xMergeOptions
                },
                isDialogLoading: false,
                isPatientSaving: false
            }
        case actionTypes.deleteImportConfigDataFail:
            return {
                ...state,
                isDialogLoading: false,
                importConfigInfo: null,
                error: true,
                isPatientSaving: false
            }

        // Global Change
        case actionTypes.runGlobalChangeStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.runGlobalChangeSuccess:
            return {
                ...state,
                globalChangeInfo: {
                    ...action.globalChangeInfo
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
            }
        case actionTypes.runGlobalChangeFail:
            return {
                ...state,
                globalChangeInfo: {
                    ...action.globalChangeInfo
                },
                isDialogLoading: false,
                error: true,
                globalChangeErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
            }

        // Database Validation
        case actionTypes.databaseValidationInfoDataStart:
            return {
                ...state,
                isDialogLoading: true,
                databaseValidationInfo: null,
                error: false,
                //initialPageVisible: true,
            }
        case actionTypes.databaseValidationInfoDataSuccess:
            return {
                ...state,
                databaseValidationInfo: {
                    ...action.databaseValidation
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.databaseValidationInfoDataFail:
            return {
                ...state,
                isDialogLoading: false,
                databaseValidationInfo: null,
                error: true
            }

        case actionTypes.runDatabaseValidationStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.runDatabaseValidationSuccess:
            return {
                ...state,
                databaseValidationInfo: {
                    ...action.databaseValidation
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }
        case actionTypes.runDatabaseValidationFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                DatabaseValidationErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        // Mass Delete
        case actionTypes.massDeleteInfoStart:
            return {
                ...state,
                isDialogLoading: true,
                massDeleteInfo: null,
                error: false,
                //initialPageVisible: true,
            }
        case actionTypes.massDeleteInfoSuccess:
            return {
                ...state,
                massDeleteInfo: {
                    ...action.massDelete
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.massDeleteInfoFail:
            return {
                ...state,
                isDialogLoading: false,
                massDeleteInfo: null,
                error: true
            }

        case actionTypes.runMassDeleteStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
            }
        case actionTypes.runMassDeleteSuccess:
            return {
                ...state,
                massDeleteInfo: {
                    ...action.massDelete
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
            }
        case actionTypes.runMassDeleteFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                MassDeleteErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
            }

        // Generate Follow-up List and Letters
        case actionTypes.loadFollowUpLettersStart:
            return {
                ...state,
                isDialogLoading: true,
                followUpLettersInfo: null,
                error: false,
                initialPageVisible: true,
                previewing: false,
            }
        case actionTypes.loadFollowUpLettersSuccess:
            return {
                ...state,
                followUpLettersInfo: {
                    ...action.followUpLetters
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.loadFollowUpLettersFail:
            return {
                ...state,
                isDialogLoading: false,
                followUpLettersInfo: null,
                error: true
            }
        case actionTypes.runFollowUpLettersStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                showClockTimer: true,
                previewing: false,
            }
        case actionTypes.runFollowUpLettersSuccess:
            return {
                ...state,
                followUpLettersInfo: {
                    ...action.followUpLetters
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                showClockTimer: false,
                previewing: true,
            }
        case actionTypes.runFollowUpLettersFail:
            return {
                ...state,
                followUpLettersInfo: {
                    ...action.followUpLetters
                },
                isDialogLoading: false,
                error: true,
                graphAnItemErrMsg: action.msg,
                errorMessage: action.msg,
                isFormDirty: true,
                isPatientSaving: false,
                showClockTimer: false,
            }

        // ZipCodes Start
        case actionTypes.getZipCodesDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedZipCodesOnModal: null,
                isAddNewDialogVisible: !action.isNotExist,
                isAddNewDialogVisible2: action.isNotExist,
                error: false,
                deletedDataSuccess: false,
            }
        case actionTypes.getZipCodesDataSuccess:
            return {
                ...state,
                selectedZipCodesOnModal: {
                    ...action.zipCodes
                },
                isDialogLoading: false,
                error: false
            }
        case actionTypes.getZipCodesDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedZipCodesOnModal: null,
                error: true
            }
        case actionTypes.saveZipCodesDataStart:
            return {
                ...state,
                isDialogLoading: true,
                waitingFor: false,
                deletedDataSuccess: false,
            }
        case actionTypes.saveZipCodesDataSuccess:
            return {
                ...state,
                selectedZipCodesOnModal: {
                    ...action.zipCodes
                },
                isDialogLoading: false,
                isFormDirty: false,
                error: false,
                isPatientSaving: false,
                pageLoaded: false,
            }
        case actionTypes.saveZipCodesDataFail:
            return {
                ...state,
                isDialogLoading: false,
                error: true,
                errorMessage: action.message,
                isPatientSaving: false,
                isFormDirty: true,
            }

        case actionTypes.deleteZipCodesDataStart:
            return {
                ...state,
                isDialogLoading: true,
                selectedZipCodesOnModal: null,
                //isAddNewDialogVisible: true,
                deletedDataSuccess: false,
            }
        case actionTypes.deleteZipCodesDataSuccess:
            return {
                ...state,
                selectedZipCodesOnModal: {
                    ...action.zipCodes
                },
                isDialogLoading: false,
                isFormDirty: false,
                deletedDataSuccess: true,
                pageLoaded: false,
            }
        case actionTypes.deleteZipCodesDataFail:
            return {
                ...state,
                isDialogLoading: false,
                selectedZipCodesOnModal: null,
                error: true,
                deletedDataSuccess: false,
            }
        // ZipCodes End

        default:
            return state;
    }
}