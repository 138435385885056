/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Footer from "../../../components/AbstractManagement/PatientAbstract/Shared/Footer";
import "./CustomFormsMaint.css";
import "../../../assets/css/default-ocean-blue.css";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
//import '@progress/kendo-theme-default/dist/all.css';
import FontSize from "../EditorTools/FontSize";
import FontName from "../EditorTools/FontName";
import FormatBlock from "../EditorTools/FormatBlock";

const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    ForeColor,
    BackColor,
    CleanFormatting,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    NumberedList,
    BulletedList,
    Undo,
    Redo,
    //FontSize,
    //FontName,
    //FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    InsertFile,
    SelectAll,
    Print,
    Pdf,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
} = EditorTools;

class CustomFormsMaint extends Component {
    //
    editor = React.createRef();

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,

        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,

        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        isSaving: false,
        enabledDeletedBtn: false,
        loaded: false,
        previewing: false,
        editorHtml: "",
        xPos: "0px",
        yPos: "0px",
    }

    //https://www.telerik.com/kendo-react-ui/components/editor/content/?utm_medium=referral&utm_source=npm&utm_campaign=kendo-ui-react-trial-npm-editor
    handleHtmlChange = (event) => {
        //var htmlContent = this.getHtml();//event.target.iframe.contentWindow.document.querySelector(".k-content").innerHTML;
        //this.setState({ editorHtml: htmlContent });
        //this.getHtml();
        if (this.state.allowed)
            this.setState({ isFormDirty: true });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
        });
        this.props.storeToGlobal(null);
        return false;
    }
    handleOnEditorFocus = () => {
        this.props.storeToGlobal(null);
        this.setState({ allowed: true });
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false,
            hasError: false,
            //enabledDeletedBtn: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);
        return false;
    }

    hidePreviewLetters = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showingPreviewLetters: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnPreview") ? document.querySelector(".Footer2>#btnPreview").focus() : "";
        }, 0);
        return false;
    }

    componentWillUnmount() {
        this.props.clearCommonLookupItem();
        this.setState({ showingPreviewLetters: false });
    }
    componentDidMount() {
        if (!this.state.dialogForm && this.props.customFormsMaintInfo) {
            this.setFormData();
        } /*else if (this.state.initialDialogForm) {
            this.refreshFormFnc();
            var thisMe = this;
            setTimeout(function () {
                thisMe.refreshFormFnc();
            }, 1000);
        }*/

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        this.initial();
        this.setState({ showingPreviewLetters: false });
    }

    initial() {
        setTimeout(function () {
            var editorHtml = document.querySelector(".k-editor");
            if (editorHtml) {
                //var toolbar = editorHtml.querySelector(".k-toolbar");
                var content = editorHtml.querySelector(".k-editor-content");
                if (content) {
                    var iframe = content.querySelector("iframe");
                    if (iframe) {
                        iframe.contentWindow.document.body.style.fontFamily = "Courier New";
                        iframe.contentWindow.document.body.style.fontSize = "10pt";
                        //iframe.style.height = (editorHtml.clientHeight - toolbar.clientHeight) + "px";
                    }
                }
            }

            document.getElementById("hospitalCodeName") ? document.getElementById("hospitalCodeName").innerHTML = "" : null;
        }, 1500);
    }

    setHeight() {
        setTimeout(function () {
            var editorHtml = document.querySelector(".k-widget.k-editor");
            if (editorHtml) {
                var toolbar = editorHtml.querySelector(".k-widget.k-toolbar");
                var content = editorHtml.querySelector(".k-editor-content");
                if (content) {
                    var iframe = content.querySelector("iframe");
                    if (iframe) {
                        iframe.style.height = (editorHtml.clientHeight - toolbar.clientHeight) + "px";
                    }
                }
            }
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if ((!this.state.dialogForm && this.props.customFormsMaintInfo)
            || (prevProps.customFormsMaintInfo && this.props.customFormsMaintInfo
            && (prevProps.customFormsMaintInfo.letter !== this.props.customFormsMaintInfo.letter || prevProps.customFormsMaintInfo.hospitalCode !== this.props.customFormsMaintInfo.hospitalCode))
        ) {
            this.setFormData();
        }

        if (this.state.dialogForm && this.props.customFormsMaintInfo && this.props.customFormsMaintInfo.previewHtml
            && (!this.state.previewing || prevProps.customFormsMaintInfo && prevProps.customFormsMaintInfo.previewHtml !== this.props.customFormsMaintInfo.previewHtml)) {
            this.setState({
                showingPreviewLetters: true,
                previewing: true,
                previewHtml: this.props.customFormsMaintInfo.previewHtml
            });
            this.props.setChildrenOpening(true);
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.pAllItemFields) {
            var dialogForm = this.state.dialogForm;
            if (this.props.customFormsMaintInfo && this.props.customFormsMaintInfo.id != "0" && this.state.reload) {
                dialogForm = this.props.customFormsMaintInfo;
            }

            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                    reload: false,
                });
            } else {
                this.setState({
                    //pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                    // And also update name again
                    dialogForm: { ...dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                    reload: false,
                });
            }
            this.props.clearCommonLookupItem();
        }

        //
        if (this.state.tableStructureList === null && !this.state.loadedTableStructure) {
            this.setState({ loadedTableStructure: true });
            this.getTableStructure();
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (this.state.savedForm && !this.props.isDialogLoading && ((prevProps.isFormDirty != this.props.isFormDirty || this.props.error) || (prevProps.error != this.props.error || !this.state.isFormDirty))) {
            if (this.props.isFormDirty) {
                if (!this.state.hasError && this.state.hasError != this.props.error) {
                    this.setState({ showMessageError: true, hasError: true, isFormDirty: true });
                    this.props.setChildrenOpening(true);
                }
            } else {
                if (this.state.isFormDirty && this.state.isFormDirty != this.props.isFormDirty) {
                    this.setState({ isFormDirty: this.props.isFormDirty, hasError: false });
                }
            }

            if (this.props.customFormsMaintInfo && this.props.customFormsMaintInfo.id) {
                if (this.props.customFormsMaintInfo.action == "Copy") {
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Form Copied" : null;
                } else {
                    document.querySelector('.statusBarOnFooterCls') ? document.querySelector('.statusBarOnFooterCls').innerHTML = "Form Saved" : null;
                }
            }
        }

        if (this.state.isFormDirty && (this.state.isSaving || this.props.deletedDataSuccess)
            && (prevProps.isFormDirty !== this.props.isFormDirty || this.state.isFormDirty !== this.props.isFormDirty)) {

            document.getElementById("hospitalCode") && this.props.deletedDataSuccess ? document.getElementById("hospitalCode").focus() : "";

            this.setState({
                isFormDirty: false,//this.props.isFormDirty,
                isSaving: false,//this.props.isFormDirty,
                savedForm: false,
            });

            this.setFormData();

            // clear html content in editor
            if (this.editor.current && this.editor.current.view && this.props.deletedDataSuccess ) {
                EditorUtils.setHtml(this.editor.current.view, "");
            }

            var _this = this;
            setTimeout(function () {
                document.getElementById("letter0") && _this.props.deletedDataSuccess  ? document.getElementById("letter0").focus() : "";
            }, 1000);

            this.props.resetDeletedDataSuccess(false);
            this.forceUpdate();
            return true;
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Letters", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);

                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res ? res.data.result : null
        });
    }

    Display_Placeholders() {
        var refText = "{Abst-Initials}\n"
        refText = refText + "{Acc-Nbr}\n"
        refText = refText + "{Age-Dx}\n"
        refText = refText + "{Ca-Status-Desc}\n"
        refText = refText + "{Chem-Date}\n"
        refText = refText + "{Chem-Days-From-Dx}\n"
        refText = refText + "{Chemo-Code}\n"
        refText = refText + "{Chemo-Desc}\n"
        refText = refText + "{Chemo-Text}\n"
        refText = refText + "{Class-Desc}\n"
        refText = refText + "{Clinical-Trial}\n"
        refText = refText + "{Clin-TNM-Stage}\n"
        refText = refText + "{Date}\n"
        refText = refText + "{Days-Comp-Abst}\n"
        refText = refText + "{Der-CS-AJCC-Stage}\n"
        refText = refText + "{Der-CS-Summ-Stage}\n"
        refText = refText + "{Dob}\n"
        refText = refText + "{Dx-Dt}\n"
        refText = refText + "{Fol-Phys-Email}\n"
        refText = refText + "{Fol-Phys-FN}\n"
        refText = refText + "{Fol-Phys-LN}\n"
        refText = refText + "{Fol-Phys-Phone}\n"
        refText = refText + "{Fol-Phys-Title}\n"
        refText = refText + "{Gen-Stage-Code}\n"
        refText = refText + "{Gen-Stage-Desc}\n"
        refText = refText + "{Grade}\n"
        refText = refText + "{Height}\n"
        refText = refText + "{Hema-Code}\n"
        refText = refText + "{Hema-Date}\n"
        refText = refText + "{Hema-Days-From-Dx}\n"
        refText = refText + "{Hema-Desc}\n"
        refText = refText + "{Hist-Code}\n"
        refText = refText + "{Hist-Desc}\n"
        refText = refText + "{Horm-Code}\n"
        refText = refText + "{Horm-Date}\n"
        refText = refText + "{Horm-Days-From-Dx}\n"
        refText = refText + "{Horm-Desc}\n"
        refText = refText + "{Horm-Text}\n"
        refText = refText + "{Hosp-Address}\n"
        refText = refText + "{Hosp-City}\n"
        refText = refText + "{Hosp-Name}\n"
        refText = refText + "{Hosp-Phone}\n"
        refText = refText + "{Hosp-State}\n"
        refText = refText + "{Hosp-Zip}\n"
        refText = refText + "{Immuno-Code}\n"
        refText = refText + "{Immuno-Date}\n"
        refText = refText + "{Immuno-Desc}\n"
        refText = refText + "{Immuno-Text}\n"
        refText = refText + "{Imun-Days-From-Dx}\n"
        refText = refText + "{Initial-Rx-Dt}\n"
        refText = refText + "{Lab-Text}\n"
        refText = refText + "{Last-Cont-Dt}\n"
        refText = refText + "{Lat-Desc}\n"
        refText = refText + "{LN-Exam}\n"
        refText = refText + "{LN-Pos}\n"
        refText = refText + "{Med-Onc-Email}\n"
        refText = refText + "{Med-Onc-FN}\n"
        refText = refText + "{Med-Onc-LN}\n"
        refText = refText + "{Med-Onc-Phone}\n"
        refText = refText + "{Med-Onc-Title}\n"
        refText = refText + "{Med-Rec-Nbr}\n"
        refText = refText + "{Mng-Phys-Email}\n"
        refText = refText + "{Mng-Phys-FN}\n"
        refText = refText + "{Mng-Phys-LN}\n"
        refText = refText + "{Mng-Phys-Phone}\n"
        refText = refText + "{Mng-Phys-Title}\n"
        refText = refText + "{Most-Def-Surg-Dt}\n"
        refText = refText + "{Oth-Days-From-Dx}\n"
        refText = refText + "{Other-Date}\n"
        refText = refText + "{Other-Tx-Code}\n"
        refText = refText + "{Other-Tx-Desc}\n"
        refText = refText + "{Other-Tx-Text}\n"
        refText = refText + "{Page-Break}\n"
        refText = refText + "{Pall-Date}\n"
        refText = refText + "{Palliative-Code}\n"
        refText = refText + "{Palliative-Desc}\n"
        refText = refText + "{Pat-Address-1}\n"
        refText = refText + "{Pat-Address-2}\n"
        refText = refText + "{Pat-City}\n"
        refText = refText + "{Pat-Email}\n"
        refText = refText + "{Pat-FN}\n"
        refText = refText + "{Path-Text}\n"
        refText = refText + "{Path-TNM-Stage}\n"
        refText = refText + "{Pat-LN}\n"
        refText = refText + "{Pat-MN}\n"
        refText = refText + "{Pat-Name-Pre}\n"
        refText = refText + "{Pat-Name-Suf}\n"
        refText = refText + "{Pat-Phone}\n"
        refText = refText + "{Pat-Phone-2}\n"
        refText = refText + "{Pat-State}\n"
        refText = refText + "{Pat-Zip}\n"
        refText = refText + "{Pediatric-Stage}\n"
        refText = refText + "{Phase-I-Rad-Prim-Tx-Vol}\n"
        refText = refText + "{Phase-I-Rad-Drain-LN}\n"
        refText = refText + "{Phase-I-Rad-Tx-Modality}\n"
        refText = refText + "{Phase-I-Rad-Ext-Beam-Tech}\n"
        refText = refText + "{Phase-I-Rad-Dose-Fraction}\n"
        refText = refText + "{Phase-I-Rad-Nbr-Fractions}\n"
        refText = refText + "{Phase-I-Rad-Total-Dose}\n"
        refText = refText + "{Phase-II-Rad-Prim-Tx-Vol}\n"
        refText = refText + "{Phase-II-Rad-Drain-LN}\n"
        refText = refText + "{Phase-II-Rad-Tx-Modality}\n"
        refText = refText + "{Phase-II-Rad-Ext-Beam-Tech}\n"
        refText = refText + "{Phase-II-Rad-Dose-Fraction}\n"
        refText = refText + "{Phase-II-Rad-Nbr-Fractions}\n"
        refText = refText + "{Phase-II-Rad-Total-Dose}\n"
        refText = refText + "{Phase-III-Rad-Prim-Tx-Vol}\n"
        refText = refText + "{Phase-III-Rad-Drain-LN}\n"
        refText = refText + "{Phase-III-Rad-Tx-Modality}\n"
        refText = refText + "{Phase-III-Rad-Ext-Beam-Tech}\n"
        refText = refText + "{Phase-III-Rad-Dose-Fraction}\n"
        refText = refText + "{Phase-III-Rad-Nbr-Fractions}\n"
        refText = refText + "{Phase-III-Rad-Total-Dose}\n"
        refText = refText + "{Place-of-Dx-Text}\n"
        refText = refText + "{Post-TNM-Stage}\n"
        refText = refText + "{Prim-Surg-Email}\n"
        refText = refText + "{Prim-Surg-FN}\n"
        refText = refText + "{Prim-Surg-LN}\n"
        refText = refText + "{Prim-Surg-Phone}\n"
        refText = refText + "{Prim-Surg-Title}\n"
        refText = refText + "{Race-Desc}\n"
        //refText = refText + "{Rad-Code}\n"
        refText = refText + "{Rad-Days-From-Dx}\n"
        refText = refText + "{Rad-Discontinued}\n"
        //refText = refText + "{Rad-Desc}\n"
        refText = refText + "{Rad-End-Dt}\n"
        refText = refText + "{Rad-Nbr-Phases}\n"
        refText = refText + "{Rad-Onc-Email}\n"
        refText = refText + "{Rad-Onc-FN}\n"
        refText = refText + "{Rad-Onc-LN}\n"
        refText = refText + "{Rad-Onc-Phone}\n"
        refText = refText + "{Rad-Onc-Title}\n"
        refText = refText + "{Rad-Start-Dt}\n"
        refText = refText + "{Rad-Text}\n"
        refText = refText + "{Rad-Total-Dose}\n"
        refText = refText + "{Recur-Dt}\n"
        refText = refText + "{Recur-Type-Desc}\n"
        refText = refText + "{Sentinel-LN-Bx-Date}\n"
        refText = refText + "{Sentinel-LN-Examined}\n"
        refText = refText + "{Sentinel-LN-Positive}\n"
        refText = refText + "{Seq}\n"
        refText = refText + "{Sex-Desc}\n"
        refText = refText + "{Site-Specific-Data-Items}\n"
        refText = refText + "{Surg-Code}\n"
        refText = refText + "{Surg-Days-From-Dx}\n"
        refText = refText + "{Surg-Desc}\n"
        refText = refText + "{Surg-Margins-Desc}\n"
        refText = refText + "{Surg-Text}\n"
        refText = refText + "{Topo-Code}\n"
        refText = refText + "{Topo-Desc}\n"
        refText = refText + "{Tumor-Size}\n"
        refText = refText + "{UserName}\n"
        refText = refText + "{Vital-Status-Desc}\n"
        refText = refText + "{Weight}\n"
        refText = refText + "{Xray-Scan-Text}\n"

        //{ dialog.Object }.setValue('Placeholders', refText, false);
        return refText;
    }

    setFormData = () => {
        var dataForm = this.props.customFormsMaintInfo;
        if (!this.state.pAllItemFields && this.props.customFormsMaintInfo) {
            let _fields = {};
            Object.keys(this.props.customFormsMaintInfo).map((t) => {
                _fields[t] = "";
            });

            this.setState({ pAllItemFields: _fields, pageMaxlengthInput: _fields });
        };

        dataForm.placeholders = this.Display_Placeholders();

        let fields = {};
        Object.keys(dataForm).map((t) => {
            t != "placeholders" ? fields[t] = "" : "";
        });

        this.setState({
            initialDialogForm: { ...fields },
            dialogForm: { ...dataForm },
            isFormDirty: this.props.isFormDirty,//isFormDirty: this.state.savedForm ? false : this.state.isFormDirty || this.state.reload,
            reload: false,
            hasError: this.props.error,
            savedForm: false,
            hasError: false,
            loaded: true,
            editorHtml: dataForm.htmlContent ? dataForm.htmlContent.replaceAll("<br/>", "<p></p>") : "",
        });

        //
        if (this.editor.current && this.editor.current.view) {
            EditorUtils.setHtml(this.editor.current.view, dataForm.htmlContent ? dataForm.htmlContent.replaceAll("<br/>","<p></p>") : "");
        }

        this.forceUpdate();
        this.setHeight();
        return true;
    }

    saveCustomFormsMaint = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;

        //@TODO - set html editor back to form before submit
        var editorHtml = "";
        if (this.editor.current && this.editor.current.view) {
            editorHtml = EditorUtils.getHtml(this.editor.current.view.state);
        }

        dialogFormData.htmlContent = editorHtml.replaceAll("<p></p>", "<br/>");
        dialogFormData.action = "Save";

        this.props.saveCustomFormsMaint(dialogFormData);
        this.props.storeToGlobal(null);

        this.setState({ isSaving: true, savedForm: true, isFormDirty: false });
        return false;
    }

    handleFormChange = (event) => {
        this.props.resetDeletedDataSuccess(false);
        var enabledDeletedBtn = this.state.enabledDeletedBtn;

        var selectedLetterByName = this.state.dialogForm && this.state.dialogForm.letter ? this.state.dialogForm.letter : "";
        var selectedById = this.state.dialogForm && this.state.dialogForm.id ? this.state.dialogForm.id : 0;
        if (event.target && event.target.selectedById) {
            enabledDeletedBtn = true;

            if (selectedLetterByName != event.target.value) {
                selectedLetterByName = event.target.value;
            }

            if (selectedById != event.target.selectedById) {
                selectedById = event.target.selectedById;
                // reload form by selected name & hospCode
                let hosp = event.target2 ? event.target2.value : "";

                this.props.loadCustomFormsMaint(selectedLetterByName, hosp);
                this.setState({ allowed: false });
            }

            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    ["id"]: selectedById
                },
                isFormDirty: true,
                isSaving: false,
                enabledDeletedBtn: enabledDeletedBtn,
                reload: true
            });
        } else {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target ? event.target.name : ""]: event.target ? event.target.value : "",
                },
                isFormDirty: true,
                isSaving: false,
                enabledDeletedBtn: enabledDeletedBtn,
                reload: false
            });

            if (event.target.name == "hospitalCode" && event.target.value == "") {
                document.getElementById("hospitalCodeName") ? document.getElementById("hospitalCodeName").innerHTML = "" : null;
            }
        }
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            this.setState({ uAllItemFields: { ...this.state.uAllItemFields, [event.target.name]: "" } });
        }

        var _this = this;
        if (event.target.name == "letter") {
            // reload form by selected input
            if (this.props.customFormsMaintInfo && this.state.dialogForm
                && (this.props.customFormsMaintInfo.letter != this.state.dialogForm.letter || this.props.customFormsMaintInfo.hospitalCode != this.state.dialogForm.hospitalCode)) {
                let letter = event.target.value;
                let hosp = document.getElementById("hospitalCode") ? document.getElementById("hospitalCode").value : "";
                if (letter == "" && hosp == "") return false;
                this.props.loadCustomFormsMaint(letter, hosp);
                this.setState({ isFormDirty: true, allowed: false });
            }
        } else if (event.target.name == "hospitalCode") {
            if (this.props.customFormsMaintInfo && this.state.dialogForm
                && (this.props.customFormsMaintInfo.letter != this.state.dialogForm.letter || this.props.customFormsMaintInfo.hospitalCode != this.state.dialogForm.hospitalCode)) {
                let letter = document.getElementById("letter0") ? document.getElementById("letter0").value : "";
                let hosp = event.target.value;

                if (letter == "" || hosp == "") return false;

                this.props.loadCustomFormsMaint(letter, hosp);
                this.setState({ isFormDirty: true, allowed: false });
            }
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.customFormsMaintInfo) {
            Object.keys(this.props.customFormsMaintInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            pAllItemFields: fields,
            //pageMaxlengthInput: fields,
            isFormDirty: false,
            reload: false
        });

        //
        setTimeout(function () {
            document.getElementById("letter0") ? document.getElementById("letter0").focus() : "";
        }, 500);
    }

    previewFuncHandler = () => {
        let dialogFormData = this.state.dialogForm;

        //@TODO - set html editor back to form before submit
        var editorHtml = "";
        if (this.editor.current && this.editor.current.view) {
            editorHtml = EditorUtils.getHtml(this.editor.current.view.state);
        }
        dialogFormData.htmlContent = editorHtml;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.action = "Preview";

        this.props.previewCustomFormsMaint(dialogFormData);
        this.props.storeToGlobal(null);
        this.setState({ previewing: false });
    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
            width: rect.width,
            height: rect.height
        }
    }

    copyFuncHandler = () => {
        var target = document.getElementById("btnCopy");
        var divOffset = this.offset(target);

        this.setState({ showCopyDialog: true, xPos: divOffset.left, yPos: divOffset.top - 135 });
        this.props.storeToGlobal(null);
    }

    //
    printLetters = () => {
        var printStyle = document.getElementById('printStyle') ? document.getElementById('printStyle').innerHTML : this.state.printStyle;
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Sample Forms</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';

        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        return false;
    }

    //
    hideCopyDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showCopyDialog: false });
        this.props.setChildrenOpening(false);

        /*
        if (event.target.value === "yes") {
            this.copyLetters(event);
        }
        */

        //
        setTimeout(function () {
            document.getElementById('btnCopy') ? document.getElementById('btnCopy').focus() : "";
            return false;
        }, 100);

        return false;
    }
    copyLetters = (event) => {
        event.preventDefault();

        let dialogFormData = this.state.dialogForm;

        var Letter_Title = dialogFormData.letter ? dialogFormData.letter : "";//{dialog.Object}.getValue('Letter_Title');
        var Letter_Title_Copy = dialogFormData.newLetter ? dialogFormData.newLetter : "";//{dialog.Object}.getValue('Letter_Title_Copy');
        var Hospital_Code = dialogFormData.hospitalCode ? dialogFormData.hospitalCode : "";//{dialog.Object}.getValue('Hospital_Code');
        var Hospital_Code_Copy = dialogFormData.newHospitalCode ? dialogFormData.newHospitalCode : "";//{dialog.Object}.getValue('Hospital_Code_Copy');

        if ((Letter_Title != Letter_Title_Copy && Letter_Title_Copy != "") || (Hospital_Code != Hospital_Code_Copy && Hospital_Code_Copy != "")) {
            //{Dialog.Object}.ajaxCallback('', '', 'Copy_Letter', '', '');

            //@TODO - set html editor back to form before submit
            var editorHtml = "";
            if (this.editor.current && this.editor.current.view) {
                editorHtml = EditorUtils.getHtml(this.editor.current.view.state);
            }

            dialogFormData.htmlContent = editorHtml;

            //
            dialogFormData.letter = dialogFormData.newLetter;
            dialogFormData.hospitalCode = dialogFormData.newHospitalCode;
            dialogFormData.action = "Copy";

            this.props.saveCustomFormsMaint(dialogFormData);
            this.setState({ showCopyDialog: false, isSaving: true, savedForm: true, isFormDirty: false });
        }

        this.props.setChildrenOpening(false);
        this.props.storeToGlobal(null);

        return false;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">Can't save data.</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let showPreviewLetters;
        {
            showPreviewLetters = this.state.showingPreviewLetters && this.props.previewing ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls" id="letterPreviewModal">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Form Preview</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hidePreviewLetters(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container row" id="previewLettersDialog">
                                <div className="col-sm-12">
                                    <p dangerouslySetInnerHTML={{ __html: this.state.previewHtml }}></p>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button onClick={(e) => { this.printLetters(e) }} className="ErsGrBlueButton" autoFocus>Print</button>
                                <button onClick={(e) => { this.hidePreviewLetters(e) }} className="ErsGrBlueButton">Exit</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let copyDialog = (
            this.state.showCopyDialog ?
                <div className="CRStar_Window" id="copyLettersMainForm">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls" style={{ left: this.state.xPos + "px", top: this.state.yPos + "px" }}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header hidden">
                                <div className="k-window-title k-dialog-title hidden"></div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="#" onClick={this.javascript_void_fnc} className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close hidden">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideCopyDialog(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="New Form Name" field="newLetter" lineStyle="oneLine"
                                        value={this.state.dialogForm.newLetter} labelWidth="105px" width="170px"
                                        inValid={this.state.pAllItemFields["newLetter"]}
                                        lostfocused={this.handleLostFocus} autoFocus
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["letter"] : ""}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                        labelText="Hospital Code" field="newHospitalCode" lineStyle="oneLine"
                                        value={this.state.dialogForm.newHospitalCode} labelWidth="105px" width="30px"
                                        inValid={this.state.pAllItemFields["newHospitalCode"]}
                                        lostfocused={this.handleLostFocus}
                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                    />&nbsp;
                                    <Input type="label" //changed={this.handleFormChange}
                                        labelText="" field="newHospitalCodeName"
                                        value={this.state.dialogForm.newHospitalCodeName}
                                        width="400px" labelWidth="0px" lineStyle="oneLine"
                                    />
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <button onClick={(e) => { this.copyLetters(e) }} value="yes">OK</button>
                                <button onClick={(e) => { this.hideCopyDialog(e) }} value="no">Cancel</button>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        );

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        const dialogFrm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="customFormsMaintMainPage" style={{ height: "580px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form onSubmit={this.saveCustomFormsMaint} id="dialogForm" className="DialogForm2 customFormsMaintMaintForm">
                                        <div className="emptyLine"></div>
                                        <div id="inputAreaCls">
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Form Name" field="letter" lostfocused={this.handleLostFocus}
                                                            value={this.state.dialogForm["letter"]} width="195px" labelWidth="90px"
                                                            dataSource="Letters" titleDialog="" autoFocus
                                                            inValid={this.state.pAllItemFields["letter"]} lineStyle="oneLine"
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["letter"] : ""}
                                                            keyId="letter0"//filter: Letter_Type <>  'Pat'  And  Letter_Type <> 'Con' And Letter_Type <> 'QA' And Letter_Type <> 'Doc'
                                                            shownType="dropdown" rowIndex="0"
                                                            //title1="Form"
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Form Type" field="letterType" lostfocused={this.handleLostFocus}
                                                            value={this.state.dialogForm["letterType"]} width="135px" labelWidth="70px"
                                                            dataSource="Letters_Form_Type" titleDialog=""
                                                            inValid={this.state.pAllItemFields["letterType"]} lineStyle="oneLine"
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["letterType"] : ""}
                                                            keyId="letterType0" displayingCodeOnly={true}
                                                            shownType="dropdown" rowIndex="0"
                                                        />
                                                    </div>

                                                    <div className="col-sm-6" style={{ display: "flex" }}>
                                                        <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Hospital Code" field="hospitalCode" lostfocused={this.handleLostFocus}
                                                            value={this.state.dialogForm.hospitalCode} codeLength="2"//static filter: Len(Hosp_Nbr) = 2 / defaultValue: =session.userHosp
                                                            dataSource="Hospital" titleDialog="Hospital Lookup" labelWidth="90px" width="50px"
                                                            inValid={this.state.pAllItemFields["hospitalCode"]} lineStyle="oneLine" itemNbr=""
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                                        />&nbsp;
                                                        <Input type="label" changed={this.handleFormChange}
                                                            labelText="" field="hospitalCodeName"
                                                            value={this.state.dialogForm.hospitalCodeName}
                                                            width="400px" labelWidth="0px" lineStyle="oneLine"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="emptyLine"></div>

                                                <div className="row">
                                                    <div className="col-sm-9" style={{ display: "flex" }}>
                                                        <label id="LBL-HtmlContent" className="lblGeneralCls " htmlFor="htmlContent" style={{ width: "100px" }}>Form Content:</label>
                                                        <Editor
                                                            //defaultValue={this.state.editorHtml ? this.state.editorHtml : ""}
                                                            //defaultContent={this.state.editorHtml ? this.state.editorHtml : ""}
                                                            onFocus={this.handleOnEditorFocus} onChange={(e) => { this.handleHtmlChange(e) }}
                                                            tools={[
                                                                [Bold, Italic, Underline, Strikethrough],
                                                                [Subscript, Superscript],
                                                                ForeColor,
                                                                BackColor,
                                                                [CleanFormatting],
                                                                [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                                                                [Indent, Outdent],
                                                                [OrderedList, UnorderedList],
                                                                [NumberedList, BulletedList],
                                                                FormatBlock,
                                                                FontName,
                                                                FontSize,
                                                                [SelectAll],
                                                                [Undo, Redo],
                                                                [Link, Unlink, InsertImage, ViewHtml],
                                                                [InsertTable, InsertFile],
                                                                [Pdf, Print],
                                                                [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
                                                                [DeleteRow, DeleteColumn, DeleteTable],
                                                                [MergeCells, SplitCell],
                                                            ]}
                                                            contentStyle={{ width: "100%", height: "100%", maxHeight: "312px" }}//w:750px / h:400px
                                                            ref={this.editor}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Input type="textarea" lostfocused={this.handleLostFocus} focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Placeholders" labelWidth="auto" field="placeholders" value={this.state.dialogForm.placeholders}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["placeholders"] : ""}
                                                            width="200px" height="400px" lineStyle="newLine" normalTaField={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="emptyLine"></div>

                                    <Footer closeWindow={this.props.closeWindow}
                                        refreshForm={this.refreshFormFnc} formId="dialogForm"
                                        isFormDirty={this.state.isFormDirty /*|| (this.state.dialogForm && this.state.dialogForm.letter && this.state.dialogForm.letter != "" && this.state.dialogForm.action != "Save")*/}
                                        showBtnNew="CustomFormsMaint"
                                        showBtnDelete="CustomFormsMaint"
                                        className="Footer2"
                                        initialPageVisible={true}
                                        exitFnc={this.props.closeWindow}
                                        focusingField={this.state.focusingField}
                                        inquiringMode={this.props.inquiringMode}
                                        enabledDeletedBtn={this.state.dialogForm && this.state.dialogForm.letter && this.state.dialogForm.letter != ""}
                                        enabledAlways={false}
                                        ignoreExit={true}
                                        showBtnPreview={true}
                                        showBtnCopy={true} btnCopyText="Copy Form" enabledCopyBtn={this.state.dialogForm && this.state.dialogForm.letter && this.state.dialogForm.letter != ""}

                                        disabledOnInitialForm={!this.state.isFormDirty && !(this.state.dialogForm && this.state.dialogForm.letter && this.state.dialogForm.letter != "")}
                                        //disabledOnInitialForm={this.state.isFormDirty || (this.state.dialogForm && this.state.dialogForm.letter && this.state.dialogForm.letter != "" && this.state.dialogForm.action != "Save")}

                                        lettersData={this.state.dialogForm} formsData={this.state.dialogForm}
                                        previewFnc={this.previewFuncHandler}
                                        copyFnc={this.copyFuncHandler}
                                    />
                                </div>
                                : <p>Custom Forms Maintenance data loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {showPreviewLetters}
                </React.Fragment>

                <React.Fragment>{copyDialog}</React.Fragment>

                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

CustomFormsMaint.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        customFormsMaintInfo: state.patientAbstract.customFormsMaintInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        previewing: state.patientAbstract.previewing,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        userId: state.auth.userId,
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadCustomFormsMaint: (name, hosp) => dispatch(actionCreators.loadCustomFormsMaint(name, hosp)),//
        saveCustomFormsMaint: (dialogForm) => dispatch(actionCreators.saveCustomFormsMaint(dialogForm)),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        previewCustomFormsMaint: (form) => dispatch(actionCreators.previewCustomFormsMaint(form)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFormsMaint);