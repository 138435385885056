/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TabContent, TabPane, Nav, NavLink } from "reactstrap";
import { actionCreators } from "../../../../store/PatientAbstract";
import Footer from "../Shared/Footer";
import Input from "../../../UI/Input";
import "../PatientMaintenance/PatientMaintenance.css";
import "./StagingMaintenance.css";
import "../DiagnosisMaintenance/DiagnosisMaintenance.css";
import TextForm from "../DiagnosisMaintenance/TextForm";
import Help from "../../../UI/Help";
import classnames from "classnames";
import axios from "../../../../axios-instance";
import Draggable from 'react-draggable';
import * as TextPopup from "../TitleOfTextPopup";
import V21CompatibilityFeatureForm from "./V21CompatibilityFeatureForm";

class Staging2018Maintenance extends Component {

    // At Staging page, before initial page -> run this store procedure to get data:
    // Exec Abstract_Select 'value of CID'

    state = {
        activeTab: "1",
        sAllItemFields: null,
        // This 'initial' copy of the form will not be modified
        // All input changes set state on the 'stagingForm'
        // When user presses "Refresh" the 'initial' form is used to set the dirty stagingForm back to what it was on load
        initialStagingForm: null,
        stagingForm: null,
        isFormDirty: false,
        isAddNewVisible: false,
        isShowTextVisible: false,
        fieldInFocus: "ndPos",
        isHelpVisible: false,
        isDialogVisible: true,
        determinedSchemaId: false,
        schemaId: "",
        schemaIdDisplaying: "",
        schemaIdDisplaying_WithError: "",
        schemaName: "",
        ajccId: "",
        ajccDiseaseId: "",
        fn_schema_id: "",
        fn_disc_schema_id: "",
        fn_disc_enabled: "",
        dynamicItemFields: null,
        lookupOfItemFields: null,
        itemNoLookupOnPopup: null,
        showDynamicItemFields: null,
        // set max-length for input fields
        getTableStructure: false,
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        tableStructureListForSsdis: null,
        pageMaxlengthInputForSsdis: null,
        hasSetMaxLengthForSsdis: false,
        // Set this flag to allow the screen ignore the validations and save anyway.
        allowSaveAnyway: true,
        setPrimValue: false,
        primTopo: null,
        primHist: null,
        primSite: null,
        primKey: null,
        schemaNbr: "",
        fnSchemaNbr: "",
        confirmingAllCsSsf: false,
        loadedPage: false,
        focusingField: null,
        savingData: false,
        ssdiTitle: null,
        ssdiHasChanged: false,
        hasChangedDataOfSchemaInfo: false,
        reloadAjccIdAndSchemaId: false,
        updatedAjccId: null,
        updatedSchemaId: null,
        showAjccManualInfo: false,//#PR00530::Add AJCC Manual Content to right-side panel
    }

    reflectFnc = (ajccId, schemaId, schemaName) => {
        console.log('Welcome back to Staging parent screen!');
        this.setState({
            reloadAjccIdAndSchemaId: true,
            updatedAjccId: ajccId,
            updatedSchemaId: schemaId,
            schemaName: schemaName,
            ajccId: ajccId,
        });
    }

    updateSaveFlagToProcess = (flag) => {
        this.setState({ allowSaveAnyway: true });
    }

    closeWarningDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isDialogVisible: false });
        return false;
    }

    //capitalize all words of a string. 
    capitalizeWords(string) {
        return string.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
    };

    getFieldNameByIndex = (index) => {
        var name = "csSsf" + index;
        if (this.state.lookupOfItemFields && this.state.lookupOfItemFields[name]) {
            var fieldName = this.state.lookupOfItemFields[name];

            fieldName = fieldName.toLowerCase();

            fieldName = fieldName.split(/_/g).map(word =>
                `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                .join(" ");

            fieldName = fieldName.replace(/ /g, "");
            name = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);
        }
        return name;
    }

    showValueByFieldIndex = (index) => {
        var value = "";
        if (this.state.stagingForm && this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf" + index]) {
            var name = this.state.lookupOfItemFields["csSsf" + index];

            name = name.toLowerCase();

            var fieldName = name.split(/_/g).map(word =>
                `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
                .join(" ");

            fieldName = fieldName.replace(/ /g, "");
            fieldName = fieldName.charAt(0).toLowerCase() + fieldName.slice(1);

            if (this.state.stagingForm[fieldName]) {
                value = this.state.stagingForm[fieldName];
                this.setState({
                    stagingForm: { ...this.state.stagingForm, ["csSsf" + index]: value },
                });
            }
        }
        return value;
    }

    // #20210125 - START
    showConfirmationMsgOrContinue = (event) => {
        if (this.props.isFormDirty) {
            if (event.target.value === "yes") {
                if (this.props.isChildren) {
                    this.props.setChildrenWindowVisible(false);
                } else {
                    this.props.setParentWindowVisible(false);
                    this.setState({ isDialogVisible: false });

                    // set this tab to disabled
                    this.props.setEnabledStatus(this.props.currentDialog, false);
                    // move to first tab is LOOKUP
                    this.props.removeTabItems(this.props.currentDialog);
                }
                this.setState({ isShowConfirmationMsg: false });
            } else {
                this.setState({ isShowConfirmationMsg: true });
            }
        } else {
            if (this.props.isChildren) {
                this.props.setChildrenWindowVisible(false);
            } else if (this.props.isLookup) {
                this.props.closeAddNewDialog(false);
            } else {
                this.props.setParentWindowVisible(false);
                this.setState({ isDialogVisible: false });

                // set this tab to disabled
                this.props.setEnabledStatus(this.props.currentDialog, false);
                // move to first tab is LOOKUP
                this.props.removeTabItems(this.props.currentDialog);
            }
            return false;
        }
    }
    // #20210125 - END

    showTextDialog = () => {
        this.setState({ isShowTextVisible: true });
    }

    closeTextDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ isShowTextVisible: false });
        this.props.closeTextDialog(false);
        return false;
    }

    closeV21CompatibilityFeature = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.props.closeV21CompatibilityFeature(false);
        return false;
    }

    async reloadPrimInfo(cid, topoCode, histCode) {
        let site = topoCode;
        let hist = histCode;

        let Use_Screen_Values = "No";

        var schemaDiscrim1 = this.state.stagingForm != null && this.state.stagingForm.schemaDiscrim1 != null && this.state.stagingForm.schemaDiscrim1 != undefined ? this.state.stagingForm.schemaDiscrim1 : "";
        var schemaDiscrim2 = this.state.stagingForm != null && this.state.stagingForm.schemaDiscrim2 != null && this.state.stagingForm.schemaDiscrim2 != undefined ? this.state.stagingForm.schemaDiscrim2 : "";

        var thjs = this;
        //DetermineNecessaryDiscriminators
        await axios.post(this.props.clientgroup + "/DetermineNecessaryDiscriminators", {
            "site": site,
            "hist": hist,
            "sex": this.props.selectedPatient.sex,
            "disc1": schemaDiscrim1,
            "disc2": schemaDiscrim2,
            "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
            "useScreenValue": Use_Screen_Values,
            accessToken: localStorage.getItem('token')
        })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    let schemaId = res.data.commonLookupItem ? res.data.commonLookupItem.code : "";
                    let totalRows = res.data.commonLookupItem ? res.data.commonLookupItem.id : 0;

                    //When called from screen loading, go ahead and update this control
                    thjs.setState({
                        fn_disc_schema_id: schemaId != "" ? schemaId : thjs.state.fn_disc_schema_id,
                        schemaId: schemaId
                    });

                    if (totalRows > 0 && Use_Screen_Values == "No") {
                        thjs.setState({ fn_disc_enabled: "1" });
                    } else if (totalRows == 0 && Use_Screen_Values == "No") {
                        thjs.setState({ fn_disc_enabled: "" });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });

        var savedSchemaId = this.state.stagingForm && this.state.stagingForm.schemaId && this.state.stagingForm.schemaId != "" ? this.state.stagingForm.schemaId : "";
        // check if whether the schemaId has changed
        axios.post(this.props.clientgroup + "/DetermineSchemaId", {
            "cid": cid,
            "site": site,
            "hist": hist,
            "sex": this.props.selectedPatient.sex,
            "ageDx": this.props.selectedMedicalRecord.ageDx,
            "disc1": schemaDiscrim1,
            "disc2": schemaDiscrim2,
            "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
            accessToken: localStorage.getItem('token')
        })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    let schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";
                    let schemaName = res.data.commonLookupItem && res.data.commonLookupItem.name ? res.data.commonLookupItem.name : "";
                    let totalRows = res.data.commonLookupItem && res.data.commonLookupItem.id ? res.data.commonLookupItem.id : 0;
                    let description = res.data.commonLookupItem && res.data.commonLookupItem.description ? res.data.commonLookupItem.description : "";

                    var schemaIdDisplaying = schemaId;
                    var schemaIdDisplaying_WithError = "";
                    if (totalRows > 1) {
                        schemaIdDisplaying = "Discriminator Check";
                        schemaIdDisplaying_WithError = description;//schemaId
                    }

                    let ajccId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                    let ajccDiseaseId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccDiseaseId : "";

                    var _schemaId = thjs.state.schemaId ? thjs.state.schemaId : null;
                    var _ajccId = thjs.state.ajccId ? thjs.state.ajccId : null;

                    var msgOut = "when visiting the staging screen, if schema_id or ajcc_id change from a previous value, the save button should become enabled even though these fields are behind the scenes and not displayed.";
                    console.log("===PR00335===", msgOut);

                    thjs.setState({
                        schemaId: schemaId,
                        schemaName: schemaName,
                        ajccId: ajccId,
                        ajccDiseaseId: ajccDiseaseId,

                        schemaIdDisplaying: "Schema ID: " + schemaIdDisplaying,
                        fn_schema_id: schemaId,
                        schemaIdDisplaying_WithError: schemaIdDisplaying_WithError,

                        isFormDirty: thjs.state.isFormDirty || (_schemaId && _schemaId != schemaId) || (_ajccId && _ajccId != ajccId)
                    });

                    var stg2018 = thjs;
                    axios.post(thjs.props.clientgroup + "/GetSsdiItemLists", { schemaId: schemaName, accessToken: localStorage.getItem('token') })
                        .then(res => {
                            //update new accessToken again
                            var newAccessToken = res && res.data ? res.data.accessToken : null;
                            if (newAccessToken) {
                                localStorage.setItem('token', newAccessToken);
                            }

                            if (!res.data.error) {
                                let ssdiItemLists = res.data.commonLookupList;
                                if (ssdiItemLists != null && ssdiItemLists.length > 0) {
                                    // first hide all fields...
                                    let dif = stg2018.state.dynamicItemFields;
                                    let sdi = stg2018.state.showDynamicItemFields;
                                    let loi = stg2018.state.lookupOfItemFields;
                                    let iNo = stg2018.state.itemNoLookupOnPopup;

                                    ssdiItemLists.map((t, index) => {
                                        //index = index + 1;
                                        if (!ssdiItemLists[index] || !ssdiItemLists[index]["order"]) return false;
                                        // then show it by order/name
                                        /*
                                        dif["csSsf" + ssdiItemLists[index]["order"]] ? dif["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"] : "";//label
                                        loi["csSsf" + ssdiItemLists[index]["order"]] ? loi["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"] : "";//value
                                        iNo["csSsf" + ssdiItemLists[index]["order"]] ? iNo["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"] : "";//itemNbr
                                        sdi["csSsf" + ssdiItemLists[index]["order"]] ? sdi["csSsf" + ssdiItemLists[index]["order"]] = "" : "";//show or hide field
                                        */
                                        try {
                                            dif["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"];
                                        } catch (exps) {
                                        }
                                        try {
                                            loi["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"];
                                        } catch (exps) {
                                        }
                                        try {
                                            iNo["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"];
                                        } catch (exps) {
                                        }
                                        try {
                                            sdi["csSsf" + ssdiItemLists[index]["order"]] = "";
                                        } catch (exps) {
                                        }
                                    });

                                    var startFromIndex = 0;
                                    if (schemaIdDisplaying.indexOf("Discriminator Check") != -1) {
                                        startFromIndex = 0;
                                    } else {
                                        startFromIndex = ssdiItemLists.length + 1;
                                    }

                                    for (var idx = startFromIndex; idx <= 25; idx++) {
                                        try {
                                            dif["csSsf" + idx] = null;//label
                                        } catch (exps) { }
                                        try {
                                            sdi["csSsf" + idx] = "hidden";//show or hide field
                                        } catch (exps) { }
                                    }

                                    setTimeout(function () {
                                        stg2018.setState({
                                            dynamicItemFields: dif,
                                            showDynamicItemFields: sdi,
                                            lookupOfItemFields: loi,
                                            itemNoLookupOnPopup: iNo,
                                        });
                                        return false;
                                    }, 500);
                                } else {
                                    // when initial is hidden all fields
                                    if (stg2018.state.dynamicItemFields && stg2018.props.selectedStagingRecord) {
                                        let fields = {};
                                        Object.keys(stg2018.props.selectedStagingRecord).map((t) => {
                                            fields[t] = " ";
                                        });

                                        stg2018.setState({ dynamicItemFields: { ...fields } });
                                    };
                                    if (stg2018.state.showDynamicItemFields && stg2018.props.selectedStagingRecord) {
                                        let fields = {};
                                        Object.keys(stg2018.props.selectedStagingRecord).map((t) => {
                                            fields[t] = "hidden";
                                        });

                                        stg2018.setState({ showDynamicItemFields: { ...fields } });
                                    };
                                }
                            } else {
                                stg2018.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            return false;
                        });

                    /*
                    When opening the staging screen, and calculating schema_Id.
                    If newly calculated schema_id is different than the previously saved schema_id (staging table)
                    */
                    if (savedSchemaId != schemaName && savedSchemaId != schemaId && stg2018.state.hasChangedDataOfSchemaInfo) {
                        //the user should be prompted with a message that says “SEER Schema has changed. Would you like to delete previously saved SSDI’s that no longer apply to the current schema?” 
                        //[Yes / No]
                        //If the user chooses “Yes” we will need to delete SSDIs that correspond the the saved schema and are not applicable to the new schema.
                        stg2018.setState({
                            sAllItemFields: {
                                ...stg2018.state.sAllItemFields,
                                ["ndPos"]: "",
                                //["lymphNd"]: ""
                            },
                            showConfirmationSchemaIdHasChanged: true
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });
    }

    async getPrimInfo(cid) {
        let url = this.props.clientgroup + "/ReloadPrimInfo";
        let result = await axios
            .post(url, { key: cid, accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });

        //update new accessToken again
        var newAccessToken = result && result.data ? result.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            setPrimValue: true,
            primSite: result.data.split('|')[0],
            primTopo: result.data.split('|')[1],
            primHist: result.data.split('|')[2],
            primKey: cid
        });
    }

    //
    async Check_Schema_ID_Has_Changed(Topo_Code, Hist_Code, Topo_Code2, Hist_Code2) {
        var _this = this;
        var _schemaId;
        var _ajccId;
        var _schemaId2;
        var _ajccId2;
        var Use_Screen_Values = "No";
        var schemaDiscrim1 = this.state.stagingForm.schemaDiscrim1 != null && this.state.stagingForm.schemaDiscrim1 != undefined ? this.state.stagingForm.schemaDiscrim1 : "";
        var schemaDiscrim2 = this.state.stagingForm.schemaDiscrim2 != null && this.state.stagingForm.schemaDiscrim2 != undefined ? this.state.stagingForm.schemaDiscrim2 : "";

        var dxYear = this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
        var sex = this.props.selectedPatient.sex;
        var ageDx = this.props.selectedMedicalRecord.ageDx;

        if (Topo_Code !== "" && Hist_Code !== "") {
            await axios.post(this.props.clientgroup + "/DetermineSchemaId", {
                "site": Topo_Code,
                "hist": Hist_Code,
                "sex": sex,
                "ageDx": ageDx,
                "disc1": schemaDiscrim1,
                "disc2": schemaDiscrim2,
                "dxYear": dxYear,
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    if (!res.data.error) {
                        _schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//Code = SeerapiId
                        _ajccId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                    } else {
                        _this.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                    }

                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }

        if (Topo_Code2 !== "" && Hist_Code2 !== "") {
            let sexBak = this.props.selectedPatient["sex_bak"] ? this.props.selectedPatient["sex_bak"] : this.props.selectedPatient["sexBak"];
            let ageDxBak = this.props.selectedMedicalRecord["ageDx_bak"] ? this.props.selectedMedicalRecord["ageDx_bak"] : this.props.selectedMedicalRecord["ageDxBak"];
            let dxYearBak = this.props.selectedMedicalRecord["dxYear_bak"] ? this.props.selectedMedicalRecord["dxYear_bak"] : this.props.selectedMedicalRecord["dxYearBak"];

            await axios.post(this.props.clientgroup + "/DetermineSchemaId", {
                "site": Topo_Code2,
                "hist": Hist_Code2,
                "sex": sexBak ? sexBak : sex,
                "ageDx": ageDxBak ? ageDxBak : ageDx,
                "disc1": schemaDiscrim1,
                "disc2": schemaDiscrim2,
                "dxYear": dxYearBak ? dxYearBak : dxYear,
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        _schemaId2 = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//Code = SeerapiId
                        _ajccId2 = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                    } else {
                        _this.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }

        //
        if ((_schemaId && _schemaId2 && _schemaId != _schemaId2)
            || (_ajccId && _ajccId2 && _ajccId != _ajccId2)) {
            _this.setState({ isFormDirty: true, hasChangedDataOfSchemaInfo: (_schemaId && _schemaId2 && _schemaId != _schemaId2) });
        }
    }

    //
    showAjccManualInfoFnc = async() => {
        var ajccId = this.state.ajccId ? this.state.ajccId : (this.state.stagingForm && this.state.stagingForm.ajccId ? this.state.stagingForm.ajccId : "");
        var edition = document.getElementById("tnmEdNbr") ? document.getElementById("tnmEdNbr").value : "";//{ dialog.Object }.getValue('Tnm_Ed_Nbr');

        var diseaseName = "";
        switch (ajccId) {
            case "10": diseaseName = "HAN-PHA-ORO-HPV"; break;
            case "11.1": diseaseName = "HAN-PHA-ORO"; break;
            case "11.2": diseaseName = "HAN-PHA-HYP"; break;
            case "12.1": diseaseName = "HAN-NAS-MAX"; break;
            case "12.2": diseaseName = "HAN-NAS-NCE"; break;
            case "13.0": diseaseName = "HAN-LAR-NOS"; break;
            case "13.1": diseaseName = "HAN-LAR-SUP"; break;
            case "13.2": diseaseName = "HAN-LAR-GLO"; break;
            case "13.3": diseaseName = "HAN-LAR-SUB"; break;
            case "14": diseaseName = "HAN-MUC"; break;
            case "15": diseaseName = "HAN-SKN"; break;
            case "16.1": diseaseName = "UGI-ESO-SCC"; break;
            case "16.2": diseaseName = "UGI-ESO-ADE"; break;
            case "16.3": diseaseName = "UGI-ESO-OTH"; break;
            case "17": diseaseName = "UGI-STO"; break;
            case "18.1": diseaseName = "UGI-SMB-ADE"; break;
            case "18.2": diseaseName = "UGI-SMB-OTH"; break;
            case "19": diseaseName = "LGI-APP"; break;
            case "20": diseaseName = "LGI-CRC"; break;
            case "21": diseaseName = "LGI-ANU"; break;
            case "22": diseaseName = "HEP-LIV"; break;
            case "23": diseaseName = "HEP-IBD"; break;
            case "24": diseaseName = "HEP-GAL"; break;
            case "25": diseaseName = "HEP-PBD"; break;
            case "26": diseaseName = "HEP-DBD"; break;
            case "27": diseaseName = "HEP-AMP"; break;
            case "28": diseaseName = "HEP-PAN"; break;
            case "29": diseaseName = "NET-STO"; break;
            case "30": diseaseName = "NET-AMP-DUO"; break;
            case "31": diseaseName = "NET-JEJ-ILE"; break;
            case "32": diseaseName = "NET-APP"; break;
            case "33": diseaseName = "NET-COL"; break;
            case "34": diseaseName = "NET-PAN"; break;
            case "35": diseaseName = "THO-THY"; break;
            case "36": diseaseName = "THO-LUN"; break;
            case "37": diseaseName = "THO-MES"; break;
            case "38.1": diseaseName = "BON-SKE"; break;
            case "38.2": diseaseName = "BON-SPI"; break;
            case "38.3": diseaseName = "BON-PEL"; break;
            case "40": diseaseName = "STS-HAN"; break;
            case "41": diseaseName = "STS-TEX"; break;
            case "42": diseaseName = "STS-ABD"; break;
            case "43.1": diseaseName = "STS-GIS-GAS"; break;
            case "43.2": diseaseName = "STS-GIS-OTH"; break;
            case "44": diseaseName = "STS-RET"; break;
            case "45": diseaseName = "STS-UHI"; break;
            case "46": diseaseName = "SKN-MCC"; break;
            case "47": diseaseName = "SKN-MSK"; break;
            case "48.1": diseaseName = "BRE-DCIS"; break;
            case "48.2": diseaseName = "BRE-INV"; break;
            case "50": diseaseName = "GYN-VUL"; break;
            case "51": diseaseName = "GYN-VAG"; break;
            case "52": diseaseName = "GYN-CER-8TH"; break;
            case "53": diseaseName = "GYN-COR-CAR"; break;
            case "54.1": diseaseName = "GYN-CUS-LES"; break;
            case "54.2": diseaseName = "GYN-CUS-ADE"; break;
            case "55": diseaseName = "GYN-OVA"; break;
            case "56": diseaseName = "GYN-GTN"; break;
            case "57": diseaseName = "MAG-PEN"; break;
            case "58": diseaseName = "MAG-PRO"; break;
            case "59": diseaseName = "MAG-TES"; break;
            case "6": diseaseName = "HAN-CLN"; break;
            case "60": diseaseName = "KUT-KID"; break;
            case "61.1": diseaseName = "KUT-RPU-URO"; break;
            case "61.2": diseaseName = "KUT-RPU-SQU"; break;
            case "62.1": diseaseName = "KUT-BLA-URO"; break;
            case "62.2": diseaseName = "KUT-BLA-SQU"; break;
            case "63.1": diseaseName = "KUT-URE-MFU-URO"; break;
            case "63.2": diseaseName = "KUT-URE-MFU-SQU"; break;
            case "63.3": diseaseName = "KUT-URE-PRO-URO"; break;
            case "63.4": diseaseName = "KUT-URE-PRO-SQU"; break;
            case "64": diseaseName = "OPH-EYL"; break;
            case "65": diseaseName = "OPH-CCO"; break;
            case "66": diseaseName = "OPH-CMO"; break;
            case "67.1": diseaseName = "OPH-UVE-IRS"; break;
            case "67.2": diseaseName = "OPH-UVE-CCB"; break;
            case "68": diseaseName = "OPH-RET"; break;
            case "69": diseaseName = "OPH-LAC"; break;
            case "7": diseaseName = "HAN-LOR"; break;
            case "70": diseaseName = "OPH-ORB"; break;
            case "71": diseaseName = "OPH-OAL"; break;
            case "72": diseaseName = "CNS-BSC"; break;
            case "72.1":
            case "72.2": diseaseName = "CNS-BSC-MED-9TH"; break;
            case "73.1": diseaseName = "END-THY-DIF"; break;
            case "73.2": diseaseName = "END-THY-ANA"; break;
            case "74": diseaseName = "END-THY-MED"; break;
            case "75": diseaseName = "END-PAR"; break;
            case "76": diseaseName = "END-ADR-ACC"; break;
            case "77": diseaseName = "END-ADR-NET"; break;
            case "79.0": diseaseName = "HEM-LYM-NOS"; break;
            case "79.1": diseaseName = "HEM-LYM-DLB"; break;
            case "79.2": diseaseName = "HEM-LYM-MCL"; break;
            case "79.3": diseaseName = "HEM-LYM-FOL"; break;
            case "79.4": diseaseName = "HEM-LYM-MZL"; break;
            case "79.5": diseaseName = "HEM-LYM-CLL"; break;
            case "79.6": diseaseName = "HEM-LYM-PTC"; break;
            case "79.7": diseaseName = "HEM-LYM-HOD"; break;
            case "8": diseaseName = "HAN-MSG"; break;
            case "81.1": diseaseName = "HEM-PCL-MFS"; break;
            case "81.2": diseaseName = "HEM-PCL-PCB"; break;
            case "82.1": diseaseName = "HEM-MYE-PCM"; break;
            case "82.2": diseaseName = "HEM-MYE-OTH"; break;
            case "83.0": diseaseName = "HEM-LEU-NOS"; break;
            case "83.1": diseaseName = "HEM-LEU-AML"; break;
            case "83.2": diseaseName = "HEM-LEU-ALC"; break;
            case "83.3": diseaseName = "HEM-LEU-ALA"; break;
            case "83.4": diseaseName = "HEM-LEU-CML"; break;
            case "9": diseaseName = "HAN-PHA-NPC"; break;
            case "9001": diseaseName = "GYN-CER-9TH"; break //Cervix Uteri 
            case "9002": diseaseName = "LGI-APP-9TH"; break //Appendix
            case "9003": diseaseName = "LGI-ANU-9TH"; break //Anus
            case "9004": diseaseName = ""; break //Brain and Spinal Cord Other
            case "9005": diseaseName = "CNS-BSC-MED-9TH"; break //Brain and Spinal Cord Medulloblastoma
            case "9006": diseaseName = "GYN-VUL-9TH"; break //Vulva
            case "9007": diseaseName = "NET-STO-9TH"; break //Neuroendocrine Tumors of the Stomach
            case "9008": diseaseName = "NET-AMP-DUO-9TH"; break //Neuroendocrine Tumors of the Duodenum and Ampulla of Vater
            case "9009": diseaseName = "NET-JEJ-ILE-9TH"; break //Neuroendocrine Tumors of the Jejunum and Ileum
            case "9010": diseaseName = "NET-APP-9TH"; break //Neuroendocrine Tumors of the Appendix
            case "9011": diseaseName = "NET-COL-9TH"; break //Neuroendocrine Tumors of the Colon and Rectum
            case "9012": diseaseName = "NET-PAN-9TH"; break //Neuroendocrine Tumors of the Pancreas
            default: diseaseName = "";
        }

        let topoCode = this.props.selectedMedicalRecord.topoCode;
        var siteCode = topoCode.substring(1, 3);//{ dialog.object }.stateInfo.Topo_Code.substring(1, 3);
        if (siteCode == '53' && edition == '09') {
            diseaseName = 'GYN-CER-9TH';
        }

        var _this = this;
        //diseaseName = "LGI-APP";
        await axios.post(this.props.clientgroup + "/ShowAjccManualInfo", { ajccId: diseaseName, accessToken: localStorage.getItem('token') })
            .then(res => {
                //update new accessToken again
                var newAccessToken = res && res.data ? res.data.accessToken : null;
                if (newAccessToken) {
                    localStorage.setItem('token', newAccessToken);
                }

                if (!res.data.error) {
                    var tnmManual = res.data.tnmManual;
                    if (document.getElementById("Ajcc_Manual_Content")) {
                        var manualContent = document.getElementById("Ajcc_Manual_Content");
                        manualContent.setAttribute("class", "");

                        var tabClinical = manualContent.querySelector("#tabClinicalContent");
                        tabClinical && tnmManual.cClassification ? tabClinical.innerHTML = tnmManual.cClassification : "";

                        var tabPathological = manualContent.querySelector("#tabPathologicalContent");
                        tabPathological && tnmManual.pClassification ? tabPathological.innerHTML = tnmManual.pClassification : "";

                        var tabPrognosticFactors = manualContent.querySelector("#tabPrognosticFactorsContent");
                        tabPrognosticFactors && tnmManual.prognosticFactors ? tabPrognosticFactors.innerHTML = tnmManual.prognosticFactors : "";

                        var tabDefinitions = manualContent.querySelector("#tabDefinitionsContent");
                        tabDefinitions && tnmManual.definitions ? tabDefinitions.innerHTML = tnmManual.definitions : "";

                        // Chapter title
                        var chapterTitleAlias = manualContent.querySelectorAll(".Chapter_Title_Alias");
                        var titleArr = [...chapterTitleAlias]; // converts NodeList to Array
                        titleArr.forEach(chapterTitle => {
                            chapterTitle && tnmManual.title ? chapterTitle.innerHTML = tnmManual.title : "";
                        });

                        setTimeout(function () {
                            document.getElementById("linkOfClinicalManualTab") ? document.getElementById("linkOfClinicalManualTab").click() : null;
                        }, 100);
                    }
                } else {
                    _this.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                }
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });
    }

    componentWillUnmount() {
        this.setState({
            stagingForm: null,
            loadedPage: false,
            fn_disc_enabled: ""
        });
    }

    componentDidMount() {
        if (!this.state.stagingForm && this.props.selectedStagingRecord) {
            this.setFormData();
        }

        //
        if (this.props.selectedMedicalRecord && this.props.selectedPrimarySummary) {
            let siteCode = this.props.selectedPrimarySummary.siteCode;
            let dxYear = this.props.selectedPrimarySummary.diagnosisDateYear;
            //let dxYearBak = this.props.selectedPrimarySummary["dxYear_bak"];//restore the saved value from session in the server

            let topoCode = this.props.selectedMedicalRecord.topoCode;
            let histCode = this.props.selectedMedicalRecord.histCode;
            let sex = this.props.selectedPatient.sex;
            let ageDx = this.props.selectedMedicalRecord.ageDx;

            let topoCodeBak = this.props.selectedMedicalRecord["topoCode_bak"];
            let histCodeBak = this.props.selectedMedicalRecord["histCode_bak"];
            let sexBak = this.props.selectedPatient["sex_bak"] ? this.props.selectedPatient["sex_bak"] : this.props.selectedPatient["sexBak"];
            let ageDxBak = this.props.selectedMedicalRecord["ageDx_bak"] ? this.props.selectedMedicalRecord["ageDx_bak"] : this.props.selectedMedicalRecord["ageDxBak"];
            let dxYearBak = this.props.selectedMedicalRecord["dxYear_bak"] ? this.props.selectedMedicalRecord["dxYear_bak"] : this.props.selectedMedicalRecord["dxYearBak"];

            var ssdiHasChanged = false;
            if ((topoCodeBak && topoCode !== topoCodeBak) || (histCodeBak && histCode !== histCodeBak)
                || (dxYearBak && dxYear !== dxYearBak) || (sexBak && sex !== sexBak)
                || (ageDxBak && ageDx !== ageDxBak)) {
                ssdiHasChanged = true;
            }

            // 1) brand new primary (blank --> values)
            if (!topoCodeBak && !histCodeBak) {
                this.setState({ isFormDirty: true });
            } else {
                // 2) Change TOPO code to different site (schema AND AJCCID change)
                this.setState({
                    primSite: siteCode,
                    primTopo: topoCode,
                    primHist: histCode,

                    ssdiHasChanged: ssdiHasChanged,
                    hasChangedDataOfSchemaInfo: ssdiHasChanged,
                });
            }
            console.log("ssdiHasChanged", ssdiHasChanged);
        } else {
            let cid = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.cid : "0";
            this.getPrimInfo(cid);
        }
    }

    //@TODO: add this function [initializeStaging]
    componentDidUpdate(prevProps) {
        if (!this.props.selectedMedicalRecord) return false;

        if (!this.state.ssdiDescriptionArr && this.state.stagingForm && !this.state.loadSsdiListToBk) {
            var savedSchemaId = this.state.stagingForm.schemaId && this.state.stagingForm.schemaId != "" ? this.state.stagingForm.schemaId : "";
            var _this = this;
            axios.post(_this.props.clientgroup + "/GetSsdiItemLists", { schemaId: savedSchemaId, accessToken: localStorage.getItem('token') })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let ssdiItemLists = res.data.commonLookupList;
                        if (ssdiItemLists != null && ssdiItemLists.length > 0) {
                            var descriptionArr = [];
                            ssdiItemLists.map((t, index) => {
                                if (!ssdiItemLists[index] || !ssdiItemLists[index]["order"]) return false;
                                // then show it by order/name
                                try {
                                    ssdiItemLists[index]["description"] ? descriptionArr.push(ssdiItemLists[index]["description"]) : null;
                                } catch (exps) {
                                }
                            });
                            _this.setState({ ssdiDescriptionArr: descriptionArr, loadSsdiListToBk: true });
                        }
                    } else {
                        _this.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }

        if ((!this.state.showAjccManualInfo && ((this.state.stagingForm && this.state.stagingForm.ajccId) || this.state.ajccId))
            || this.state.ajccId && this.state.stagingForm && this.state.stagingForm.ajccId && this.state.ajccId != this.state.stagingForm.ajccId) {

            this.setState({
                showAjccManualInfo: true,
                stagingForm: {
                    ...this.state.stagingForm,
                    ajccId: this.state.ajccId
                }
            });

            this.showAjccManualInfoFnc();
        }

        if (this.state.stagingForm && !this.state.loadedPage) {
            this.Determine_Necessary_Discriminators("No");
            this.Determine_Schema_ID("No");

            this.setState({ loadedPage: true, lymphNdChanged: false });
        }

        if ((this.props.selectedPrimarySummary.siteCode === null || this.props.selectedPrimarySummary.siteCode === "")
            || (this.props.selectedMedicalRecord.topoCode === null || this.props.selectedMedicalRecord.topoCode === "")
            || (this.props.selectedMedicalRecord.histCode === null || this.props.selectedMedicalRecord.histCode === "")
            || this.props.selectedPrimarySummary.siteCode !== this.state.primSite
            || this.props.selectedMedicalRecord.topoCode !== this.state.primTopo
            || this.props.selectedMedicalRecord.histCode !== this.state.primHist) {
            if (!this.state.setPrimValue && this.props.selectedMedicalRecord && this.props.selectedMedicalRecord.cid != null && this.state.sAllItemFields) {
                let cid = this.props.selectedMedicalRecord.cid;

                let siteCode = this.props.selectedPrimarySummary.siteCode;
                let topoCode = this.props.selectedMedicalRecord.topoCode;
                let histCode = this.props.selectedMedicalRecord.histCode;

                this.setState({
                    setPrimValue: true,
                    primSite: siteCode,
                    primTopo: topoCode,
                    primHist: histCode
                });

                this.reloadPrimInfo(cid, topoCode, histCode);
            }
        } else {
            if (!this.state.setPrimValue && (this.state.primSite === null || this.state.primTopo === null || this.state.primHist === null) && this.state.sAllItemFields) {
                let cid = this.props.selectedMedicalRecord.cid;
                let siteCode = this.props.selectedPrimarySummary.siteCode;
                let topoCode = this.props.selectedMedicalRecord.topoCode;
                let histCode = this.props.selectedMedicalRecord.histCode;

                this.setState({
                    setPrimValue: true,
                    primSite: siteCode,
                    primTopo: topoCode,
                    primHist: histCode
                });

                this.reloadPrimInfo(cid, topoCode, histCode);
            }
        }

        if ((!this.state.stagingForm && this.props.selectedStagingRecord)
            || (prevProps.selectedStagingRecord && this.props.selectedStagingRecord && prevProps.selectedStagingRecord !== this.props.selectedStagingRecord)
            || (this.props.initialAbstractPages && this.props.selectedStagingRecord)) {
            this.setFormData();
        }
        if (prevProps.selectedStagingRecord && prevProps.selectedStagingRecord !== this.props.selectedStagingRecord) {
            let _isFormDirty = this.state.isFormDirty ? this.state.isFormDirty : false;
            this.setState({ isFormDirty: this.props.isFormDirty ? this.props.isFormDirty : _isFormDirty });
        }

        if (this.state.ssdiHasChanged && this.state.stagingForm) {
            let topoCode = this.props.selectedMedicalRecord.topoCode;
            let histCode = this.props.selectedMedicalRecord.histCode;

            let topoCodeBak = this.props.selectedMedicalRecord["topoCode_bak"];
            let histCodeBak = this.props.selectedMedicalRecord["histCode_bak"];

            //
            if (!topoCode || !histCode) {
                this.setState({ isFormDirty: true });
            } else {
                this.Check_Schema_ID_Has_Changed(topoCode, histCode, topoCodeBak, histCodeBak);//Topo_Code, Hist_Code, Topo_Code2, Hist_Code2
            }

            this.setState({ ssdiHasChanged: false });
        }

        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.stagingForm && this.state.sAllItemFields) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    stagingForm: { ...this.state.stagingForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                if (this.props.currentSelectedField.indexOf("csSsf") != -1) {
                    this.setState({
                        sAllItemFields: { ...this.state.sAllItemFields, [this.props.currentSelectedField]: "IN_VALID" },
                        // And also update name again
                        stagingForm: { ...this.state.stagingForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                } else {
                    var _this = this;
                    if (_this.props.currentSelectedField == "ndPos") {//lymphNd
                        _this.setState({
                            sAllItemFields: {
                                ..._this.state.sAllItemFields,
                                [_this.props.currentSelectedField]: (_this.state.showConfirmationSchemaIdHasChanged ? "" : (_this.state.lymphNdChanged ? "IN_VALID" : ""))
                            },
                            // And also update name again
                            stagingForm: { ..._this.state.stagingForm, [_this.props.currentSelectedField + "Name"]: "" },
                        });
                    } else {
                        _this.setState({
                            sAllItemFields: {
                                ..._this.state.sAllItemFields,
                                [_this.props.currentSelectedField]: "IN_VALID"
                            },
                            // And also update name again
                            stagingForm: { ..._this.state.stagingForm, [_this.props.currentSelectedField + "Name"]: "" },
                        });
                    }
                }
            }
            this.props.clearCommonLookupItem();
        }

        // set maxlength for all fields
        if (this.state.tableStructureList === null && !this.state.getTableStructure) {
            this.getTableStructure();
            this.setState({ getTableStructure: true });
        } else {
            if (this.state.hasSetMaxLength && this.state.tableStructureList && this.state.tableStructureList.length > 0 && this.state.pageMaxlengthInput) {
                let maxLengthValue = this.state.tableStructureList;
                let maxLengthItem = this.state.pageMaxlengthInput;

                maxLengthValue = maxLengthValue.split(',');
                for (var i = 0; i < maxLengthValue.length; i++) {
                    let obj = maxLengthValue[i].split(':');
                    maxLengthItem[obj[0]] = (obj[1] === "-1" ? "" : obj[1]);
                }

                this.setState({
                    pageMaxlengthInput: {
                        ...this.state.pageMaxlengthInput,
                        maxLengthItem,
                    },
                    //Ssdi fields
                    pageMaxlengthInputForSsdis: {
                        ...this.state.pageMaxlengthInputForSsdis,
                        maxLengthItem,
                    },
                    hasSetMaxLength: false,
                });
            }
        }

        if (!this.state.confirmingAllCsSsf && this.state.schemaName !== "" && this.props.selectedStagingRecord) {
            this.setFormData();
            this.setState({ confirmingAllCsSsf: true });
        }
    }

    async getTableStructure() {
        let url = this.props.clientgroup + "/GetTableStructure";
        let res = await axios
            .post(url, { table: "Staging", table2: "Ssdi", table3: "Diagnosis", accessToken: localStorage.getItem('token') })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });

        //update new accessToken again
        var newAccessToken = res && res.data ? res.data.accessToken : null;
        if (newAccessToken) {
            localStorage.setItem('token', newAccessToken);
        }

        this.setState({
            hasSetMaxLength: true,
            tableStructureList: res.data ? res.data.result : null,
            getTableStructure: true
        });
    }

    setFormData = () => {
        var dataForm = this.props.selectedStagingRecord;
        var siteCode = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.siteCode : "";
        var dxYear = this.props.selectedPrimarySummary ? this.props.selectedPrimarySummary.diagnosisDateYear : "";
        var schemaName = this.state.schemaName;
        //var ajccId = this.state.ajccId;

        const _isFormDirty = this.state.isFormDirty;

        Object.keys(dataForm).map((t) => {
            let dataVal = dataForm[t];
            let defaultValue = null;

            if (t === "tnmEdNbr" && dxYear >= "2018" && dataVal !== "88" && dataVal !== "99") {

                defaultValue = "08";
                
                if (siteCode === "42") {
                    if (dxYear >= "2022") {
                        defaultValue = "08";
                    } else {
                        defaultValue = "88";
                    }
                }

                // 2021 //
                if (dxYear >= "2021" && schemaName === "09520") { 
                    defaultValue = "09"; //Cervix
                }

                // 2023 //
                if (dxYear >= "2023" && schemaName === "09210") {
                    defaultValue = "09"; //Anus
                }

                if (dxYear >= "2023" && schemaName === "09190") {
                    defaultValue = "09"; //Appendix
                }

                if (dxYear >= "2023" && schemaName === "09721") {
                    defaultValue = "09"; //Brain
                }

                if (dxYear >= "2023" && schemaName === "09722") {
                    defaultValue = "09"; //CNS
                }

                if (dxYear >= "2023" && schemaName === "09723") {
                    defaultValue = "09"; //Intracranial Gland
                }

                if (dxYear >= "2023" && schemaName === "09724") {
                    defaultValue = "09"; //Medulloblastoma
                }

                // 2024 //
                if (dxYear >= "2024" && schemaName === "09290") {
                    defaultValue = "09"; //NET Stomach
                }

                if (dxYear >= "2024" && schemaName === "09301") {
                    defaultValue = "09"; //NET Duodenum
                }

                if (dxYear >= "2024" && schemaName === "09302") {
                    defaultValue = "09"; //NET Ampulla of Vater
                }

                if (dxYear >= "2024" && schemaName === "09310") {
                    defaultValue = "09"; //NET Jejunum and Ileum
                }

                if (dxYear >= "2024" && schemaName === "09320") {
                    defaultValue = "09"; //NET Appendix
                }

                if (dxYear >= "2024" && schemaName === "09330") {
                    defaultValue = "09"; //NET Colon and Rectum
                }

                if (dxYear >= "2024" && schemaName === "09340") {
                    defaultValue = "09"; //NET Pancreas
                }

                if (dxYear >= "2024" && schemaName === "09500") {
                    defaultValue = "09"; //Vulva
                }


                // if (dxYear >= "2023" && schemaName !== "09520") {
                //     switch (schemaName) {
                //         case "09210":defaultValue = "09";break; //Anus
                //         case "09190":defaultValue = "09";break; //Appendix
                //         case "09721":defaultValue = "09";break; //Brain
                //         case "09722":defaultValue = "09";break; //CNS
                //         case "09723":defaultValue = "09";break; //Intracranial Gland
                //         case "09724":defaultValue = "09";break; //Medulloblastoma
                //         default: defaultValue = "08";
                //     }
                // }

                // if (dxYear >= "2024") {
                //     switch (schemaName) {
                //         case "09290":defaultValue = "09";break; //NET Stomach
                //         case "09301":defaultValue = "09";break; //NET Duodenum
                //         case "09302":defaultValue = "09";break; //NET Ampulla of Vater
                //         case "09310":defaultValue = "09";break; //NET Jejunum and Ileum
                //         case "09320":defaultValue = "09";break; //NET Appendix
                //         case "09330":defaultValue = "09";break; //NET Colon and Rectum 
                //         case "09340":defaultValue = "09";break; //NET Pancreas
                //         case "09500":defaultValue = "09";break; //Vulva
                //         default: defaultValue = "08";
                //     }
                // }

                // if (siteCode === "53") {
                //     if (dxYear >= "2018" && dxYear <= "2020") {
                //         defaultValue = "08";
                //     } else {
                //         defaultValue = "88";
                //     }
                //     if (dxYear >= "2021") {
                //         defaultValue = "09";
                //     }
                // };            
            } else if (t === "tnmEdNbr" && (dataVal === null || dataVal === "" || dataVal === undefined)) {
                defaultValue = "08";
            }
 
            //if (t === "tnmEdNbr" && (dataVal === null || dataVal === "" || dataVal === undefined)) {
            if (t === "tnmEdNbr" && defaultValue != null) {
                dataVal = defaultValue;
            } else if ((t === "pStgDescriptor" || t === "cStgDescriptor") && (dataVal === null || dataVal === "" || dataVal === undefined)) {
                dataVal = "0";

                // IMPORTANT!!!
            } else if (t.indexOf("csSsf") !== -1 && schemaName !== "") {
                if (dataForm["schemaId"] !== schemaName) {
                    dataVal = " ";
                }
            }

            dataForm[t] = dataVal;
        });

        // only make below objects when they're not existed
        let dynamicItemFields = this.state.dynamicItemFields ? this.state.dynamicItemFields : {};
        let showDynamicItemFields = this.state.showDynamicItemFields ? this.state.showDynamicItemFields : {};
        if (!this.state.dynamicItemFields && !this.state.showDynamicItemFields) {
            Object.keys(this.props.selectedStagingRecord).map((t) => {
                dynamicItemFields[t] = " ";
                showDynamicItemFields[t] = "hidden";
            });
        }

        let lookupOfItemFields = this.state.lookupOfItemFields;
        let itemNoLookupOnPopup = this.state.itemNoLookupOnPopup;

        /*
        //Fixing #PR00335 START
        var schemaOrAjccIdChanged = false;
        if ((schemaName && dataForm["schemaId"] !== schemaName) || (ajccId && dataForm["ajccId"] !== ajccId)) {
            schemaOrAjccIdChanged = true;
        }

        var diagnosisChanged = false;
        if (this.props.selectedMedicalRecord && this.props.selectedDiagnosisRecord
            && (this.props.selectedMedicalRecord.topoCode != this.props.selectedDiagnosisRecord.topoCode
            || this.props.selectedMedicalRecord.histCode != this.props.selectedDiagnosisRecord.histCode)
        ) {
            diagnosisChanged = true;
        }
        //Fixing #PR00335 END
        */

        this.setState({
            stagingForm: { ...dataForm },
            initialStagingForm: { ...dataForm },

            dynamicItemFields: { ...dynamicItemFields },
            showDynamicItemFields: { ...showDynamicItemFields },

            lookupOfItemFields: (lookupOfItemFields ? lookupOfItemFields : { ...dataForm }),
            itemNoLookupOnPopup: (itemNoLookupOnPopup ? itemNoLookupOnPopup : { ...dataForm }),

            //isFormDirty: (diagnosisChanged && schemaOrAjccIdChanged) || this.props.isFormDirty
            isFormDirty: _isFormDirty || this.props.isFormDirty
        });

        if (!this.state.sAllItemFields && this.props.selectedStagingRecord) {
            let fields = {};
            Object.keys(this.props.selectedStagingRecord).map((t) => {
                fields[t] = " ";
            });

            this.setState({
                sAllItemFields: fields,
                pageMaxlengthInput: fields,
                pageMaxlengthInputForSsdis: fields
            });
        };

        if (this.state.showConfirmationSchemaIdHasChanged) {
            setTimeout(function () {
                document.getElementById("staging2018MsgConfirmation_btnYes") ? document.getElementById("staging2018MsgConfirmation_btnYes").focus() : null;
            }, 1000);
        }

        this.props.setInitialAbstractPages(false);
        return false;
    }

    toggleTab = (event, tab) => {
        try {
            event.preventDefault();
        } catch (exp) { }
        this.setState({ activeTab: tab });
        if (tab === "1") {
            setTimeout(function () {
                document.getElementById("ndPos").focus();//lymphNd
            }, 0);
        } else if (tab === "2") {
            var focused = false;
            setTimeout(function () {
                document.getElementById("SSDI_USER_1").focus();
                focused = true;
            }, 0);
            setTimeout(function () {
                !focused ? document.getElementById("SSDI_USER_1").focus() : "";
            }, 1000);
        } else if (tab === "3") {
            setTimeout(function () {
                document.getElementById("ajccBasis").focus();
            }, 0);
        }
        return false;
    }

    saveStaging = (event) => {
        event.preventDefault();

        // TODO - check form validation
        let isValid = true;
        const allowSaveAnyway = this.state.allowSaveAnyway;

        const formItems = this.state.sAllItemFields;
        Object.keys(formItems).map((t) => {
            if (formItems[t] === "IN_VALID") {
                return isValid = false;
            }
        });

        if (!isValid && !allowSaveAnyway) {
            return false;
        } else {
            let stagingFormData = this.state.stagingForm;
            stagingFormData.cid = this.props.selectedMedicalRecord.cid;

            stagingFormData.schemaId = this.state.schemaName;
            stagingFormData.ajccId = this.state.ajccId;

            // SSDI fields
            /*
            let lookupOfItemFields = this.state.lookupOfItemFields;
            for (var tmpNo = 1; tmpNo <= 25; tmpNo++) {
                if (lookupOfItemFields["csSsf" + tmpNo] && lookupOfItemFields["csSsf" + tmpNo] != "") {
                    var fieldName = this.getFieldNameByIndex(tmpNo);
                    /*
                    this.setState({
                        stagingForm: {
                            ...this.state.stagingForm,
                            [fieldName]: stagingFormData["csSsf" + tmpNo]
                        }
                    });
                    * /
                    stagingFormData[fieldName] = stagingFormData["csSsf" + tmpNo];
                }
            }
            */
            stagingFormData.schemaName = this.state.schemaId;
            this.props.saveStaging(stagingFormData, "2018plus");

            // clear this flag to update the status of page after saved!
            this.setState({ determinedSchemaId: false, savingData: true, isFormDirty: false });
            return false;
        }
    }

    resetStaging = () => {
        this.setState({
            stagingForm: { ...this.state.initialStagingForm },
            isFormDirty: false,
        });
    }

    handleOnFocus = (event) => {
        event.preventDefault();
        if (event.target.getAttribute("data-help-naming") && event.target.getAttribute("data-help-naming") != undefined) {
            var helpTitle = event.target.getAttribute("data-help-title") && event.target.getAttribute("data-help-title") != undefined ? event.target.getAttribute("data-help-title") : "";
            this.setState({
                fieldInFocus: event.target.name,
                focusingField: event.target,
                savingData: false,
                ssdiTitle: helpTitle
            });
        } else {
            this.setState({
                fieldInFocus: event.target.name,
                focusingField: event.target,
                savingData: false,
                ssdiTitle: null
            });
        }
    }

    helpFocusingField = (field) => {
        this.setState({ fieldInFocus: field });
        return false;
    }

    getHelp = (event) => {
        event.preventDefault();
        if (this.state.fieldInFocus) {
            this.setState({ isHelpVisible: true });
        }
        return false;
    }

    closeCodeDialog = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        this.setState({ isHelpVisible: false });
        return false;
    }

    nextSSDI = (userControlName) => {
        var userNumber = userControlName.substr(userControlName.length - 1);
        userNumber = Number(userNumber) + 1;

        if (userNumber < 21) {
            var nextSSDIVisibility = document.getElementById('SSDI_USER_' + userNumber).style.visibility;
            if (nextSSDIVisibility == 'hidden') {
                setTimeout(function () {
                    document.getElementById("linkOfAjccTnmTab").click();
                }, 100);
            } else {
                var parentDiv = document.getElementById('SSDI_USER_' + userNumber).parentElement;
                if (parentDiv.style.visibility == 'hidden'
                    || (parentDiv.hasAttribute('class') && parentDiv.getAttribute('class').indexOf('hidden') !== -1)) {
                    setTimeout(function () {
                        document.getElementById("linkOfAjccTnmTab").click();
                    }, 100);
                }
            }
        }
    }

    // Add lostfocus event for all input fields on screen - START
    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            if (event.target.name === "ndPos") {//lymphNd
                let lymphNdVal = event.target.value;
                let lymphNdOld = this.state.initialStagingForm && this.state.initialStagingForm.ndPos ? this.state.initialStagingForm.ndPos : "";
                if (lymphNdVal == lymphNdOld) {
                    return false;
                }
            }

            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "";
            let pcy = event.target.getAttribute('data-pcy') ? event.target.getAttribute('data-pcy') : "";
            let cid = this.props.selectedPrimarySummary.cid;

            if (event.target.name === "ndPos") {//lymphNd
                if (this.state.lymphNdChanged) {
                    this.props.checkValidInput(name, value, table, key, -1, flag, cid, pcy);
                }
            } else {
                this.props.checkValidInput(name, value, table, key, -1, flag, cid, pcy);
            }
        } else {
            this.setState({ sAllItemFields: { ...this.state.sAllItemFields, [event.target.name]: "" } });
        }

        // add onchange event on alpha system
        var stg2018 = this;
        let schemaIdOnState = stg2018.state.schemaId;
        let schemaName = stg2018.state.schemaName;
        if (event.target.name === "schemaDiscrim1") {
            stg2018.Determine_Necessary_Discriminators("Yes");
            stg2018.Determine_Schema_ID("Yes");
        } else if (event.target.name === "schemaDiscrim2") {
            stg2018.Determine_Schema_ID("Yes");
        } else if (event.target.name === "ndPos" /*"lymphNd"*/ && schemaIdOnState !== null && schemaIdOnState !== "" && !stg2018.state.determinedSchemaId) {
            console.log("schemaIdOnState=" + schemaIdOnState);
            stg2018.setState({ determinedSchemaId: true });
            setTimeout(function () {
                axios.post(stg2018.props.clientgroup + "/GetSsdiItemLists", { schemaId: schemaName, accessToken: localStorage.getItem('token') })
                    .then(res => {
                        //update new accessToken again
                        var newAccessToken = res && res.data ? res.data.accessToken : null;
                        if (newAccessToken) {
                            localStorage.setItem('token', newAccessToken);
                        }

                        if (!res.data.error) {
                            let ssdiItemLists = res.data.commonLookupList;
                            if (ssdiItemLists != null && ssdiItemLists.length > 0) {
                                // first hide all fields...
                                let dif = stg2018.state.dynamicItemFields;
                                let sdi = stg2018.state.showDynamicItemFields;
                                let loi = stg2018.state.lookupOfItemFields;
                                let iNo = stg2018.state.itemNoLookupOnPopup;

                                ssdiItemLists.map((t, index) => {
                                    //index = index + 1;
                                    if (!ssdiItemLists[index] || !ssdiItemLists[index]["order"]) return false;
                                    // then show it by order/name
                                    /*
                                    dif["csSsf" + ssdiItemLists[index]["order"]] ? dif["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"] : "";//label
                                    loi["csSsf" + ssdiItemLists[index]["order"]] ? loi["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"] : "";//value
                                    iNo["csSsf" + ssdiItemLists[index]["order"]] ? iNo["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"] : "";//itemNbr
                                    sdi["csSsf" + ssdiItemLists[index]["order"]] ? sdi["csSsf" + ssdiItemLists[index]["order"]] = "" : "";//show or hide field
                                    */
                                    try {
                                        dif["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"];
                                    } catch (exps) {
                                    }
                                    try {
                                        loi["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"];
                                    } catch (exps) {
                                    }
                                    try {
                                        iNo["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"];
                                    } catch (exps) {
                                    }
                                    try {
                                        sdi["csSsf" + ssdiItemLists[index]["order"]] = "";
                                    } catch (exps) {
                                    }
                                });

                                var schemaIdDisplaying = stg2018.state.schemaIdDisplaying;

                                var startFromIndex = 0;
                                if (schemaIdDisplaying.indexOf("Discriminator Check") != -1) {
                                    startFromIndex = 0;
                                } else {
                                    startFromIndex = ssdiItemLists.length + 1;
                                }

                                for (var idx = startFromIndex; idx <= 25; idx++) {
                                    try {
                                        dif["csSsf" + idx] = null;//label
                                    } catch (exps) { }
                                    try {
                                        sdi["csSsf" + idx] = "hidden";//show or hide field
                                    } catch (exps) { }
                                }

                                stg2018.setState({
                                    dynamicItemFields: dif,
                                    showDynamicItemFields: sdi,
                                    lookupOfItemFields: loi,
                                    itemNoLookupOnPopup: iNo,
                                });
                            } else {
                                // when initial is hidden all fields
                                if (stg2018.state.dynamicItemFields && stg2018.props.selectedStagingRecord) {
                                    let fields = {};
                                    Object.keys(stg2018.props.selectedStagingRecord).map((t) => {
                                        fields[t] = " ";
                                    });

                                    stg2018.setState({ dynamicItemFields: { ...fields } });
                                };
                                if (stg2018.state.showDynamicItemFields && stg2018.props.selectedStagingRecord) {
                                    let fields = {};
                                    Object.keys(stg2018.props.selectedStagingRecord).map((t) => {
                                        fields[t] = "hidden";
                                    });

                                    stg2018.setState({
                                        showDynamicItemFields: { ...fields },
                                    });
                                };
                            }
                        } else {
                            stg2018.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        return false;
                    });
            }, 500);
        }
    }
    // -- END

    async Determine_Necessary_Discriminators(Use_Screen_Values) {
        var Topo_Code = this.state.primTopo;
        var Hist_Code = this.state.primHist;
        var stg2018 = this;
        if (Topo_Code !== "" && Hist_Code !== "") {
            await axios.post(this.props.clientgroup + "/DetermineNecessaryDiscriminators", {
                "site": Topo_Code,
                "hist": Hist_Code,
                "sex": this.props.selectedPatient.sex,
                "disc1": (this.state.stagingForm.schemaDiscrim1 != null && this.state.stagingForm.schemaDiscrim1 != undefined ? this.state.stagingForm.schemaDiscrim1 : ""),
                "disc2": (this.state.stagingForm.schemaDiscrim2 != null && this.state.stagingForm.schemaDiscrim2 != undefined ? this.state.stagingForm.schemaDiscrim2 : ""),
                "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//SEERAPI_id
                        let totalRows = res.data.commonLookupItem ? res.data.commonLookupItem.id : 0;//Total_Rows

                        //FN_Disc_Schema_Id=Schema_ID
                        //if (schemaId !== "") {
                        /*
                        if total_rows > 0 .And. Use_Screen_Value = "No" then 'When called from screen loading, go ahead and update this control
                            js = js + "{dialog.Object}.setValue('FN_Disc_Enabled', '1');"
                        end if
                        */
                        stg2018.setState({
                            fn_disc_schema_id: schemaId != "" ? schemaId : stg2018.state.fn_disc_schema_id,
                            //fn_disc_enabled: (totalRows > 0 && Use_Screen_Values == "No" ? "1" : ""),
                        });

                        if (totalRows > 0 && Use_Screen_Values == "No") {
                            stg2018.setState({ fn_disc_enabled: "1" });
                        } else if (totalRows == 0 && Use_Screen_Values == "No") {
                            stg2018.setState({ fn_disc_enabled: "" });
                        }
                        //}
                    } else {
                        stg2018.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }
    }

    async Determine_Schema_ID(Use_Screen_Values) {
        var Topo_Code = this.state.primTopo;
        var Hist_Code = this.state.primHist;

        var _this = this;

        var schemaDiscrim1 = this.state.stagingForm.schemaDiscrim1 != null && this.state.stagingForm.schemaDiscrim1 != undefined ? this.state.stagingForm.schemaDiscrim1 : "";
        var schemaDiscrim2 = this.state.stagingForm.schemaDiscrim2 != null && this.state.stagingForm.schemaDiscrim2 != undefined ? this.state.stagingForm.schemaDiscrim2 : "";

        if (Topo_Code !== "" && Hist_Code !== "") {
            var savedSchemaId = this.state.stagingForm && this.state.stagingForm.schemaId && this.state.stagingForm.schemaId != "" ? this.state.stagingForm.schemaId : "";
            await axios.post(this.props.clientgroup + "/DetermineSchemaId", {
                "site": Topo_Code,
                "hist": Hist_Code,
                "sex": this.props.selectedPatient.sex,
                "ageDx": this.props.selectedMedicalRecord.ageDx,
                "disc1": schemaDiscrim1,
                "disc2": schemaDiscrim2,
                "dxYear": (this.props.selectedPrimarySummary != null ? this.props.selectedPrimarySummary.diagnosisDateYear : ""),
                "useScreenValue": Use_Screen_Values,
                accessToken: localStorage.getItem('token')
            })
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (!res.data.error) {
                        let schemaId = res.data.commonLookupItem && res.data.commonLookupItem.code && res.data.commonLookupItem.code !== "" ? res.data.commonLookupItem.code : "";//Code = SeerapiId
                        let schemaName = res.data.commonLookupItem && res.data.commonLookupItem.name ? res.data.commonLookupItem.name : "";//Name = SchemaName
                        let totalRows = res.data.commonLookupItem && res.data.commonLookupItem.id ? res.data.commonLookupItem.id : 0;//Total_Rows
                        let description = res.data.commonLookupItem && res.data.commonLookupItem.description ? res.data.commonLookupItem.description : "";

                        var schemaIdDisplaying = schemaId;
                        var schemaIdDisplaying_WithError = "";
                        if (totalRows > 1) {
                            schemaIdDisplaying = "Discriminator Check";
                            schemaIdDisplaying_WithError = description;//schemaId
                        }

                        let ajccId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccId : "";
                        let ajccDiseaseId = res.data.commonLookupItem ? res.data.commonLookupItem.ajccDiseaseId : "";

                        //
                        var _schemaId = _this.state.schemaId ? _this.state.schemaId : null;
                        var _ajccId = _this.state.ajccId ? _this.state.ajccId : null;

                        //FN_Schema_Id=Schema_Name
                        //if (schemaId !== "") {
                        _this.setState({
                            schemaId: schemaId,
                            schemaIdDisplaying: "Schema ID: " + schemaIdDisplaying,
                            schemaName: schemaName,

                            fn_schema_id: schemaId,
                            ajccId: ajccId,
                            ajccDiseaseId: ajccDiseaseId,
                            schemaIdDisplaying_WithError: schemaIdDisplaying_WithError,

                            //
                            isFormDirty: _this.state.isFormDirty || (_schemaId && _schemaId != schemaId) || (_ajccId && _ajccId != ajccId)
                        });

                        var stg2018 = _this;

                        axios.post(_this.props.clientgroup + "/GetSsdiItemLists", { schemaId: schemaName, accessToken: localStorage.getItem('token') })
                            .then(res => {
                                //update new accessToken again
                                var newAccessToken = res && res.data ? res.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (!res.data.error) {
                                    let ssdiItemLists = res.data.commonLookupList;
                                    if (ssdiItemLists != null && ssdiItemLists.length > 0) {
                                        // first hide all fields...
                                        let dif = _this.state.dynamicItemFields;
                                        let sdi = _this.state.showDynamicItemFields;
                                        let loi = _this.state.lookupOfItemFields;
                                        let iNo = _this.state.itemNoLookupOnPopup;

                                        ssdiItemLists.map((t, index) => {
                                            //index = index + 1;
                                            if (!ssdiItemLists[index] || !ssdiItemLists[index]["order"]) return false;
                                            // then show it by order/name
                                            /*
                                            dif["csSsf" + ssdiItemLists[index]["order"]] ? dif["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"] : "";//label
                                            loi["csSsf" + ssdiItemLists[index]["order"]] ? loi["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"] : "";//value
                                            iNo["csSsf" + ssdiItemLists[index]["order"]] ? iNo["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"] : "";//itemNbr
                                            sdi["csSsf" + ssdiItemLists[index]["order"]] ? sdi["csSsf" + ssdiItemLists[index]["order"]] = "" : "";//show or hide field
                                            */
                                            try {
                                                dif["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["description"];
                                            } catch (exps) {
                                            }
                                            try {
                                                loi["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["name"];
                                            } catch (exps) {
                                            }
                                            try {
                                                iNo["csSsf" + ssdiItemLists[index]["order"]] = ssdiItemLists[index]["code"];
                                            } catch (exps) {
                                            }
                                            try {
                                                sdi["csSsf" + ssdiItemLists[index]["order"]] = "";
                                            } catch (exps) {
                                            }
                                        });

                                        var startFromIndex = 0;
                                        if (schemaIdDisplaying.indexOf("Discriminator Check") != -1) {
                                            startFromIndex = 0;
                                        } else {
                                            startFromIndex = ssdiItemLists.length + 1;
                                        }

                                        for (var idx = startFromIndex; idx <= 25; idx++) {
                                            try {
                                                dif["csSsf" + idx] ? dif["csSsf" + idx] = null : "";//label
                                            } catch (exps) { }

                                            try {
                                                sdi["csSsf" + idx] ? sdi["csSsf" + idx] = "hidden" : "";//show or hide field
                                            } catch (exps) { }
                                        }

                                        setTimeout(function () {
                                            stg2018.setState({
                                                dynamicItemFields: dif,
                                                showDynamicItemFields: sdi,
                                                lookupOfItemFields: loi,
                                                itemNoLookupOnPopup: iNo,
                                            });
                                            return false;
                                        }, 500);
                                    } else {
                                        // when initial is hidden all fields
                                        if (stg2018.state.dynamicItemFields && stg2018.props.selectedStagingRecord) {
                                            let fields = {};
                                            Object.keys(stg2018.props.selectedStagingRecord).map((t) => {
                                                fields[t] = " ";
                                            });

                                            stg2018.setState({ dynamicItemFields: { ...fields } });
                                        };
                                        if (stg2018.state.showDynamicItemFields && stg2018.props.selectedStagingRecord) {
                                            let fields = {};
                                            Object.keys(stg2018.props.selectedStagingRecord).map((t) => {
                                                fields[t] = "hidden";
                                            });

                                            stg2018.setState({
                                                showDynamicItemFields: { ...fields },
                                            });
                                        };
                                    }
                                } else {
                                    stg2018.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                                return false;
                            });
                        //}

                        /*
                        When opening the staging screen, and calculating schema_Id.
                        If newly calculated schema_id is different than the previously saved schema_id (staging table)
                        */
                        if (savedSchemaId != schemaName && savedSchemaId != schemaId && stg2018.state.hasChangedDataOfSchemaInfo) {
                            //the user should be prompted with a message that says “SEER Schema has changed. Would you like to delete previously saved SSDI’s that no longer apply to the current schema?” 
                            //[Yes / No]
                            //If the user chooses “Yes” we will need to delete SSDIs that correspond the the saved schema and are not applicable to the new schema.
                            stg2018.setState({
                                sAllItemFields: {
                                    ...stg2018.state.sAllItemFields,
                                    ["ndPos"]: "",
                                    //["lymphNd"]: ""
                                },
                                showConfirmationSchemaIdHasChanged: true
                            });
                        }
                    } else {
                        _this.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }
    }

    handleFormChange = (event) => {
        // SSDI fields
        var fid = event.target.id;
        if (fid && fid.indexOf("SSDI_USER_") != -1) {
            var index = fid.replace("SSDI_USER_", "");
            var fieldName = this.getFieldNameByIndex(index);
            this.setState({
                stagingForm: {
                    ...this.state.stagingForm,
                    [fieldName]: event.target.value,
                    [event.target.name]: event.target.value,
                    ["ssdiHasChanged"]: "1"
                },
                isFormDirty: true
            });
            return false;
        }

        if (event.target2) {
            var fname = event.target.name;
            if (fname && fname.indexOf("csSsf") != -1) {
                var index = fname.replace("csSsf", "");
                var fieldName = this.getFieldNameByIndex(index);
                this.setState({
                    stagingForm: {
                        ...this.state.stagingForm,
                        [fieldName]: event.target.value,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value,
                        ["ssdiHasChanged"]: "1"
                    },
                    isFormDirty: true
                });
                return false;
            }

            this.setState({ stagingForm: { ...this.state.stagingForm, [event.target.name]: event.target.value, [event.target2.name]: event.target2.value }, isFormDirty: true });
        } else {
            var fname = event.target.name;
            if (fname && fname.indexOf("csSsf") != -1) {
                var index = fname.replace("csSsf", "");
                var fieldName = this.getFieldNameByIndex(index);
                this.setState({
                    stagingForm: {
                        ...this.state.stagingForm,
                        [fieldName]: event.target.value,
                        [event.target.name]: event.target.value,
                        ["ssdiHasChanged"]: "1"
                    },
                    isFormDirty: true
                });
                return false;
            }

            this.setState({
                stagingForm: {
                    ...this.state.stagingForm,
                    [event.target.name]: event.target.value
                }, isFormDirty: true,
                lymphNdChanged: fname == "ndPos" //"lymphNd"
            });
        }

        if (event.target.name === "prostatePathExtension" && event.target.value != this.state.stagingForm.prostatePathExtension) {
            this.setState({
                stagingForm: {
                    ...this.state.stagingForm,
                    [event.target.name]: event.target.value,
                    ["ssdiHasChanged"]: "1"
                },
                isFormDirty: true
            });
        }
    }

    hideConfirmationSchemaIdHasChanged = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({ showConfirmationSchemaIdHasChanged: false, confirmedSchemaIdHasChanged: true });

        if (event.target.value === "yes") {
            let url = this.props.clientgroup + "/DeleteSsdis";
            let param = { stagingId: this.props.selectedStagingRecord.cid, accessToken: localStorage.getItem('token') };
            var _this = this;
            axios.post(url, param)
                .then(res => {
                    //update new accessToken again
                    var newAccessToken = res && res.data ? res.data.accessToken : null;
                    if (newAccessToken) {
                        localStorage.setItem('token', newAccessToken);
                    }

                    if (res && res.data && !res.data.error) {
                        console.log("SSdis have deleted.");
                        /*
                        var savedSchemaId = _this.state.stagingForm && _this.state.stagingForm.schemaId && _this.state.stagingForm.schemaId != "" ? _this.state.stagingForm.schemaId : "";
                        var descriptionArr = [];
                        axios.get(_this.props.clientgroup + "/GetSsdiItemLists?schemaId=" + savedSchemaId)
                            .then(res => {
                                if (!res.data.error) {
                                    let ssdiItemLists = res.data.commonLookupList;
                                    if (ssdiItemLists != null && ssdiItemLists.length > 0) {
                                        ssdiItemLists.map((t, index) => {
                                            if (!ssdiItemLists[index] || !ssdiItemLists[index]["order"]) return false;
                                            // then show it by order/name
                                            try {
                                                ssdiItemLists[index]["description"] ? descriptionArr.push(ssdiItemLists[index]["description"]) : null;
                                            } catch (exps) {
                                            }
                                        });
                                    }
                                }
                            });
                        */
                        // Also clear all stored value in form

                        /*
                         * All SSDI fields are blanked out, but only the SSDI fields that belong to the old schema and do not belong to the new schema should be cleared out.  
                         * The SSDI fields that belong in both schemas should not be cleared out.
                         */
                        var lookupOfItemFields = _this.state.lookupOfItemFields;
                        var _ssdiItemArr = [];
                        if (lookupOfItemFields) {
                            Object.keys(lookupOfItemFields).map((t) => {
                                if (t && t.indexOf("csSsf") != -1) {
                                    const idx = t.replace("csSsf", "");
                                    const field = _this.getFieldNameByIndex(idx);

                                    if (t != field) _ssdiItemArr.push(field);
                                }
                            });
                        }
                        var dynamicItemFields = _this.state.dynamicItemFields;
                        var _ssdiLabelArr = [];
                        if (dynamicItemFields) {
                            Object.keys(dynamicItemFields).map((t) => {
                                if (t && t.indexOf("csSsf") != -1) {
                                    if (dynamicItemFields[t]) _ssdiLabelArr.push(dynamicItemFields[t]);
                                }
                            });
                        }

                        //debugger
                        var sSdiFields = {};
                        url = _this.props.clientgroup + "/GetTableStructure";
                        axios.post(url, { table: "Ssdi", accessToken: localStorage.getItem('token') })
                            .then(function (response) {
                                //update new accessToken again
                                var newAccessToken = response && response.data ? response.data.accessToken : null;
                                if (newAccessToken) {
                                    localStorage.setItem('token', newAccessToken);
                                }

                                if (response.data && response.data.result) {
                                    let maxLengthData = response.data.result;
                                    maxLengthData = maxLengthData.split(',');
                                    for (var i = 0; i < maxLengthData.length; i++) {
                                        let obj = maxLengthData[i].split(':');
                                        if (obj && obj[0] != "id" && obj[0] != "cid" && obj[0] != "schemaId" && !_ssdiItemArr.includes(obj[0])) {
                                            sSdiFields[obj[0]] = null;
                                        }
                                    }
                                    //console.log(...sSdiFields);
                                    _this.setState({
                                        stagingForm: {
                                            ..._this.state.stagingForm,
                                            ...sSdiFields,
                                            ["ssdiHasChanged"]: "1"
                                        },
                                        isFormDirty: true
                                    });
                                }
                                return false;
                            })
                            .catch(function (error) {
                                console.log(error);
                                return false;
                            });
                    } else {
                        _this.props.showingMessagePatientAbstracts(res.data.error, res.data.errorCode, res.data.errorMessage);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }
        return false;
    }

    render() {
        let showConfirmationSchemaIdHasChanged;
        {
            showConfirmationSchemaIdHasChanged = this.state.showConfirmationSchemaIdHasChanged && !this.state.confirmedSchemaIdHasChanged ?
                <div className="CRStar_Window staging2018MsgConfirmation">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Confirm</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideConfirmationSchemaIdHasChanged(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label>SEER Schema has changed. Would you like to delete previously saved SSDI’s that no longer apply to the current schema?</label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container center">
                                <button id="staging2018MsgConfirmation_btnYes" onClick={this.hideConfirmationSchemaIdHasChanged} value="yes" autoFocus>Yes</button>
                                <button onClick={this.hideConfirmationSchemaIdHasChanged} value="no">No</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        const stagingMaintenance = this.state.stagingForm && !this.props.isPatientLoading && this.state.loadedPage ?
            (
                <div className="container-fluid">
                    {
                        this.state.isDialogVisible && this.props.selectedMedicalRecord &&
                        (this.props.selectedMedicalRecord.topoCode === null || this.props.selectedMedicalRecord.topoCode === "" || this.props.selectedMedicalRecord.histCode === null || this.props.selectedMedicalRecord.histCode === "") &&
                        <div className="CRStar_Window">
                            <div className="fake_popup_cls"></div>
                            <Draggable handle=".k-window-titlebar">
                                <div className="dragableWindowCls">
                                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                                        <div className="k-window-title k-dialog-title">Warning</div>
                                        <div className="k-window-actions k-dialog-actions">
                                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWarningDialog(e) }}></span>
                                            </a>
                                        </div>
                                    </div>
                                    <label>A valid topography code and histology code must be entered prior to entering the staging screen.</label>
                                    <div className="center">
                                        <button className="validationErr_btnOk_Cls" onClick={(e) => { this.showConfirmationMsgOrContinue(e) }} autoFocus>OK</button>
                                    </div>
                                </div>
                            </Draggable>
                        </div>
                    }

                    {
                        this.state.isHelpVisible && <Help fieldInFocus={this.state.fieldInFocus}
                            focusingField={this.state.focusingField} //fieldItemNbr={this.state.fieldItemNbr}
                            closeCode={this.closeCodeDialog} ssdiTitle={this.state.ssdiTitle} />
                    }

                    <form onSubmit={this.saveStaging} id="stagingForm" className="DialogForm TabParentPage" autoComplete="off">
                        <div className="topMainInfo">
                            <div className="row">
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPrimarySummary.MedRecNbr ? this.props.selectedPrimarySummary.MedRecNbr : this.props.selectedPrimarySummary.medRecNbr}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.LastName ? this.props.selectedPatient.LastName : this.props.selectedPatient.lastName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="titleCls">{this.props.selectedPatient.FirstName ? this.props.selectedPatient.FirstName : this.props.selectedPatient.firstName}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Seq Prim: {this.props.selectedPrimarySummary.SeqPrim ? this.props.selectedPrimarySummary.SeqPrim : this.props.selectedPrimarySummary.seqPrim}</label>
                                </div>
                                <div className="col-md-1">
                                    <label>Hosp: {this.props.selectedPrimarySummary.hosp ? this.props.selectedPrimarySummary.hosp : this.props.selectedPrimarySummary.Hosp}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Acc Nbr: {this.props.selectedPrimarySummary.accNbr ? this.props.selectedPrimarySummary.accNbr : this.props.selectedPrimarySummary.AccNbr}</label>
                                </div>
                                <div className="col-md-1 helpInfo0">
                                    <button type="button" onClick={this.getHelp} name="btnHelp">
                                        <img src="https://crstar.ers-can.com/images/$sys_question.png.a5image" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2">
                                    <label>Topo Code: {this.props.selectedMedicalRecord.TopoCode ? this.props.selectedMedicalRecord.TopoCode : this.props.selectedMedicalRecord.topoCode}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Class: {this.props.selectedPrimarySummary.class ? this.props.selectedPrimarySummary.class : this.props.selectedPrimarySummary.Class}</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Dob: {
                                        this.props.selectedPatient.dobMonth + "/" + this.props.selectedPatient.dobDay + "/" + this.props.selectedPatient.dobYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label>Diagnosis Date: {
                                        this.props.selectedPrimarySummary.diagnosisDateMonth + "/" + this.props.selectedPrimarySummary.diagnosisDateDay + "/" + this.props.selectedPrimarySummary.diagnosisDateYear
                                    }</label>
                                </div>
                                <div className="col-md-2">
                                    <label className="ellipsis">Histology: {this.props.selectedMedicalRecord.HistCodeName ? this.props.selectedMedicalRecord.HistCodeName : this.props.selectedMedicalRecord.histCodeName}</label>
                                </div>
                                <div className="col-md-2">
                                    <label></label>
                                </div>
                            </div>
                        </div>
                        <div className="emptyLine"></div>
                        <div style={{ border: "1px solid #eee", marginBottom: "15px" }}>
                            <Nav tabs>
                                <NavLink className={classnames({ active: this.state.activeTab === "1" })}
                                    onClick={(event) => { this.toggleTab(event, "1") }} tabIndex="1" href="#" id="linkOfStagingTab">
                                    Staging</NavLink>
                                <NavLink className={classnames({ active: this.state.activeTab === "2" })}
                                    onClick={(event) => { this.toggleTab(event, "2") }} tabIndex="2" href="#" id="linkOfSiteSpecificTab">
                                    Site-Specific Data Items</NavLink>
                                <NavLink className={classnames({ active: this.state.activeTab === "3" })}
                                    onClick={(event) => { this.toggleTab(event, "3") }} tabIndex="3" href="#" id="linkOfAjccTnmTab">
                                    AJCC TNM Staging</NavLink>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1" className="tab1">
                                    <div className="row">
                                        <div className="col-sm-3 col1">
                                            <div className="frameInner">
                                                <div className="header">&nbsp;</div>
                                                <div className="LymphNodes">
                                                    <fieldset>
                                                        <legend>Lymph Nodes</legend>
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Positive" field="ndPos" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ndPos} autoFocus
                                                            labelWidth="105px" width="40px" itemNbr="155"
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ndPos"] : ""} />
                                                        <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Examined" field="ndXam" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ndXam}
                                                            labelWidth="105px" width="40px" itemNbr="156"
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ndXam"] : ""} />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Lymph Vascular" field="lymphVascularInv" lineStyle="oneLine"
                                                            value={this.state.stagingForm.lymphVascularInv ? this.state.stagingForm.lymphVascularInv : this.state.stagingForm.LymphVascularInv}
                                                            dataSource="Global_Dir_Mv" aliasNo="3159"
                                                            titleDialog="Lymph Vascular Inv"
                                                            labelWidth="105px" width="25px"
                                                            inValid={this.state.sAllItemFields["lymphVascularInv"]} itemNbr="3159"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lymphVascularInv"] : "1"}
                                                        />
                                                    </fieldset>
                                                </div>
                                                <div className="Mets">
                                                    <fieldset>
                                                        <legend>Mets</legend>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Bone" field="csMetsDxBone" lineStyle="oneLine"
                                                                value={this.state.stagingForm.CsMetsDxBone ? this.state.stagingForm.CsMetsDxBone : this.state.stagingForm.csMetsDxBone}
                                                                dataSource="Global_Dir_Mv" aliasNo="3161"
                                                                titleDialog="Cs Mets Dx Bone"
                                                                inValid={this.state.sAllItemFields["csMetsDxBone"]} itemNbr="3161"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxBone"] : ""}
                                                                labelWidth="55px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Brain" field="csMetsDxBrain" lineStyle="oneLine"
                                                                value={this.state.stagingForm.CsMetsDxBrain ? this.state.stagingForm.CsMetsDxBrain : this.state.stagingForm.csMetsDxBrain}
                                                                dataSource="Global_Dir_Mv" aliasNo="3162"
                                                                titleDialog="Cs Mets Dx Brain" labelStyle="center"
                                                                inValid={this.state.sAllItemFields["csMetsDxBrain"]} itemNbr="3162"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxBrain"] : ""}
                                                                labelWidth="45px" width="25px"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Liver" field="csMetsDxLiver" lineStyle="oneLine"
                                                                value={this.state.stagingForm.CsMetsDxLiver ? this.state.stagingForm.CsMetsDxLiver : this.state.stagingForm.csMetsDxLiver}
                                                                dataSource="Global_Dir_Mv" aliasNo="3163"
                                                                titleDialog="Cs Mets Dx Liver"
                                                                inValid={this.state.sAllItemFields["csMetsDxLiver"]} itemNbr="3163"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxLiver"] : ""}
                                                                labelWidth="55px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Lung" field="csMetsDxLung" lineStyle="oneLine"
                                                                value={this.state.stagingForm.CsMetsDxLung ? this.state.stagingForm.CsMetsDxLung : this.state.stagingForm.csMetsDxLung}
                                                                dataSource="Global_Dir_Mv" aliasNo="3164"
                                                                titleDialog="Cs Mets Dx Lung" labelStyle="center"
                                                                inValid={this.state.sAllItemFields["csMetsDxLung"]} itemNbr="3164"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csMetsDxLung"] : ""}
                                                                labelWidth="45px" width="25px"
                                                            />
                                                        </div>
                                                        <div style={{ display: "flex" }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Dist Ln" field="metsDxDistantLn" lineStyle="oneLine"
                                                                value={this.state.stagingForm.MetsDxDistantLn ? this.state.stagingForm.MetsDxDistantLn : this.state.stagingForm.metsDxDistantLn}
                                                                dataSource="Global_Dir_Mv" aliasNo="4073"
                                                                titleDialog="Mets Dx Distant Ln"
                                                                inValid={this.state.sAllItemFields["metsDxDistantLn"]} itemNbr="4073"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["metsDxDistantLn"] : ""}
                                                                labelWidth="55px" width="25px"
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Other" field="metsDxOther" lineStyle="oneLine"
                                                                value={this.state.stagingForm.MetsDxOther ? this.state.stagingForm.MetsDxOther : this.state.stagingForm.metsDxOther}
                                                                dataSource="Global_Dir_Mv" aliasNo="4074"
                                                                titleDialog="Mets Dx Other" labelStyle="center"
                                                                inValid={this.state.sAllItemFields["metsDxOther"]} itemNbr="4074"
                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["metsDxOther"] : ""}
                                                                labelWidth="45px" width="25px"
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-3 col3 extendDiv">
                                            <div className="frameInner">
                                                <div className="header">&nbsp;</div>
                                                <div className="SchemaQualifiers">
                                                    <fieldset>
                                                        <legend>Schema Qualifiers</legend>
                                                        <div style={{ display: (this.state.fn_disc_enabled !== "1" ? "" : "none") }}>
                                                            <p>(discriminators appear if needed)</p>
                                                        </div>
                                                        <div style={{ display: (this.state.fn_disc_enabled === "1" ? "" : "none") }}>
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Discriminator 1" field="schemaDiscrim1" lineStyle="oneLine"
                                                                value={this.state.stagingForm.schemaDiscrim1 ? this.state.stagingForm.schemaDiscrim1 : this.state.stagingForm.SchemaDiscrim1}
                                                                dataSource="SSDI_Codes_Popup" aliasNo="4167"
                                                                titleDialog="Schema Discriminator 1" labelWidth="125px" width="30px"
                                                                inValid={this.state.sAllItemFields["schemaDiscrim1"]} itemNbr="4167"

                                                                //flag={this.state.schemaId && this.state.schemaId !== null ? this.state.schemaId : this.state.stagingForm.schemaId}
                                                                flag={this.state.fn_schema_id}//fn_disc_schema_id

                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["schemaDiscrim1"] : ""}
                                                            />
                                                            <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                labelText="Discriminator 2" field="schemaDiscrim2" lineStyle="oneLine"
                                                                value={this.state.stagingForm.schemaDiscrim2 ? this.state.stagingForm.schemaDiscrim2 : this.state.stagingForm.SchemaDiscrim2}
                                                                dataSource="SSDI_Codes_Popup" aliasNo="4168"
                                                                titleDialog="Schema Discriminator 2" labelWidth="125px" width="30px"
                                                                inValid={this.state.sAllItemFields["schemaDiscrim2"]} itemNbr="4168"

                                                                //flag={this.state.schemaId && this.state.schemaId !== null ? this.state.schemaId : this.state.stagingForm.schemaId}
                                                                flag={this.state.fn_schema_id}//fn_disc_schema_id

                                                                lostfocused={this.handleLostFocus}
                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["schemaDiscrim2"] : ""}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </div>

                                                <div className="">
                                                    <fieldset>
                                                        <legend>&nbsp;</legend>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="SEER Sum Stage" field="genStage" lineStyle="oneLine"
                                                            value={this.state.stagingForm.GenStage ? this.state.stagingForm.GenStage : this.state.stagingForm.genStage}
                                                            dataSource="Schema_SSDI_Codes" aliasNo="120"
                                                            titleDialog="General Stage SS2018" labelWidth="125px" width="30px"
                                                            inValid={this.state.sAllItemFields["genStage"]} itemNbr="120"
                                                            flag={this.state.schemaId}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["genStage"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="EOD Prim Tumor" field="eodPrimTumor" lineStyle="oneLine"
                                                            value={this.state.stagingForm.eodPrimTumor ? this.state.stagingForm.eodPrimTumor : this.state.stagingForm.EodPrimTumor}
                                                            dataSource="Schema_SSDI_Codes" aliasNo="4343"
                                                            titleDialog="EOD Primary Tumor (size)" labelWidth="125px" width="45px"
                                                            inValid={this.state.sAllItemFields["eodPrimTumor"]} itemNbr="4343"
                                                            flag={this.state.schemaId}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["eodPrimTumor"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="EOD Reg Nodes" field="eodRegNodes" lineStyle="oneLine"
                                                            value={this.state.stagingForm.eodRegNodes ? this.state.stagingForm.eodRegNodes : this.state.stagingForm.EodRegNodes}
                                                            dataSource="Schema_SSDI_Codes" aliasNo="4344"
                                                            titleDialog="EOD Regional Nodes" labelWidth="125px" width="45px"
                                                            inValid={this.state.sAllItemFields["eodRegNodes"]} itemNbr="4344"
                                                            flag={this.state.schemaId}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["eodRegNodes"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="EOD Mets" field="eodMets" lineStyle="oneLine"
                                                            value={this.state.stagingForm.eodMets ? this.state.stagingForm.eodMets : this.state.stagingForm.EodMets}
                                                            dataSource="Schema_SSDI_Codes" aliasNo="4345"
                                                            titleDialog="EOD Mets" labelWidth="125px" width="35px"
                                                            inValid={this.state.sAllItemFields["eodMets"]} itemNbr="4345"
                                                            flag={this.state.schemaId}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["eodMets"] : ""}
                                                        />

                                                        {/* #PR00622: Add the EOD Prostate Pathological Extension */}
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="EOD Prostate Pathologic Extension" field="prostatePathExtension" lineStyle="oneLine"
                                                            value={this.state.stagingForm.prostatePathExtension}
                                                            dataSource="Global_Dir_Mv" aliasNo="4276"
                                                            titleDialog="EOD Prostate Pathologic Extension" labelWidth="125px" width="35px"
                                                            inValid={this.state.sAllItemFields["prostatePathExtension"]} itemNbr="4276"
                                                            flag={this.state.schemaId}
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["prostatePathExtension"] : ""}
                                                        />

                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="SEER SSF 1" field="seerSsf1" lineStyle="oneLine"
                                                            value={this.state.stagingForm.seerSsf1 ? this.state.stagingForm.seerSsf1 : this.state.stagingForm.SeerSsf1}
                                                            dataSource="Global_Dir_Mv" aliasNo="4350"
                                                            titleDialog="SEER SSF 1" labelWidth="125px" width="30px"
                                                            inValid={this.state.sAllItemFields["seerSsf1"]} itemNbr="4350"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["seerSsf1"] : ""}
                                                        />
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-3 col3">
                                            <div className="frameInner">
                                                <div className="header">&nbsp;</div>
                                                <div className="PediatricStage">
                                                    <fieldset>
                                                        <legend>Pediatric Stage</legend>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Stage System" field="pedStgSystem" lineStyle="oneLine"
                                                            value={this.state.stagingForm.pedStgSystem ? this.state.stagingForm.pedStgSystem : this.state.stagingForm.PedStgSystem}
                                                            dataSource="Global_Dir_Mv" aliasNo="791" titleDialog="Ped Stg System"
                                                            labelWidth="110px" width="35px"
                                                            inValid={this.state.sAllItemFields["pedStgSystem"]} itemNbr="791"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pedStgSystem"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Stage" field="pedStg" lineStyle="oneLine"
                                                            value={this.state.stagingForm.pedStg ? this.state.stagingForm.pedStg : this.state.stagingForm.PedStg}
                                                            dataSource="Global_Dir_Mv" aliasNo="792" titleDialog="Ped Stg"
                                                            labelWidth="110px" width="35px"
                                                            inValid={this.state.sAllItemFields["pedStg"]} itemNbr="792"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pedStg"] : ""}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="Staged By" field="pedStagedBy" lineStyle="oneLine"
                                                            value={this.state.stagingForm.PedStagedBy ? this.state.stagingForm.PedStagedBy : this.state.stagingForm.pedStagedBy}
                                                            dataSource="Global_Dir_Mv" aliasNo="790" titleDialog="Ped Staged By"
                                                            labelWidth="110px" width="30px"
                                                            inValid={this.state.sAllItemFields["pedStagedBy"]} itemNbr="790"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pedStagedBy"] : ""}
                                                        />
                                                    </fieldset>
                                                </div>
                                                <div className="UserDefined">
                                                    <fieldset>
                                                        <legend>User Defined</legend>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.stagingForm.stagingUserLblText1 ? this.state.stagingForm.stagingUserLblText1 : "Stg User 1"}
                                                            field="stagingUser1" lineStyle="oneLine"
                                                            value={this.state.stagingForm.StagingUser1 ? this.state.stagingForm.StagingUser1 : this.state.stagingForm.stagingUser1}
                                                            dataSource="Global_Dir_Mv" titleDialog="Staging User 1" aliasNo="3976"
                                                            inValid={this.state.sAllItemFields["stagingUser1"]} itemNbr="3976"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stagingUser1"] : ""}
                                                            labelWidth="105px" width="125px"
                                                            flag="ERS_UserDefinedMaintenance"
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText={this.state.stagingForm.stagingUserLblText2 ? this.state.stagingForm.stagingUserLblText2 : "Stg User 2"}
                                                            field="stagingUser2" lineStyle="oneLine"
                                                            value={this.state.stagingForm.StagingUser2 ? this.state.stagingForm.StagingUser2 : this.state.stagingForm.stagingUser2}
                                                            dataSource="Global_Dir_Mv" titleDialog="Staging User 2" aliasNo="3977"
                                                            inValid={this.state.sAllItemFields["stagingUser2"]} itemNbr="3977"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["stagingUser2"] : ""}
                                                            labelWidth="105px" width="125px"
                                                            flag="ERS_UserDefinedMaintenance"
                                                        />
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId="2" className="tab2">
                                    <div className={this.state.schemaIdDisplaying && this.state.schemaIdDisplaying.indexOf("Discriminator Check") == -1 ? "frameInner" : "frameInner emptySsdiFieldsCls"}>
                                        <div className="header">{this.state.schemaIdDisplaying && this.state.schemaIdDisplaying.indexOf("Discriminator Check") == -1 ? this.state.schemaIdDisplaying : (this.state.schemaIdDisplaying_WithError ? this.state.schemaIdDisplaying_WithError : " ")}</div>
                                        <div className="row">
                                            <div className="col-sm-5">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf1"] ? this.state.dynamicItemFields["csSsf1"] : "SSDI User 1"}
                                                            field="csSsf1" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf1"] + " oneLine onTopFrame" : "oneLine onTopFrame"}

                                                            //value={this.state.stagingForm.csSsf1}//this.showValueByFieldIndex('1')
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('1')]}

                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf1"] ? this.state.itemNoLookupOnPopup["csSsf1"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf1"] ? this.state.lookupOfItemFields["csSsf1"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf1"] ? this.state.dynamicItemFields["csSsf1"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf1"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_1"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf1"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('1')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf2"] ? this.state.dynamicItemFields["csSsf2"] : "SSDI User 2"}
                                                            field="csSsf2" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf2"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('2')]}//value={this.state.stagingForm.csSsf2}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf2"] ? this.state.itemNoLookupOnPopup["csSsf2"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf2"] ? this.state.lookupOfItemFields["csSsf2"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf2"] ? this.state.dynamicItemFields["csSsf2"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf2"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_2"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf2"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('2')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf3"] ? this.state.dynamicItemFields["csSsf3"] : "SSDI User 3"}
                                                            field="csSsf3" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf3"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('3')]}//value={this.state.stagingForm.csSsf3}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf3"] ? this.state.itemNoLookupOnPopup["csSsf3"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf3"] ? this.state.lookupOfItemFields["csSsf3"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf3"] ? this.state.dynamicItemFields["csSsf3"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf3"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_3"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf3"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('3')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf4"] ? this.state.dynamicItemFields["csSsf4"] : "SSDI User 4"}
                                                            field="csSsf4" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf4"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('4')]}//value={this.state.stagingForm.csSsf4}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf4"] ? this.state.itemNoLookupOnPopup["csSsf4"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf4"] ? this.state.lookupOfItemFields["csSsf4"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf4"] ? this.state.dynamicItemFields["csSsf4"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf4"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_4"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf4"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('4')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf5"] ? this.state.dynamicItemFields["csSsf5"] : "SSDI User 5"}
                                                            field="csSsf5" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf5"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('5')]}//value={this.state.stagingForm.csSsf5}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf5"] ? this.state.itemNoLookupOnPopup["csSsf5"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf5"] ? this.state.lookupOfItemFields["csSsf5"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf5"] ? this.state.dynamicItemFields["csSsf5"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf5"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_5"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf5"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('5')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf6"] ? this.state.dynamicItemFields["csSsf6"] : "SSDI User 6"}
                                                            field="csSsf6" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf6"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('6')]}//value={this.state.stagingForm.csSsf6}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf6"] ? this.state.itemNoLookupOnPopup["csSsf6"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf6"] ? this.state.lookupOfItemFields["csSsf6"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf6"] ? this.state.dynamicItemFields["csSsf6"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf6"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_6"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf6"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('6')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf7"] ? this.state.dynamicItemFields["csSsf7"] : "SSDI User 7"}
                                                            field="csSsf7" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf7"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('7')]}//value={this.state.stagingForm.csSsf7}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf7"] ? this.state.itemNoLookupOnPopup["csSsf7"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf7"] ? this.state.lookupOfItemFields["csSsf7"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf7"] ? this.state.dynamicItemFields["csSsf7"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf7"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_7"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf7"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('7')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf8"] ? this.state.dynamicItemFields["csSsf8"] : "SSDI User 8"}
                                                            field="csSsf8" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf8"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('8')]}//value={this.state.stagingForm.csSsf8}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf8"] ? this.state.itemNoLookupOnPopup["csSsf8"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf8"] ? this.state.lookupOfItemFields["csSsf8"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf8"] ? this.state.dynamicItemFields["csSsf8"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf8"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_8"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf8"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('8')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf9"] ? this.state.dynamicItemFields["csSsf9"] : "SSDI User 9"}
                                                            field="csSsf9" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf9"] + " oneLine onTopFrame" : "oneLine onTopFrame"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('9')]}//value={this.state.stagingForm.csSsf9}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf9"] ? this.state.itemNoLookupOnPopup["csSsf9"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf9"] ? this.state.lookupOfItemFields["csSsf9"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf9"] ? this.state.dynamicItemFields["csSsf9"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf9"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_9"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf9"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('9')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf10"] ? this.state.dynamicItemFields["csSsf10"] : "SSDI User 10"}
                                                            field="csSsf10" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf10"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('10')]}//value={this.state.stagingForm.csSsf10}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf10"] ? this.state.itemNoLookupOnPopup["csSsf10"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf10"] ? this.state.lookupOfItemFields["csSsf10"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf10"] ? this.state.dynamicItemFields["csSsf10"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf10"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_10"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf10"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('10')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf11"] ? this.state.dynamicItemFields["csSsf11"] : "SSDI User 11"}
                                                            field="csSsf11" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf11"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('11')]}//value={this.state.stagingForm.csSsf11}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf11"] ? this.state.itemNoLookupOnPopup["csSsf11"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf11"] ? this.state.lookupOfItemFields["csSsf11"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf11"] ? this.state.dynamicItemFields["csSsf11"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf11"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_11"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf11"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('11')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-1" style={{ borderLeft: "1px solid #ddd" }}></div>
                                            <div className="col-sm-5">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields ? this.state.dynamicItemFields["csSsf12"] : "SSDI User 12"}
                                                            field="csSsf12" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf12"] + " oneLine onTopFrame" : "oneLine onTopFrame"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('12')]}//value={this.state.stagingForm.csSsf12}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf12"] ? this.state.itemNoLookupOnPopup["csSsf12"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf12"] ? this.state.lookupOfItemFields["csSsf12"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf12"] ? this.state.dynamicItemFields["csSsf12"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf12"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_12"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf12"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('12')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf13"] ? this.state.dynamicItemFields["csSsf13"] : "SSDI User 13"}
                                                            field="csSsf13" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf13"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('13')]}//value={this.state.stagingForm.csSsf13}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf13"] ? this.state.itemNoLookupOnPopup["csSsf13"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf13"] ? this.state.lookupOfItemFields["csSsf13"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf13"] ? this.state.dynamicItemFields["csSsf13"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf13"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_13"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf13"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('13')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf14"] ? this.state.dynamicItemFields["csSsf14"] : "SSDI User 14"}
                                                            field="csSsf14" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf14"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('14')]}//value={this.state.stagingForm.csSsf14}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf14"] ? this.state.itemNoLookupOnPopup["csSsf14"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf14"] ? this.state.lookupOfItemFields["csSsf14"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf14"] ? this.state.dynamicItemFields["csSsf14"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf14"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_14"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf14"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('14')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf15"] ? this.state.dynamicItemFields["csSsf15"] : "SSDI User 15"}
                                                            field="csSsf15" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf15"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('15')]}//value={this.state.stagingForm.csSsf15}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf15"] ? this.state.itemNoLookupOnPopup["csSsf15"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf15"] ? this.state.lookupOfItemFields["csSsf15"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf15"] ? this.state.dynamicItemFields["csSsf15"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf15"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_15"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf15"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('15')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf16"] ? this.state.dynamicItemFields["csSsf16"] : "SSDI User 16"}
                                                            field="csSsf16" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf16"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('16')]}//value={this.state.stagingForm.csSsf16}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf16"] ? this.state.itemNoLookupOnPopup["csSsf16"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf16"] ? this.state.lookupOfItemFields["csSsf16"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf16"] ? this.state.dynamicItemFields["csSsf16"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf16"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_16"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf16"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('16')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf17"] ? this.state.dynamicItemFields["csSsf17"] : "SSDI User 17"}
                                                            field="csSsf17" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf17"] + " oneLine onTopFrame" : "oneLine onTopFrame"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('17')]}//value={this.state.stagingForm.csSsf17}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf17"] ? this.state.itemNoLookupOnPopup["csSsf17"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf17"] ? this.state.lookupOfItemFields["csSsf17"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf17"] ? this.state.dynamicItemFields["csSsf17"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf17"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_17"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf17"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('17')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf18"] ? this.state.dynamicItemFields["csSsf18"] : "SSDI User 18"}
                                                            field="csSsf18" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf18"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('18')]}//value={this.state.stagingForm.csSsf18}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf18"] ? this.state.itemNoLookupOnPopup["csSsf18"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf18"] ? this.state.lookupOfItemFields["csSsf18"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf18"] ? this.state.dynamicItemFields["csSsf18"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf18"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_18"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf18"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('18')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf19"] ? this.state.dynamicItemFields["csSsf19"] : "SSDI User 19"}
                                                            field="csSsf19" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf19"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('19')]}//value={this.state.stagingForm.csSsf19}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf19"] ? this.state.itemNoLookupOnPopup["csSsf19"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf19"] ? this.state.lookupOfItemFields["csSsf19"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf19"] ? this.state.dynamicItemFields["csSsf19"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf19"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_19"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf19"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('19')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf20"] ? this.state.dynamicItemFields["csSsf20"] : "SSDI User 20"}
                                                            field="csSsf20" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf20"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('20')]}//value={this.state.stagingForm.csSsf20}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf20"] ? this.state.itemNoLookupOnPopup["csSsf20"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf20"] ? this.state.lookupOfItemFields["csSsf20"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf20"] ? this.state.dynamicItemFields["csSsf20"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf20"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_20"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf20"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('20')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <Input type="codeSelect" focused={this.handleOnFocus}
                                                            changed={this.handleFormChange}
                                                            labelText={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf21"] ? this.state.dynamicItemFields["csSsf21"] : "SSDI User 21"}
                                                            field="csSsf21" lineStyle={this.state.showDynamicItemFields ? this.state.showDynamicItemFields["csSsf21"] + " oneLine" : "oneLine"}
                                                            value={this.state.stagingForm[this.getFieldNameByIndex('21')]}//value={this.state.stagingForm.csSsf21}
                                                            dataSource="Global_Dir_Mv" aliasNo={this.state.itemNoLookupOnPopup && this.state.itemNoLookupOnPopup["csSsf21"] ? this.state.itemNoLookupOnPopup["csSsf21"] : ""}
                                                            helpNaming={this.state.lookupOfItemFields && this.state.lookupOfItemFields["csSsf21"] ? this.state.lookupOfItemFields["csSsf21"] : ""}
                                                            titleDialog={this.state.dynamicItemFields && this.state.dynamicItemFields["csSsf21"] ? this.state.dynamicItemFields["csSsf21"] : "Lookup"}
                                                            inValid={this.state.sAllItemFields["csSsf21"]} itemNbr=""
                                                            lostfocused={this.handleLostFocus} keyId="SSDI_USER_21"

                                                            //maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["csSsf21"] : ""}
                                                            maxLength={this.state.pageMaxlengthInputForSsdis ? this.state.pageMaxlengthInputForSsdis[this.getFieldNameByIndex('21')] : ""}

                                                            labelWidth="300px" width="55px"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane tabId="3" className="tab3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="row onTopFrame">
                                                <div className="col-sm-2">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="AJCC Basis" field="ajccBasis" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccBasis ? this.state.stagingForm.ajccBasis : this.state.stagingForm.AjccBasis}
                                                        dataSource="Global_Dir_Mv" aliasNo="121"
                                                        titleDialog="Ajcc Basis" labelWidth="80px" width="30px"
                                                        inValid={this.state.sAllItemFields["ajccBasis"]} itemNbr="121"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccBasis"] : ""}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="TNM Edition Number" field="tnmEdNbr" lineStyle="oneLine"
                                                        value={this.state.stagingForm.tnmEdNbr ? this.state.stagingForm.tnmEdNbr : ""}
                                                        dataSource="Global_Dir_Mv" aliasNo="786"
                                                        titleDialog="Tnm Ed Nbr" labelWidth="140px" width="35px"
                                                        inValid={this.state.sAllItemFields["tnmEdNbr"]} itemNbr="786"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tnmEdNbr"] : ""}
                                                    />
                                                </div>
                                                <div className="col-sm-2">
                                                    <Input type="input" focused={this.handleOnFocus}
                                                        changed={this.handleFormChange}
                                                        labelText="Size Summary" field="tumorSizeSummary" lineStyle="oneLine"
                                                        value={this.state.stagingForm.tumorSizeSummary}
                                                        labelWidth="100px" width="30px" itemNbr="4072"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorSizeSummary"] : ""} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <div className="frameInner">
                                                <div className="header">AJCC TNM Clinical</div>
                                                <div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grade" field="gradeClinical" lineStyle="oneLine onTopFrame"
                                                        value={this.state.stagingForm.gradeClinical ? this.state.stagingForm.gradeClinical : this.state.stagingForm.GradeClinical}
                                                        dataSource="Schema_SSDI_Codes" aliasNo="4140"
                                                        titleDialog="Grade Clinical" labelWidth="50px" width="30px"
                                                        inValid={this.state.sAllItemFields["gradeClinical"]} itemNbr="4140"
                                                        flag={this.state.schemaId}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gradeClinical"] : ""}
                                                    />
                                                    <div className="" style={{ textAlign: "right", width: "220px" }}><Input type="gridHeader" labelText="Suffix" labelWidth="45px" width="45px" text-align="right" field="ajccClinTSuffix" lineStyle="lblSuffixCls" /> </div>
                                                    <div className="" style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="T" field="ajccClinT" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccClinT ? this.state.stagingForm.ajccClinT : this.state.stagingForm.AjccClinT}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="CLINICAL_T"//DataItem::CLINICAL_T = 1001
                                                            titleDialog="AJCC TNM Clinical T" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccClinT"]} itemNbr="4147"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccClinT"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccClinTSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccClinTSuffix ? this.state.stagingForm.ajccClinTSuffix : this.state.stagingForm.AjccClinTSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4148"
                                                            titleDialog="AJCC TNM Clin T Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccClinTSuffix"]} itemNbr="4148"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccClinTSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <div className="" style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="N" field="ajccClinN" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccClinN ? this.state.stagingForm.ajccClinN : this.state.stagingForm.AjccClinN}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="CLINICAL_N"//DataItem::CLINICAL_N = 1002
                                                            titleDialog="AJCC TNM Clinical N" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccClinN"]} itemNbr="4149"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccClinN"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccClinNSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccClinNSuffix ? this.state.stagingForm.ajccClinNSuffix : this.state.stagingForm.AjccClinNSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4150"
                                                            titleDialog="AJCC TNM Clin N Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccClinNSuffix"]} itemNbr="4150"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccClinNSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="M" field="ajccClinM" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccClinM ? this.state.stagingForm.ajccClinM : this.state.stagingForm.AjccClinM}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="CLINICAL_M"//DataItem::CLINICAL_M = 1003
                                                        titleDialog="AJCC TNM Clinical M" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccClinM"]} itemNbr="4151"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccClinM"] : ""}
                                                        flag={this.state.ajccId}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grp" field="ajccClinStageGroup" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccClinStageGroup ? this.state.stagingForm.ajccClinStageGroup : this.state.stagingForm.AjccClinStageGroup}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="CLINICAL_STAGE"
                                                        titleDialog="AJCC TNM Clinical Stage Group" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccClinStageGroup"]} itemNbr="4152"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccClinStageGroup"] : ""}
                                                        flag={this.state.ajccId}

                                                        schemaId={this.state.schemaId} t={this.state.stagingForm.ajccClinT} n={this.state.stagingForm.ajccClinN} m={this.state.stagingForm.ajccClinM}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="c Staged By" field="cStagedBy" lineStyle="oneLine"
                                                        value={this.state.stagingForm.cStagedBy ? this.state.stagingForm.cStagedBy : this.state.stagingForm.CStagedBy}
                                                        dataSource="Global_Dir_Mv" aliasNo="372"
                                                        titleDialog="C Staged By" labelWidth="120px" width="30px"
                                                        inValid={this.state.sAllItemFields["cStagedBy"]} itemNbr="372"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cStagedBy"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="c Stg Descriptor" field="cStgDescriptor" lineStyle="oneLine"
                                                        value={this.state.stagingForm.cStgDescriptor ? this.state.stagingForm.cStgDescriptor : this.state.stagingForm.CStgDescriptor}
                                                        dataSource="Global_Dir_Mv" aliasNo="806"
                                                        titleDialog="C Stg Descriptor" labelWidth="120px" width="30px"
                                                        inValid={this.state.sAllItemFields["cStgDescriptor"]} itemNbr="806"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["cStgDescriptor"] : ""}
                                                    />
                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Size Clinical" field="tumorSizeClinical" lineStyle="oneLine"
                                                        value={this.state.stagingForm.tumorSizeClinical}
                                                        labelWidth="120px" width="50px" itemNbr="4070"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorSizeClinical"] : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="frameInner">
                                                <div className="header">AJCC TNM Pathological</div>
                                                <div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grade" field="gradePathological" lineStyle="oneLine onTopFrame"
                                                        value={this.state.stagingForm.gradePathological ? this.state.stagingForm.gradePathological : this.state.stagingForm.GradePathological}
                                                        dataSource="Schema_SSDI_Codes" aliasNo="4141"
                                                        titleDialog="Grade Pathological" labelWidth="50px" width="30px"
                                                        inValid={this.state.sAllItemFields["gradePathological"]} itemNbr="4141"
                                                        flag={this.state.schemaId}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gradePathological"] : ""}
                                                    />
                                                    <div className="" style={{ textAlign: "right", width: "220px" }}><Input type="gridHeader" labelText="Suffix" labelWidth="45px" width="45px" text-align="right" field="ajccPathTSuffix" lineStyle="lblSuffixCls" /> </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="T" field="ajccPathT" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPathT ? this.state.stagingForm.ajccPathT : this.state.stagingForm.AjccPathT}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="PATHOLOGIC_T"//DataItem::PATHOLOGIC_T = 1011
                                                            titleDialog="AJCC TNM Path T" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccPathT"]} itemNbr="4153"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPathT"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccPathTSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPathTSuffix ? this.state.stagingForm.ajccPathTSuffix : this.state.stagingForm.AjccPathTSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4154"
                                                            titleDialog="AJCC TNM Path T Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccPathTSuffix"]} itemNbr="4154"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPathTSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="N" field="ajccPathN" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPathN ? this.state.stagingForm.ajccPathN : this.state.stagingForm.AjccPathN}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="PATHOLOGIC_N"//DataItem::PATHOLOGIC_N = 1012
                                                            titleDialog="AJCC TNM Path N" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccPathN"]} itemNbr="4155"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPathN"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccPathNSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPathNSuffix ? this.state.stagingForm.ajccPathNSuffix : this.state.stagingForm.AjccPathNSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4156"
                                                            titleDialog="AJCC TNM Path N Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccPathNSuffix"]} itemNbr="4156"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPathNSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="M" field="ajccPathM" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccPathM ? this.state.stagingForm.ajccPathM : this.state.stagingForm.AjccPathM}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="PATHOLOGIC_M"//DataItem::PATHOLOGIC_M = 1013
                                                        titleDialog="AJCC TNM Path M" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccPathM"]} itemNbr="4157"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPathM"] : ""}
                                                        flag={this.state.ajccId}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grp" field="ajccPathStageGroup" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccPathStageGroup ? this.state.stagingForm.ajccPathStageGroup : this.state.stagingForm.AjccPathStageGroup}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="PATHOLOGIC_STAGE"
                                                        titleDialog="AJCC TNM Path Stage Group" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccPathStageGroup"]} itemNbr="4158"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPathStageGroup"] : ""}
                                                        flag={this.state.ajccId}

                                                        schemaId={this.state.schemaId} t={this.state.stagingForm.ajccPathT} n={this.state.stagingForm.ajccPathN} m={this.state.stagingForm.ajccPathM}
                                                    />

                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="p Staged By" field="pStagedBy" lineStyle="oneLine"
                                                        value={this.state.stagingForm.pStagedBy ? this.state.stagingForm.pStagedBy : this.state.stagingForm.PStagedBy}
                                                        dataSource="Global_Dir_Mv" aliasNo="327"
                                                        titleDialog="P Staged By" labelWidth="120px" width="30px"
                                                        inValid={this.state.sAllItemFields["pStagedBy"]} itemNbr="327"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pStagedBy"] : ""}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="p Stg Descriptor" field="pStgDescriptor" lineStyle="oneLine"
                                                        value={this.state.stagingForm.pStgDescriptor ? this.state.stagingForm.pStgDescriptor : this.state.stagingForm.PStgDescriptor}
                                                        dataSource="Global_Dir_Mv" aliasNo="805"
                                                        titleDialog="C Stg Descriptor" labelWidth="120px" width="30px"
                                                        inValid={this.state.sAllItemFields["pStgDescriptor"]} itemNbr="805"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["pStgDescriptor"] : ""}
                                                    />
                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Size Pathologic" field="tumorSizePathologic" lineStyle="oneLine"
                                                        value={this.state.stagingForm.tumorSizePathologic}
                                                        labelWidth="120px" width="50px" itemNbr="4071"
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["tumorSizePathologic"] : ""}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="frameInner">
                                                <div className="header">AJCC TNM Post Therapy Clinical</div>
                                                <div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grade" field="gradePostTherapyClinical" lineStyle="oneLine onTopFrame"
                                                        value={this.state.stagingForm.gradePostTherapyClinical ? this.state.stagingForm.gradePostTherapyClinical : this.state.stagingForm.GradePostTherapyClinical}
                                                        dataSource="Schema_SSDI_Codes" aliasNo="4142"
                                                        titleDialog="Grade Post Therapy Clinical" labelWidth="50px" width="30px"
                                                        inValid={this.state.sAllItemFields["gradePostTherapyClinical"]} itemNbr="4460"
                                                        flag={this.state.schemaId}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gradePostTherapyClinical"] : ""}
                                                    />
                                                    <div className="" style={{ textAlign: "right", width: "220px" }}><Input type="gridHeader" labelText="Suffix" labelWidth="45px" width="45px" text-align="right" field="ajccPostClinTSuffix" lineStyle="lblSuffixCls" /> </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="T" field="ajccPostClinT" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostClinT ? this.state.stagingForm.ajccPostClinT : this.state.stagingForm.AjccPostClinT}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_CLIN_T"//DataItem::POSTTHERAPY_CLIN_T = 1062
                                                            titleDialog="AJCC TNM Post Therapy Clinical T" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccPostClinT"]} itemNbr="4454"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostClinT"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccPostClinTSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostClinTSuffix ? this.state.stagingForm.ajccPostClinTSuffix : this.state.stagingForm.AjccPostClinTSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4455"
                                                            titleDialog="AJCC TNM Post Therapy Clinical T Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccPostClinTSuffix"]} itemNbr="4455"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostClinTSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="N" field="ajccPostClinN" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostClinN ? this.state.stagingForm.ajccPostClinN : this.state.stagingForm.AjccPostClinN}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_CLIN_N"//DataItem::POSTTHERAPY_CLIN_N = 1064
                                                            titleDialog="AJCC TNM Post Therapy Clinical N" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccPostClinN"]} itemNbr="4456"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostClinN"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccPostClinNSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostClinNSuffix ? this.state.stagingForm.ajccPostClinNSuffix : this.state.stagingForm.AjccPostClinNSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4457"
                                                            titleDialog="AJCC TNM Post Therapy Clinical N Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccPostClinNSuffix"]} itemNbr="4457"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostClinNSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="M" field="ajccPostClinM" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccPostClinM ? this.state.stagingForm.ajccPostClinM : this.state.stagingForm.AjccPostClinM}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_CLIN_M"//DataItem::POSTTHERAPY_CLIN_M = 1066
                                                        titleDialog="AJCC TNM Post Therapy Clinical M" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccPostClinM"]} itemNbr="4458"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostClinM"] : ""}
                                                        flag={this.state.ajccId}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grp" field="ajccPostClinStageGroup" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccPostClinStageGroup ? this.state.stagingForm.ajccPostClinStageGroup : this.state.stagingForm.AjccPostClinStageGroup}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_CLIN_STAGE"
                                                        titleDialog="AJCC TNM Post Therapy Clinical Stage Group" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccPostClinStageGroup"]} itemNbr="4459"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostClinStageGroup"] : ""}
                                                        flag={this.state.ajccId}
                                                        schemaId={this.state.schemaId} t={this.state.stagingForm.ajccPostClinT} n={this.state.stagingForm.ajccPostClinN} m={this.state.stagingForm.ajccPostClinM}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="frameInner">
                                                <div className="header">AJCC TNM Post Therapy Pathological</div>
                                                <div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grade" field="gradePostTherapy" lineStyle="oneLine onTopFrame"
                                                        value={this.state.stagingForm.gradePostTherapy ? this.state.stagingForm.gradePostTherapy : this.state.stagingForm.GradePostTherapy}
                                                        dataSource="Schema_SSDI_Codes" aliasNo="4142"
                                                        titleDialog="Grade Post Therapy Pathological" labelWidth="50px" width="30px"
                                                        inValid={this.state.sAllItemFields["gradePostTherapy"]} itemNbr="4142"
                                                        flag={this.state.schemaId}
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["gradePostTherapy"] : ""}
                                                    />
                                                    <div className="" style={{ textAlign: "right", width: "220px" }}><Input type="gridHeader" labelText="Suffix" labelWidth="45px" width="45px" text-align="right" field="ajccPostTSuffix" lineStyle="lblSuffixCls" /> </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="T" field="ajccPostT" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostT ? this.state.stagingForm.ajccPostT : this.state.stagingForm.AjccPostT}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_PATH_T"//DataItem::POSTTHERAPY_PATH_T = 1021
                                                            titleDialog="AJCC TNM Post Therapy Pathological T" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccPostT"]} itemNbr="4159"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostT"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccPostTSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostTSuffix ? this.state.stagingForm.ajccPostTSuffix : this.state.stagingForm.AjccPostTSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4160"
                                                            titleDialog="AJCC TNM Post Therapy Pathological T Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccPostTSuffix"]} itemNbr="4160"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostTSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="N" field="ajccPostN" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostN ? this.state.stagingForm.ajccPostN : this.state.stagingForm.AjccPostN}
                                                            dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_PATH_N"//DataItem::POSTTHERAPY_PATH_N = 1022
                                                            titleDialog="AJCC TNM Post Therapy Pathological N" labelWidth="30px" width="120px"
                                                            inValid={this.state.sAllItemFields["ajccPostN"]} itemNbr="4161"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostN"] : ""}
                                                            flag={this.state.ajccId}
                                                        />
                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                            labelText="" field="ajccPostNSuffix" lineStyle="oneLine"
                                                            value={this.state.stagingForm.ajccPostNSuffix ? this.state.stagingForm.ajccPostNSuffix : this.state.stagingForm.AjccPostNSuffix}
                                                            dataSource="Global_Dir_Mv" aliasNo="4162"
                                                            titleDialog="AJCC TNM Post Therapy Pathological N Suffix" labelWidth="0px" width="60px"
                                                            inValid={this.state.sAllItemFields["ajccPostNSuffix"]} itemNbr="4162"
                                                            lostfocused={this.handleLostFocus}
                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostNSuffix"] : ""}
                                                        />
                                                    </div>
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="M" field="ajccPostM" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccPostM ? this.state.stagingForm.ajccPostM : this.state.stagingForm.AjccPostM}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_PATH_M"//DataItem::POSTTHERAPY_PATH_M = 1023
                                                        titleDialog="AJCC TNM Post Therapy Pathological M" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccPostM"]} itemNbr="4163"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostM"] : ""}
                                                        flag={this.state.ajccId}
                                                    />
                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                        labelText="Grp" field="ajccPostStageGroup" lineStyle="oneLine"
                                                        value={this.state.stagingForm.ajccPostStageGroup ? this.state.stagingForm.ajccPostStageGroup : this.state.stagingForm.AjccPostStageGroup}
                                                        dataSource="Tnm_Lookup8th_Mv" aliasNo="POSTTHERAPY_PATH_STAGE"
                                                        titleDialog="AJCC TNM Post Therapy Pathological Stage Group" labelWidth="30px" width="120px"
                                                        inValid={this.state.sAllItemFields["ajccPostStageGroup"]} itemNbr="4164"
                                                        lostfocused={this.handleLostFocus}
                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["ajccPostStageGroup"] : ""}
                                                        flag={this.state.ajccId}
                                                        schemaId={this.state.schemaId} t={this.state.stagingForm.ajccPostT} n={this.state.stagingForm.ajccPostN} m={this.state.stagingForm.ajccPostM}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                        <div style={{ display: "none" }}>
                            <input type="hidden" id="Definition_Contains_Fn_ajcc_id_name" value={this.state.ajccId} />
                        </div>
                    </form>

                    <Footer showNavigation closeWindow={this.props.closeWindow}
                        refreshForm={this.resetStaging} formId="stagingForm"
                        isFormDirty={this.state.isFormDirty}
                        showExtra={[{ name: "v21 Compatibility Fields", action: "v21CompatibilityFeature" }, { name: "Text", action: "showDiagnosisText" }]}
                        callbackToParent={this.updateSaveFlagToProcess}
                        focusingField={this.state.focusingField}
                    />
                </div>
            )
            : <p className="data_loading_page">Staging data loading...</p>;
        return (
            <React.Fragment>

                <React.Fragment>
                    {showConfirmationSchemaIdHasChanged}
                </React.Fragment>

                {stagingMaintenance}

                <div id="textareaEditorForItemsCls" className={this.props.selectedPrimarySummary.diagnosisDateYear < 2018 ? "hidden" : ""}>
                    {
                        this.props.isShowTextDialogVisible ?
                            <div className="CRStar_Window">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar">
                                    <div className="dragableWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">
                                                <pre className="textPopupPreTitleCls">{TextPopup.Title}</pre>
                                            </div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeTextDialog(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <TextForm closeWindow={this.closeTextDialog} setFocusingField={this.helpFocusingField} />
                                    </div>
                                </Draggable>
                            </div>
                            : null
                    }
                </div>


                <div id="v21CompatibilityFeaturePopup" className={this.props.selectedPrimarySummary.diagnosisDateYear < 2018 ? "hidden" : ""}>
                    {
                        this.props.isV21CompatibilityVisible ?
                            <div className="CRStar_Window_0">
                                <div className="fake_popup_cls"></div>
                                <Draggable handle=".k-window-titlebar" id={"V21CompatibilityMode_Modal"}
                                    disabled={this.props.childrenOpening}
                                    axis={this.props.childrenOpening ? "none" : "both"}>
                                    <div className="dragableLargestWindowCls">
                                        <div className="k-window-titlebar k-dialog-titlebar k-header">
                                            <div className="k-window-title k-dialog-title">Staging and SSDI v21 Compatibility Mode</div>
                                            <div className="k-window-actions k-dialog-actions">
                                                <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                                    <span className="k-icon k-i-x" onClick={(e) => { this.closeV21CompatibilityFeature(e) }}></span>
                                                </a>
                                            </div>
                                        </div>
                                        <V21CompatibilityFeatureForm
                                            closeWindow={this.closeV21CompatibilityFeature}
                                            setFocusingField={this.helpFocusingField}
                                            //reflectFnc={this.reflectFnc}
                                            ajccId={this.state.ajccId} schemaId={this.state.schemaId}
                                            schemaDiscrim2={this.state.stagingForm && this.state.stagingForm.schemaDiscrim2 ? this.state.stagingForm.schemaDiscrim2 : ""}
                                        />
                                    </div>
                                </Draggable>
                            </div>
                            : null
                    }
                </div>

            </React.Fragment>
        );
    }
}

Staging2018Maintenance.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    //closeWindow: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        selectedStagingRecord: state.patientAbstract.selectedStagingRecord,
        isPatientLoading: state.patientAbstract.isPatientLoading,

        selectedMedicalRecord: state.patientAbstract.selectedMedicalRecord,
        selectedDiagnosisRecord: state.patientAbstract.selectedDiagnosisRecord,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,

        selectedPatient: state.patientAbstract.selectedPatient,
        isShowTextDialogVisible: state.patientAbstract.isShowTextDialogVisible,
        isV21CompatibilityVisible: state.patientAbstract.isV21CompatibilityVisible,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,

        currentDialog: state.patientAbstract.currentDialog,
        //
        clientgroup: state.auth.clientgroup,
        // get save anyway flag to allow the screen ignore the validations.
        allowSaveAnyway: state.patientAbstract.allowSaveAnyway,
        isFormDirty: state.patientAbstract.isFormDirty,
        childrenOpening: state.patientAbstract.childrenOpening,

        initialAbstractPages: state.patientAbstract.initialAbstractPages,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDialog: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        saveStaging: (stagingData, alias) => dispatch(actionCreators.saveStaging(stagingData, alias)),
        saveTextDialog: (textDialogData) => dispatch(actionCreators.saveTextDialog(textDialogData)),
        closeTextDialog: () => dispatch(actionCreators.closeTextDialog()),
        closeV21CompatibilityFeature: () => dispatch(actionCreators.closeV21CompatibilityFeature()),

        checkValidInput: (field, value, table, key, index, flag, cid, pcy) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag, cid, pcy)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),

        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        setChildrenWindowVisible: (flag) => dispatch(actionCreators.setChildDialogVisible(flag)),
        // Hospital and Physician lookup
        closeAddNewDialog: (flag) => dispatch(actionCreators.closeAddNewDialog(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        selectTabItems: (dialogType) => dispatch(actionCreators.selectTabItems(dialogType)),
        removeTabItems: (dialogType) => dispatch(actionCreators.removeTabItems(dialogType)),
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setChildrenOpening: (flag) => dispatch(actionCreators.setChildrenOpening(flag)),
        clearSelectedStagingRecord: () => dispatch(actionCreators.clearSelectedStagingRecord()),

        setInitialAbstractPages: (flag) => dispatch(actionCreators.setInitialAbstractPages(flag)),
        showingMessagePatientAbstracts: (err, code, msg) => dispatch(actionCreators.showingMessagePatientAbstracts(err, code, msg)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Staging2018Maintenance);