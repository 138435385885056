/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../../store/PatientAbstract";
import Draggable from 'react-draggable';
import axios from "../../../../axios-instance";
import ConfigData from '../../../../store/config.json';

class PrintAndDownloadFU extends Component {

    state = {
        radioListLetters: "List",
        outputFileNotFound: false
    }

    drawingHtmlTable(reportDataInfo) {
        this.setState({
            dialogForm: { ...reportDataInfo },
            radioOptions: "List",
            //
            isDrawingHtmlTable: (reportDataInfo.fnDisplay && reportDataInfo.fnDisplay != "") || (reportDataInfo.urlList && reportDataInfo.urlList != ""),
            radioListLetters: "List",
            fnDisplay: reportDataInfo.fnDisplay,
            fnExcel: reportDataInfo.fnExcel,
            htmlOutputOfList: reportDataInfo.htmlOutputOfList,
            htmlOutputOfLetters: reportDataInfo.htmlOutputOfLetters,
            reportFileName: reportDataInfo.reportFileName,
        });

        setTimeout(function () {
            document.getElementById("stateExportOption_Id") ? document.getElementById("stateExportOption_Id").click() : null;

            if (document.getElementById("outputDataHandler") && document.getElementById("outputDataHandler").value == "1") {
                document.getElementById("outputDataHandler") ? document.getElementById("outputDataHandler").value = "0" : null;
                return false;
            } else {
                document.getElementById("outputDataHandler") ? document.getElementById("outputDataHandler").value = "1" : null;
            }

            var reportDisplay = document.getElementById("displayReportIframe");
            if (reportDisplay && reportDisplay.contentDocument) {
                reportDisplay.contentDocument.src = "about:blank";
                reportDisplay.contentDocument.write("");
                reportDisplay.contentDocument.write(reportDataInfo.htmlOutputOfLetters);
            }
            var reportListDisplay = document.getElementById("displayReportListIframe");
            if (reportListDisplay && reportListDisplay.contentDocument) {
                reportListDisplay.contentDocument.src = "about:blank";
                reportListDisplay.contentDocument.write("");
                reportListDisplay.contentDocument.write(reportDataInfo.htmlOutputOfList);
            }
        }, 100);

        return false;
    };

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.props.closeWindow();
        return false;
    }

    componentDidMount() {
        if (this.props.reportDataInfo && this.props.reportDataInfo.endTime) {
            let dataForm = this.props.reportDataInfo;
            if (dataForm.endTime) {
                this.drawingHtmlTable(dataForm);
            }
        }
    }

    printTable = () => {
        var printStyle = document.getElementById('PrintStyle') ? document.getElementById('PrintStyle').innerHTML : document.getElementById('printStyle') ? document.getElementById('printStyle').innerHTML : "";
        var printContent = document.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>' + (/*this.props.pTitle ? this.props.pTitle :*/ "Follow-Up Letters") + '</title><style type="text/css">';
        html += printStyle + '</style></head><body><div id="printableContent">';
        html += (printContent ? printContent.innerHTML : "") + '</div></body></html>';
        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
        return false;
    }

    selectionStateExport = (event) => {
        try {
            event.preventDefault();
        } catch (e) { }
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            radioListLetters: event.target.value,
            dialogForm: dialogFormData,
        });

        setTimeout(function () {
            document.getElementById("option1").click();
            return false;
        }, 0);
        return false;
    }

    Update_Letters_Sent_Flag = () => {
        var url = this.props.clientgroup + "/UpdateLettersSentFlag";
        let followUpLetters = {
            lettersSent: "",//alpha sources -- comment out
            updateFlag: this.props.updateFlag,
            clientAlias: this.props.clientgroup
        };

        var _this = this;
        axios.post(url, {
            followUpLetters: followUpLetters,
            accessToken: localStorage.getItem('token')
        }).then((response) => {
            return false;
        });
    }

    //
    async downloadFileFnc(event, ext) {
        event.preventDefault();

        /*
        if (this.downloadFile) {
            this.downloadFile = false;
            return false;
        }
        this.downloadFile = true;
        */

        var radioStateExport = this.state.radioListLetters;
        var fileName = this.state.reportFileName;
        let userId = this.props.userId;

        var url = this.props.clientgroup + "/DownloadFu";
        var followUpLetters = {
            userId: userId,
            fileName: fileName,
            type: radioStateExport,
            ext: ext
        };

        /*
        var _this = this;
        axios.post(url, {
            followUpLetters: followUpLetters,
            method: 'POST',
            responseType: 'blob',//important
        }).then((response) => {
            const _url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = _url;

            var fname = (ext == ".xlsx" ? "Follow-Up List and Letters Data_" : (radioStateExport == "List" ? "Follow-Up List_" : "Follow-Up Letters_")) + fileName + ext;

            link.setAttribute('download', fname);
            document.body.appendChild(link);
            link.click();
            link.remove();
            _this.downloadFile = true;
            return false;
        });
        */

        var fname = (ext == ".xlsx" ? "Follow-Up List and Letters Data_" : (radioStateExport == "List" ? "Follow-Up List_" : "Follow-Up Letters_")) + fileName + ext;
        if (this.props.pTitle == "Demand Letters") {
            fname = (ext == ".xlsx" ? "Demand List and Letters Data_" : (radioStateExport == "List" ? "Demand List_" : "Demand Letters_")) + fileName + ext;
            this.Update_Letters_Sent_Flag();
        }

        //Followup Letters - display error message "Could not find the exported file: ..." and stop the process to prevent Excel grab the index file for downloading
        var _this = this;
        var url = ConfigData.CRStarAPI_URL + this.props.clientgroup + "/CheckExistedDownloadFile";
        var _params = { fileName: fname, clientAlias: userId, accessToken: localStorage.getItem('token') };
        var result = await axios.post(url, _params)
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                console.log(error);
                _this.props.showSessionTimeoutMsg(error && error.toString() == "Error: Request failed with status code 408" ? true : false);
                /*
                var btnLogout = document.getElementById("logoutButtonArea");
                if (btnLogout) {
                    btnLogout.querySelector("a").click();
                }
                */
                return false;
            });

        if (result && result.data && result.data.error) {
            let path = this.state.fnExcel ? this.state.fnExcel : "";
            path = path.substr(0, path.lastIndexOf("\\") + 1);

            //alert("Could not find the exported file: " + fname);
            _this.setState({ outputFileNotFound: true, fileName: path + fname });
            return false;
        } else {
            const link = document.createElement('a');
            link.href = this.props.clientgroup + "/" + fname;
            link.setAttribute('download', fname);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }

        return false;
    }

    hideValidationError = (event) => {
        try {
            event.preventDefault();
        } catch (e) { }
        this.setState({ outputFileNotFound: false, fileName: null });
        return false;
    }

    handleOptionChange = (event) => {
        try {
            event.preventDefault();
        } catch (e) { }
        this.setState({
            selectedOption: event.target.value
        });
        return false;
    }

    render() {
        let validationError;
        {
            validationError = this.state.outputFileNotFound ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls" id="PrintAndDownloadFuPopup">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Validation Error</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideValidationError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls">
                                    {"Could not find the exported file: " + this.state.fileName}
                                </label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideValidationError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        //
        var Report_Url;
        var reportFile = this.state.radioListLetters ? this.state.radioListLetters : "List";
        if (reportFile == "List") {
            Report_Url = this.state.htmlOutputOfList;
        } else {
            Report_Url = this.state.htmlOutputOfLetters;
        }

        const _radioListLetters = this.state.radioListLetters ? this.state.radioListLetters : "List";

        let pdFU;
        {
            pdFU = this.state.isDrawingHtmlTable && this.state.fnDisplay ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className={reportFile == "Letters" ? "dragableSmallWindowCls" : "dragableLargestWindowCls"}
                            id={reportFile == "Letters" ? "letterPreviewModal" : "dataExportMainPopup_FU"}>
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">{/*this.props.pTitle ? this.props.pTitle :*/ "Follow-Up Letters"}</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingHtmlTable(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>

                            <div className="container printOnQaForm" id="printAbtractsMainTable"
                                style={{ overflowY: this.state.publicFolder && this.state.publicFolder != "" ? "hidden" : "auto" }}>
                                {/*Drawing table here*/}

                                <iframe id="displayReportIframe" className={reportFile == "Letters" ? "hidden" : "hidden"} />
                                <div className="container row" id="previewLettersDialog" style={{ display: reportFile == "Letters" ? "" : "none" }}>
                                    <div className="col-sm-12">
                                        <p dangerouslySetInnerHTML={{ __html: Report_Url }}></p>
                                    </div>
                                </div>

                                <div id="useForPrintHtml" className="hidden">
                                    <div dangerouslySetInnerHTML={{ __html: Report_Url }} className="state_export_popup" />
                                </div>

                                <iframe id="displayReportListIframe" className={reportFile == "List" ? "" : "hidden"} />

                                { /* Show this to overwrite CSS Style of #printableContent element  * / }
                                <div className="container row" id="previewLettersDialog" style={{ display: reportFile == "Letters" ? "none" : "none" }}>
                                    <div className="col-sm-12">
                                        <p dangerouslySetInnerHTML={{ __html: Report_Url }}></p>
                                    </div>
                                </div>
                                { */ }

                                <input id="outputDataHandler" value="0" type="hidden" />
                            </div>

                            <div className="emptyLine"></div>

                            <div className="emptyLine hidden" style={{ display: "none" }}>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option1" id="option1"
                                            checked={this.state.selectedOption === 'option1'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 1
                                    </label>
                                </div>
                                <div className="radio">
                                    <label>
                                        <input type="radio" value="option2" id="option2"
                                            checked={this.state.selectedOption === 'option2'}
                                            onClick={this.handleOptionChange}
                                            onChange={this.handleOptionChange} />Option 2
                                        </label>
                                </div>
                            </div>

                            <div className="container" style={{ padding: "10px" }}>
                                <div className="row">
                                    <div className="col-sm-7" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ScreenButton ScreenButtonHover" title=""
                                            onClick={this.printTable} autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" />
                                            Print</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_DOWNLOAD" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "5px" }}
                                            onClick={(e) => { this.downloadFileFnc(e, ".rtf") }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$application.ms.word.png.a5image" />
                                            Download</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_DOWNLOAD_XFER" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "5px" }}
                                            onClick={(e) => { this.downloadFileFnc(e, ".xlsx") }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$application.ms.excel.png.a5image" />
                                            Data Xfer</button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ScreenButton ScreenButtonHover" title=""
                                            style={{ marginLeft: "5px" }}
                                            onClick={this.hideDrawingHtmlTable} value="exit">
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" />
                                            Exit</button>
                                    </div>
                                    <div className="col-sm-3" style={{ display: "flex" }} id="stateExportRadioOptions">
                                        <label style={{ width: "auto", height: "0px" }}>
                                            <input type="radio" onChange={(e) => { this.selectionStateExport(e) }}
                                                checked={_radioListLetters == "List" ? true : false}
                                                name="radioStateExport" field="radioStateExport" id="stateExportOption_Id" value="List"
                                                style={{ cursor: "pointer", fontWeight: "bold", marginRight: "-35px" }} />
                                        List
                                    </label>

                                        <label style={{ width: "auto", height: "0px" }}>
                                            <input type="radio" onChange={(e) => { this.selectionStateExport(e) }}
                                                checked={_radioListLetters == "Letters" ? true : false}
                                                name="radioStateExport" field="radioStateExport" value="Letters" width="240px"
                                                style={{ cursor: "pointer", fontWeight: "bold", marginRight: "-35px" }} />
                                        Letters
                                    </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null;
        }

        return (
            <React.Fragment>
                <React.Fragment>
                    {validationError}
                </React.Fragment>
                <React.Fragment>
                    {pdFU}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

PrintAndDownloadFU.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,

        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        //
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        userId: state.auth.userId,
        selectedHospital: state.patientAbstract.selectedHospital,
        selectedPatientOnPopup: state.patientAbstract.selectedPatientOnGrid,
        selectedLabelPopInfo: state.patientAbstract.selectedLabelPopInfo,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),

        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType) => dispatch(actionCreators.addTabItems(dialogType)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintAndDownloadFU);