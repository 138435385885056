/* eslint-disable */
import React, { Component } from "react";
import Iframe from 'react-iframe';
import Draggable from 'react-draggable';
import { connect } from "react-redux";
import "./ShowOrHideBtnCancel.css";

class PrintDemandLetters extends Component {

    state = {
        loaded: false,
    }

    closeWindow = (e) => {
        try {
            e ? e.preventDefault() : "";
        } catch (e) { }
        this.props.closeWindow();
        return false;
    }

    _componentDidMount() {
        setTimeout(function () {
            if (document.getElementById("GENDEMANDL_V_R1_BUTTON_EXIT"))
                document.getElementById("GENDEMANDL_V_R1_BUTTON_EXIT").style.display = 'block';
        }, 5000);
    }

    mouseEnterEvent(event) {
        event.target.className = "A5CWLayout showBtnExit";
    }
    mouseLeaveEvent(event) {
        if (event.target.nodeName == "BUTTON") {
            event.target.parentNode.className = "A5CWLayout";
        } else {
            event.target.className = "A5CWLayout";
        }
    }

    render() {
        let printDemandLetters = null;
        {
            printDemandLetters = <Draggable handle=".dragableAlphaWindowCls">
                <div className="dragableAlphaWindowCls" id="PrintDemandLettersModal">
                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                        <div className="k-window-title k-dialog-title">Generate Demand Letters</div>
                        <div className="k-window-actions k-dialog-actions">
                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWindow(e) }}></span>
                            </a>
                        </div>
                    </div>

                    <div>
                        {
                            <Iframe
                                url={this.props.alphaSystemUrlBaseUrl + "/sso.a5w?tk=" + this.props.alphaToken + "&r=" + this.props.alphaUrl + ".a5w"}
                                id="iFrameToAlphaSystemOnModal"
                                className="iFrameOnModalCls"
                                display="initial"
                                position="relative"
                                frameborder="0" //allowfullscreen
                                frameBorder="0"
                                target="_self"
                            />
                        }
                    </div>
                    <div className="A5CWLayout" id="GENDEMANDL_DIV_HANDLE_BUTTON_EXIT" style={{ width: "100px", position: "absolute" }} onMouseEnter={this.mouseEnterEvent} onMouseLeave={this.mouseLeaveEvent}>
                        <button type="button" id="GENDEMANDL_V_R1_BUTTON_EXIT" className="__ScreenButton" title=""
                            style={{ width: "100%", height: "27px", boxSizing: "border-box", position: "initial", left: "230px", bottom: "92px", borderRadius: "5px", display: "none" }}
                            onClick={(e) => { this.closeWindow(e) }} onMouseLeave={this.mouseLeaveEvent}>
                            <img id="GENDEMANDL.V.R1.BUTTON_EXIT.ICON" src="https://testalpha.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" style={{ verticalAlign: "middle", border: "none" }} /> Exit
                        </button>
                    </div>

                </div>
            </Draggable>
        }

        return (
            <div>
                <div className="fake_popup_cls"></div>
                {printDemandLetters}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        clientgroup: state.auth.clientgroup,
        alphaSystemUrlBaseUrl: state.patientAbstract.alphaSystemUrlBaseUrl,
        //alphaToken: state.auth.token,
        alphaToken: state.patientAbstract.tokenOnSession,
    }
}

export default connect(mapStateToProps, null)(PrintDemandLetters);