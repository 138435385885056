/* eslint-disable */
//Window Title: Generate Demand Letters
//Window height: 600px, width: 1250px
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../store/PatientAbstract";
import Input from "../UI/Input";
import axios from "../../axios-instance";
import Draggable from 'react-draggable';
import "./GenFollowUpLetters.css";
import PrintAndDownloadFU from "../AbstractManagement/PatientAbstract/Shared/PrintAndDownloadFU";

class GenDemandLetters extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        isNew: false,
        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        loaded: false,
        isCheckedUpdateFlag: false,
        selectedStateExport: "List",
        selectedPatientCid: "0"
    }
    selectedPatientBk = null;

    toggleChecked = (event) => {
        const name = event.target.name;
        const checked = this.state.isCheckedUpdateFlag;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = !checked ? "True" : "False";

        this.setState({
            isCheckedUpdateFlag: !checked,
            dialogForm: dialogFormData,
            isFormDirty: true,
        });
    }

    handleOnFocus = (event, index) => {
        event.preventDefault();
        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
            selectedPatientLookupRowIndex: index ? index : 0
        });
        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);
        return false;
    }

    _componentDidUnmount() {
        this.props.loadFollowUpLetters();

        document.getElementById("startTime") ? document.getElementById("startTime").text = "" : null;
        document.getElementById("endTime") ? document.getElementById("endTime").text = "" : null;
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, allowedHosps: null });

        this.initClock();
    }

    componentDidMount() {
        //document.title = "Generate Demand Letters";

        this.setState({ selectedItemRowIndex: 0 });

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);
        /*
        // Add all tabs in maintDialogs.js
        dialogTypes.followUpMgtTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });
        */
        this.setState({ isLoading: true, loaded: false });//#End

        if (!this.state.dialogForm && this.props.followUpLettersInfo) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        if (!this.props.onRightMenu) {
            setTimeout(function () {
                document.getElementById("BUTTON_NEWRECORD_FK") ? document.getElementById("BUTTON_NEWRECORD_FK").click() : null;
            }, 1000);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                    startTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.startTime ? this.props.selectedReportLabelsInfo.startTime : this.props.selectedReportLabelsInfo.startTimeSAP) : "",
                    endTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.endTime ? this.props.selectedReportLabelsInfo.endTime : this.props.selectedReportLabelsInfo.endTimeSAP) : "",
                    numberOfRecords: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.numberOfRecords : "",
                },
                errorMessage: this.props.errorMessage,
            });

            document.querySelector('#BUTTON_RUN_REPORT') ? document.querySelector('#BUTTON_RUN_REPORT').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        if ((!this.state.dialogForm && this.props.followUpLettersInfo)
            || (prevProps.followUpLettersInfo && this.props.followUpLettersInfo && prevProps.followUpLettersInfo.startTime !== this.props.followUpLettersInfo.startTime)
            || (prevProps.followUpLettersInfo && this.props.followUpLettersInfo && prevProps.followUpLettersInfo.endTime !== this.props.followUpLettersInfo.endTime)
            || (this.state.dialogForm && this.props.followUpLettersInfo && this.state.dialogForm.lastAccessTime !== this.props.followUpLettersInfo.lastAccessTime)
        ) {
            this.setFormData();
        }

        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }

        //
        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm && this.state.pAllItemFields) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                this.setState({
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                });
            }
            this.props.clearCommonLookupItem();
        }

        // LoadPatientLookupMv List
        if (this.props.followUpLettersInfo && !this.state.patientLookupList && !this.state.patientLookupListLoading) {
            var patientLookupList = [];
            for (var i = 0; i < 4; i++) {
                let pItems = {
                    medRecNbr: "",
                    lastName: "",
                    firstName: "",
                    siteCode: "",
                    seqPrim: "",
                    hosp: "",
                    cid: "0",
                    pid: "0"
                };
                patientLookupList.push(pItems);
            }
            this.setState({
                selectedPatientCid: 0,
                patientLookupListLoading: true,
                patientLookupList: patientLookupList
            });
        }

        // Load Physicians List/Alpha::Fill_Physicians_Display
        if (this.state.patientLookupList && !this.state.physiciansMv && !this.state.physiciansMvLoading) {
            this.setState({ physiciansMvLoading: true });
            let cid = this.state.selectedPatientCid && this.state.selectedPatientCid != "not used" ? this.state.selectedPatientCid : 0;
            if (this.props.onRightMenu && cid == 0 && this.props.selectedPrimarySummary && this.props.selectedPrimarySummary.cid && !this.state.isNew) {
                cid = this.props.selectedPrimarySummary.cid;
            }

            var _this = this;
            axios.post(this.props.clientgroup + "/GetPhysiciansMvList", { "cid": cid, accessToken: localStorage.getItem('token') })
                .then(res => {
                    if (!res.data.error) {
                        var physiciansMvList = res.data.physiciansMvList;
                        _this.setState({ physiciansMv: physiciansMvList, physiciansMvLoading: true });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    return false;
                });
        }

        //
        if (this.state.patientLookupList && !this.state.selectedPatientOnState && this.props.selectedPatientOnPopup
            && (this.props.alphaUrl == "/GenFollowUpLetters" || this.props.onRightMenu)) {
            var index = this.state.selectedPatientLookupRowIndex ? this.state.selectedPatientLookupRowIndex : (this.props.selectedPatientIndex ? this.props.selectedPatientIndex : 0);
            this.setState({ selectedPatientOnState: true });

            var selectedPatient = this.props.selectedPatientOnPopup;//
            var selectedPrimarySummary = this.props.selectedPrimarySummary;//

            if (!this.selectedPatientBk) {
                this.selectedPatientBk = selectedPatient;
                if (!this.props.onRightMenu) {
                    selectedPrimarySummary = selectedPatient;
                }
            } else {
                selectedPrimarySummary = selectedPatient;
            }

            let _cid = selectedPatient.cid && selectedPatient.cid != "not used" ? selectedPatient.cid : 0;
            if (this.props.onRightMenu && _cid == "0" && this.props.selectedPrimarySummary && this.props.selectedPrimarySummary.cid) {
                _cid = this.props.selectedPrimarySummary.cid;
            }

            this.setState(prevState => {
                const newItems = [...prevState.patientLookupList];
                newItems[index]["medRecNbr"] = selectedPrimarySummary ? selectedPrimarySummary.medRecNbr : "";
                newItems[index]["firstName"] = selectedPatient ? selectedPatient.firstName : "";
                newItems[index]["lastName"] = selectedPatient ? selectedPatient.lastName : "";
                newItems[index]["siteCode"] = selectedPrimarySummary ? selectedPrimarySummary.siteCode : "";
                newItems[index]["seqPrim"] = selectedPrimarySummary ? selectedPrimarySummary.seqPrim : "";
                newItems[index]["hosp"] = selectedPrimarySummary ? selectedPrimarySummary.hosp : "";
                newItems[index]["cid"] = _cid;//selectedPatient ? selectedPatient.cid : "";

                return {
                    isFormDirty: true,
                    patientLookupList: newItems,
                    selectedPatientCid: _cid,//selectedPatient ? selectedPatient.cid : "0",
                    physiciansMv: null,
                    physiciansMvLoading: false
                };
            });

            //reset selectedPatientOnPopup props again
            this.props.setInitialPageVisible(true);
        }
    }

    setFormData = () => {
        let dataForm = this.props.followUpLettersInfo;

        let fields = {};
        Object.keys(dataForm).map((t) => {
            fields[t] = "";
        });

        this.setState({
            initialDialogForm: fields,
            dialogForm: { ...dataForm },
            //isFormDirty: false,
            isNew: false,
            hasError: false,
            loaded: true,
            showClockTimer: false,
        });

        if (!this.state.pAllItemFields && this.props.followUpLettersInfo) {
            let _fields = {};
            Object.keys(this.props.followUpLettersInfo).map((t) => {
                _fields[t] = "";
            });

            this.setState({ pAllItemFields: _fields, pageMaxlengthInput: _fields });
        };

        // Processing output...
        if (dataForm.endTime && dataForm.endTime != "") {
            this.setState({
                isDrawingHtmlTable: dataForm.fnDisplay && dataForm.fnDisplay != "",
                Fn_Html_Display_URL: dataForm.htmlOutputOfList,
                selectedStateExport: "List",
                completed: false,
            });
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            if (event.target2.name === "letterTitleName") {
                //Fill-in fields::Letter_Hosp<Hospital_Code
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value,
                        ["letterHosp"]: event.target2.value,
                    },
                    isFormDirty: true,
                });
                return false;
            } else if (event.target2.name === "hospitalCodeName") {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value,
                    },
                    isFormDirty: true,
                });
                return false;
            }
        }

        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value,
            },
            isFormDirty: true,
        });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });

            if (event.target.name == "hospitalCode" && event.target.value == "") {
                document.getElementById("hospitalCodeName") ? document.getElementById("hospitalCodeName").innerHTML = "" : null;
            }
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.followUpLettersInfo) {
            Object.keys(this.props.followUpLettersInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: { ...this.state.initialDialogForm },
            pAllItemFields: fields,
            isFormDirty: false,
            isNew: true,
            patientLookupList: null,
            patientLookupListLoading: false,
            physiciansMv: null,
            physiciansMvLoading: null,
            selectedPatientCid: "0",
            isCheckedUpdateFlag: false,
            selectedPatientLookupRowIndex: 0,
            selectedPhysiciansMvRowIndex: 0
        });

        //
        document.getElementById("populationLabel") ? document.getElementById("populationLabel").focus() : "";
        setTimeout(function () {
            document.getElementById("populationLabel") ? document.getElementById("populationLabel").focus() : "";
        }, 500);
        return false;
    }

    closeWindow = (e) => {
        e.preventDefault();
        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null,
            selectedPatientOnState: false
        });

        if (this.props.onRightMenu) {
            this.props.closeWindow();

            if (this.selectedPatientBk) {
                this.props.selectedPatientOnGrid(this.selectedPatientBk, 0);
            }
        }
        return false;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");
            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });
            return false;
        }, 1000);
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail";
        var _this = this;
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ allowedHosps: _availableHospsRptArr.join(',') });
                } else {
                    _this.setState({ allowedHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });
        return false;
    }

    Demand_Letters() {
        var dialogFormData = this.state.dialogForm;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.userHosp = this.state.allowedHosps;

        var cidList = "";
        for (var i = 0; i < this.state.patientLookupList.length; i++) {
            if (this.state.patientLookupList[i]["cid"] && this.state.patientLookupList[i]["cid"] != "" && this.state.patientLookupList[i]["cid"] != "0") {
                cidList += "," + this.state.patientLookupList[i]["cid"];
            }
        }
        dialogFormData.cidList = cidList.substr(1);

        if (!dialogFormData["refYear"] || dialogFormData["refYear"] == "") {
            dialogFormData["refYear"] = 0;
        }

        //
        this.props.runFollowUpLetters(dialogFormData, "demand");

        this.downloadFile = false;//reset this flag to get download new file
        return false;
    }

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        var _this = this;
        setTimeout(function () {
            _this.Demand_Letters();
            document.getElementById("outputDataHandler") ? document.getElementById("outputDataHandler").value = "" : null;

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");

            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });
            return false;
        }, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);
        return false;
    }

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckStatusFUL";
        axios.post(url, { accessToken: localStorage.getItem('token') })
            .then(res => {
                if (res && res.data && res.data.error) {
                    _this.setState({
                        showMessageError: true,
                        errorMessage: res.data.message
                    });
                } else if (res && res.data && res.data.fnDisplay) {
                    console.clear();
                    console.log("CheckStatusFUL::Completed");
                }
            }).catch(error => {
                console.log(error);
                return false;
            });
        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();

        _this.props.cancelSBSReport();

        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);
        return false;
    }

    newRecord(e) {
        this.refreshFormFnc();
        return false;
    }

    exitScreen = (event) => {
        event.preventDefault();

        this.setState({ selectedPatientOnState: false });
        this.props.setInitialPageVisible(false);
        this.refreshFormFnc();

        if (this.props.onRightMenu) {
            this.props.closeWindow();

            if (this.selectedPatientBk) {
                this.props.selectedPatientOnGrid(this.selectedPatientBk, 0);
            }
        }
        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        return false;
    }

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false });
        return false;
    }

    //
    handlePatientLookupLostfocus = (event, index) => {
        //console.log("handlePatientLookupLostfocus at " + index);
        try {
            event.preventDefault();
        } catch (ex) { }

        this.setState({ selectedPatientOnState: false });
        return false;
    }

    handlePatientLookupGridChange = (event, index) => {
        //console.log("handlePatientLookupGridChange at " + index);
        const name = event.target.name;
        const value = event.target.value;

        let name2 = null;
        let value2 = null;
        if (event.target2) {
            name2 = event.target2.name;
            value2 = event.target2.value;
        }

        this.setState(prevState => {
            const newItems = [...prevState.patientLookupList];
            if (name == "lookup") {
                newItems[index][name] = "";
            } else {
                newItems[index][name] = value;
            }

            if (name2 && value2) {
                newItems[index][name2] = value2;
            }
            return { patientLookupList: newItems, isFormDirty: true, selectedPatientOnState: false };
        });
        return false;
    }

    addNewPatientLookup = (event) => {
        event.preventDefault();

        this.setState(prevState => {
            const newItems = [...this.state.patientLookupList];
            const firstLabels = newItems[0];
            const newLabels = {};
            Object.keys(firstLabels).map((t, k) => {
                if (t === "pid" || t === "cid") {
                    newLabels[t] = 0;
                } else {
                    newLabels[t] = "";
                }
            });
            newItems.push(newLabels);
            // 
            return { patientLookupList: newItems, selectedPatientOnState: false };
        });
    }

    deleteSelectedPatientLookup = (event) => {
        event ? event.preventDefault() : null;

        let selectedRow = this.state.selectedPatientLookupRowIndex;
        if (selectedRow === -1 || selectedRow >= this.state.patientLookupList.length) {
            selectedRow = this.state.patientLookupList.length - 1;
        }

        this.setState(prevState => {
            const newItems = [...prevState.patientLookupList];
            if (newItems.length === 1) {
                const _newItems = [...this.state.patientLookupList];
                const firstItem = _newItems[0];

                const newItem = {};
                Object.keys(firstItem).map((t, k) => {
                    if (t === "pid" || t === "cid") {
                        //newItem[t] = 0;
                    } else {
                        newItem[t] = "";
                    }
                });
                newItems.splice(selectedRow, 1);
                newItems.push(newItem);
            } else {
                newItems.splice(selectedRow, 1);
            }

            if (selectedRow >= newItems.length) {
                selectedRow = newItems.length - 1;
            }

            return {
                patientLookupList: newItems,
                selectedPatientLookupRowIndex: selectedRow,
                selectedPatientOnState: false
            };
        });
        return false;
    }

    setSelectionPatientLookupRow = (event, index) => {
        event ? event.preventDefault() : null;
        var cid = 0;
        if (event.target.nodeName == "TD") {
            cid = event.target.parentNode.getAttribute("data-cid") ? event.target.parentNode.getAttribute("data-cid") : "0";
        } else if (event.target.nodeName == "TR") {
            cid = event.target.getAttribute("data-cid") ? event.target.getAttribute("data-cid") : "0";
        }

        if (cid != 0) {
            this.setState({
                selectedPatientLookupRowIndex: index,
                selectedPatientCid: cid,
                physiciansMv: null,
                physiciansMvLoading: false,
                selectedPhysiciansMvRowIndex: 0
            });
        } else {
            this.setState({ selectedPatientLookupRowIndex: index });
        }
        return false;
    }

    setSelectionPhysiciansMvRow = (event, index, flg) => {
        event ? event.preventDefault() : null;

        let fuNum = this.state.dialogForm ? this.state.dialogForm.fuDocNum : "";

        if (flg) fuNum = index + 1;

        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                fuDocNum: fuNum,
            },
            selectedPhysiciansMvRowIndex: index,
            isFormDirty: flg ? flg : this.state.isFormDirty
        });
        return false;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Access Denied</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls" dangerouslySetInnerHTML={{ __html: this.state.errorMessage ? this.state.errorMessage : this.props.errorMessage }}></label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Creating List and Letters - Please Wait&nbsp;&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton" value="no">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let dialogFrm;
        if (this.props.onRightMenu) {
            dialogFrm = <Draggable handle=".k-window-titlebar">
                <div className="dragableLargestDialogCls" id="generateDemandLettersModal">
                    <div className="k-window-titlebar k-dialog-titlebar k-header">
                        <div className="k-window-title k-dialog-title">Generate Demand Letters</div>
                        <div className="k-window-actions k-dialog-actions">
                            <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                <span className="k-icon k-i-x" onClick={(e) => { this.closeWindow(e) }}></span>
                            </a>
                        </div>
                    </div>

                    <div className="limitedInGridCls">
                        <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                            {loading}
                            <div className="parentWindow">
                                <div className="patientAbstractGridView" id="followUpLettersMainPage" style={{ height: "580px" }}>
                                    {
                                        this.state.dialogForm ?
                                            <div className="container-fluid2">
                                                <form onSubmit={this.javascript_void_fnc} id="followUpLettersForm" className="followUpLettersForm">
                                                    <div id="inputAreaCls">
                                                        <div style={{ width: "100%" }}>
                                                            <div className="_frameInner">
                                                                <div className="emptyLine"></div>
                                                                <div className="row">
                                                                    <div className="col-sm-5">
                                                                        <Input type="LabelPopup" focused={this.handleOnFocus}
                                                                            changed={this.handleFormChange} width="320px"
                                                                            labelText="Population Label" autoFocus
                                                                            field="populationLabel" lineStyle="oneLine"
                                                                            value={this.state.dialogForm.populationLabel}
                                                                            dataSource="Labels"
                                                                            titleDialog="Population Labels" labelWidth="130px"
                                                                            inValid={this.state.pAllItemFields["populationLabel"]}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["populationLabel"] : ""}
                                                                            lostfocused={this.handleLostFocus}
                                                                        />
                                                                        <input type="hidden" id="populationLabelHidden" />
                                                                    </div>
                                                                    <div className="col-sm-5" style={{ display: "flex" }}>
                                                                        <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}//Hosp_Nbr (C)|Equals any of|Allowed_hosps
                                                                            labelText="Hospital Code" field="hospitalCode" lostfocused={this.handleLostFocus}
                                                                            value={this.state.dialogForm.hospitalCode} codeLength="2"//Hospital_Code<Code,Hospital_Name<Description
                                                                            dataSource="Hospital" titleDialog="Hospital Lookup" labelWidth="90px" width="40px"
                                                                            inValid={this.state.pAllItemFields["hospitalCode"]} lineStyle="oneLine" itemNbr=""
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                                                        />
                                                                        <Input type="label" changed={this.handleFormChange}
                                                                            labelText="" field="hospitalCodeName"
                                                                            value={this.state.dialogForm.hospitalCodeName}
                                                                            width="400px" labelWidth="10px" lineStyle="oneLine"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                            labelText="Letter" field="letterTitle" lineStyle="oneLine"//show/hide::if(Repeat_Date_Range <> "Use Enhanced Follow Up",1=1,1=2)
                                                                            value={this.state.dialogForm.letterTitle} labelWidth="110px" width="135px"
                                                                            inValid={this.state.pAllItemFields["letterTitle"]}
                                                                            lostfocused={this.handleLostFocus}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["letterTitle"] : ""}
                                                                            dataSource="Letters_Fu" titleDialog=""//Filter::Letter_Type  = 'Pat'  OR Letter_Type  = 'Con'  OR Letter_Type  = 'QA'  OR Letter_Type  = 'Doc'
                                                                            shownType="dropdown" keyId="letterTitle0" rowIndex="0" codeLabel="Letter"
                                                                        />
                                                                    </div>
                                                                    <div className="col-sm-2">
                                                                        <Input type="input" changed={this.handleFormChange}
                                                                            focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                            labelText="Fu Doctor Nbr" field="fuDocNum" lineStyle="oneLine"
                                                                            labelWidth="90px" width="40px" //show/hide::Repeat_Date_Range <> 'Use Enhanced Follow Up'
                                                                            value={this.state.dialogForm.fuDocNum}
                                                                            maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fuDocNum"] : ""}
                                                                            inValid={this.state.pAllItemFields["fuDocNum"]} />
                                                                    </div>
                                                                    <div className="col-sm-5 checkboxTypeCls">
                                                                        <Input type="checkbox" lineStyle="oneLine"
                                                                            labelText="Update Letters Sent Flag" field="updateFlag"
                                                                            labelWidth="200px" labelStyle="lblOnCheckbox" width="40px"
                                                                            value={this.state.isCheckedUpdateFlag ? "True" : "False"}
                                                                            checked={this.state.isCheckedUpdateFlag}
                                                                            clicked={this.toggleChecked}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-8">
                                                                        <div style={{ display: "flex" }}>
                                                                            <div className="frameInner" id="FOLLOWUPNA_GENDEMANDL_RULW">
                                                                                <div className="header">&nbsp;</div>
                                                                                <div>
                                                                                    <table>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                                <th style={{ width: "35px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                                                                <th style={{ width: "100px" }}>Med Rec Nbr</th>
                                                                                                <th style={{ width: "150px" }}>Last Name</th>
                                                                                                <th style={{ width: "150px" }}>First Name</th>
                                                                                                <th style={{ width: "50px" }}>Site</th>
                                                                                                <th style={{ width: "50px" }}>Seq</th>
                                                                                                <th style={{ width: "50px" }}>Hosp</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody></tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div id="patientLookupListListArea" className="patientLookupListListArea">
                                                                                    <div className="patientLookupListChildrenArea innerBody" style={{ height: "115px", overflow: "auto" }}>
                                                                                        <table>
                                                                                            <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                                <tr>
                                                                                                    <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                                    <th style={{ width: "35px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                                                                    <th style={{ width: "100px" }}>Med Rec Nbr</th>
                                                                                                    <th style={{ width: "150px" }}>Last Name</th>
                                                                                                    <th style={{ width: "150px" }}>First Name</th>
                                                                                                    <th style={{ width: "50px" }}>Site</th>
                                                                                                    <th style={{ width: "50px" }}>Seq</th>
                                                                                                    <th style={{ width: "50px" }}>Hosp</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.state.patientLookupList && this.state.patientLookupList.length > 0
                                                                                                    ? this.state.patientLookupList.map((mv, index) => (
                                                                                                        <tr key={"data-key-" + index} data-cid={mv.cid}
                                                                                                            onClick={(e) => { this.setSelectionPatientLookupRow(e, index) }}
                                                                                                            className={this.state.selectedPatientLookupRowIndex === index ? "selectedRowCls" : ""}>
                                                                                                            <td style={{ width: "18px" }}>
                                                                                                                {index + 1}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <Input type="PatientFu" focused={(e) => { this.handleOnFocus(e, index) }}
                                                                                                                    changed={(e) => { this.handlePatientLookupGridChange(e, index) }}
                                                                                                                    labelText="&nbsp;" field="lookup" value={mv.lookup} lineStyle="oneLine"
                                                                                                                    dataSource="Patient" titleDialog="Patient Lookup"
                                                                                                                    width="15px" labelWidth="0px"
                                                                                                                    lostfocused={(e) => { this.handlePatientLookupLostfocus(e, index) }}
                                                                                                                    keyId={"lookup" + index} focusingField={this.state.focusingField}
                                                                                                                    rowIndex={index} noneColon itemIndex={index} //isFollowUp="1"
                                                                                                                    parentState={this.state.selectedPatientOnState}
                                                                                                                    resetParentState={(e) => { this.handlePatientLookupLostfocus(e, index) }}
                                                                                                                />
                                                                                                            </td>
                                                                                                            <td>{mv ? mv.medRecNbr : ""}</td>
                                                                                                            <td>{mv ? mv.lastName : ""}</td>
                                                                                                            <td>{mv ? mv.firstName : ""}</td>
                                                                                                            <td>{mv ? mv.siteCode : ""}</td>
                                                                                                            <td>{mv ? mv.seqPrim : ""}</td>
                                                                                                            <td>{mv ? mv.hosp : ""}</td>
                                                                                                        </tr>
                                                                                                    )) : <tr><td colSpan="8">No records available</td></tr>
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                    <div className="contactBtnArea right">
                                                                                        <button onClick={this.addNewPatientLookup}>Add</button>
                                                                                        <button id="btndeleteSelectedPatientLookup" onClick={this.deleteSelectedPatientLookup}>Delete</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="emptyLine"></div>

                                                                <div className="row">
                                                                    <div className="col-sm-6">
                                                                        <div style={{ display: "flex" }}>
                                                                            <div className="frameInner" style={{ width: "790px" }} id="FOLLOWUPNA_GENDEMANDL_RULW_DTL">
                                                                                <div className="header">&nbsp;</div>
                                                                                <div>
                                                                                    <table>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                                <th style={{ width: "90px" }}>Physicians</th>
                                                                                                <th style={{ width: "105px" }}>Last Name</th>
                                                                                                <th style={{ width: "120px" }}>First Name</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody></tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div id="physiciansMvListArea" className="physiciansMvListArea">
                                                                                    <div className="physiciansMvChildrenArea innerBody" style={{ height: "115px", overflow: "auto" }}>
                                                                                        <table>
                                                                                            <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                                <tr>
                                                                                                    <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                                    <th style={{ width: "100px" }}>Physicians</th>
                                                                                                    <th style={{ width: "120px" }}>Last Name</th>
                                                                                                    <th style={{ width: "120px" }}>First Name</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.state.physiciansMv && this.state.physiciansMv.length > 0
                                                                                                    ? this.state.physiciansMv.map((mv, index) => (
                                                                                                        <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionPhysiciansMvRow(e, index, mv.physiciansId) }} className={this.state.selectedPhysiciansMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                                            <td style={{ width: "18px" }}>
                                                                                                                {index + 1}
                                                                                                            </td>
                                                                                                            <td>{mv.physiciansId}</td>
                                                                                                            <td>{mv.lastName}</td>
                                                                                                            <td>{mv.firstName}</td>
                                                                                                        </tr>
                                                                                                    )) : <tr><td colSpan="4">Loading...</td></tr>
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                                <div className="emptyLine"></div>

                                                <div className="emptyLine"></div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                            labelText="Start Time" field="startTime" width="auto"
                                                            value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                                        />
                                                    </div>
                                                    <div className="col-sm-9">
                                                        <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                            labelText="End Time" field="endTime" width="auto"
                                                            value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                                        />
                                                    </div>
                                                </div>
                                                <fieldset className="bottomAreaButtonCls">
                                                    <legend></legend>
                                                    <div className="row">
                                                        <div className="col-sm-12" style={{ display: "flex" }}>
                                                            <div className="_A5CWLayout">
                                                                <button id="BUTTON_RUN_REPORT" className="ErsGrBlueButton" onClick={(e) => { this.showEvent(e) }}>
                                                                    <img id="BUTTON_RUN_REPORT_ICON" src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>Run
                                                                </button>
                                                            </div>

                                                            <div className="_A5CWLayout">
                                                                <button id="BUTTON_NEWRECORD"
                                                                    className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                                    disabled={this.state.isFormDirty ? false : true}
                                                                    onClick={(e) => { this.newRecord(e) }}>
                                                                    <img id="BUTTON_NEWRECORD_ICON"
                                                                        src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                                        style={{ verticalAlign: "middle", border: "none" }}></img>New
                                                                </button>
                                                            </div>

                                                            <div className="hidden">
                                                                <button id="BUTTON_NEWRECORD_FK" onClick={(e) => { this.newRecord(e) }} ></button>
                                                            </div>

                                                            <div className="_A5CWLayout">
                                                                <button id="BUTTON_EXIT"
                                                                    className="ErsGrBlueButton"
                                                                    onClick={(e) => { this.exitScreen(e) }}>
                                                                    <img id="BUTTON_EXIT_ICON"
                                                                        src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image"
                                                                        style={{ verticalAlign: "middle", border: "none" }}></img>Exit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            : <p>Generate Demand Letters data loading...</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Draggable>;
        } else {
            dialogFrm = <div className="limitedInGridCls">
                <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                    {loading}
                    <div className="parentWindow">
                        <div className="patientAbstractGridView" id="followUpLettersMainPage" style={{ height: "580px" }}>
                            {
                                this.state.dialogForm ?
                                    <div className="container-fluid2">
                                        <form onSubmit={this.javascript_void_fnc} id="followUpLettersForm" className="followUpLettersForm">
                                            <div id="inputAreaCls">
                                                <div style={{ width: "100%" }}>
                                                    <div className="_frameInner">
                                                        <div className="emptyLine"></div>
                                                        <div className="row">
                                                            <div className="col-sm-5">
                                                                <Input type="LabelPopup" focused={this.handleOnFocus}
                                                                    changed={this.handleFormChange} width="320px"
                                                                    labelText="Population Label" autoFocus
                                                                    field="populationLabel" lineStyle="oneLine"
                                                                    value={this.state.dialogForm.populationLabel}
                                                                    dataSource="Labels"
                                                                    titleDialog="Population Labels" labelWidth="110px"
                                                                    inValid={this.state.pAllItemFields["populationLabel"]}
                                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["populationLabel"] : ""}
                                                                    lostfocused={this.handleLostFocus}
                                                                />
                                                                <input type="hidden" id="populationLabelHidden" />
                                                            </div>
                                                            <div className="col-sm-5" style={{ display: "flex" }}>
                                                                <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}//Hosp_Nbr (C)|Equals any of|Allowed_hosps
                                                                    labelText="Hospital Code" field="hospitalCode" lostfocused={this.handleLostFocus}
                                                                    value={this.state.dialogForm.hospitalCode} codeLength="2"//Hospital_Code<Code,Hospital_Name<Description
                                                                    dataSource="Hospital" titleDialog="Hospital Lookup" labelWidth="90px" width="40px"
                                                                    inValid={this.state.pAllItemFields["hospitalCode"]} lineStyle="oneLine" itemNbr=""
                                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                                                />
                                                                <Input type="label" changed={this.handleFormChange}
                                                                    labelText="" field="hospitalCodeName"
                                                                    value={this.state.dialogForm.hospitalCodeName}
                                                                    width="400px" labelWidth="10px" lineStyle="oneLine"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-3">
                                                                <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                    labelText="Letter" field="letterTitle" lineStyle="oneLine"//show/hide::if(Repeat_Date_Range <> "Use Enhanced Follow Up",1=1,1=2)
                                                                    value={this.state.dialogForm.letterTitle} labelWidth="110px" width="135px"
                                                                    inValid={this.state.pAllItemFields["letterTitle"]}
                                                                    lostfocused={this.handleLostFocus}
                                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["letterTitle"] : ""}
                                                                    dataSource="Letters_Fu" titleDialog=""//Filter::Letter_Type  = 'Pat'  OR Letter_Type  = 'Con'  OR Letter_Type  = 'QA'  OR Letter_Type  = 'Doc'
                                                                    shownType="dropdown" keyId="letterTitle0" rowIndex="0" codeLabel="Letter"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <Input type="input" changed={this.handleFormChange}
                                                                    focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                    labelText="Fu Doctor Nbr" field="fuDocNum" lineStyle="oneLine"
                                                                    labelWidth="90px" width="40px" //show/hide::Repeat_Date_Range <> 'Use Enhanced Follow Up'
                                                                    value={this.state.dialogForm.fuDocNum}
                                                                    maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fuDocNum"] : ""}
                                                                    inValid={this.state.pAllItemFields["fuDocNum"]} />
                                                            </div>
                                                            <div className="col-sm-5 checkboxTypeCls">
                                                                <Input type="checkbox" lineStyle="oneLine"
                                                                    labelText="Update Letters Sent Flag" field="updateFlag"
                                                                    labelWidth="200px" labelStyle="lblOnCheckbox" width="40px"
                                                                    value={this.state.isCheckedUpdateFlag ? "True" : "False"}
                                                                    checked={this.state.isCheckedUpdateFlag}
                                                                    clicked={this.toggleChecked}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-8">
                                                                <div style={{ display: "flex" }}>
                                                                    <div className="frameInner" id="FOLLOWUPNA_GENDEMANDL_RULW">
                                                                        <div className="header">&nbsp;</div>
                                                                        <div>
                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                        <th style={{ width: "35px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                                                        <th style={{ width: "100px" }}>Med Rec Nbr</th>
                                                                                        <th style={{ width: "150px" }}>Last Name</th>
                                                                                        <th style={{ width: "150px" }}>First Name</th>
                                                                                        <th style={{ width: "50px" }}>Site</th>
                                                                                        <th style={{ width: "50px" }}>Seq</th>
                                                                                        <th style={{ width: "50px" }}>Hosp</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody></tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div id="patientLookupListListArea" className="patientLookupListListArea">
                                                                            <div className="patientLookupListChildrenArea innerBody" style={{ height: "115px", overflow: "auto" }}>
                                                                                <table>
                                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                        <tr>
                                                                                            <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                            <th style={{ width: "35px" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                                                            <th style={{ width: "100px" }}>Med Rec Nbr</th>
                                                                                            <th style={{ width: "150px" }}>Last Name</th>
                                                                                            <th style={{ width: "150px" }}>First Name</th>
                                                                                            <th style={{ width: "50px" }}>Site</th>
                                                                                            <th style={{ width: "50px" }}>Seq</th>
                                                                                            <th style={{ width: "50px" }}>Hosp</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {this.state.patientLookupList && this.state.patientLookupList.length > 0
                                                                                            ? this.state.patientLookupList.map((mv, index) => (
                                                                                                <tr key={"data-key-" + index} data-cid={mv.cid}
                                                                                                    onClick={(e) => { this.setSelectionPatientLookupRow(e, index) }}
                                                                                                    className={this.state.selectedPatientLookupRowIndex === index ? "selectedRowCls" : ""}>
                                                                                                    <td style={{ width: "18px" }}>
                                                                                                        {index + 1}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <Input type="PatientFu" focused={(e) => { this.handleOnFocus(e, index) }}
                                                                                                            changed={(e) => { this.handlePatientLookupGridChange(e, index) }}
                                                                                                            labelText="&nbsp;" field="lookup" value={mv.lookup} lineStyle="oneLine"
                                                                                                            dataSource="Patient" titleDialog="Patient Lookup"
                                                                                                            width="15px" labelWidth="0px"
                                                                                                            lostfocused={(e) => { this.handlePatientLookupLostfocus(e, index) }}
                                                                                                            keyId={"lookup" + index} focusingField={this.state.focusingField}
                                                                                                            rowIndex={index} noneColon itemIndex={index} //isFollowUp="1"
                                                                                                            parentState={this.state.selectedPatientOnState}
                                                                                                            resetParentState={(e) => { this.handlePatientLookupLostfocus(e, index) }}
                                                                                                        />
                                                                                                    </td>
                                                                                                    <td>{mv ? mv.medRecNbr : ""}</td>
                                                                                                    <td>{mv ? mv.lastName : ""}</td>
                                                                                                    <td>{mv ? mv.firstName : ""}</td>
                                                                                                    <td>{mv ? mv.siteCode : ""}</td>
                                                                                                    <td>{mv ? mv.seqPrim : ""}</td>
                                                                                                    <td>{mv ? mv.hosp : ""}</td>
                                                                                                </tr>
                                                                                            )) : <tr><td colSpan="8">No records available</td></tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div className="contactBtnArea right">
                                                                                <button onClick={this.addNewPatientLookup}>Add</button>
                                                                                <button id="btndeleteSelectedPatientLookup" onClick={this.deleteSelectedPatientLookup}>Delete</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="emptyLine"></div>

                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <div style={{ display: "flex" }}>
                                                                    <div className="frameInner" style={{ width: "790px" }} id="FOLLOWUPNA_GENDEMANDL_RULW_DTL">
                                                                        <div className="header">&nbsp;</div>
                                                                        <div>
                                                                            <table>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                        <th style={{ width: "90px" }}>Physicians</th>
                                                                                        <th style={{ width: "105px" }}>Last Name</th>
                                                                                        <th style={{ width: "120px" }}>First Name</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody></tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div id="physiciansMvListArea" className="physiciansMvListArea">
                                                                            <div className="physiciansMvChildrenArea innerBody" style={{ height: "115px", overflow: "auto" }}>
                                                                                <table>
                                                                                    <thead style={{ visibility: "hidden", lineHeight: "0px" }}>
                                                                                        <tr>
                                                                                            <th style={{ width: "18px" }}>&nbsp;</th>
                                                                                            <th style={{ width: "100px" }}>Physicians</th>
                                                                                            <th style={{ width: "120px" }}>Last Name</th>
                                                                                            <th style={{ width: "120px" }}>First Name</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {this.state.physiciansMv && this.state.physiciansMv.length > 0
                                                                                            ? this.state.physiciansMv.map((mv, index) => (
                                                                                                <tr key={"data-key-" + index} onClick={(e) => { this.setSelectionPhysiciansMvRow(e, index, mv.physiciansId) }} className={this.state.selectedPhysiciansMvRowIndex === index ? "selectedRowCls" : ""}>
                                                                                                    <td style={{ width: "18px" }}>
                                                                                                        {index + 1}
                                                                                                    </td>
                                                                                                    <td>{mv.physiciansId}</td>
                                                                                                    <td>{mv.lastName}</td>
                                                                                                    <td>{mv.firstName}</td>
                                                                                                </tr>
                                                                                            )) : <tr><td colSpan="4">Loading...</td></tr>
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <div className="emptyLine"></div>

                                        <div className="emptyLine"></div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                    labelText="Start Time" field="startTime" width="auto"
                                                    value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                                />
                                            </div>
                                            <div className="col-sm-9">
                                                <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                    labelText="End Time" field="endTime" width="auto"
                                                    value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                                />
                                            </div>
                                        </div>
                                        <fieldset className="bottomAreaButtonCls">
                                            <legend></legend>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="A5CWLayout">
                                                        <button id="BUTTON_RUN_REPORT" className="ErsGrBlueButton" onClick={(e) => { this.showEvent(e) }}>
                                                            <img id="BUTTON_RUN_REPORT_ICON" src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image" style={{ verticalAlign: "middle", border: "none" }}></img>Run
                                                        </button>
                                                    </div>

                                                    <div className="A5CWLayout">
                                                        <button id="BUTTON_NEWRECORD"
                                                            className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                            disabled={this.state.isFormDirty ? false : true}
                                                            onClick={(e) => { this.newRecord(e) }}>
                                                            <img id="BUTTON_NEWRECORD_ICON"
                                                                src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                                style={{ verticalAlign: "middle", border: "none" }}></img>New
                                                        </button>
                                                    </div>

                                                    <div className="hidden">
                                                        <button id="BUTTON_NEWRECORD_FK" onClick={(e) => { this.newRecord(e) }} ></button>
                                                    </div>

                                                    <div className="A5CWLayout">
                                                        <button id="BUTTON_EXIT"
                                                            className="ErsGrBlueButton"
                                                            onClick={(e) => { this.exitScreen(e) }}>
                                                            <img id="BUTTON_EXIT_ICON"
                                                                src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image"
                                                                style={{ verticalAlign: "middle", border: "none" }}></img>Exit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                    : <p>Generate Demand Letters data loading...</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        };

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>

                <React.Fragment>
                    {
                        this.state.isDrawingHtmlTable && this.props.previewing
                        && <PrintAndDownloadFU pTitle="Demand Letters"
                            reportDataInfo={this.props.followUpLettersInfo}
                            closeWindow={this.hideDrawingHtmlTable}
                            updateFlag={this.state.isCheckedUpdateFlag}
                        />
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}

GenDemandLetters.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        followUpLettersInfo: state.patientAbstract.followUpLettersInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        selectedHospital: state.patientAbstract.selectedHospital,
        user_id: state.auth.userId,
        userId: state.auth.userId,
        showReport: state.patientAbstract.showReport,
        isDownloadFile: state.patientAbstract.isDownloadFile,
        selectedPatientOnPopup: state.patientAbstract.selectedPatientOnGrid,
        selectedPatientIndex: state.patientAbstract.selectedPatientIndex,
        alphaUrl: window.location ? window.location.pathname : "",
        previewing: state.patientAbstract.previewing,
        selectedPrimarySummary: state.patientAbstract.selectedPrimarySummary,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        runFollowUpLetters: (dialogForm, flag) => dispatch(actionCreators.runFollowUpLetters(dialogForm, flag)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        loadFollowUpLetters: () => dispatch(actionCreators.loadFollowUpLetters()),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        selectedPatientOnGrid: (patient, index) => dispatch(actionCreators.selectedPatientOnGrid(patient, index)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenDemandLetters);