/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../../store/PatientAbstract";
import Input from "../../UI/Input";
import axios from "../../../axios-instance";
import Draggable from 'react-draggable';
import * as dialogTypes from "../../AbstractManagement/maintDialogs";
import "./CPMReport.css";

class CPMReport extends Component {
    clockInterval = "";
    downloadFile = false;
    state = {
        beginDate: "",
        endDate: "",
        hospNbr: "",
        hospital_Code: "",     //This line does not work as the default
        hospital_Code_Name: "",  //This line works as the default //"ERS Test 2 Hosp"
        includePatientList: false,
        includeSuspense: false,
        outputType: "Screen",

        allStandards: true,

        standard_4_4: true,
        standard_5_1: true,
        standard_5_2: true,
        standard_5_3: true,
        standard_5_4: true,
        standard_5_5: true,
        standard_5_6: true,
        standard_5_7: true,
        standard_5_8: true,
        
        htmlOutput: null,
        startTime: null,
        endTime: null,
        statusBar: "Start Time: ",

        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        loaded: true,
        titleDialog: null,
        showClockTimer: false,
    }


    CPM_Report_Date_Format(myDate) {
        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        myDate = myDate.replace(/[-]/gi, '/');

        if ((myDate.length == 6 || myDate.length == 7 || myDate.length == 8 || myDate.length == 9) && myDate.split('/').length == 3) {
            var mm = myDate.split('/')[0];
            var dd = myDate.split('/')[1];
            var yy = myDate.split('/')[2];

            myDate = ('00' + mm).substr(mm.length, 2) + '/' + ('00' + dd).substr(dd.length, 2) + '/' + yy;
        }

        myDate = myDate.replace(/[^0-9-/]/gi, '');

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;

    }


    setStateBeginDate = (event) => {
        var value = event.target.value;
        const oldValue = this.state.beginDate;

        this.setState({
            beginDate: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateEndDate = (event) => {
        const value = event.target.value;
        const oldValue = this.state.endDate;

        this.setState({
            endDate: value,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }

    setStateOutputType = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const oldValue = this.state.outputType;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;

        this.setState({
            outputType: value,
            dialogForm: dialogFormData,
            isFormDirty: oldValue != value ? true : this.state.isFormDirty,
        });
    }


    componentDidMount() {
        // Add all navigation tabs in maintDialogs.js
        dialogTypes.accreditationReportsTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
            this.props.setControlName(dialog.id);
        });

        var _this = this;
        _this.props.generateCPMReport();

        if (!this.state.dialogForm) {
            this.setFormData(this.props.cpmReportInfo);
        }
        this.setState({ isLoading: true });
    }


    componentWillMount() {

    }
 
    componentDidUpdate(prevProps, prevState) {
        if (!this.state.dialogForm) {
            this.setFormData(this.props.cpmReportInfo);
        }

        var cpmReportInfo = this.props.cpmReportInfo;
        
        if (this.state.dialogForm && (prevProps.cpmReportInfo && this.props.cpmReportInfo && (prevProps.cpmReportInfo.startTime !== this.props.cpmReportInfo.startTime || prevProps.cpmReportInfo.endTime !== this.props.cpmReportInfo.endTime))) {
            //console.log("$$$ CPM Report Output: " + cpmReportInfo.output_Type);
            clearInterval(this.clockInterval); //If the report has finished, clear the clock interval
            this.setState({
                showClockTimer: false, //If the report has finished, hide the clock
                endTime: new Date().toLocaleTimeString(),
            });
            
            if (cpmReportInfo.output_Type == "Screen" && cpmReportInfo.htmlOutput) //If the report has finished, it was sent to the screen, and we have html data returned, display the report to the screen
                this.displayReport(cpmReportInfo);
            else if (cpmReportInfo.output_Type == "Excel" && cpmReportInfo.urlOutputFile && !this.downloadFile) { //If the report has finished, it was sent to Excel, and we have a url/path to the Excel file returned by the report, download the file
                var fileURL = this.props.clientgroup + "/DownloadFile";
                this.downloadxlsx(fileURL, cpmReportInfo.urlOutputFile); //Download the Excel file produced by the report
                this.downloadFile = true;
            }
        }
    }

    downloadxlsx(fileURL, urlOutputFile) {
        axios.post(fileURL, {
            method: 'POST',
            responseType: 'blob',//important
            urlOutputFile: urlOutputFile,
            accessToken: localStorage.getItem("token")
        }).then((response) => {
            var fileName = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            const link = document.createElement('a');

            let excel;
            if (urlOutputFile.indexOf('/') != -1) {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('/') + 1);
            } else {
                excel = urlOutputFile.substring(urlOutputFile.lastIndexOf('\\') + 1);
            }
            link.href = this.props.clientgroup + "/" + excel;

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return false;
        })
        .catch(function (error) {
            console.log(error);
        });

        return false;
    }

    setFormData = (cpmReportInfo) => {
        this.setState({
            initialDialogForm: { ...cpmReportInfo },
            dialogForm: { ...cpmReportInfo },

            //["outputType"]: requestLogInfo && requestLogInfo.sendReportTo ? requestLogInfo.sendReportTo : "Screen",
            reload: false,
            changed: true,
            showClockTimer: false,
            titleDialog: null,
            isDrawingCharts: false,
        });

        return false;
    }

    handleOnFocus = (event, index, alias) => {

    }

    handleFormChange = (event) => {
        if (event.target2) {
            this.setState({
                dialogForm: {
                    ...this.state.dialogForm,
                    [event.target.name]: event.target.value,
                    [event.target2.name]: event.target2.value
                },
                isFormDirty: true,
            });
        } else {
            this.setState({
                //dialogForm: {
                //    ...this.state.dialogForm,
                //    [event.target.name]: event.target.value
                //},
                [event.target.name]: event.target.value, //11/07/2022 - Switching to this per REA to resolve the issue of typing in the hospital code directly
                isFormDirty: true,
            });
        }
    }

    handleLostFocus = (event) => {
        const name = event.target.name;

        if (event.target.name == "beginDate") {
            this.setState({
                beginDate: this.CPM_Report_Date_Format(event.target.value),
            });
        }
        if (event.target.name == "endDate") {
            this.setState({
                endDate: this.CPM_Report_Date_Format(event.target.value),
            });
        }

        if (event.target.name == "hospital_Code") {
            var dialogFormData = (this.state.dialogForm ? this.state.dialogForm : "Cannot Load this.state.dialogForm")

            dialogFormData.hospital_Code = event.target.value;
            this.setState({
                hospNbr: event.target.value,
                hospital_Code: event.target.value,
                isFormDirty: this.state.hospNbr != event.target.value ? true : this.state.isFormDirty,
                dialogForm: dialogFormData,
            });

            //this.props.checkValidInput(name, value, table, key, -1, flag);

        }
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;

        if (name == "patientList") {
            const checked = this.state.includePatientList;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                includePatientList: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "includeSuspense") {
            const checked = this.state.includeSuspense;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                includeSuspense: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "allStandards") {
            const checked = this.state.allStandards;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                allStandards: !checked,
                standard_4_4: !checked,
                standard_5_1: !checked,
                standard_5_2: !checked,
                standard_5_3: !checked,
                standard_5_4: !checked,
                standard_5_5: !checked,
                standard_5_6: !checked,
                standard_5_7: !checked,
                standard_5_8: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_4_4") {
            const checked = this.state.standard_4_4;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_4_4: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_5_1") {
            const checked = this.state.standard_5_1;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_1: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_5_2") {
            const checked = this.state.standard_5_2;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_2: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_5_3") {
            const checked = this.state.standard_5_3;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_3: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_5_4") {
            const checked = this.state.standard_5_4;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_4: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_5_5") {
            const checked = this.state.standard_5_5;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_5: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_5_6") {
            const checked = this.state.standard_5_6;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_6: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });

        } else if (name == "standard_5_7") {
            const checked = this.state.standard_5_7;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_7: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });


        } else if (name == "standard_5_8") {
            const checked = this.state.standard_5_8;
            dialogFormData[name] = !checked ? "true" : "false";
            this.setState({
                standard_5_8: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }


    }


    Run_CPM_Report = () => {
        //Collate all values needed by the report into one object, dialogFormData, and pass it to the report.
        var dialogFormData = this.state.dialogForm;


        dialogFormData.Begin_Date = this.state.beginDate;
        dialogFormData.End_Date = this.state.endDate;
        dialogFormData.Hospital_Code = this.state.hospNbr;
        dialogFormData.Include_Patient_List = this.state.includePatientList;
        dialogFormData.Include_Suspense = this.state.includeSuspense;

        dialogFormData.Standard_4_4 = this.state.standard_4_4;
        dialogFormData.Standard_5_1 = this.state.standard_5_1;
        dialogFormData.Standard_5_2 = this.state.standard_5_2;
        dialogFormData.Standard_5_3 = this.state.standard_5_3;
        dialogFormData.Standard_5_4 = this.state.standard_5_4;
        dialogFormData.Standard_5_5 = this.state.standard_5_5;
        dialogFormData.Standard_5_6 = this.state.standard_5_6;
        dialogFormData.Standard_5_7 = this.state.standard_5_7;
        dialogFormData.Standard_5_8 = this.state.standard_5_8;
        dialogFormData.Output_Type = this.state.outputType;

        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        

        //alert("Running CPM Report with 'This dialog' values of: " + JSON.stringify(this.state.dialogForm, null, 4))
        //console.log("Running CPM Report with 'This' values of: " + JSON.stringify(this.state.dialogForm, null, 4));

        this.props.runCPMReport(dialogFormData);
        
        this.downloadFile = false;//reset this flag to get download new file

        return false;
    }

    //on before show event
    showClockEvent(e) {
        try {
            e.preventDefault();// This function prevents the form from being auto-submitted
        } catch (ex) {}

        var popLabelComponent = document.getElementById("btnCallToParentFunc");
        if (popLabelComponent) {
            popLabelComponent.click();
        }


        var _this = this;
        _this.setState({ showClockTimer: true, startTime: new Date().toLocaleTimeString()}); // set clock timer...

        this.Run_CPM_Report();

        this.clockInterval = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(this.clockInterval);
            } else {
                console.log("Timer Interval Running");
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
                _this.setState({ currentTime: new Date() ? new Date().toLocaleTimeString() : " " });
            }
        }, 1000);

        return false; // Returns false to stop everything after this event has been completed

    }

    Cancel_Report = (e, _this) => {
        _this.setState({ showClockTimer: false });
        clearInterval(this.clockInterval);
        return false;
    }


    displayReport = (data) => {
        this.setState({
            showClockTimer: false,//hide clock timer
            endTime: new Date().toLocaleTimeString(),
            htmlOutput: data.htmlOutput, //Commenting out because I think this already gets set in the data tunnel, but will have to test
            isDrawingCharts: true
        });

        var reportDisplay = document.getElementById("displayReportIframe");
        if (reportDisplay) { //This function runs twice and in one instance this component doesn't exist, so this check needs to be there to prevent the entire screen from erroring out
            var reportContent = reportDisplay.contentDocument;
            reportContent.write(data.htmlOutput);
        }

        return false;
    }


    hideDrawingCharts = (e) => {
        this.setState({ isDrawingCharts: false });
        return false;
    }


    newRecord(e) {
        try {
            e.preventDefault();  //Added to prevent the entire window from refreshing when reload is set to "true" below

            this.setState({
                beginDate: "",
                endDate: "",
                hospNbr: "",
                hospital_Code: "",     //This line does not work as the default
                hospital_Code_Name: "",  //This line works as the default //"ERS Test 2 Hosp"
                includePatientList: false,
                includeSuspense: false,
                outputType: "Screen",

                allStandards: true,

                standard_4_4: true,
                standard_5_1: true,
                standard_5_2: true,
                standard_5_3: true,
                standard_5_4: true,
                standard_5_5: true,
                standard_5_6: true,
                standard_5_7: true,
                standard_5_8: true,

                htmlOutput: null,
                startTime: null,
                endTime: null,
                statusBar: "",

                isFormDirty: false,
            })
        } catch (ex) {}
    }


    printReport = (e) => {
        var doc = document.getElementById("displayReportIframe").contentDocument;

        var printStyle = doc.getElementById('PrintStyle');
        var printContent = doc.getElementById('printableContent');
        var windowUrl = 'about:blank';
        var uniqueName = new Date();
        var windowName = 'Print' + uniqueName.getTime();
        var printWindow = window.open(windowUrl, windowName);

        var html = '<!DOCTYPE html><html><head><title>Cancer Program Management Report</title><style type="text/css">';
        html += printStyle.innerHTML + '</style></head><body><div id="printableContent">';
        html += printContent.innerHTML + '</div></body></html>';

        printWindow.document.write(html);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }

    render() {
        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window" style={{ cursor: "progress" }}>
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Selecting Cases - Please wait <div class="waitingWaves"><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div><div class="wave"></div></div></div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTime}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="validationErr_btnOk_Cls" autoFocus>Cancel</button>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                </div >
                : null;
        }

        let drawingCharts;
        {
            drawingCharts = this.state.isDrawingCharts ?
                <div className="CRStar_Window" id="CONTAINER_DISPLAY">
                    <div className="fake_popup_cls hidden"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="CPMReportDisplay" className="dragableLargestWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">CPM Report </div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideDrawingCharts(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="container" id="CPMReportShowingCharts">
                                <iframe id="displayReportIframe" />
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12" style={{ display: "flex" }}>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_PRINT" className="ErsGrBlueButton" title=""
                                            onClick={(e) => { this.printReport(e) }}>
                                            <img id="TBI_REPORTADHO_ICON_PRINT" src="https://crstar.ers-can.com/images/$$device.printer.png.a5image" className="" />
                                            Print
                                        </button>
                                        <button type="button" id="TBI_REPORTADHO_BUTTON_EXIT" className="ErsGrBlueButton" title=""
                                            style={{ marginLeft: "15px" }}
                                            onClick={(e) => { this.hideDrawingCharts(e) }} value="exit" autoFocus>
                                            <img id="TBI_REPORTADHO_ICON_EXIT" src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image" className="" />
                                            Exit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let dialogForm = <div className="reportDialogForm">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                <div className="reportDialogFormContent">

                    <div className="reportScreenTitle">CPM Report</div>
                    {this.state.dialogForm || this.state.loaded ?

                        <form onSubmit={false} id="CPMReportForm" className="">
                        <div className="frameInner">

                            <div className="" style={{ display: "flex" }}>
                                <Input type="codeMultiSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                    labelText="Hospital" field="hospital_Code" lostfocused={this.handleLostFocus} name="hospital_Code"
                                    value={this.state.hospital_Code}
                                    dataSource="Hospital2L" titleDialog="Hospital Lookup" codeLength="2"
                                    labelWidth="90px" width="240px"
                                    lineStyle="oneLine" itemNbr=""
                                    flag={this.state.availableHosps ? this.state.availableHosps : ""}
                                    hospNbr={this.state.hospital_Code ? this.state.hospital_Code : this.state.availableHosps}
                                    shownType="dropdown" isMultiSelection={false}
                                />
                                <label type="label" id="hospital_Code_Name" name="hospital_Code_Name" >{this.state.hospital_Code_Name}</label>
                            </div>

                                <div className="upperLeftBox">
                                <div id="beginDate">
                                    <label title="Select cases with a Dx Date on or after this date">Begin Date:</label>
                                    <input type="text" id="beginDate" name="beginDate"
                                        placeholder="mm/dd/yyyy"
                                        value={this.state.beginDate}
                                        onChange={(e) => this.setState({ beginDate: e.target.value })}
                                        onBlur={this.handleLostFocus}
                                        labelText="Begin Date: "
                                        field="beginDate"
                                    />
                                </div>

                                <div id="endDate">
                                    <label title="Select cases with a Dx Date on or prior to this date">End Date:</label>
                                    <input type="text" id="endDate" name="endDate"
                                        placeholder="mm/dd/yyyy"
                                        value={this.state.endDate}
                                        onChange={(e) => this.setState({ endDate: e.target.value })}
                                        onBlur={this.handleLostFocus}
                                    />
                                </div>


                                <br />

                                <input type="checkbox" id="includeSuspense" className={this.state.hospital_Code.length > 1 ? "hidden" : "checkbox"}
                                    name="includeSuspense"
                                    value={this.state.includeSuspense ? "true" : "false"}
                                    checked={this.state.includeSuspense}
                                    onChange={this.toggleChecked} />
                                <label for="includeSuspense" className={this.state.hospital_Code.length > 1 ? "hidden" : "checkboxLabel"}> Include Suspense Cases </label>
                                <br />

                                <input type="checkbox" id="patientList" className="checkbox"
                                    name="patientList"
                                    value={this.state.includePatientList ? "true" : "false"}
                                    checked={this.state.includePatientList}
                                    onChange={this.toggleChecked} />
                                <label for="patientList" className="checkboxLabel"> Include Patient List </label>
                                    <br />
                                    <br />


                            <div className="outputDeviceRadio">
                                <div id="outputDeviceLabel">Send Report To:</div>
                                <label>
                                    <input type="radio" name="outputType" field="outputType" value="Excel"
                                        onClick={this.setStateOutputType}
                                        checked={this.state.outputType === "Excel"} />
                                    Excel
                                </label>
                                <br />
                                <label>
                                    <input type="radio" name="outputType" field="outputType" value="Screen"
                                        onClick={this.setStateOutputType}
                                        checked={this.state.outputType === "Screen"} />
                                    Screen
                                </label>
                             </div>


                             </div>
                                <div className="upperRightBox">
                                    <div id="column2" className="column2">
                                        {/*<label for="" className="column2Header"> Chart Audit </label>*/}
                                    <div id="column2Content" className="column2Content"> 
                                    <input type="checkbox" id="allStandards" className="checkbox"
                                        name="allStandards"
                                        value={this.state.allStandards ? "true" : "false"}
                                        checked={this.state.allStandards}
                                        onChange={this.toggleChecked} />
                                    <label for="allStandards" className="checkboxLabel"> All </label>
                                    <br />
                                    <br />
                                    <input type="checkbox" id="standard_4_4" className="checkbox"
                                        name="standard_4_4"
                                        value={this.state.standard_4_4 ? "true" : "false"}
                                        checked={this.state.standard_4_4}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_4_4" className="checkboxLabel"> Standard 4.4 Genetic Counseling and Risk Assessment</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_1" className="checkbox"
                                        name="standard_5_1"
                                        value={this.state.standard_5_1 ? "true" : "false"}
                                        checked={this.state.standard_5_1}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_1" className="checkboxLabel"> Standard 5.1 CAP Synoptic Reporting</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_2" className="checkbox"
                                        name="standard_5_2"
                                        value={this.state.standard_5_2 ? "true" : "false"}
                                        checked={this.state.standard_5_2}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_2" className="checkboxLabel"> Standard 5.2 Psychosocial Distress Screening</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_3" className="checkbox"
                                        name="standard_5_3"
                                        value={this.state.standard_5_3 ? "true" : "false"}
                                        checked={this.state.standard_5_3}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_3" className="checkboxLabel"> Standard 5.3 Breast SLNB</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_4" className="checkbox"
                                        name="standard_5_4"
                                        value={this.state.standard_5_4 ? "true" : "false"}
                                        checked={this.state.standard_5_4}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_4" className="checkboxLabel"> Standard 5.4 Breast LN Procedure</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_5" className="checkbox"
                                        name="standard_5_5"
                                        value={this.state.standard_5_5 ? "true" : "false"}
                                        checked={this.state.standard_5_5}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_5" className="checkboxLabel"> Standard 5.5 Melanoma</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_6" className="checkbox"
                                        name="standard_5_6"
                                        value={this.state.standard_5_6 ? "true" : "false"}
                                        checked={this.state.standard_5_6}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_6" className="checkboxLabel"> Standard 5.6 Colon</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_7" className="checkbox"
                                        name="standard_5_7"
                                        value={this.state.standard_5_7 ? "true" : "false"}
                                        checked={this.state.standard_5_7}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_7" className="checkboxLabel"> Standard 5.7 Total Mesorectal Excision</label>
                                    <br />
                                    <input type="checkbox" id="standard_5_8" className="checkbox"
                                        name="standard_5_8"
                                        value={this.state.standard_5_8 ? "true" : "false"}
                                        checked={this.state.standard_5_8}
                                        onChange={this.toggleChecked} />
                                    <label for="standard_5_8" className="checkboxLabel"> Standard 5.8 Pulmonary Resection</label>
                                            <br />
                                        </div>
                                    </div>
                                </div>

                            <br />

                            <div id="CONTAINER_JS_CLOCK" className="hidden">
                                <label>Selecting Cases</label>
                                <div id="IMAGE_WAIT" style={{ color: "black", fontWeight: "bold" }}>
                                    Selecting Cases - Please Wait ...
                                </div>
                            </div>
                        </div>

                        <div className="reportScreenStatusBar">
                                Start Time: {this.state.startTime} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; End Time: {this.state.endTime}
                        </div>

                        <fieldset className="reportDialogFormButtonBar">
                            <button id="BUTTON_RUN"
                                className="ErsGrBlueButton"
                                onClick={(e) => { this.showClockEvent(e) }}
                            >
                                <img id="BUTTON_RUN_ICON" src="https://crstar.ers-can.com/images/$$code.action.run.png.a5image"></img>
                                Run
                            </button>

                            <button id="BUTTON_NEWRECORD"
                                className="ErsGrBlueButton"
                                onClick={this.newRecord.bind(this)}
                            >
                                <img id="BUTTON_NEWRECORD_ICON" src={"https://crstar.ers-can.com/images/$$navigate.new.png.a5image"}></img>
                                New
                            </button>
                        </fieldset>
                    </form>
                        : <p> CPM Report screen encountered an issue and cannot load ...</p>}
                </div>
            </div>
        </div>
 
        return (
            <React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>

                <React.Fragment>
                    {drawingCharts}
                </React.Fragment>

                <React.Fragment>
                    {dialogForm}
                </React.Fragment>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        cpmReportInfo: state.patientAbstract.cpmReportInfo,           //This holds information passed from the C# report back to the screen
        clientgroup: state.auth.clientgroup,                            //Passed to C# report to get path
        userId: state.auth.userId,                                      //Passed to C# report to get path

        initialPageVisible: state.patientAbstract.initialPageVisible,   //Controls if security restrictions are set to prevent page from loading
        isDialogLoading: state.patientAbstract.isDialogLoading,
        waitingFor: state.patientAbstract.waitingFor,

        //Trying to get hospital control to work//
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        isFormDirty: state.patientAbstract.isFormDirty,
        isAddNewUser: state.patientAbstract.isAddNewUser,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        generateCPMReport: (flag) => dispatch(actionCreators.generateCPMReport(flag)),
        runCPMReport: (dialogForm) => dispatch(actionCreators.runCPMReport(dialogForm)),

        // Tabs view 03/02/2023
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setControlName: (name) => dispatch(actionCreators.setControlName(name)),

        //Trying to get hospital control to work//
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        clearAddNewUserFlag: () => dispatch(actionCreators.clearAddNewUserFlag()),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CPMReport);