/* eslint-disable */
//Window Title: Generate Follow-up List and Letters
//Window height: 600px, width: 1250px
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actionCreators } from "../../store/PatientAbstract";
import Input from "../UI/Input";
import axios from "../../axios-instance";
import Draggable from 'react-draggable';
import "./GenFollowUpLetters.css";
import moment from "moment";
import * as dialogTypes from "../AbstractManagement/maintDialogs";
import PrintAndDownloadFU from "../AbstractManagement/PatientAbstract/Shared/PrintAndDownloadFU";

class GenFollowUpLetters extends Component {

    state = {
        initialDialogForm: null,
        dialogForm: null,
        isFormDirty: false,
        reload: false,
        pAllItemFields: null,
        // set max-length for input fields
        tableStructureList: null,
        pageMaxlengthInput: null,
        hasSetMaxLength: false,
        showMessageError: false,
        hasError: false,
        savedForm: false,
        fieldInFocus: null,
        focusingField: null,
        loaded: false,
        selectedCaseOption: "Date Range Entered Only",
        isCheckedAnalytic: true,
        selectedStateExport: "List"
    }

    selectionCases = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var dialogFormData = this.state.dialogForm;
        dialogFormData[name] = value;
        this.setState({
            selectedCaseOption: event.target.value,
            dialogForm: dialogFormData,
            isFormDirty: true,
        });
    }

    toggleChecked = (event) => {
        const name = event.target.name;
        var dialogFormData = this.state.dialogForm;
        if (name == "analyticCases") {
            const checked = this.state.isCheckedAnalytic;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedAnalytic: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "class00Cases") {
            const checked = this.state.isCheckedClass00;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedClass00: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "benignCases") {
            const checked = this.state.isCheckedBenign;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedBenign: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "basalAndSquamousCell") {
            const checked = this.state.isCheckedBasalAndSquamousCell;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedBasalAndSquamousCell: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "aliveExpired") {
            const checked = this.state.isCheckedAliveExpired;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedAliveExpired: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "updateFlag") {
            const checked = this.state.isCheckedUpdateFlag;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedUpdateFlag: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "createListPopulation") {
            const checked = this.state.isCheckedCreateListPopulation;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedCreateListPopulation: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        } else if (name == "createLettersPopulation") {
            const checked = this.state.isCheckedCreateLettersPopulation;
            dialogFormData[name] = !checked ? "True" : "False";
            this.setState({
                isCheckedCreateLettersPopulation: !checked,
                dialogForm: dialogFormData,
                isFormDirty: true,
            });
        }
    }

    handleOnFocus = (event) => {
        event.preventDefault();

        var selectedRowIndex = this.state.selectedItemRowIndex;
        if (event.target.getAttribute("data-index") != "0") {
            selectedRowIndex = event.target.getAttribute("data-index");
        }

        this.setState({
            fieldInFocus: event.target.name,
            focusingField: event.target,
            selectedItemRowIndex: selectedRowIndex
        });

        if ((event.target.name == "beginDate" || event.target.name == "endDate") && event.target.value == "Invalid date") {
            event.target.value = "";
            let dialogFormData = this.state.dialogForm;
            dialogFormData[event.target.name] = "";
            this.setState({ dialogForm: dialogFormData });
        }
        return false;
    }

    hideMessageError = (event) => {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }

        this.setState({
            showMessageError: false
        });
        this.props.setChildrenOpening(false);

        setTimeout(function () {
            document.querySelector(".Footer2>#btnSubmitForm_Fake") ? document.querySelector(".Footer2>#btnSubmitForm_Fake").focus() : "";
        }, 0);
        return false;
    }

    _componentDidUnmount() {
        this.props.loadFollowUpLetters();

        document.getElementById("startTime") ? document.getElementById("startTime").text = "" : null;
        document.getElementById("endTime") ? document.getElementById("endTime").text = "" : null;
    }

    componentWillMount() {
        this.props.setChildrenOpening(false);
        this.setState({ setEventHandlerForGrid: false, allowedHosps: null });
        this.initClock();
    }

    componentDidMount() {
        //document.title = "Generate Follow-up List and Letters";

        this.setState({ selectedItemRowIndex: 0 });

        this.props.setPatientMenu(true);
        this.props.setPageLoaded(false);

        // Add all tabs in maintDialogs.js
        dialogTypes.followUpMgtTabsDisplay.map((dialog, index) => {
            this.props.addTabItems(dialog.name, dialog.id);
            this.props.setEnabledStatus(dialog.name);
        });

        this.setState({ isLoading: true, loaded: false });//#End

        if (!this.state.dialogForm && this.props.followUpLettersInfo) {
            this.setFormData();
        }

        var childrenOpening = this.props.childrenOpening;
        this.props.setChildrenOpening(childrenOpening);

        setTimeout(function () {
            document.getElementById("BUTTON_NEWRECORD_FK") ? document.getElementById("BUTTON_NEWRECORD_FK").click() : null;
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.errorMessage) {
            this.setState({
                showClockTimer: false,
                showMessageError: true,
                dialogForm: {
                    ...this.state.dialogForm,
                    startTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.startTime ? this.props.selectedReportLabelsInfo.startTime : this.props.selectedReportLabelsInfo.startTimeSAP) : "",
                    endTime: this.props.selectedReportLabelsInfo ? (this.props.selectedReportLabelsInfo.endTime ? this.props.selectedReportLabelsInfo.endTime : this.props.selectedReportLabelsInfo.endTimeSAP) : "",
                    numberOfRecords: this.props.selectedReportLabelsInfo ? this.props.selectedReportLabelsInfo.numberOfRecords : "",
                },
                errorMessage: this.props.errorMessage,
            });

            document.querySelector('#BUTTON_RUN_REPORT') ? document.querySelector('#BUTTON_RUN_REPORT').focus() : null;

            this.props.clearErrorMessage();
            return false;
        }

        if ((!this.state.dialogForm && this.props.followUpLettersInfo)
            || (prevProps.followUpLettersInfo && this.props.followUpLettersInfo && prevProps.followUpLettersInfo.startTime !== this.props.followUpLettersInfo.startTime)
            || (prevProps.followUpLettersInfo && this.props.followUpLettersInfo && prevProps.followUpLettersInfo.endTime !== this.props.followUpLettersInfo.endTime)
            || (this.state.dialogForm && this.props.followUpLettersInfo && this.state.dialogForm.lastAccessTime !== this.props.followUpLettersInfo.lastAccessTime)
        ) {
            this.setFormData();
        }

        if (!this.state.getAvailableHosps) {
            this.getHospSecurity();
            this.setState({ getAvailableHosps: true });
        }

        //
        if (this.props.currentSelectedField != null && this.props.commonLookupItem && this.state.dialogForm && this.state.pAllItemFields) {
            if (this.props.commonLookupItem.code && this.props.commonLookupItem.code !== "") {
                this.setState({
                    pAllItemFields: { ...this.state.pAllItemFields, [this.props.currentSelectedField]: "" },
                    // And also update name again
                    dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: this.props.commonLookupItem.name },
                });
            } else {
                if (this.props.currentSelectedField == "letterTitle") {
                    this.setState({
                        // And also update name again
                        dialogForm: {
                            ...this.state.dialogForm,
                            [this.props.currentSelectedField]: "",
                            [this.props.currentSelectedField + "Name"]: ""
                        },
                    });
                } else {
                    this.setState({
                        // And also update name again
                        dialogForm: { ...this.state.dialogForm, [this.props.currentSelectedField + "Name"]: "" },
                    });
                }
            }
            this.props.clearCommonLookupItem();
        }
    }

    setFormData = () => {
        let dataForm = this.props.followUpLettersInfo;

        let fields = {};
        Object.keys(dataForm).map((t) => {
            fields[t] = "";
        });

        this.setState({
            initialDialogForm: fields,
            dialogForm: { ...dataForm },
            isFormDirty: false,
            reload: false,
            hasError: false,
            loaded: true,
            showClockTimer: false,
        });

        if (!this.state.pAllItemFields && this.props.followUpLettersInfo) {
            let _fields = {};
            Object.keys(this.props.followUpLettersInfo).map((t) => {
                _fields[t] = "";
            });

            this.setState({ pAllItemFields: _fields, pageMaxlengthInput: _fields });
        };

        // Processing output...
        if (dataForm.endTime && dataForm.endTime != "") {
            this.setState({
                isDrawingHtmlTable: dataForm.fnDisplay && dataForm.fnDisplay != "",
                Fn_Html_Display_URL: dataForm.htmlOutputOfList,
                selectedStateExport: "List",
                completed: false,
            });
        }
    }

    handleFormChange = (event) => {
        if (event.target2) {
            if (event.target2.name === "letterTitleName") {
                //Fill-in fields::Letter_Hosp<Hospital_Code
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value,
                        ["letterHosp"]: event.target2.value,
                    },
                    isFormDirty: true,
                });
                return false;
            } else if (event.target2.name === "hospitalCodeName") {
                this.setState({
                    dialogForm: {
                        ...this.state.dialogForm,
                        [event.target.name]: event.target.value,
                        [event.target2.name]: event.target2.value,
                    },
                    isFormDirty: true,
                });
                return false;
            }
        }

        this.setState({
            dialogForm: {
                ...this.state.dialogForm,
                [event.target.name]: event.target.value,
            },
            isFormDirty: true,
        });
    }

    handleLostFocus = (event) => {
        if (event.target.hasAttribute && event.target.hasAttribute('data-table') && event.target.value !== "") {
            let name = event.target.name;
            let value = event.target.value;
            let table = event.target.getAttribute('data-table');
            let key = event.target.getAttribute('data-key') ? event.target.getAttribute('data-key') : "0";
            let flag = event.target.getAttribute('data-flag') ? event.target.getAttribute('data-flag') : "0";
            let index = event.target.getAttribute('data-index') ? event.target.getAttribute('data-index') : "-1";

            var _this = this;
            setTimeout(function () {
                _this.props.checkValidInput(name, value, table, key, index, flag);
            }, 1000);
        } else {
            this.setState({ pAllItemFields: { ...this.state.pAllItemFields, [event.target.name]: "" } });

            if (event.target.name == "hospitalCode" && event.target.value == "") {
                document.getElementById("hospitalCodeName") ? document.getElementById("hospitalCodeName").innerHTML = "" : null;
            }
        }

        //
        var fName = event.target.name;
        var fVal = event.target.value;
        if (fName.indexOf("beginDate") != -1 || fName.indexOf("endDate") != -1) {
            let thisValue = fVal;
            let thisDate = this.Func_Date_Format(thisValue.replace(/[^0-9-/]/gi, ''));
            //if (thisDate != thisValue) {
                //Fill_Begin_Dates
                if (fName.indexOf("beginDate") != -1) {
                    if (fVal == "") {
                        this.setState({
                            dialogForm: {
                                ...this.state.dialogForm,
                                [fName]: thisDate ? moment(new Date(thisDate)).format("MM/DD/YYYY") : "",
                                "beginDate1moBack": "",
                                "beginDate2moBack": "",
                                "beginDate3moBack": "",
                            }
                        });
                    } else {
                        this.setState({
                            dialogForm: {
                                ...this.state.dialogForm,
                                [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY"),
                                "beginDate1moBack": moment(new Date(thisDate)).add(-1, 'M').format("MM/DD/YYYY"),
                                "beginDate2moBack": moment(new Date(thisDate)).add(-2, 'M').format("MM/DD/YYYY"),
                                "beginDate3moBack": moment(new Date(thisDate)).add(-3, 'M').format("MM/DD/YYYY"),
                            }
                        });
                    }
                } else {//Fill_End_Dates
                    if (fVal == "") {
                        this.setState({
                            dialogForm: {
                                ...this.state.dialogForm,
                                [fName]: thisDate ? moment(new Date(thisDate)).format("MM/DD/YYYY") : "",
                                "endDate1moBack": "",
                                "endDate2moBack": "",
                                "endDate3moBack": "",
                            }
                        });
                    } else if (moment(new Date(thisDate)).format("MM/DD/YYYY") === moment(new Date(thisDate)).endOf('month').format("MM/DD/YYYY")) {
                        this.setState({
                            dialogForm: {
                                ...this.state.dialogForm,
                                [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY"),
                                "endDate1moBack": moment(new Date(thisDate)).add(-1, 'M').endOf('month').format("MM/DD/YYYY"),
                                "endDate2moBack": moment(new Date(thisDate)).add(-2, 'M').endOf('month').format("MM/DD/YYYY"),
                                "endDate3moBack": moment(new Date(thisDate)).add(-3, 'M').endOf('month').format("MM/DD/YYYY"),
                            }
                        });
                    } else {
                        this.setState({
                            dialogForm: {
                                ...this.state.dialogForm,
                                [fName]: moment(new Date(thisDate)).format("MM/DD/YYYY"),
                                "endDate1moBack": moment(new Date(thisDate)).add(-1, 'M').format("MM/DD/YYYY"),
                                "endDate2moBack": moment(new Date(thisDate)).add(-2, 'M').format("MM/DD/YYYY"),
                                "endDate3moBack": moment(new Date(thisDate)).add(-3, 'M').format("MM/DD/YYYY"),
                            }
                        });
                    }
                }
            //}
        }
    }

    refreshFormFnc = () => {
        let fields = {};
        if (!this.state.pAllItemFields && this.props.followUpLettersInfo) {
            Object.keys(this.props.followUpLettersInfo).map((t) => {
                fields[t] = "";
            });
        };

        this.setState({
            dialogForm: {
                ...this.state.initialDialogForm,
                beginDate: "",
                endDate: ""
            },
            pAllItemFields: fields,
            isFormDirty: false,
            reload: false,
            isCheckedCreateLettersPopulation: false,
            isCheckedCreateListPopulation: false,
            isCheckedClass00: false,
            isCheckedBenign: false,
            isCheckedBasalAndSquamousCell: false,
            isCheckedAliveExpired: false,
            isCheckedUpdateFlag: false,
            selectedCaseOption: "Date Range Entered Only",
            isCheckedAnalytic: true,
        });

        //
        setTimeout(function () {
            document.getElementById("beginDate") ? document.getElementById("beginDate").value = "" : null;
            document.getElementById("beginDate") ? document.getElementById("beginDate").setAttribute('class', '') : null;
            document.getElementById("endDate") ? document.getElementById("endDate").value = "" : null;
            document.getElementById("endDate") ? document.getElementById("endDate").setAttribute('class', '') : null;
            document.getElementById("referenceYear") ? document.getElementById("referenceYear").focus() : null;
        }, 100);
    }

    closeWindow = (e) => {
        e.preventDefault();
        this.setState({
            browsedItem: "",
            browsedIndex: null,
            listFiles: null
        });
        return false;
    }

    Func_Date_Format = (myDate) => {
        if (myDate == "") return myDate;

        //This code tries to fill in slashes and the two digit century when a user doesn't enter them
        var fmtDate = "";

        if (myDate.length == 8 && myDate.indexOf('/') == -1) {
            fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/" + myDate.substr(4, 4);
        } else if (myDate.length == 8 && myDate.indexOf('/') >= 1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(6, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 6) + "19" + myDate.substr(6, 2);
            } else {
                fmtDate = myDate.substr(0, 6) + "20" + myDate.substr(6, 2);
            }
        } else if (myDate.length == 6 && myDate.indexOf('/') == -1) {
            var d = new Date().toLocaleDateString();
            if (myDate.substr(4, 2) > d.substr(d.length - 2, 2)) {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/19" + myDate.substr(4, 2);
            } else {
                fmtDate = myDate.substr(0, 2) + "/" + myDate.substr(2, 2) + "/20" + myDate.substr(4, 2);
            }
        } else {
            fmtDate = myDate;
        }

        return fmtDate;
    }

    initClock() {
        var _this = this;
        setInterval(function () {
            var today = new Date(),
                time = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");
            //d.toLocaleTimeString();
            _this.setState({ currentTime: time });
            return false;
        }, 1000);
    }

    getHospSecurity = () => {
        let url = this.props.clientgroup + "/GetUserByEmail";
        var _this = this;
        axios.post(url, { email: this.props.userId, accessToken: localStorage.getItem('token') })
            .then(res => {
                const availableHospsRpt = res.data.user !== null && res.data.user.availableHospsRpt !== null ? res.data.user.availableHospsRpt : "";
                if (!res.data.error && res.data.user !== null
                    && availableHospsRpt !== null && availableHospsRpt !== "") {
                    var _availableHospsRptArr = availableHospsRpt.split('|');
                    _availableHospsRptArr = _availableHospsRptArr.filter((obj, idx) => {
                        return obj && obj != "";
                    });
                    _this.setState({ allowedHosps: _availableHospsRptArr.join(',') });
                } else {
                    _this.setState({ allowedHosps: ",All," });//#Full control
                }
                return false;
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });
        return false;
    }

    validateHospFolLet() {
        var allowedHosps = this.state.allowedHosps ? this.state.allowedHosps : "";//{ dialog.Object }.getValue('Allowed_Hosps');
        var hosp = this.state.dialogForm.hospitalCode ? this.state.dialogForm.hospitalCode : "";//{ dialog.Object }.getValue('Hospital_Code');

        if ((allowedHosps.indexOf(',All,') > -1) || (allowedHosps.indexOf(hosp + ',') > -1 && hosp != '')) {
            return true;
        } else {
            return false;
        }
    }

    Follow_Up_Letters() {
        var dialogFormData = this.state.dialogForm;
        dialogFormData.userId = this.props.userId;
        dialogFormData.clientAlias = this.props.clientgroup;
        dialogFormData.userHosp = this.state.allowedHosps;

        /*
        let beginDate = moment(new Date(dialogFormData.beginDate)).format("YYYY-MM-DD");
        let endDate = dialogFormData.endDate && dialogFormData.endDate.trim() != "" ? moment(new Date(dialogFormData.endDate)).format("YYYY-MM-DD") : null;

        dialogFormData.beginDate = beginDate;
        dialogFormData.endDate = endDate;
        */

        //
        this.props.runFollowUpLetters(dialogFormData);

        this.downloadFile = false;//reset this flag to get download new file
        return false;
    }

    //on before show event
    showEvent(e) {
        try {
            e.preventDefault();
        } catch (ex) { }

        //Abstract Date that need validation adjustments.
        var dialogFormData = this.state.dialogForm;
        let beginDate = dialogFormData["beginDate"] ? dialogFormData["beginDate"] : (document.getElementById("beginDate") ? document.getElementById("beginDate").value : "");
        let endDate = dialogFormData["endDate"] ? dialogFormData["endDate"] : (document.getElementById("endDate") ? document.getElementById("endDate").value : "");
        if (beginDate && !moment(beginDate, "MM/DD/YYYY", true).isValid()) {
            alert('Please input a valid date');
            document.getElementById("beginDate") ? document.getElementById("beginDate").focus() : null;
            return false;
        } else if (endDate && !moment(endDate, "MM/DD/YYYY", true).isValid()) {
            alert('Please input a valid date');
            document.getElementById("endDate") ? document.getElementById("endDate").focus() : null;
            return false;
        }
     
        var _this = this;
        setTimeout(function () {
            _this.Follow_Up_Letters();
            document.getElementById("outputDataHandler") ? document.getElementById("outputDataHandler").value = "" : null;

            var today = new Date(),
                timeFrom = (today.getHours() < 12 ? today.getHours() : today.getHours() - 12)
                    + ':' + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes())
                    + ':' + (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds())
                    + " " + (today.getHours() < 12 ? "AM" : "PM");
            // set clock timer...
            _this.setState({ showClockTimer: true, startTimeFrom: timeFrom });
            return false;
        }, 200);

        //
        var sInVal = setInterval(function () {
            let titleDialog = _this.state.titleDialog;
            if (titleDialog && !(titleDialog === "|" || titleDialog === "/" || titleDialog === "--" || titleDialog === "\\")) {
                clearInterval(sInVal);
            } else {
                // show loading... |/--\|
                switch (titleDialog) {
                    case "|":
                        titleDialog = "/";
                        break;
                    case "/":
                        titleDialog = "--";
                        break;
                    case "--":
                        titleDialog = "\\";
                        break;
                    case "\\":
                        titleDialog = "|";
                        break;
                    default:
                        titleDialog = "|";
                        break;
                }
                _this.setState({ titleDialog: titleDialog });
            }
        }, 750);
        return false;
    }

    Check_Status = (e, _this) => {
        e.preventDefault();

        let url = this.props.clientgroup + "/CheckStatusFUL";
        axios.post(url, { accessToken: localStorage.getItem('token') })
            .then(res => {
                if (res && res.data && res.data.error) {
                    _this.setState({
                        showMessageError: true,
                        errorMessage: res.data.message
                    });
                } else if (res && res.data && res.data.fnDisplay) {
                    console.clear();
                    console.log("CheckStatusFUL::Completed");
                }
            })
            .catch(function (error) {
                console.log(error);
                return false;
            });
        return false;
    }

    Cancel_Report = (e, _this) => {
        e.preventDefault();
        _this.props.cancelSBSReport();
        setTimeout(function () {
            _this.setState({ showClockTimer: false });
            return false;
        }, 0);
        return false;
    }

    newRecord(e) {
        this.refreshFormFnc();
        return false;
    }

    exitScreen = (event) => {
        event.preventDefault();
        this.props.setInitialPageVisible(false);
        this.refreshFormFnc();
        return false;
    }

    javascript_void_fnc(event) {
        try {
            event ? event.preventDefault() : "";
        } catch (ex) { }
        return false;
    }

    hideDrawingHtmlTable = (e) => {
        try {
            e.preventDefault();
        } catch (ex) { }
        this.setState({ isDrawingHtmlTable: false });
        return false;
    }

    render() {
        let showMessageError;
        {
            showMessageError = this.state.showMessageError ? this.props.setChildrenOpening(true) &&
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div className="dragableSmallWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Notification</div>
                                <div className="k-window-actions k-dialog-actions">
                                    <a href="javascript:void(0)" role="button" aria-label="Close" className="k-button k-flat k-button-icon k-window-action k-dialog-action k-dialog-close">
                                        <span className="k-icon k-i-x" onClick={(e) => { this.hideMessageError(e) }}></span>
                                    </a>
                                </div>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container">
                                <label className="invalidMsgErrorCls" dangerouslySetInnerHTML={{ __html: this.state.errorMessage ? this.state.errorMessage : this.props.errorMessage }}></label>
                            </div>
                            <div className="emptyLine"></div>
                            <div className="container right">
                                <button onClick={(e) => { this.hideMessageError(e) }} className="validationErr_btnOk_Cls" autoFocus>OK</button>
                            </div>
                            <div className="emptyLine"></div>
                        </div>
                    </Draggable>
                </div>
                : null
        }

        let loading = (
            !this.state.loaded || this.props.waitingFor ? <div className="col-sm-12 showLoadingOnTopPageCls">Working...</div> : ""
        );

        var clockJs;
        {
            clockJs = this.state.showClockTimer ?
                <div className="CRStar_Window">
                    <div className="fake_popup_cls"></div>
                    <Draggable handle=".k-window-titlebar">
                        <div id="textEditorForm" className="dragableWindowCls">
                            <div className="k-window-titlebar k-dialog-titlebar k-header">
                                <div className="k-window-title k-dialog-title">Creating List and Letters - Please Wait&nbsp;&nbsp;&nbsp;{this.state.titleDialog}</div>
                                <div className="k-window-actions k-dialog-actions"></div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Start Time - {this.state.startTimeFrom}</label>
                                </div>
                                <div className="col-sm-6">
                                    <label>Ending Time - {this.state.currentTime}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 center">
                                    <button onClick={(e) => { this.Check_Status(e, this) }} className="ErsGrBlueButton" value="yes" autoFocus>Check Status</button>
                                    <button onClick={(e) => { this.Cancel_Report(e, this) }} className="ErsGrBlueButton" value="no">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                </div >
                : null;
        }

        const dialogFrm = <div className="limitedInGridCls">
            <div className={this.props.initialPageVisible ? "mainPage" : "hidden"}>
                {loading}
                <div className="parentWindow">
                    <div className="patientAbstractGridView" id="followUpLettersMainPage" style={{ height: "580px" }}>
                        {
                            this.state.dialogForm ?
                                <div className="container-fluid2">
                                    <form onSubmit={this.javascript_void_fnc} id="followUpLettersForm" className="followUpLettersForm">
                                        <div id="inputAreaCls">
                                            <div style={{ width: "100%" }}>
                                                <div className="_frameInner">
                                                    <div className="emptyLine"></div>
                                                    <div className="row">
                                                        <div className="col-sm-5">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        labelText="Reference Year" field="referenceYear" lineStyle="oneLine"
                                                                        labelWidth="95px" width="55px" autoFocus
                                                                        value={this.state.dialogForm.referenceYear}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["referenceYear"] : ""}
                                                                        inValid={this.state.pAllItemFields["referenceYear"]} />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-12" style={{ display: "flex" }}>
                                                                    <Input type="Hospital" focused={this.handleOnFocus} changed={this.handleFormChange}//Hosp_Nbr (C)|Equals any of|Allowed_hosps
                                                                        labelText="Hospital Code" field="hospitalCode" lostfocused={this.handleLostFocus}
                                                                        value={this.state.dialogForm.hospitalCode} codeLength="2"//Hospital_Code<Code,Hospital_Name<Description
                                                                        dataSource="Hospital" titleDialog="Hospital Lookup" labelWidth="95px" width="65px"
                                                                        inValid={this.state.pAllItemFields["hospitalCode"]} lineStyle="oneLine" itemNbr=""
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["hospitalCode"] : ""}
                                                                    />
                                                                    <Input type="label" changed={this.handleFormChange}
                                                                        labelText="" field="hospitalCodeName"
                                                                        value={this.state.dialogForm.hospitalCodeName}
                                                                        width="400px" labelWidth="10px" lineStyle="oneLine"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Begin Date" field="beginDate"//Follow_Up_Letters_Date_Format
                                                                        value={this.state.dialogForm.beginDate && this.state.dialogForm.beginDate != "Invalid date" ? this.state.dialogForm.beginDate : ""}
                                                                        maxLength="10" labelWidth="95px" width="110px"
                                                                        lineStyle="oneLine" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy"
                                                                        inValid={this.state.pAllItemFields["beginDate"]}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="End Date" field="endDate"//Follow_Up_Letters_Date_Format
                                                                        value={this.state.dialogForm.endDate && this.state.dialogForm.endDate != "Invalid date" ? this.state.dialogForm.endDate : ""}//Fill_End_Dates
                                                                        maxLength="10" labelWidth="80px" width="110px"
                                                                        lineStyle="oneLine" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy"
                                                                        inValid={this.state.pAllItemFields["endDate"]}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row" style={{ visibility: this.state.selectedCaseOption === "Use Enhanced Follow Up" ? "" : "hidden" }}>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="+" field="beginDate1moBack"//show/hide::if(Repeat_Date_Range = "Use Enhanced Follow Up",1=1,1=2)
                                                                        value={this.state.dialogForm.beginDate1moBack}//Begin_Date_1Mo_Back
                                                                        maxLength="10" labelWidth="95px" width="95px"
                                                                        lineStyle="oneLine center" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy" noneColon disabled
                                                                        inValid={this.state.pAllItemFields["beginDate1moBack"]}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="to" field="endDate1moBack"//show/hide::if(Repeat_Date_Range = "Use Enhanced Follow Up",1=1,1=2)
                                                                        value={this.state.dialogForm.endDate1moBack}//End_Date_1Mo_Back
                                                                        maxLength="10" labelWidth="80px" width="100px"
                                                                        lineStyle="oneLine center" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy" noneColon disabled
                                                                        inValid={this.state.pAllItemFields["endDate1moBack"]}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ visibility: this.state.selectedCaseOption === "Use Enhanced Follow Up" ? "" : "hidden" }}>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="+" field="beginDate2moBack"//show/hide::if(Repeat_Date_Range = "Use Enhanced Follow Up",1=1,1=2)
                                                                        value={this.state.dialogForm.beginDate2moBack}//Begin_Date_2Mo_Back
                                                                        maxLength="10" labelWidth="95px" width="95px"
                                                                        lineStyle="oneLine center" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy" noneColon disabled
                                                                        inValid={this.state.pAllItemFields["beginDate2moBack"]}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="to" field="endDate2moBack"//show/hide::if(Repeat_Date_Range = "Use Enhanced Follow Up",1=1,1=2)
                                                                        value={this.state.dialogForm.endDate2moBack}//End_Date_2Mo_Back
                                                                        maxLength="10" labelWidth="80px" width="100px"
                                                                        lineStyle="oneLine center" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy" noneColon disabled
                                                                        inValid={this.state.pAllItemFields["endDate2moBack"]}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ visibility: this.state.selectedCaseOption === "Use Enhanced Follow Up" ? "" : "hidden" }}>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="+" field="beginDate3moBack"//show/hide::if(Repeat_Date_Range = "Use Enhanced Follow Up",1=1,1=2)
                                                                        value={this.state.dialogForm.beginDate3moBack}//Begin_Date_3Mo_Back
                                                                        maxLength="10" labelWidth="95px" width="95px"
                                                                        lineStyle="oneLine center" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy" noneColon disabled
                                                                        inValid={this.state.pAllItemFields["beginDate3moBack"]}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <Input type="input" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="to" field="endDate3moBack"//show/hide::if(Repeat_Date_Range = "Use Enhanced Follow Up",1=1,1=2)
                                                                        value={this.state.dialogForm.endDate3moBack}//End_Date_3Mo_Back
                                                                        maxLength="10" labelWidth="80px" width="100px"
                                                                        lineStyle="oneLine center" itemNbr="" labelStyle=""
                                                                        lostfocused={this.handleLostFocus}
                                                                        placeholder="mm/dd/yyyy" noneColon disabled
                                                                        inValid={this.state.pAllItemFields["endDate3moBack"]}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row" style={{ visibility: this.state.selectedCaseOption !== "Use Enhanced Follow Up" ? "" : "hidden"}}>
                                                                <div className="col-sm-12">
                                                                    <Input type="codeSelect" focused={this.handleOnFocus} changed={this.handleFormChange}
                                                                        labelText="Letter" field="letterTitle" lineStyle="oneLine"//show/hide::if(Repeat_Date_Range <> "Use Enhanced Follow Up",1=1,1=2)
                                                                        value={this.state.dialogForm.letterTitle} labelWidth="95px" width="125px"
                                                                        inValid={this.state.pAllItemFields["letterTitle"]}
                                                                        lostfocused={this.handleLostFocus}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["letterTitle"] : ""}
                                                                        dataSource="Letters_Fu" titleDialog=""//Filter::Letter_Type  = 'Pat'  OR Letter_Type  = 'Con'  OR Letter_Type  = 'QA'  OR Letter_Type  = 'Doc'
                                                                        shownType="dropdown" keyId="letterTitle0" rowIndex="0" codeLabel="Letter"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ visibility: this.state.selectedCaseOption !== "Use Enhanced Follow Up" ? "" : "hidden" }}>
                                                                <div className="col-sm-12">
                                                                    <Input type="input" changed={this.handleFormChange}
                                                                        focused={this.handleOnFocus} lostfocused={this.handleLostFocus}
                                                                        labelText="Fu Doctor Nbr" field="fuDocNum" lineStyle="oneLine"
                                                                        labelWidth="95px" width="40px" //show/hide::Repeat_Date_Range <> 'Use Enhanced Follow Up'
                                                                        value={this.state.dialogForm.fuDocNum}
                                                                        maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["fuDocNum"] : ""}
                                                                        inValid={this.state.pAllItemFields["fuDocNum"]} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-5">
                                                            <div className="row">
                                                                <div className="" style={{ marginLeft: "-5px" }}>
                                                                    <label style={{ width: "250px", height: "0px" }}>
                                                                        <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Date Range Entered Only"}
                                                                            name="repeatDateRange" field="repeatDateRange" value="Date Range Entered Only"
                                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-10px" }} />
                                                                        Date Range Entered Only</label>
                                                                    <br />
                                                                    <label style={{ width: "250px", height: "0px" }}>
                                                                        <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Repeat for All Years Prior to End Date"}
                                                                            name="repeatDateRange" field="repeatDateRange" value="Repeat for All Years Prior to End Date"
                                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-10px" }} />
                                                                        Repeat for All Years Prior to End Date</label>
                                                                    <br />
                                                                    <label style={{ width: "250px", height: "0px" }}>
                                                                        <input type="radio" onClick={this.selectionCases} checked={this.state.selectedCaseOption === "Use Enhanced Follow Up"}
                                                                            name="repeatDateRange" field="repeatDateRange" value="Use Enhanced Follow Up"
                                                                            style={{ cursor: "pointer", fontWeight: "bold", width: "50px", marginRight: "-10px" }} />
                                                                        Use Enhanced Follow Up</label>
                                                                    <br />
                                                                </div>
                                                            </div>

                                                            <br/>
                                                            <div className="row">
                                                                <div className="col-sm-12 checkboxTypeCls">
                                                                    <Input type="checkbox" lineStyle="oneLine"
                                                                        labelText="Analytic 10-22 Cases Only" field="analyticCases"
                                                                        labelWidth="200px" labelStyle="lblOnCheckbox" width="40px"
                                                                        value={this.state.isCheckedAnalytic ? "True" : "False"}
                                                                        checked={this.state.isCheckedAnalytic}
                                                                        clicked={this.toggleChecked}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12 checkboxTypeCls" style={{ visibility: this.state.isCheckedAnalytic ? "" : "hidden" }}>
                                                                    <Input type="checkbox" lineStyle="oneLine"
                                                                        labelText="Include Class 00" field="class00Cases"//show/hide::Analytic_Cases = true
                                                                        labelWidth="200px" labelStyle="lblOnCheckbox" width="40px"
                                                                        value={this.state.isCheckedClass00 ? "True" : "False"}
                                                                        checked={this.state.isCheckedClass00}
                                                                        clicked={this.toggleChecked}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12 checkboxTypeCls">
                                                                    <Input type="checkbox" lineStyle="oneLine"
                                                                        labelText="Include Benign Cases" field="benignCases"
                                                                        labelWidth="200px" labelStyle="lblOnCheckbox" width="40px"
                                                                        value={this.state.isCheckedBenign ? "True" : "False"}
                                                                        checked={this.state.isCheckedBenign}
                                                                        clicked={this.toggleChecked}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-12 checkboxTypeCls">
                                                                    <Input type="checkbox" lineStyle="oneLine"
                                                                        labelText="Include Basal and Squamous Cell Ca" field="basalAndSquamousCell"
                                                                        labelWidth="210px" labelStyle="lblOnCheckbox" width="40px"
                                                                        value={this.state.isCheckedBasalAndSquamousCell ? "True" : "False"}
                                                                        checked={this.state.isCheckedBasalAndSquamousCell}
                                                                        clicked={this.toggleChecked}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12 checkboxTypeCls">
                                                                    <Input type="checkbox" lineStyle="oneLine"
                                                                        labelText="Include Expired Cases" field="aliveExpired"
                                                                        labelWidth="200px" labelStyle="lblOnCheckbox" width="40px"
                                                                        value={this.state.isCheckedAliveExpired ? "True" : "False"}
                                                                        checked={this.state.isCheckedAliveExpired}
                                                                        clicked={this.toggleChecked}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-12 checkboxTypeCls">
                                                                    <Input type="checkbox" lineStyle="oneLine"
                                                                        labelText="Update Letters Sent Flag" field="updateFlag"
                                                                        labelWidth="200px" labelStyle="lblOnCheckbox" width="40px"
                                                                        value={this.state.isCheckedUpdateFlag ? "True" : "False"}
                                                                        checked={this.state.isCheckedUpdateFlag}
                                                                        clicked={this.toggleChecked}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="emptyLine"></div>
                                                    <div className="row">
                                                        <div className="col-sm-5">
                                                            <fieldset style={{ height: "108px", width: "auto" }}>
                                                                <legend>Create Population Labels</legend>
                                                                <div>
                                                                    <div className="row">
                                                                        <div className="col-sm-5 checkboxTypeCls">
                                                                            <Input type="checkbox" lineStyle="oneLine"
                                                                                labelText="Based on Follow-Up List" field="createListPopulation"
                                                                                labelWidth="200px" labelStyle="lblOnCheckbox" width="30px"
                                                                                value={this.state.isCheckedCreateListPopulation ? "True" : "False"}
                                                                                checked={this.state.isCheckedCreateListPopulation}
                                                                                clicked={this.toggleChecked}
                                                                            />
                                                                        </div>
                                                                        <div className="col-sm-7" style={{ visibility: this.state.isCheckedCreateListPopulation ? "" : "hidden" }} >
                                                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                changed={this.handleFormChange} width="250px"//show/hide::if(Create_List_Population = true,1=1,1=2)
                                                                                labelText="List Population Label" labelText=""//Display::Label(Field to return),Request_Date,Requested_By,Number_Of_Records
                                                                                field="listPopLabel" lineStyle="oneLine"//Fill-in fields::Number_of_Pop_Records<Number_Of_Records
                                                                                value={this.state.dialogForm.listPopLabel}
                                                                                dataSource="Labels" shownType="dropdown" keyId="listPopLabel0" rowIndex="0"
                                                                                titleDialog="" labelWidth="0px"
                                                                                inValid={this.state.pAllItemFields["listPopLabel"]}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["listPopLabel"] : ""}
                                                                                lostfocused={this.handleLostFocus}
                                                                            />
                                                                            <input type="hidden" id="populationLabelHidden" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-sm-5 checkboxTypeCls">
                                                                            <Input type="checkbox" lineStyle="oneLine"
                                                                                labelText="Based on Letters Generated" field="createLettersPopulation"
                                                                                labelWidth="200px" labelStyle="lblOnCheckbox" width="30px"
                                                                                value={this.state.isCheckedCreateLettersPopulation ? "True" : "False"}
                                                                                checked={this.state.isCheckedCreateLettersPopulation}
                                                                                clicked={this.toggleChecked}
                                                                            />
                                                                        </div>
                                                                        <div className="col-sm-7" style={{ visibility: this.state.isCheckedCreateLettersPopulation ? "" : "hidden" }} >
                                                                            <Input type="codeSelect" focused={this.handleOnFocus}
                                                                                changed={this.handleFormChange} width="250px"//show/hide::if(Create_Letters_Population = true,1=1,1=2)
                                                                                labelText="Letters Population Label" labelText=""//Display::Label(Field to return),Request_Date,Requested_By,Number_Of_Records
                                                                                field="lettersPopLabel" lineStyle="oneLine"//Fill-in fields::Number_of_Pop_Records<Number_Of_Records
                                                                                value={this.state.dialogForm.lettersPopLabel}
                                                                                dataSource="Labels" shownType="dropdown" keyId="lettersPopLabel0" rowIndex="0"
                                                                                titleDialog="" labelWidth="0px"
                                                                                inValid={this.state.pAllItemFields["lettersPopLabel"]}
                                                                                maxLength={this.state.pageMaxlengthInput ? this.state.pageMaxlengthInput["lettersPopLabel"] : ""}
                                                                                lostfocused={this.handleLostFocus}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="emptyLine"></div>

                                    <div className="emptyLine"></div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", marginLeft: "15px", paddingTop: "2px" }}
                                                labelText="Start Time" field="startTime" width="auto"
                                                value={this.state.dialogForm.startTime ? this.state.dialogForm.startTime : "-"}
                                            />
                                        </div>
                                        <div className="col-sm-9">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", paddingTop: "2px" }}
                                                labelText="End Time" field="endTime" width="auto"
                                                value={this.state.dialogForm.endTime ? this.state.dialogForm.endTime : "-"}
                                            />
                                        </div>

                                        <div className="col-sm-6 hidden">
                                            <Input type="label" labelWidth="auto" style={{ fontWeight: "bold", borderStyle: "none", paddingTop: "2px" }}
                                                labelText="Records Processed" field="numberOfRecords" width="auto"
                                                value={this.state.dialogForm.numberOfRecords ? this.state.dialogForm.numberOfRecords : "0"}
                                            />
                                        </div>

                                    </div>
                                    <fieldset className="bottomAreaButtonCls">
                                        <legend></legend>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_RUN_REPORT"
                                                        //enable expression::if(validateHospFolLet() = true And Reference_Year <> '' And Begin_Date <> '',1=1,1=2)
                                                        className={(this.state.dialogForm.referenceYear && this.state.dialogForm.beginDate && this.validateHospFolLet()) ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        onClick={(e) => { this.showEvent(e) }}
                                                        //onMouseOver={(e) => { this.mouseOverEventOnBtn(e) }}
                                                        disabled={(this.state.dialogForm.referenceYear && this.state.dialogForm.beginDate && this.validateHospFolLet()) ? false : true} >
                                                        <img id="BUTTON_RUN_REPORT_ICON"
                                                            src={(this.state.dialogForm.referenceYear && this.state.dialogForm.beginDate && this.validateHospFolLet()) ? "https://crstar.ers-can.com/images/$$code.action.run.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$code.action.run.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Run
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_NEWRECORD"
                                                        className={this.state.isFormDirty ? "ErsGrBlueButton" : "ErsGrBlueButton ScreenButtonDisabled"}
                                                        disabled={this.state.isFormDirty ? false : true}
                                                        onClick={(e) => { this.newRecord(e) }} >
                                                        <img id="BUTTON_NEWRECORD_ICON"
                                                            src={this.state.isFormDirty ? "https://crstar.ers-can.com/images/$$navigate.new.png.a5image" : "https://crstar.ers-can.com/images/disabled/$$navigate.new.png.a5image"}
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        New
                                                    </button>
                                                </div>

                                                <div className="hidden">
                                                    <button id="BUTTON_NEWRECORD_FK" onClick={(e) => { this.newRecord(e) }} ></button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button id="BUTTON_EXIT"
                                                        className="ErsGrBlueButton"
                                                        onClick={(e) => { this.exitScreen(e) }} >
                                                        <img id="BUTTON_EXIT_ICON"
                                                            src="https://crstar.ers-can.com/images/$$window.controlPanel.close.png.a5image"
                                                            style={{ verticalAlign: "middle", border: "none" }}></img>
                                                        Exit
                                                    </button>
                                                </div>

                                                <div className="A5CWLayout">
                                                    <button type="button" id="reportDefaultBtn" className="hidden" title=""
                                                        onClick={(e) => { this.defaultRadio(e) }} value="default" autoFocus>
                                                            Default
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                : <p>Generate Follow-up List and Letters data loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>;

        return (
            <React.Fragment>
                <React.Fragment>
                    {showMessageError}
                </React.Fragment>
                <React.Fragment>
                    {clockJs}
                </React.Fragment>
                <React.Fragment>
                    {/*drawingHtmlTable*/}
                </React.Fragment>
                <React.Fragment>
                    {dialogFrm}
                </React.Fragment>
                <React.Fragment>
                    {
                        this.state.isDrawingHtmlTable && this.props.previewing && <PrintAndDownloadFU
                            pTitle="Follow-Up Letters"
                            reportDataInfo={this.props.followUpLettersInfo}
                            closeWindow={this.hideDrawingHtmlTable}
                        />
                    }
                </React.Fragment>
            </React.Fragment>
        );
    }
}

GenFollowUpLetters.propTypes = {
    // This function is passed down to the Footer so that its Exit button can close this Maintenance's parent Window
    // And is expected to be passed in from this component's parent
    closeWindow: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        followUpLettersInfo: state.patientAbstract.followUpLettersInfo,
        isDialogLoading: state.patientAbstract.isDialogLoading,
        childrenOpening: state.patientAbstract.childrenOpening,
        isFormDirty: state.patientAbstract.isFormDirty,
        error: state.patientAbstract.error,
        errorMessage: state.patientAbstract.errorMessage,
        currentSelectedField: state.patientAbstract.currentSelectedField,
        commonLookupItem: state.patientAbstract.commonLookupItem,
        currentSelectedIndex: state.patientAbstract.currentSelectedIndex,
        clientgroup: state.auth.clientgroup,
        deletedDataSuccess: state.patientAbstract.deletedDataSuccess,
        initialPageVisible: state.patientAbstract.initialPageVisible,
        waitingFor: state.patientAbstract.waitingFor,
        selectedHospital: state.patientAbstract.selectedHospital,
        user_id: state.auth.userId,
        userId: state.auth.userId,
        showReport: state.patientAbstract.showReport,
        isDownloadFile: state.patientAbstract.isDownloadFile,
        previewing: state.patientAbstract.previewing,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        runFollowUpLetters: (dialogForm) => dispatch(actionCreators.runFollowUpLetters(dialogForm)),
        clearErrorMessage: () => dispatch(actionCreators.clearErrorMessage()),
        setChildrenOpening: (value) => dispatch(actionCreators.setChildrenOpening(value)),
        checkValidInput: (field, value, table, key, index, flag) => dispatch(actionCreators.checkValidInput(field, value, table, key, index, flag)),
        clearCommonLookupItem: () => dispatch(actionCreators.clearCommonLookupItem()),
        resetDeletedDataSuccess: (value) => dispatch(actionCreators.resetDeletedDataSuccess(value)),
        storeToGlobal: (fName) => dispatch(actionCreators.storeToGlobal(fName)),
        setHandlerControlItem: (item) => dispatch(actionCreators.setHandlerControlItem(item)),
        showPageLoading: (flag) => dispatch(actionCreators.showPageLoading(flag)),
        showSessionTimeoutMsg: (flag) => dispatch(actionCreators.showSessionTimeoutMsg(flag)),
        setInitialPageVisible: (flag) => dispatch(actionCreators.setInitialPageVisible(flag)),
        // Tabs view
        setEnabledStatus: (dialogType, flag) => dispatch(actionCreators.setEnabledStatus(dialogType, flag)),
        setTab: (dialogType) => dispatch(actionCreators.setCurrentDialog(dialogType)),
        setChildDialogVisible: (flg) => dispatch(actionCreators.setChildDialogVisible(flg)),
        setParentWindowVisible: (flag) => dispatch(actionCreators.setParentWindowVisible(flag)),
        routingPage: (dialogType) => dispatch(actionCreators.setRoutingPage(dialogType)),
        addTabItems: (dialogType, id) => dispatch(actionCreators.addTabItems(dialogType, id)),
        setPatientMenu: (value) => dispatch(actionCreators.setPatientMenu(value)),
        setPageLoaded: (value) => dispatch(actionCreators.setPageLoaded(value)),
        setChildDialog: (dialogType) => dispatch(actionCreators.setChildDialog(dialogType)),
        showOrHideLoading: (flag) => dispatch(actionCreators.showOrHideLoading(flag)),
        loadFollowUpLetters: () => dispatch(actionCreators.loadFollowUpLetters()),
        cancelSBSReport: () => dispatch(actionCreators.cancelSBSReport()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenFollowUpLetters);